<div class="form-header">
  <div style="align-self: center;">
    <span class="form-title-main">BOL files</span>
  </div>
  <div>
    <i class="ic-right-close clickable" nz-icon nzType="close" nzTheme="outline" (click)="onClose()"></i>
  </div>
</div>
<div class="form-body">
  <div>
    <div class="file-item" *ngFor="let item of items;let i = index">
      <div class="image">
        <a (click)="viewFileItem(item)">
          <img *ngIf="!item.isPdf" class="attached-pod" nz-image width="100px" [nzSrc]="item.imgUrl" alt=""
            [nzDisablePreview]="true" [nzFallback]="fallbackImg" />
          <div *ngIf="item.isPdf" class="attached-pod pdf overlay-container">
            <canvas id="file-{{i}}" width="100px"></canvas>
          </div>
        </a>
      </div>
      <div>
        <nz-space>
          <button *nzSpaceItem nz-button nzType="default" (click)="onBtnDownload(item)"
            [nzLoading]="item?.isDownloading" style="border: none">
            <i nz-icon nzType="download" nzTheme="outline"></i>
          </button>
          <button *nzSpaceItem nz-button nzType="default" (click)="onBtnDelete(item)" style="border: none">
            <span nz-icon nzType="delete" nzTheme="outline"></span>
          </button>
        </nz-space>
      </div>
    </div>
  </div>
  <div>
    <button nz-button nzShape="round" nzType="dashed" (click)="inputFile.click()" [nzLoading]="isUploading">
      <i nz-icon nzType="upload" nzTheme="outline"></i>Upload
    </button>
    <input multiple #inputFile type="file" hidden accept=".pdf,.png,.jpg,.jpeg" (change)="onFileSelected(inputFile.files)" id="uploadBOLFilesInput">
  </div>
</div>


import { Component, Input } from '@angular/core';
import { BaseDetail } from '@app/admin/base/detail';
import { ActivatedRoute } from '@angular/router';
import { Const } from '@const/Const';

@Component({
  selector: 'crossdock-warehouse-table',
  templateUrl: './view.html',
  styleUrls: ['./style.scss']
})
export class CrossdockWarehouseTable extends BaseDetail {
  protected get crudEntity(): string { return 'crossdock-warehouse-table' }

  public listInventoryOnTable = []; //list inventory hiển thị trên table mode view
  public isLoadingListInventoryOnTable //loading khi get list warehouse hiển thị trên table view

  private _warehouseId: string;

  @Input() set warehouseId(value: string) {
     this._warehouseId = value;
     this.getListInventoryOnTable(this._warehouseId);
  }
  
  get warehouseId(): string {
    return this._warehouseId;
  }
  constructor(protected activatedRoute: ActivatedRoute) {
    super(activatedRoute);
  }

  handleNavigationEnd() {
  }

  ngOnInit(): void {
    super.ngOnInit();
  }
  async getListInventoryOnTable(warehouseId, keyword = undefined) {
    if (!warehouseId) return
    this.isLoadingListInventoryOnTable = true;
    const url = `${Const.APIURI_INVENTORIES}/get_list_inventory_on_table`;
    const params = {warehouseId: warehouseId, keyword: keyword}
    const resp = await this.api.POST(url, params).toPromise().catch(e => {
      this.showErr(`${e.message}`)
      this.listInventoryOnTable = []
      this.isLoadingListInventoryOnTable = false;
    });
    this.listInventoryOnTable = resp?.data?.list_data
    this.isLoadingListInventoryOnTable = false;
  }

  getLinkToShipmentDetail(item){
    return `${this.routeAdminShipmentList}/${item?.orderId}`
  }

  async doSearch(keyword) {
    this.getListInventoryOnTable(this.warehouseId, keyword)
  }

  get naText(){
    return 'N/A'
  }
}

<ng-container *ngIf="isLoading">
  <span nz-icon nzType="loading" nzTheme="outline"></span>
</ng-container>
<ng-container *ngIf="!isLoading">
  <ng-container *ngIf="viewTemplate">
    <ng-container *ngTemplateOutlet="viewTemplate; context: { hasData, displayInfo }"></ng-container>
  </ng-container>
  
  <ng-container *ngIf="!viewTemplate">
    <warp-when-by-template-default [displayInfo]="displayInfo"></warp-when-by-template-default>
  </ng-container>
</ng-container>

<ng-template [nzModalTitle]>
  <div class="flex-space-between">
    <div>Update Optional Equipment</div>
    <div>
      <span nz-icon nzType="close" nzTheme="outline" class="clickable" (click)="closeDialog()"></span>
    </div>
  </div>
</ng-template>

<form class="form-detail" nz-form>
  <nz-form-item>
    <nz-form-control>
      <nz-form-control>
        <div [mode]="'multiple'" vehicle-selector [value]="optionalEquipments" (valueChange)="onDropdownSelectChange('optionalEquipments', $event)" [quoting]="false"></div>
      </nz-form-control>
    </nz-form-control>
  </nz-form-item>
</form>

<div form-footer class="no-border"
  [canClickOK]="needUpdate" 
  [canClickCancel]="true"
  [onProgress]="onProgress"
  [nzIconOK]="false" [nzIconCancel]="false" labelOK="Update" nzOkDanger="false" (onOK)="onBtnSave()"
  (onCancel)="closeDialog()">
</div>

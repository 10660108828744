import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Const } from '@const/Const';
import { DateUtil } from '@services/date-utils';

@Component({
  selector: 'address-str',
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})
export class Address extends Component  {
    _data: { address: any, format?: string} = null

    @Input() set data(v) {
        this._data = v

        this.display = v.time ? DateUtil.displayLocalTime(v.time, {
            format: v.format ?? Const.FORMAT_GUI_DATETIME_V5,
            timezone: v.tz
        }) : null
    }

    display: string | null = null    
}
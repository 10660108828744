<div class="dashboard-child-container no-padding">
  <div *ngIf="shouldShowFirstLoading" class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
  <form class="form-detail" [formGroup]="formInput" nz-form *ngIf="shouldShowForm">

    <!-- Group 1: Customer info -->
    <div class="group no-padding">
      <div class="group-header">Customer Information
        <i *ngIf="shouldShowHistory" style="margin-left: 10px;" class="clickable"
        nz-icon nzType="history" nzTheme="outline"
        nz-tooltip nzTooltipTitle="View Changes History" (click)="goToHistory(model, routeAdminClientList)"></i>
      </div>
      <div class="group-content">
        <div class="tdm-row cell-spacing">
          <div class="tdm-col tdm-col-2" *ngFor="let key of ['name', 'website']">
            <div>
              <div class="form-label-v2">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
              <nz-form-item>
                <nz-form-control>
                  <input nz-input [formControlName]="key"
                    [type]="getInputType(key)"
                    [placeholder]="getPlaceHolder(key)"
                    (input)="onInputChanged($event, key)">
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </div>

        <ng-container *ngFor="let key of ['address']">
          <div class="form-label-v2">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
          <nz-form-item>
            <nz-form-control>
              <form-address [formControlName]="key"></form-address>
            </nz-form-control>
          </nz-form-item>
        </ng-container>

        <button nz-button *ngIf="isEditing" (click)="onBtnAddLocation()">Add Location</button>
      </div>
    </div>

    <!-- Group: Parent Company -->
    <div class="group no-padding" *ngIf="isCreateSubAccount()">
      <div class="group-header">Parent Company</div>
      <div class="group-content">
        <div class="tdm-row cell-spacing">
          <div class="tdm-col tdm-col-2" *ngFor="let key of ['parentClientId']">
            <div>
              <div class="form-label-v2">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
              <nz-form-item>
                <nz-form-control>
                  <select-by-searching [formControlName]="key" [version]="2" [placeholder]="getPlaceHolder(key)"></select-by-searching>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Group 2: Contact info -->
    <div class="group no-padding">
      <div class="group-header">Contact Information</div>
      <div class="group-content">
        <ng-container *ngFor="let groupKey of ['primaryContact', 'payableContact']">
          <form *ngIf="!isHidden(groupKey)" nz-form [formGroupName]="groupKey">
            <div class="tdm-row cell-spacing">
              <div class="tdm-col tdm-col-5" *ngFor="let key of ['firstName', 'lastName', 'phone', 'email', 'slackId']">
                <div>
                  <div class="form-label-v2">{{getLabel(fullKey(groupKey, key))}}<span *ngIf="isRequired(fullKey(groupKey, key))" class="label-mark-required"></span></div>
                  <ng-container [ngSwitch]="key">
                    <div *ngSwitchCase="'phone'" [ngClass]="key">
                      <nz-form-item>
                        <nz-form-control>
                          <nz-input-group nzCompact>
                            <input nz-input [formControlName]="key"
                              [type]="getInputType(fullKey(groupKey, key))"
                              [placeholder]="getPlaceHolder(fullKey(groupKey, key))"
                              (input)="onInputChanged($event, key)"
                              (keypress)="onInputKeyPress($event, key)"
                              style="width: 70%;"
                            >

                            <input nz-input [formControlName]="'phoneExtension'"
                              [type]="getInputType(fullKey(groupKey, 'phoneExtension'))"
                              [placeholder]="getPlaceHolder(fullKey(groupKey, 'phoneExtension'))"
                              (input)="onInputChanged($event, 'phoneExtension')"
                              (keypress)="onlyNumberKey($event)"
                              style="width: 30%;"
                            >
                          </nz-input-group>
                        </nz-form-control>
                      </nz-form-item>
                    </div>

                    <div *ngSwitchDefault [ngClass]="key">
                      <nz-form-item>
                        <nz-form-control>
                          <input nz-input [formControlName]="key"
                            [type]="getInputType(fullKey(groupKey, key))"
                            [placeholder]="getPlaceHolder(fullKey(groupKey, key))"
                            (input)="onInputChanged($event, key)"
                            (keypress)="onInputKeyPress($event, key)" >
                            <div *ngIf="groupKey == 'payableContact' && key == 'email'" class="hint-payable-email">Invoices will be sent to this contact.</div>
                        </nz-form-control>
                      </nz-form-item>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
            <div class="tdm-row cell-spacing" *ngIf="groupKey=='primaryContact'">
              <div class="tdm-col">
                <nz-form-item>
                  <nz-form-control>
                    <div nz-checkbox *ngFor="let key of ['isPayableContact']" [formControlName]="key"
                      (ngModelChange)="onCheckBoxPayableContactChange($event)">{{getLabel(fullKey(groupKey, key))}}</div>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
          </form>
        </ng-container>
      </div>
    </div>

    <!-- Group 3: Payments info -->
    <div class="group no-padding">
      <div class="group-header flex">
        <div>Payments Information</div>
        <!-- <div *ngIf="(isEditing || isCreateNew) && (!isExistPaymentInfo)" class="left10">
          <button nz-button (click)="addItemToFormArray('payments')">Add bank information</button>
        </div> -->
      </div>
      <div class="group-content">
        <ng-container *ngIf="'paymentTerm' as key">
          <div>{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
          <nz-form-item>
            <nz-form-control>
              <nz-select style="width: 200px;" nzBackdrop="true" nzAllowClear
                [formControlName]="key" [nzPlaceHolder]="getPlaceHolder(key)">
                <nz-option *ngFor="let item of allPaymentTerms" [nzLabel]="getPaymentTermName(item)" [nzValue]="item"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </ng-container>

        <div *ngIf="!isExistPaymentInfo">
          <button style="width: 200px;" nz-button (click)="addItemToFormArray('payments')">Add bank information</button>
        </div>
        <ng-container *ngFor="let groupKey of ['payments'];" [formArrayName]="groupKey">
          <form nz-form *ngFor="let item of getArrayControls(groupKey); let i = index" [formGroupName]="i">
            <div nz-row [nzGutter]="12">
              <div nz-col nzMd="23">
                <div class="tdm-row cell-spacing">
                  <div class="tdm-col tdm-col-5" *ngFor="let key of ['bankName', 'bankRoutingNumber', 'bankAccountNumber', 'ein', 'ssn']">
                    <div class="form-label-v2">
                      {{getLabel(fullKey(groupKey, key))}}
                      <span *ngIf="isRequired(fullKey(groupKey, key))" class="label-mark-required"></span>
                    </div>
                    <nz-form-item>
                      <nz-form-control>
                        <input nz-input [formControlName]="key"
                          [type]="getInputType(fullKey(groupKey, key))"
                          [placeholder]="getPlaceHolder(fullKey(groupKey, key))"
                          (input)="onInputChanged($event, key)"
                          (keypress)="onInputKeyPress($event, key)" >
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                </div>
              </div>
              <div nz-col nzMd="1">
                <div class="form-label-v2">&nbsp;</div>
                <nz-form-item>
                  <nz-form-control>
                    <nz-space class="group-button">
                      <ng-container *ngIf="!isAdminReadOnlyRole && (isCreateNew || isEditing)">
                        <span *nzSpaceItem
                          nz-icon nzType="plus-circle"
                          nzTheme="outline" (click)="addItemToFormArray(groupKey)"
                        ></span>
                      </ng-container>

                      <ng-container *ngIf="i > 0 && !isAdminReadOnlyRole && (isCreateNew || isEditing)">
                        <span *nzSpaceItem
                          nz-icon nzType="minus-circle"
                          nzTheme="twotone" [nzTwotoneColor]="'#ff4d4f'"
                          (click)="removeItemInFormArray(groupKey, i)"
                        ></span>
                      </ng-container>
                    </nz-space>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
          </form>
        </ng-container>
      </div>
    </div>

    <!-- Group 3: Notification Recipients info -->
    <div class="group no-padding">
      <div class="group-header">Notification Recipients</div>
      <div class="group-content">
        <ng-container *ngFor="let groupKey of ['contacts'];" [formArrayName]="groupKey">
          <form nz-form *ngFor="let item of getArrayControls(groupKey); let i = index" [formGroupName]="i">
            <div class="tdm-row cell-spacing">
              <div class="tdm-col tdm-col-5" *ngFor="let key of ['firstName', 'lastName', 'phone', 'email', 'action']">
                <div *ngIf="key !== 'action'">
                  <div class="form-label-v2">
                    {{getLabel(fullKey(groupKey, key))}}
                    <span *ngIf="isRequired(fullKey(groupKey, key))" class="label-mark-required"></span>
                  </div>
                  <nz-form-item>
                    <nz-form-control>
                      <input nz-input [formControlName]="key"
                        [type]="getInputType(fullKey(groupKey, key))"
                        [placeholder]="getPlaceHolder(fullKey(groupKey, key))"
                        (input)="onInputChanged($event, key)"
                        (keypress)="onInputKeyPress($event, key)" >
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <div *ngIf="key === 'action' && (isEditing || isCreateNew)" [ngClass]="key">
                  <div class="form-label-v2">&nbsp;</div>
                  <nz-form-item>
                    <nz-form-control>
                      <nz-space class="group-button">
                        <ng-container *ngIf="!isAdminReadOnlyRole && (isCreateNew || isEditing)">
                          <span *nzSpaceItem
                            nz-icon nzType="plus-circle"
                            nzTheme="outline" (click)="addItemToFormArray(groupKey)"
                          ></span>
                        </ng-container>

                        <ng-container *ngIf="i > 0 && !isAdminReadOnlyRole && (isCreateNew || isEditing)">
                          <span *nzSpaceItem
                            nz-icon nzType="minus-circle"
                            nzTheme="twotone" [nzTwotoneColor]="'#ff4d4f'"
                            (click)="removeItemInFormArray(groupKey, i)"
                          ></span>
                        </ng-container>
                      </nz-space>
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div>
            </div>
          </form>
        </ng-container>
      </div>
    </div>

    <!-- Group 4: Label Template -->
    <div class="group no-padding">
      <div class="group-header">Label Template</div>
      <div class="group-content">
        <div class="tdm-row cell-spacing">
          <div class="tdm-col tdm-col-5" *ngFor="let key of ['palletTemplateId', 'sortingTemplateId']">
            <div>
              <div class="form-label-v2">
                {{getLabel(key)}}
                <span *ngIf="isRequired(key)" class="label-mark-required"></span>
              </div>
              <nz-form-item>
                <nz-form-control>
                  <ng-container *ngIf="key === 'palletTemplateId'">
                    <nz-select nzBackdrop="true" style="width: 100%;"
                      nzShowSearch nzAllowClear [nzLoading]="isLoadingTemplates"
                      [nzPlaceHolder]="getPlaceHolder(key)" [formControlName]="key"
                    >
                      <nz-option *ngFor="let label of palletTemplates" [nzLabel]="label.name" [nzValue]="label.id"></nz-option>
                    </nz-select>
                  </ng-container>
                  <ng-container *ngIf="key === 'sortingTemplateId'">
                    <nz-select nzBackdrop="true" style="width: 100%;"
                      nzShowSearch nzAllowClear [nzLoading]="isLoadingTemplates"
                      [nzPlaceHolder]="getPlaceHolder(key)" [formControlName]="key"
                    >
                      <nz-option *ngFor="let label of sortingTemplates" [nzLabel]="label.name" [nzValue]="label.id"></nz-option>
                    </nz-select>
                  </ng-container>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Group 5: WARP info -->
    <div class="group no-padding" *ngIf="isAdmin">
      <div class="group-header">WARP Assigned Information</div>
      <div class="group-content warp-info">
        <div class="tdm-row cell-spacing">
          <div class="tdm-col tdm-col-3" *ngFor="let key of ['saleUserId', 'customerServiceUserId', 'customerServiceRepUserId']">
            <div>
              <div class="form-label-v2">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
              <nz-form-item>
                <nz-form-control>
                  <nz-select nzBackdrop="true" style="width: 100%;"
                    nzShowSearch nzAllowClear [nzLoading]="isLoadingSales"
                    [nzPlaceHolder]="getPlaceHolder(key)" [formControlName]="key">
                    <nz-option *ngFor="let user of allSaleUsers" [nzLabel]="getFullName(user)" [nzValue]="user?.id"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </div>
        <div class="tdm-row cell-spacing">
          <div class="tdm-col tdm-col-3" *ngFor="let key of ['creditLimit', 'creditRemaining', 'payableAvgDays']">
            <div>
              <div class="form-label-v2">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
              <nz-form-item>
                <nz-form-control>
                  <nz-input-group [nzPrefix]="key == 'payableAvgDays' ? null : '$'">
                    <input nz-input [formControlName]="key"
                      [type]="getInputType(key)"
                      [placeholder]="getPlaceHolder(key)"
                      (input)="onInputChanged($event, key)"
                      (keypress)="onInputKeyPress($event, key)" />
                  </nz-input-group>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </div>
        <div class="tdm-row cell-spacing" style="max-width: 620px !important;">
          <div class="tdm-col tdm-col-2" *ngFor="let key of ['isShowLoadFunctions', 'freightQuoteEnabled', 'isShowCarrierNameInQuote', 'isShowQuoteSourceName', 'isUseCrossDock', 'isInvoiceWithoutPOD', 'isShowCarrierNameInMarketPlaceQuote', 'hideWarpBrandInfo', 'marketplaceLTLEnabled']">
            <div>
              <nz-form-item>
                <nz-form-control>
                  <label nz-checkbox [formControlName]="key">{{getLabel(key)}}</label>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </div>
        <ng-container *ngFor="let groupKey of ['classificationSettings']">
          <form nz-form [formGroupName]="groupKey">
            <div *ngFor="let key of ['productType']" class="flex bottom10">
              <div>{{getLabel(fullKey(groupKey,key))}}:</div>
              <div class="left10">
                <nz-radio-group [formControlName]="key">
                  <label *ngFor="let item of productTypeArr" nz-radio [nzValue]="item">{{item}}</label>
                </nz-radio-group>
              </div>
            </div>
            <div *ngFor="let key of ['classificationShipment']" class="flex">
              <div>{{getLabel(fullKey(groupKey,key))}}:</div>
              <div class="left10">
                <nz-radio-group [formControlName]="key">
                  <label *ngFor="let item of classificationShipmentArr" nz-radio [nzValue]="item">{{item}}</label>
                </nz-radio-group>
              </div>
            </div>
          </form>
        </ng-container>
      </div>
    </div>

    <!-- Group 6: Crossdock Setting -->
    <div class="group no-padding">
      <div class="group-header">Cross Dock Shipment Information</div>
      <div class="group-content">
        <div class="tdm-row cell-spacing">
          <div *ngFor="let mode of taskSettings;" [formArrayName]="mode.type" class="tdm-col tdm-col-2">
            <label class="title">{{getLabel(fullKey(mode.type))}} </label>

            <div *ngFor="let item of getArrayControls(mode.type); let i = index" class="form-setting index-{{ i }}">
              <form nz-form [formGroupName]="i">
                <div class="tdm-row cell-spacing">
                  <div class="tdm-col tdm-col-2" *ngFor="let childKey of ['taskType', 'levels'];">
                    <div>
                      <ng-container *ngIf="i === 0">
                        <div class="form-label-v2">
                          {{getLabel(fullKey(mode.type, childKey))}}
                          <span *ngIf="isRequired(fullKey(mode.type, childKey))" class="label-mark-required"></span>
                        </div>
                      </ng-container>
                      <ng-container [ngSwitch]="childKey">
                        <div *ngSwitchCase="'taskType'" [ngClass]="childKey">
                          <label>{{ getTaskName(mode.type, i, childKey) }}</label>
                          <input nz-input [formControlName]="childKey"
                            [type]="getInputType(childKey)"
                            [placeholder]="getPlaceHolder(childKey)"
                            (input)="onInputChanged($event, childKey)"
                            (keypress)="onInputKeyPress($event, childKey)"
                          />
                        </div>

                        <div *ngSwitchCase="'levels'" [ngClass]="childKey">
                          <form nz-form [formGroupName]="childKey" class="group-crossdock">
                            <div class="item-crossdock" *ngFor="let key of getLevels(mode, i, 'taskType')">
                              <nz-form-item>
                                <nz-form-control>
                                  <label nz-checkbox 
                                    [nzDisabled]="isDisable(mode, i, 'taskType')" 
                                    [formControlName]="key"
                                  >{{getLabel(fullKey(mode.type, childKey, key))}}</label>
                                </nz-form-control>
                              </nz-form-item>
                            </div>
                          </form>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Group 7: Location Setting -->
    <div class="group no-padding">
      <div class="group-header">Location Settings 
        <ng-container *ngIf="requirePermissions([PermissionCode.location_setting.create]) && (isCreateNew || isEditing)">
          <button nz-button (click)="addSetting()" class="left10">
            <span nz-icon nzType="plus" nzTheme="outline"></span>Add
          </button>
        </ng-container>
      </div>
      <div class="group-content">
        <table class="table">
          <thead class="ant-table-thead">
            <tr>
              <th class="ant-table-cell">Warehouse</th>
              <th class="ant-table-cell">Location Type</th>
              <th class="ant-table-cell">Scan Barcode</th>
              <th class="ant-table-cell">POD</th>
              <th class="ant-table-cell">BOL</th>
              <th class="ant-table-cell">Signature</th>
              <th class="ant-table-cell">Action</th>
            </tr>
          </thead>

          <tbody class="ant-table-tbody">
            <ng-container *ngFor="let groupKey of ['settings'];" [formArrayName]="groupKey">
              <tr class="ant-table-row" *ngFor="let item of getArrayControls(groupKey); let i = index" [formGroupName]="i">
                <td class="ant-table-cell">{{ getValue(i, 'warehouseId') }}</td>
                <td class="ant-table-cell">{{ getValue(i, 'locationType') }}</td>
                <td class="ant-table-cell">{{ getValue(i, 'scanBarcodeRequired') }}</td>
                <td class="ant-table-cell">{{ getValue(i, 'podAtLocationLevel') }}</td>
                <td class="ant-table-cell">{{ getValue(i, 'bolRequired') }}</td>
                <td class="ant-table-cell">{{ getValue(i, 'signatureRequired') }}</td>
                <td class="ant-table-cell">
                  <nz-space class="group-button">
                    <ng-container *ngIf="requirePermissions([PermissionCode.location_setting.update]) && (isCreateNew || isEditing)">
                      <span *nzSpaceItem
                        style="cursor: pointer;"
                        nz-icon nzType="edit"
                        nzTheme="outline" (click)="editItemSetting(i)"
                      ></span>
                    </ng-container>

                    <ng-container *ngIf="i > 0 && requirePermissions([PermissionCode.location_setting.delete]) && (isCreateNew || isEditing)">
                      <span *nzSpaceItem
                        style="cursor: pointer;"
                        nz-icon nzType="minus-circle"
                        nzTheme="twotone" [nzTwotoneColor]="'#ff4d4f'"
                        (click)="removeItemInFormArray(groupKey, i)"
                      ></span>
                    </ng-container>
                  </nz-space>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Button save & cancel -->
    <div style="height: 50px;">
      <div *ngIf="requirePermissions([PermissionCode.client.create])" detail-footer-buttons labelButtonCreate="Create New Customer"
        [isCreateNew]="isCreateNew" [isEditing]="isEditing"
        [onProgress]="onProgress" [needUpdate]="needUpdate"
        (onEdit)="onBtnEdit()" (onSave)="onBtnSave()" (onCancel)="onBtnCancel()">
      </div>
    </div>

    <div class="group no-padding" *ngIf="isFinanceAllowed && model">
      <div class="group-header">WARP Rating Settings</div>
      <div class="warp-info no-padding">
        <div finance-settings [subject]="'CUSTOMER_' + model._id" [scope]="'PRICING'"></div>
      </div>
    </div>

    <div class="group no-padding" *ngIf="model?.quickOrderInfo?.isEnable">
      <div class="group-header flex">
        <div>WARP Quick Order Settings</div>
        <div class="left10">
          <button nz-button nzType="dashed" (click)="onBtnAddQuickOrderURL()">
            <i nz-icon nzType="plus"></i>Add
          </button>
        </div>
      </div>
      <div class="warp-info group-content">
        <div *ngFor="let info of model?.quickOrderInfo.quickOrderAccesses" class="quick-order-access">
          <div class="bottom5 flex-space-between">
            <div>Email: {{ info.email }}</div>
            <div class="flex">
              <button nz-button nzDanger nzSize="small" class="left10 f13" (click)="onBtnRemoveQuickOrderSetting(info)" [disabled]="isRemoving">Remove</button>
            </div>
          </div>
          <div class="bottom5">
            <span>Form URL:</span>
            <span *ngIf="info.isTokenExpired" class="left5 token-expired">(Token expired)</span>
          </div>
          <div class="flex">
            <div class="flex1 code-block">
              <span #pre>{{getQuickOrderUrl(info)}}</span>
            </div>
            <div *ngIf="info?.isTokenExpired">
              <button nz-button nzType="primary" (click)="onBtnReGenerateQuickOrderURL(info)" [disabled]="isGenerating">Re-Generate</button>
            </div>
            <div>
              <button nz-button nzType="primary" class="left10" (click)="onBtnSendEmailQuickOrder(info)" [disabled]="isSendingEmail">{{ getBtnLabelSendEmailQuickOrder(info) }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MapboxStaticService } from './service';
import { Mapbox } from '@services/mapbox';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [
  ],
  exports: [
  ],
  providers: [
    MapboxStaticService
  ]
})
export class MapboxStaticModule { }
import { Component } from "@angular/core";
import { BaseForm, FormGroupDeclaration } from '@wearewarp/ng-form';

export interface FormDataCarrierPaymentPlanning {
  note: string,
  transferDate: string,
}
@Component({
  selector: 'carrier-payment-planning',
  templateUrl: './view.html',
  styleUrls: ['./style.scss']
})
export class CarrierPaymentPlanning extends BaseForm<FormDataCarrierPaymentPlanning> {
  protected override formGroupDeclaration: FormGroupDeclaration = {
    note: {label: 'Note', placeHolder: 'Enter note'},
    transferDate: {label: 'Transfer date', type: 'date'}
  }

  canSubmit(): boolean {
    if (this.isCreateNew) {
      return true;
    }
    return super.canSubmit();
  }

  
}

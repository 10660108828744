import { Component } from '@angular/core';
import { Const } from '@const/Const';
import { InputHelper } from '@services/input-helper';
import { CarrierSales } from './list';

@Component({
  selector: 'report-revenue',
  templateUrl: './list.html',
  styleUrls: [
    './style.scss',
    '../list.scss'
  ]
})
export class ReportRevenue extends CarrierSales {
  public isTableHidden = true;

  public get isReportRevenue(): boolean {
    return true;
  }

  public get canSendDocs(): boolean {
    return false;
  }

  public get canPerformAction(): boolean {
      return false;
  }

  public get shouldShowTable(): boolean {
    return !this.isLoading && !this.isTableHidden && this.totalCount > 0;
  }

  public get revenueSum(): any {
    return this.sum;
  }

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  protected handleNavigationEnd(url: string, prevQueryParam: any): void {
    // do nothing
  }

  protected prepareParamGetList() {
    return {
      skip: 0,
      limit: -1,
      filter: JSON.stringify(this.filter)
    };
  }

  private filter;

  loadData(query) {
    this.filter = {fromDropDate: query.fromDropDate, toDropDate: query.toDropDate, status: [Const.JobStatus.completed]};
    this.getData();
  }

  toggleTable() {
    this.isTableHidden = !this.isTableHidden;
  }

  private sum = {
    shipmentCost: '',
    routeCost: '',
  }

  protected onGetDataSucceeded(resp) {
    super.onGetDataSucceeded(resp);
    let shipmentCost = 0;
    let routeCost = 0;
    for (let i = 0; i < this.listData.length; i++) {
      routeCost += this.listData[i].assignedCarrier?.cost?.grandTotal ?? 0;
      let shipments = this.listData[i].shipments ?? [];
      for (let j = 0; j < shipments.length; j++) {
        shipmentCost += shipments[j].cost?.grandTotal ?? 0;
      }
    }
    this.sum.routeCost = InputHelper.formatMoney2(`${routeCost}`);
    this.sum.shipmentCost = InputHelper.formatMoney2(`${shipmentCost}`);
  }

}
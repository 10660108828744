<div>
  <div class="group-header">
    <div nz-row [nzGutter]="{ md: 16 }">
      <div nz-col nzMd="12" [nzLg]="colSpanCommon">
        <nz-input-group [nzPrefix]="suffixIconSearch" [nzSuffix]="suffixIconClearCartonName">
          <input nz-input placeholder="Search by Carton Name, Fulfillment ID" 
          [(ngModel)]="filterData.cartonName"
          (ngModelChange)="onFilterChange('cartonName', $event)"/>
        </nz-input-group>
        <ng-template #suffixIconSearch>
          <span nz-icon nzType="search"></span>
        </ng-template>
        <ng-template #suffixIconClearCartonName>
          <i nz-icon nz-tooltip 
            class="ant-input-clear-icon" nzTheme="fill" nzType="close-circle" 
            *ngIf="filterData.cartonName && filterData.cartonName.length>0" 
            (click)="clearSearchKeyword('cartonName')"></i>
        </ng-template>
      </div>
      <div nz-col nzMd="12" [nzLg]="colSpanCommon">
        <nz-select nzBackdrop="true" nzMode="multiple" nzShowSearch nzAllowClear
          nzPlaceHolder="Select SKU" style="width: 100%;"
          nzDropdownClassName="manifest-filter-sku"
          [nzMaxTagCount]="0"
          [nzMaxTagPlaceholder]="tagPlaceHolderSKU"
          [(ngModel)]="filterData.skuNumber"
          (ngModelChange)="onFilterChange('skuNumber', $event)">
          <nz-option *ngFor="let option of listOfFilterSKUOption" [nzLabel]="option" [nzValue]="option"></nz-option>
        </nz-select>
        <ng-template #tagPlaceHolderSKU>{{skuSelectDesc}}</ng-template>
      </div>
      <div *ngIf="!isComeFromPalletDetail" nz-col nzMd="12" [nzLg]="colSpanCommon">
        <nz-select nzBackdrop="true" [(ngModel)]="filterData.carrierName" nzMode="multiple" 
          nzShowSearch nzAllowClear style="width: 100%;"
          nzPlaceHolder="Select Carrier"
          [nzMaxTagCount]="0"
          [nzMaxTagPlaceholder]="tagPlaceHolderCarrier"
          (ngModelChange)="onFilterChange('carrierName', $event)">
          <nz-option *ngFor="let option of listOfFilterCarrierOption" [nzLabel]="option" [nzValue]="option"></nz-option>
        </nz-select>
        <ng-template #tagPlaceHolderCarrier>{{carrierSelectDesc}}</ng-template>
      </div>
      <div *ngIf="!isComeFromPalletDetail" nz-col nzMd="12" [nzLg]="colSpanCommon">
        <nz-select nzBackdrop="true" [(ngModel)]="filterData.consigneeName" nzMode="multiple" 
          nzShowSearch nzAllowClear style="width: 100%;"
          nzPlaceHolder="Select Consignee"
          [nzMaxTagCount]="0"
          [nzMaxTagPlaceholder]="tagPlaceHolderConsignee"
          (ngModelChange)="onFilterChange('consigneeName', $event)">
          <nz-option *ngFor="let option of listOfFilterConsigneeOption" [nzLabel]="option" [nzValue]="option"></nz-option>
        </nz-select>
        <ng-template #tagPlaceHolderConsignee>{{consigneeSelectDesc}}</ng-template>
      </div>
    </div>
    <div class="top10 bottom10 flex-space-between" style="height: 32px; align-items: center;">
      <div class="flex">
        <ng-container *ngIf="numOfItemSelected">
          <button nz-button (click)="onBtnUploadLabeledSKU()">Upload Labeled SKU</button>
          <button nz-button nzType="text" class="btn-select-all" (click)="onBtnSelectAllItems()">Select all {{ totalCount }} {{ totalCount > 1 ? 'items' : 'item' }}</button>
          <button nz-button nzType="text" nzDanger (click)="onBtnUnSelectAllItems()">Unselect all</button>
        </ng-container>
        <ng-container *ngIf="isComeFromPalletDetail">
          <div>&nbsp;</div>
        </ng-container>
      </div>
      <div>
        <ng-container *ngIf="numOfItemSelected">
          <span class="number-of-selected">Selected: {{ numOfItemSelected }} {{ numOfItemSelected > 1 ? 'items' : 'item' }}</span>
          <span class="divider">|</span>
        </ng-container>
        <span  class="number-of-total">Total: {{ totalCount }} {{ totalCount > 1 ? 'items' : 'item' }}</span>
        <span class="divider">|</span>
        <span  class="number-of-showing">Show: {{ numOfItemShowing }} {{ numOfItemShowing > 1 ? 'items' : 'item' }}</span>
      </div>
    </div>
  </div>
  <div class="group-body">
    <nz-table #nzTable nzBordered="true"
      [nzData]="listOfData"
      [nzHideOnSinglePage]="true"
      [nzPageSize]="limit"
      [nzNoResult]="tplNoData"
      [nzSize]="'default'"
      (nzPageIndexChange)="onDataListPageChanged($event)"
      (nzCurrentPageDataChange)="currentPageDataChange($event)">
      <ng-template #tplNoData>
        <div class="nodata"><i nz-icon nzType="search" nzTheme="outline"></i>No data</div>
      </ng-template>
      <thead>
        <tr>
          <th *ngIf="!isComeFromPalletDetail" [(nzChecked)]="allChecked" [nzIndeterminate]="indeterminate" (nzCheckedChange)="checkAll($event)"></th>
          <th>Carton Name</th>
          <th>Fulfillment ID</th>
          <th [nzSortOrder]="null" [nzSortDirections]="['ascend', 'descend', null]" [nzSortFn]='sortSKU'>SKU</th>
          <th *ngIf="!isComeFromPalletDetail" [nzSortOrder]="null" [nzSortDirections]="['ascend', 'descend', null]" [nzSortFn]='sortPallet'>Pallet</th>
          <th *ngIf="!isComeFromPalletDetail" [nzSortOrder]="null" [nzSortDirections]="['ascend', 'descend', null]" [nzSortFn]='sortCarrier'>Carrier</th>
          <th *ngIf="!isComeFromPalletDetail" [nzSortOrder]="null" [nzSortDirections]="['ascend', 'descend', null]" [nzSortFn]='sortConsignee'>Consignee</th>
          <th *ngIf="isComeFromPalletDetail">Actions</th>
          <th>Task status</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of nzTable.data; let i = index">
          <td *ngIf="!isComeFromPalletDetail" 
            [(nzChecked)]="item.checked"
            [nzDisabled]="item.disabled"
            (nzCheckedChange)="refreshStatus()"
          ></td>
          <td>
            <a [routerLink]="getRouterLinkCartonDetail(item)" target="_blank">{{ item.name }}</a>
          </td>
          <td>
            {{ item.fulfillmentId || 'N/A' }}
          </td>
          <td>
            {{ item.sku?.join(', ') || 'N/A' }}
          </td>
          <td *ngIf="!isComeFromPalletDetail">
            <ng-container *ngIf="!item.palletId">
              LOOSE
            </ng-container>
            <ng-container *ngIf="item.palletId && item.palletName">
              <a [routerLink]="getRouterLinkPalletDetail(item)" target="_blank">{{ item.palletName }}</a>
            </ng-container>
          </td>
          <td *ngIf="!isComeFromPalletDetail">
            {{ item.carrier || 'N/A' }}
          </td>
          <td *ngIf="!isComeFromPalletDetail">
            {{ item.consignee || 'N/A' }}
          </td>
          <td *ngIf="isComeFromPalletDetail">
            <div *ngIf="canRemoveCarton" class="remove-carton" (click)="onBtnRemoveCartonFromPallet(item)">Remove</div>
          </td>
          <td>
            <nz-tag *ngIf="item.taskStatus" [nzColor]="getColor(item.taskStatus)">{{ getStatusText(item.taskStatus) }}</nz-tag>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</div>

<div class="dashboard-child-container no-padding list">
  <div class="list-header">
    <search-box
      #searchBox (doSearch)="doSearch($event)"
      placeHolder="Search by name, ID or address"
      [searchKeyword]="searchKeyword" style="width: 500px; margin-right: 20px;"
    ></search-box>
    <button nz-button [nzType]="hasFilter ? 'primary' : 'default'" style="min-width: 80px;" (click)="onBtnFilter('drawer')">
      <i nz-icon nzType="filter" nzTheme="outline"></i>Filter
    </button>
    <div class="flex1"></div>
    <button *ngIf="!isAdminReadOnlyRole" nz-button (click)="onBtnCreate()"><i nz-icon nzType="plus"
        nzTheme="outline"></i>Create Carrier Bid</button>
  </div>
  <div class="list-body">
    <nz-table #nzTable nzBordered="true" [nzData]="listData" nzSize="small" [nzTotal]="totalCount"
      [nzFrontPagination]="false" [nzShowPagination]="totalCount > listData.length" [nzPageSize]="limit"
      [nzLoading]="isLoading" [nzLoadingIndicator]="tplLoading" [nzNoResult]="tplNoData" [nzSize]="'default'"
      (nzPageIndexChange)="onDataListPageChanged($event)">
      <ng-template #tplNoData>
        <div *ngIf="!isLoading" class="nodata"><i nz-icon nzType="search" nzTheme="outline"></i>No data</div>
      </ng-template>
      <ng-template #tplLoading>
        <div class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
      </ng-template>
      <thead (nzSortOrderChange)="onSortOrderChange($event)">
        <tr>
          <th nzSortOrder="null" [nzSortFn]="true" nzColumnKey="jobCode" nzWidth="120px">Load ID</th>
          <th nzSortOrder="null" [nzSortFn]="true" nzColumnKey="orderIds" nzWidth="120px">WARP ID</th>
          <th nzSortOrder="null" [nzSortFn]="true" nzColumnKey="pickupAddress">Pickup Address</th>
          <th nzSortOrder="null" [nzSortFn]="true" nzColumnKey="dropoffAddress">Dropoff Address</th>
          <th nzSortOrder="null" [nzSortFn]="true" nzColumnKey="pickupDate">Pickup Date</th>
          <th nzColumnKey="best price">Best Offer</th>
          <th nzColumnKey="type">Type</th>
          <th nzColumnKey="rate">Rate</th>
          <th nzColumnKey="detail">Detail</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of listData; let i = index">
          <td>
            <a [routerLink]="[routeAdminCarrierBidList, item.id]">{{item.jobCode || 'N/A'}}</a>
          </td>
          <td>
            {{getShipmentIdText(item)}}
          </td>
          <td>
            {{[item.pickupAddress?.street, item.pickupAddress?.city, item.pickupAddress?.state,
            item.pickupAddress?.zipcode].join(", ")}}
          </td>
          <td>
            {{[item.dropoffAddress?.street, item.dropoffAddress?.city, item.dropoffAddress?.state,
            item.dropoffAddress?.zipcode].join(", ")}}
          </td>
          <td>{{formatDate(item.pickupDate, item.pickupAddress?.metadata?.timeZoneStandard)}}</td>
          <td nzAlign="right">{{getBestOffer(item)}}</td>
          <td>
            <a *ngIf="item.jobId" [routerLink]="[routeAdminDispatchList, item.jobId]">View Job</a>
            <div *ngIf="!item.jobId">Manually</div>
          </td>
          <td>
            <div nz-tooltip nzTooltipTitle="Accepted/Refused/Waiting">
              {{getAcceptanceCount(item)}}/{{getRefuseCount(item)}}/{{getWaitingCount(item)}}
            </div>
          </td>
          <td class="actions w50">
            <div class="flex">
              <button (click)="onBtnDetail(item)" nz-button nzType="text" nzShape="circle">
                <i nz-icon nzType="eye" nzTheme="outline"></i>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</div>

<ng-template [nzModalTitle]>
  <div class="flex-space-between">
    <div>{{headerText}}</div>
    <div>
      <span nz-icon nzType="close" nzTheme="outline" class="clickable" (click)="closeDialog()"></span>
    </div>
  </div>
</ng-template>

<div>
  <form nz-form class="form-detail" [formGroup]="formInput" nzLayout="vertical">
    <div nz-row [nzGutter]="[16, 16]">
      <div nz-col [nzSpan]="24">
        <div class="form-label-v2">Warehouse</div>
        <nz-form-item>
          <nz-form-control>
            <select-by-searching
              [version]="2"
              type="warehouse"
              [getAllAtOnce]="false"
              [formControlName]="'warehouseId'"
              [(listData)]="listWarehouses"
              [placeholder]="getPlaceHolder('warehouseId')">
            </select-by-searching>
          </nz-form-control>
        </nz-form-item>
      </div>

      <div nz-col [nzSpan]="24">
        <div class="form-label-v2">Location Type<span class="label-mark-required"></span></div>
        <nz-form-item>
          <nz-form-control>
            <nz-select formControlName="locationType">
              <nz-option *ngFor="let option of types" [nzLabel]="option.title" [nzValue]="option.value">
              </nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>

      <div nz-col [nzSpan]="24">
        <nz-form-item>
          <nz-form-control>
            <form-location-settings formControlName="settings"></form-location-settings>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
  </form>
</div>

<div *nzModalFooter>
  <div form-footer [onProgress]="onProgress" 
    [nzIconCancel]="null" [nzIconOK]="null"
    [canClickOK]="needUpdate && !onProgress"
    [canClickCancel]="!onProgress" [labelOK]="btnSubmitLabel"
    (onOK)="onBtnSave()" (onCancel)="closeDialog()"></div>
</div>

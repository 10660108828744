import { BaseComponent } from '@abstract/BaseComponent';
import { Component, Input, OnInit } from '@angular/core';
import { Const } from '@const/Const';
import { DatPostDetailComponent } from "./dat-post-detail.component";
import { DialogService } from '@dialogs/dialog.service';
import to from 'await-to-js'
@Component({
  selector: 'dat-post-button',
  templateUrl: './dat-post.component.html',
  styleUrls: ['./dat-post.component.scss']
})
export class DatPostComponent extends BaseComponent {

  @Input() carrierBidId;
  @Input() jobId;
  public datState: 'hidden' | 'view' | 'post' = 'hidden'
  public datPostLoading: boolean = false;
  public datLoad = {}

  ngOnInit(): void {
    this.getDatLoad()
  }

  async getDatLoad(){
    const resp = await this.api.POST(`${Const.APIURI_CARRIER_BIDS}/get-dat-load-info`, {
      id: this.carrierBidId
    }).toPromise().catch(err => {
      this.showErr(err);
      this.datState = 'hidden'
    });
    if(!resp?.data?.isShow){
      this.datState = 'hidden';
      return;
    }
    if(resp?.data?.datLoad){
      this.datState = 'view'
      this.datLoad = resp?.data?.datLoad
      return;
    }
    this.datState = 'post'
  }

  async onPostToDat(){
    this.datPostLoading = true
    const [error, resp] = await to(this.api.POST(Const.APIV2(`${Const.APIURI_JOBS}/${this.jobId}/post-to-dat`), {}).toPromise())
    if(error){
      this.showErr(error)
      this.datPostLoading = false
      return;
    }
    this.datLoad = resp?.data
    this.datPostLoading = false;
    this.datState = 'view'
  }

  onDeleteSuccess = () => {
    this.datState = 'post'
    this.datLoad = {}
  }

  onViewDat(){
    DialogService.openDialog(DatPostDetailComponent, {
      nzTitle: 'DAT Load Infomation',
      nzComponentParams: {
        datLoad: this.datLoad,
        onDeleteSuccess: this.onDeleteSuccess
      },
      nzClassName: "dat-detail",
    });
  }
}

<div class="form-header no-padding-bottom">
</div>
<div class="top20" nz-row >
  <div nz-col [nzMd]="6" [nzXs]="24">
    <div class="desktop">
      <div class="f20 bold">Service Area</div>
      <div>Please click the map to select your service area.</div>
      <div class=" bottom20 selected-area-title">Selected Service Area{{featureList?.length && featureList.length > 1 ? 's' : ''}}:</div>
      <div class="selected-area">
        <div class="flex" style="flex-flow: wrap;">
          <ng-container *ngFor="let item of featureList">
            <div class="label-item"><span style="cursor: pointer; margin-right: 5px;" (click)="onSelectHightlight(item)">{{item.name}}</span><span class="icon-x" nz-icon nzType="close" nzTheme="outline" (click)="onDeletedSelectedItem(item)"></span></div>
          </ng-container>
        </div>
      </div>
      <div class="top20 bottom20 text-center" style="margin-right: 16px">
        <div nz-row  [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }">
          <div class="gutter-row" nz-col [nzXs]="12">
            <button style="width: 100%" nz-button nzShape="round"(click)="onBtnCancel()">Cancel</button>
          </div>
          <div  class="gutter-row" nz-col [nzXs]="12">
            <button  style="width: 100%" nz-button nzShape="round" nzType="primary" (click)="onBtnContinue()">Submit</button>
          </div>
        </div>
      </div>
    </div>
    <div class="mobile bottom20">
      <div>Please click the map to select your service area.</div>
      <ng-container *ngIf="!featureList?.length">
        <div class="bold f20">Select service area...</div>
      </ng-container>
      <ng-container *ngIf="featureList?.length">
        <div class="bold f20">{{featureList?.length}} areas selected.</div>
      </ng-container>
    </div>
  </div>
  <div nz-col [nzMd]="18" [nzXs]="24">
    <div class="map-controls">
      <div class="parent-control flex">
        <div class="left-button">
          <div class="flex">
            <div class="item-btn state" (click)="onSelectBtn(selectBtnValues.state)" [ngClass]="{select: isSelectButon(selectBtnValues.state)}">State</div>
            <div class="item-btn metro" (click)="onSelectBtn(selectBtnValues.metro)" [ngClass]="{select: isSelectButon(selectBtnValues.metro)}">Metro</div>
          </div>
          <!-- <ng-container *ngIf="buttonSelect == selectBtnValues.state">
            <div class="item-btn select-all" (ngModelChange)="onChangeSelectAll($event)" nz-checkbox [(ngModel)]="isSelectAll">Select All</div>
          </ng-container> -->
        </div>
        <div class="right-button desktop">
          <!-- <div class="item-btn" (click)="onSelectBtn(selectBtnValues.draw)" [ngClass]="{select: isSelectButon(selectBtnValues.draw)}"> <img src="assets/img/draw.svg" />Draw</div> -->
          <div class="item-btn" (click)="onSelectBtn(selectBtnValues.radius)" [ngClass]="{select: isSelectButon(selectBtnValues.radius)}"> <img src="assets/img/blur_circular.svg" />Radius</div>
          <div class="item-btn" (click)="onSelectBtn(selectBtnValues.point)" [ngClass]="{select: isSelectButon(selectBtnValues.point)}"><img src="assets/img/polyline.svg" /> Point</div>
        </div>
      </div>
    </div>
    <div class="map-controls-mobile">
      <div class="right-button mobile">
        <!-- <div class="item-btn" (click)="onSelectBtn(selectBtnValues.draw)" [ngClass]="{select: isSelectButon(selectBtnValues.draw)}"> <img src="assets/img/draw.svg" />Draw</div> -->
        <div class="item-btn" (click)="onSelectBtn(selectBtnValues.radius)" [ngClass]="{select: isSelectButon(selectBtnValues.radius)}"> <img src="assets/img/blur_circular.svg" /></div>
        <div class="item-btn" (click)="onSelectBtn(selectBtnValues.point)" [ngClass]="{select: isSelectButon(selectBtnValues.point)}"><img src="assets/img/polyline.svg" /></div>
      </div>
    </div>
    <div #mapElm id="dispatch-map" style="width: 100%; margin-bottom: 20px;"></div>
    <div class="mobile">
      <div nz-row  [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }">
        <div class="gutter-row" nz-col [nzXs]="12">
          <button style="width: 100%" nz-button nzShape="round"(click)="onBtnCancel()">Back</button>
        </div>
        <div  class="gutter-row" nz-col [nzXs]="12">
          <button  style="width: 100%" nz-button nzShape="round" nzType="primary" (click)="onBtnContinue()">Continue</button>
        </div>
      </div>
    </div>
  </div>

<div *ngIf="isLoading" class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
<div *ngIf="isError" class="nodata">Cannot fetch data, please try again later</div>

<form nz-form *ngIf="!isLoading" [formGroup]="formInput" class="form-detail" [formGroup]="formInput"
  nzLayout="vertical">

  <div class="carrier-selection">
    <div class="f20 bottom20">Carrier's Answer</div>
        <div nz-row [nzGutter]="12">
          <div nz-col nzSpan="12">
            <nz-form-item>
              <nz-form-label>{{getLabel("answer")}}</nz-form-label>
              <nz-form-control>
                <nz-select nzBackdrop="true" formControlName="answer" (ngModelChange)="onAnswerChange($event)" [nzPlaceHolder]="getPlaceHolder('answer')">
                  <nz-option nzValue="2" nzLabel="Accepted base price" *ngIf="isShowAcceptWithOffer"></nz-option>
                  <nz-option nzValue="3" nzLabel="Placed other price"></nz-option>
                  <nz-option nzValue="1" nzLabel="Refused"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col nzSpan="12">
            <nz-form-item>
              <nz-form-label>{{getLabel("price")}}</nz-form-label>
              <nz-form-control>
                <input nz-input [placeholder]="getPlaceHolder('price')"
                (input)="onInputChanged($event, 'price')"
                  formControlName="price"/>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>

  </div>
</form>

<div form-footer [onProgress]="onProgress" [nzIconCancel]="null" [nzIconOK]="null"
  [canClickOK]="needUpdate && !onProgress && !isLoading" [canClickCancel]="!onProgress" labelOK="OK"
  (onOK)="onBtnSave()" (onCancel)="closeDialog()"></div>

import {Component, Input} from '@angular/core';
import {BaseFormDialog1} from '@dialogs/base-form-dlg1';
import {FormAddPlan} from '@app/admin/crossdock/rate-configuration/add-plan/FormAddPlan';
import {Const} from '@const/Const';
import moment from 'moment-timezone';
import {XDockDateHelper} from '@app/admin/crossdock/XDockDateHelper';

@Component({
  selector: '[warehouse-add-rate-plan]',
  templateUrl: './view.html',
  styleUrls: ['index.scss']
})
export class AddPlan extends BaseFormDialog1<FormAddPlan> {
  @Input() warehouseId: string;
  @Input() timezone: string;
  @Input() lastPlan: any;

  protected formGroupDeclaration: FormGroupDeclaration = {
    startDate: {label: 'Apply from date', type: 'date', notAcceptEmpty: true, required: true},
    endDate: {label: 'Apply to date', type: 'date', notAcceptEmpty: true, required: true},
  };

  get timezoneText(): string {
    return XDockDateHelper.timezoneText(this.timezone);
  }

  get isCreateNew(): boolean {
    return true;
  }

  get shouldCreateFormImmediately() {
    return true;
  }

  get headerText() {
    return 'Add New Plan';
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.setDefaultSelectedDates();
  }

  getDefaultEndDateSelect() {
    // ngày kết thúc plan >= ngày bắt đầu hoặc >= ngày mai
    const tomorrow = moment(new Date()).add(1, 'day').endOf('day').toDate();
    const startDateValue = this.getItemValue('startDate');
    return startDateValue ? moment(startDateValue).endOf('day').toDate() : tomorrow;
  }

  getDefaultStartDateSelect() {
    // ngày bắt đầu > lastPlan.endDate hoặc >= tomorrow
    const tomorrow = moment(new Date()).add(1, 'day').startOf('day').toDate();
    const lastPlanEndDate = this.lastPlan ?
      XDockDateHelper.getLocalMoment(this.lastPlan.endDate, this.timezone).add(1, 'day').startOf('day').toDate() : null;
    return lastPlanEndDate ? lastPlanEndDate : tomorrow;
  }

  disabledStartDate = (value: Date): boolean => {
    return moment(value).endOf('day').toDate().getTime() < this.getDefaultStartDateSelect().getTime();
  };

  disabledEndDate = (value: Date): boolean => {
    return moment(value).endOf('day').toDate().getTime() < this.getDefaultEndDateSelect().getTime();
  };

  clickAdd() {
    if (!this.warehouseId) {
      this.showErr('Missing warehouseId!');
      return;
    }
    if (!this.timezone) {
      this.showErr('Missing timezone!');
      return;
    }
    this.onProgress = true;
    const url = Const.APIURI_WAREHOUSE_RATE_PLAN;
    const body = this.getFormData_JSON(true);
    body.startDate = XDockDateHelper.getWarehouseMoment(body.startDate, this.timezone).startOf('day').toISOString();
    body.endDate = XDockDateHelper.getWarehouseMoment(body.endDate, this.timezone).endOf('day').toISOString();
    const options = {customHeaders: {warehouseId: this.warehouseId}};
    this.api.POST(url, body, options).subscribe(
      resp => {
        this.onProgress = false;
        this.onCreateSuccess(resp);
      }, err => {
        this.showErr(err);
        this.onProgress = false;
      }
    );
  }

  private setDefaultSelectedDates() {
    if (!this.lastPlan) {
      return;
    }
    const startMoment = XDockDateHelper.getLocalMoment(this.lastPlan.startDate, this.timezone);
    const endMoment = XDockDateHelper.getLocalMoment(this.lastPlan.endDate, this.timezone);
    const range = endMoment.diff(startMoment, 'days');
    this.setItemValue('startDate', endMoment.clone().add(1, 'day').toDate());
    this.formInput.get('startDate').disable();
    this.setItemValue('endDate', endMoment.clone().add(range + 1, 'day').toDate());
  }
}

import { Component, EventEmitter, Input } from "@angular/core";
import { Const } from "@const/Const";
import { DialogService } from "@dialogs/dialog.service";
import { getDashboard } from "@services/index";
import { Utils } from "@services/utils";
import { BaseList } from "../base/list";
import { SendDocsComponent } from "./send-docs/send-docs.component";
import { DrawerService } from "@app/drawers/drawer.service";
import { DateUtil } from "@services/date-utils";
import { InputHelper } from "@services/input-helper";
import { AddTargetRate } from "./target-rate";
import { Log } from "@services/log";
import { WarpId } from "@wearewarp/universal-libs";
import { SeeAllOrder } from "../components/see-all-orders/see-all-orders";
import { AssignCarrierWithCost } from "../dispatch/components/assign-carrier-with-cost";

@Component({
  selector: 'carrier-sales',
  templateUrl: './list.html',
  styleUrls: [
    './style.scss',
    '../list.scss'
  ]
})
export class CarrierSales extends BaseList {

  public selectedItem = null
  public qParams: any
  private subRefresh;
  private itemIds: string[] = [];
  isLoadAvgPastRated = true;

  // Dùng cho ReportRevenue
  public get isReportRevenue(): boolean { return false }
  public get canSendDocs(): boolean { return !this.isAdminReadOnlyRole }
  public get canPerformAction(): boolean { return true }
  public get shouldShowTable(): boolean { return true }
  public get revenueSum(): any { return {} }

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    setTimeout(() => getDashboard().sideBar.isSmallWidth = true, 1);
  }

  ngAfterContentInit(): void {
  }

  getDefaultFilter = () => {
    // Filter mặc định là "Need Carrier"
    return { carrierId: 'no', status: [Const.JobStatus.created] }
  }

  protected handleNavigationEnd(url: string, prevQueryParam: any): void {
    if (!this.queryParams.filter && !this.queryParams.search) {
      let filter = JSON.stringify(this.getDefaultFilter());
      return this.routeWithQueryUrl({ filter });
    }
    super.handleNavigationEnd(url, prevQueryParam);
  }

  onActivate(componentRef): void {
    componentRef?.activatedRoute?.params.subscribe(params => {
      this.selectedItem = params['id'];
      this.qParams = params;
    })
    let refreshListRoutes = componentRef?.refreshListRoutes;
    if (refreshListRoutes instanceof EventEmitter) {
      this.subRefresh = refreshListRoutes.subscribe(e => {
        this.onBtnRefresh();
      })
    }
  }

  onDeactivate(componentRef): void {
    this.subRefresh?.unsubscribe();
    this.selectedItem = null
  }

  loadData(query) {
    // dùng cho ReportRevenue
  }

  toggleTable() {
    // dùng cho ReportRevenue
  }

  public get queryParams() {
    return super.queryParams
  }

  protected getApiUrl(): string {
    return Const.APIURI_JOBS;
  }

  protected onGetDataSucceeded(resp) {
    this.itemIds = [];
    for (let item of this.listData) {
      this.itemIds.push(item.id);
      this.processItem(item);
    }
    if (this.itemIds?.length) {
      this.getAvgCostOfRoutes(this.itemIds);
    }
  }

  protected processItem(item) {
    item.shipmentLinks = item.shipments?.map(item => {
      return {
        warpId: item.warpId,
        id: item.id,
        orderId: item.orderId
      }
    })

    console.log(item)

    item.onHold = item.shipments.filter(it => it.tags && it.tags.indexOf('HOLD') >= 0).length > 0 || (item.tags?.length && item.tags.indexOf('HOLD') >= 0)

    item.pickWindow = this.displayWindow(Object.assign({ time: item.earliest_time, timeTo: item.latest_time }, item.pickDt));
    if (!item.dropDt) {
      item.dropDt = this.getDropDate(item.shipments);
      this.api.PUT(`${Const.APIURI_JOBS}/${item._id}/update_order_ralated_fields`, {}).subscribe(
        resp => {
        }, err => {
          // this.showErr(err);
          Log.e(err);
        }
      );
    }
    item.dropWindow = this.displayWindow(item.dropDt);
    item.isShowOnBidBoard = this.isShowOnBidBoard(item);
    // if (!item.datRate && this.canPerformAction) {
    //   const params = {};
    //   this.updateDatRate(item, params);
    // }
  }

  onReUpdateDatRate(item, event) {
    if (event.ctrlKey || event.metaKey) {
      this.updateDatRate(item, {});
    }
  }

  private updateDatRate(item, params) {
    this.api.PUT(`${Const.APIURI_JOBS}/${item._id}/update_dat_rate_for_job`, params).subscribe(
      resp => {
        if (resp?.data?.total_charge) {
          const index = this.listData.findIndex(it => it._id == item._id);
          if (index >= 0) {
            this.listData[index].datRate = resp?.data;
          }
        }
      }, err => {
        // this.showErr(err);
        Log.e(err);
      }
    );
  }
  private cacAvgPastRates(jobs) {
    let num = 0;
    let total = 0;
    for (const job of jobs) {
      const grandTotal = job?.assignedCarrier?.cost?.grandTotal;
      if (grandTotal) {
        num++;
        total += grandTotal;
      }
    }
    if (num > 0) {
      return InputHelper.formatMoney2((total / num).toFixed(2).toString());
    }
    return 'N/A'

  }

  private getAvgCostOfRoutes(jobIds) {
    this.isLoadAvgPastRated = true;
    this.api.POST(`${Const.APIV2(Const.APIURI_JOBS)}/avg-cost-of-routes`, { jobIds }).subscribe(
      resp => {
        if (resp?.data?.list_data?.length) {
          const datas = resp?.data?.list_data;
          for (const job of this.listData) {
            const item = datas.find((i) => i.jobId == job.id);
            job.avgPastRates = this.cacAvgPastRates(item?.jobs ?? [])
          }
        }
        this.isLoadAvgPastRated = false;
      }, err => {
        // this.showErr(err);
        Log.e(err);
      }
    );
  }

  // time, timeTo are ISO string 2022-03-29T18:19:10.000Z
  private displayWindow(obj: { time: string, timeTo: string, timezone: string, isAppointment: boolean }) {
    if (!obj || (!obj.time && !obj.timeTo)) return 'N/A';
    let formatDateTime = 'ddd, MMM D . h:mm a';
    let timeFrom = obj.time;
    let timeTo = obj.timeTo;
    timeFrom = DateUtil.displayLocalTime(timeFrom, { timezone: obj.timezone, format: formatDateTime });
    timeTo = DateUtil.displayLocalTime(timeTo, { timezone: obj.timezone, format: formatDateTime });
    let arr1 = timeFrom.split('.');
    let arr2 = timeTo.split('.');
    let isSameDay = arr1[0] == arr2[0];
    if (isSameDay) {
      timeTo = arr2[1]?.trim();
    }
    let str = `${timeFrom} - ${timeTo}`;
    if (obj.isAppointment) {
      str += '\n(Appointment Scheduled)';
    }
    return str;
  }

  public getRouterLink(job, index) {
    return [this.routeAdminShipmentList, job.shipmentLinks[index].orderId]
  }

  public getRouterFragment(job, index) {
    let item = job.shipmentLinks[index];
    if (item.id) {
      return `${item.id}`;
    }
    return undefined;
  }

  public getCarrierShortName(name: string) {
    if (name.length > 14) {
      return name.substring(0, 14) + '...';
    }
    return name;
  }

  public refresh() {
    this.routeWithQueryUrl(Object.assign({}, this.qParams, { loaded: Date.now() }))
  }

  public onBtnCarrier(index: number) {
    if (this.isAdminReadOnlyRole) return;
    let job = this.listData[index];
    DialogService.openFormDialog1(AssignCarrierWithCost, {
      nzComponentParams: {
        jobId: job._id,
        carrierId: job.carrier?._id,
        shipments: job.shipments,
        totalShipmentCost: InputHelper.getValueMoney(job['totalShipmentsCost']),
        closeOnSuccess: true,
        updateSuccess: resp => {
          this.listData[index].carrier = resp.data?.assignedCarrier?.carrierObj;
          this.refresh();
          let msg = 'Carrier has been assigned successfully.<br>Load tender is being generated, please wait a few seconds then refresh the page to see/download it.';
          if (Utils.isStringNotEmpty(resp)) {
            msg = resp;
          }
          this.showDialog(msg, () => this.onBtnRefresh())
        }
      },
      nzClassName: 'modal-no-padding assign-carrier-form',
    });
  }

  public onBtnSendDocs(index: number) {
    if (this.isAdminReadOnlyRole) return;
    let job = this.listData[index];
    if (!job.carrier) return;
    DialogService.openFormDialog1(SendDocsComponent, {
      nzComponentParams: {
        job: job,
        closeOnSuccess: true,
        updateSuccess: (resp) => {
        },
      },
      nzClassName: "send-docs-form",
    });
  }
  public onBtnOnUpdateTargetRate(item) {
    DialogService.openFormDialog1(AddTargetRate, {
      nzComponentParams: {
        data: item?.datRate?.cost_update?.cost,
        closeOnSuccess: true,
        onSave: data => this.updateDatRate(item, data)
      },
    });
  }



  shouldShowEquipmentAndTemp(job) {
    return job.equipments || job.tempRange;
  }

  public shouldShowClient(item): boolean {
    return this.isAdmin && Utils.isArrayNotEmpty(item.clients);
  }

  public seeAllOrder(jobIndex) {
    let job = this.listData[jobIndex];
    DrawerService.openFormDrawer(SeeAllOrder, {
      nzContentParams: {
        job: job,
      },
    });
  }

  public onClickCreateCarrierBid(item) {
    if (this.isAdminReadOnlyRole) return;
    this.api.POST(`${Const.APIURI_CARRIER_BIDS}/createByJob`, { jobId: item?._id }).subscribe(
      resp => {
        const url = this.router.serializeUrl(
          this.router.createUrlTree([`/${this.routeAdminCarrierSales}/${resp?.data?.id}`])
        );
        this.router.navigate([url]);
      }, err => {
        this.showErr(err);
      }
    );
  }

  public isShowOnBidBoard(item) {
    if (!Utils.isArrayNotEmpty(item.carrierBids)) return 'No';
    const lastBid = item.carrierBids[item.carrierBids.length - 1];
    return lastBid?.isShowOnBidBoard ? 'Yes' : 'No';
  }

  public onClickViewCarrierBid(item) {
    const lastBid = (item.carrierBids || []).pop();
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/${this.routeAdminCarrierSales}/${lastBid?.id}`])
    );
    this.router.navigate([url]);
  }

  public getBestOffers(item) {
    if (item.carrierBidInfo?.bestOffer) {
      return item.carrierBidInfo.bestOffer;
    } else {
      return [];
    }
  }

  public getAcceptanceCount(item) {
    return item.carrierBidInfo?.acceptanceCount || 0;
  }
  public getRefuseCount(item) {
    return item.carrierBidInfo?.refuseCount || 0;
  }
  public getMatchingCount(item) {
    return item.carrierBidInfo?.waitingCount + this.getRefuseCount(item) + this.getAcceptanceCount(item) || 0;
  }

  public formatDate(date) {
    return DateUtil.dateToString(date, Const.FORMAT_GUI_DATETIME_SHORT);
  }

  private getDeliveryInfoWindows(info) {
    if (Utils.isArrayNotEmpty(info.windows)) {
      return info.windows;
    } else if (info.appointmentInfo) {
      return [{ ...info.appointmentInfo, isAppointment: true }];
    }
    return [];
  }

  private handleDeliveryDate(info, arr) {
    let windows = this.getDeliveryInfoWindows(info);
    if (Utils.isArrayNotEmpty(windows)) {
      let timezone = ((info.addr || {}).metadata || {}).timeZoneStandard;
      for (let item of windows) {
        arr.push({ time: item.from, timeTo: item.to, timezone, isAppointment: item.isAppointment });
      }
    }
  }
  private getDropDate(shipments) {
    let arr = [];
    if (Utils.isArrayNotEmpty(shipments)) {
      for (let order of shipments) {
        if (order.deliveryInfos) {
          for (let info of order.deliveryInfos) {
            if (info.type == Const.TaskType.DROPOFF) {
              this.handleDeliveryDate(info, arr);
            }
          }
        } else {
          // deprecated
          if (Utils.isArrayNotEmpty(order.dropInfo.windows)) {
            let timezone = ((order.dropInfo.addr || {}).metadata || {}).timeZoneStandard;
            for (let item of order.dropInfo.windows) {
              arr.push({ time: item.from, timeTo: item.to, timezone });
            }
          }
        }
      }
    }
    arr.sort((a, b) => a.time < b.time ? 1 : (a.time > b.time ? -1 : 0));
    if (arr.length > 0) {
      return arr[0];
    } else {
      return null;
    }
  }
  getMoneyValue(value) {
    return '$' + InputHelper._formatMoney(value, 0);
  }

  getInfo(item, type) {
    let task;
    if (type == Const.TaskType.PICKUP) {//pickup đầu tiên
      task = item.tasks.filter(t => t.type == Const.TaskType.PICKUP)[0];
    }
    else {//dropoff cuối cùng
      task = item.tasks.slice().reverse().filter(t => t.type == Const.TaskType.DROPOFF)[0];
    }
    return task?.info
  }

  public getFirstShipmentId(shipments = []) {
    if (!shipments || !shipments?.length) {
      return ''
    }
    let shipmentWarpIds = shipments.map(item => item.warpId)
    shipmentWarpIds = shipmentWarpIds.sort((a, b) => a - b)
    return WarpId.showShipment(shipmentWarpIds[0])
  }

  public getFirstClientName(item) {
    return item.clients[0].name;
  }

  public getAllClientNames(item) {
    return item.clients.map(client => client.name).join(', ');;
  }

  public getFirstCommodity(item) {
    if (item.totalShipmentsItems?.commodity?.length > 1)
      return `${item.totalShipmentsItems.commodity[0]}, ${item.totalShipmentsItems.commodity[1]}`;
    else if (item.totalShipmentsItems?.commodity?.length)
      return item.totalShipmentsItems.commodity[0];
    else return "N/A";
  }

  public getAllCommodities(item) {
    return item.totalShipmentsItems?.commodity?.join(', ');
  }

  public onBtnBestOffer(job, offer) {
    this.confirmYesNo('This action will accept bid for this carrier. Please confirm you are sure you want to perform this action.', async () => this.assignCarrier(job, offer));
  }

  private makeCost(price) {
    return {
      currency: {
        type: "USD",
        fxRate: null
      },
      transitCost: {
        rate: price,
        qty: 1,
        total: price
      },
      volumeDiscount: {
        type: "percentage",
        percentage: null,
        flatRate: null,
        qty: null,
        total: 0
      },
      subTotal: price,
      fuelCost: {
        type: "rpm",
        percentage: null,
        rpm: null,
        qty: null,
        total: 0
      },
      serviceOptions: [],
      negativeMarginReason: null,
      manager: null,
      grandTotal: price,
      usdConversion: 0
    }
  }

  async assignCarrier(job, offer) {
    const params = {
      jobId: job._id,
      carrierId: offer.carrierId,
      cost: this.makeCost(offer.price)
    };
    const resp = await this.api.POST(`${Const.APIURI_CARRIER_BIDS}/accept-bid`, params).toPromise().catch(err => {
      this.showErr(err);
      console.log(" error: ", err);
    });

    if (resp) {
      this.onBtnRefresh();
      this.showDialog(`Carrier has been assigned successfully.<br /><br />
        <a href="${this.gotoDispatch(job)}" target="_blank">Go to dispatch</a>
      `);
    }
  }

  gotoDispatch(job) {
    return `${Const.routeAdminDispatchList}/${job._id}`
  }
}

import {Component, Input} from '@angular/core';
import {BaseFormDialog1} from '@dialogs/base-form-dlg1';
import {Const} from '@const/Const';
import moment from 'moment-timezone';
import {FormClonePlan} from '@app/admin/crossdock/rate-configuration/clone-plan/FormClonePlan';
import {XDockDateHelper} from '@app/admin/crossdock/XDockDateHelper';

@Component({
  selector: '[warehouse-clone-rate-plan]', templateUrl: './view.html', styleUrls: ['index.scss']
})
export class ClonePlan extends BaseFormDialog1<FormClonePlan> {
  @Input() warehouseId: string;
  @Input() timezone: string;
  @Input() clonePlan: any;
  @Input() lastPlan: any;
  protected formGroupDeclaration: FormGroupDeclaration = {
    startDate: {label: 'Apply from date', type: 'date', notAcceptEmpty: true, required: true},
    endDate: {label: 'Apply to date', type: 'date', notAcceptEmpty: true, required: true},
  };

  get timezoneText(): string {
    return XDockDateHelper.timezoneText(this.timezone);
  }

  get isCreateNew(): boolean {
    return true;
  }

  get shouldCreateFormImmediately() {
    return true;
  }

  get headerText() {
    return 'Clone Rate Plan';
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.setDefaultSelectedDates();
  }

  getDefaultEndDateSelect() {
    // ngày kết thúc plan >= ngày bắt đầu hoặc >= ngày mai
    const tomorrow = moment(new Date()).add(1, 'day').endOf('day').toDate();
    const startDateValue = this.getItemValue('startDate');
    return startDateValue ? moment(startDateValue).endOf('day').toDate() : tomorrow;
  }

  disabledEndDate = (value: Date): boolean => {
    return moment(value).endOf('day').toDate().getTime() < this.getDefaultEndDateSelect().getTime();
  };

  clickClone() {
    if (!this.warehouseId) {
      this.showErr('Missing warehouseId!');
      return;
    }
    if (!this.clonePlan.id) {
      this.showErr('Missing clone planId!');
      return;
    }
    if (!this.timezone) {
      this.showErr('Missing timezone!');
      return;
    }
    this.onProgress = true;
    const url = `${Const.APIURI_WAREHOUSE_RATE_PLAN}/${this.clonePlan.id}/clone`;
    const body = this.getFormData_JSON(true);
    body.startDate = XDockDateHelper.getWarehouseMoment(body.startDate, this.timezone).startOf('day').toISOString();
    body.endDate = XDockDateHelper.getWarehouseMoment(body.endDate, this.timezone).endOf('day').toISOString();
    const options = {customHeaders: {warehouseId: this.warehouseId}};

    this.api.POST(url, body, options).subscribe(resp => {
      this.onProgress = false;
      this.onCreateSuccess(resp);
    }, err => {
      this.showErr(err);
      this.onProgress = false;
    });
  }

  public getDurationText() {
    return XDockDateHelper.getDurationText(this.clonePlan?.startDate, this.clonePlan?.endDate, this.timezone);
  }

  private setDefaultSelectedDates() {
    if (!this.lastPlan || !this.clonePlan) {
      return;
    }
    const initMoment = XDockDateHelper.getLocalMoment(this.lastPlan.endDate, this.timezone).add(1, 'day');
    this.setItemValue('startDate', initMoment.toDate());
    this.formInput.get('startDate').disable();
    // đặt range date theo plan cũ
    const startMoment = XDockDateHelper.getLocalMoment(this.clonePlan.startDate, this.timezone);
    const endMoment = XDockDateHelper.getLocalMoment(this.clonePlan.endDate, this.timezone);
    const range = endMoment.diff(startMoment, 'days');
    this.setItemValue('endDate', initMoment.clone().add(range, 'day').toDate());
  }
}

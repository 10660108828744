import { Component, Input, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { Const } from "@const/Const";
import { ApiService } from "@services/api.service";
import { InputHelper } from "@services/input-helper";

@Component({
  selector: 'edit-carrier-lookups',
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})
export class EditCarrierLookups extends BaseFormDialog1 implements OnInit {
  @Input() carrierBidInfo;
  @Input() carrierBidItem;
  @Input() type: 'answer' | 'note' = 'note';

  public isError = false;
  public isLoading = false;

  protected formGroupDeclaration: FormGroupDeclaration = {
    answer: {
      label: "Answer",
      placeHolder: "Please choose anwser",
      required: true,
    },
    price: {
      label: "Price",
      placeHolder: "Enter price",
      getValue: InputHelper.getValueMoney, formatValue: InputHelper.formatMoney
    },
    note: {
      label: "Note",
      placeHolder: "Please enter note"
    }
  };
  constructor(api: ApiService) {
    super();
    this.api = api;
  }

  get isCreateNew(): boolean {
    return true;
  }

  get isEditNote(): boolean {
    return this.type === 'note';
  }

  get isEditAnswer(): boolean {
    return this.type === 'answer';
  }

  get isRegisteredCarrier(): boolean {
    return this.carrierBidItem?.carrierId ? true : false;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.createFormInput();
    this.setItemValue("answer", this.carrierBidItem.answer.state + "")
    this.setItemValue("price", this.carrierBidItem.answer.price)
    this.setItemValue("note", this.carrierBidItem.note)
  }

  onAnswerChange(value) {
    if (value == 1) {
      this.setItemValue("price", "")
      this.getItemByKey("price").disable()
    }
    else if (value == 2) {
      let price = this.carrierBidItem?.basePrice || this.carrierBidInfo?.basePrice
      this.setItemValue("price", price)
      this.getItemByKey("price").disable()
    }
    else {
      this.getItemByKey("price").enable()
    }
  }

  get isShowAcceptWithOffer() {
    return this.carrierBidInfo?.basePrice || this.carrierBidItem?.basePrice
  }

  onUpdateCarrierLookups() {
    let json: any = this.getFormData_JSON(true);
    this.setEnableFormGroup(false);
    this.startProgress();
    let params = {};
    if (this.type === 'answer') {
      // if(json.answer > 2) json.answer = 2; //nếu chọn accept with base price thì cũng đưa về là accept.
      if (!json.price && json.answer == 2) {
        return this.showInfo('The price field is required when agreeing to the request.')
      }
      params['answer'] = {
        state: json.answer,
        price: json.price || 0
      }
    }
    else {
      params['note'] = json.note;
    }
    let bidId = this.carrierBidInfo.id;
    let carrierLookupId = this.carrierBidItem.id;
    let carrierId = this.carrierBidItem.carrierId;
    if(carrierId) params['carrierId'] = carrierId;

    this.api.PUT(`${Const.APIURI_CARRIER_LOOKUP}/${bidId}/${carrierLookupId}/update-item`, params).subscribe(
      (resp) => {
        this.stopProgress();
        this.onUpdateSuccess(resp)
      },
      (err) => {
        this.showErr("There was an error during the update.")
        this.setEnableFormGroup(true);
        this.stopProgress();
      }
    )
  }

  onBtnSave() {
    this.onUpdateCarrierLookups();
  }

  onInputChanged(event, key) {
    switch (key) {
      case 'price': return InputHelper.handleInputChangeMoney(event, <FormControl>this.formInput.get(key), true);
    }
  }
}

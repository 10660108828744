
<div nz-row style="margin-bottom: 10px;" class="map-background">
  <div nz-col nzSpan="6">
    <div style="width: 100%;">
      <div>
        <nz-select nzAllowClear nzShowSearch [(ngModel)]="warehouseId" [nzLoading]="isLoadingWarehouseList"
          [nzPlaceHolder]="'Please select a warehouse'" (ngModelChange)="onChangeWarehouse($event)" style="width: 100%;">
          <nz-option *ngFor="let item of crossdockWarehouseList" [nzValue]="item.id" [nzLabel]="item?.name">
          </nz-option>
        </nz-select>
      </div>
    </div>
  </div>
  <!-- <div *ngIf="isShowReloadButton" nz-col nzSpan="1" style="margin-left: 10px">
    <button [nzLoading]="isLoadingMap" nz-button nzType="dashed" (click)="reload()">
      <span nz-icon nzType="reload" nzTheme="outline"></span>
    </button>
  </div> -->
  <div *ngIf="warehouseId" nz-col nzSpan="2" [nzOffset]="15">
    <button [nzLoading]="isLoadingExport" nz-button style="width: 100%;" (click)="export()">
      <span nz-icon nzType="file-excel" nzTheme="outline"></span> Export
    </button>
  </div>
</div>

<!-- <div *ngIf="isShowSyncSvgButton" nz-row style="margin-bottom: 20px;" class="map-background">
  <div nz-col nzSpan="3">
    <button [nzLoading]="isLoadingSyncSvg" nz-button nzType="dashed" style="width: 100%;" (click)="syncInventoryFromSvgMap()">
      <span nz-icon nzType="sync" nzTheme="outline"></span> Sync SVG
    </button>
  </div>
  <div nz-col nzSpan="4" style="margin-left: 10px;">
    <button [nzLoading]="isLoadingSyncSvg" nz-button nzType="dashed" nzDanger style="width: 100%;" (click)="syncInventoryFromSvgMapWithDeleteBefore()">
      <span nz-icon nzType="sync" nzTheme="outline"></span> Sync SVG (Delete All & Sync)
    </button>
  </div>
</div> -->

<nz-tabset *ngIf="warehouseId" nzType="card" (nzSelectedIndexChange)="onTabChange($event)" [nzSelectedIndex]="tabIndex">
  <nz-tab nzTitle="Map View">
    <crossdock-warehouse-map [warehouseId]="warehouseId"></crossdock-warehouse-map>
  </nz-tab>
  <nz-tab nzTitle="List View (By Item)">
    <crossdock-warehouse-table [warehouseId]="warehouseId"></crossdock-warehouse-table>
  </nz-tab>
  <nz-tab nzTitle="List View (By Order)">
    <crossdock-warehouse-list [warehouseId]="warehouseId"></crossdock-warehouse-list>
  </nz-tab>
</nz-tabset>
import { Component } from "@angular/core";
import { BaseDetail } from "@app/admin/base/detail";
import { Const } from "@const/Const";

@Component({
  selector: '[warehouse-manifest-detail]',
  templateUrl: './index.html',
  styleUrls: [ './index.scss' ]
})
export class WarehouseManifestDetail extends BaseDetail {

  ngOnInit(): void {
    super.ngOnInit(); 
  }

  protected getApiUrl(): string {
    return Const.APIURI_WAREHOUSE_MANIFEST;
  }
  
}

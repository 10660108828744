import { Component, Input } from "@angular/core";
import { BaseComponent } from "@abstract/BaseComponent";

@Component({
  selector: 'warp-when-by-template-default',
  templateUrl: './view.html',
  styleUrls: ['./style.scss']
})
export class WarpWhenByTemplateDefault extends BaseComponent {

  constructor() {
    super();
  }

  @Input() displayInfo: any = {};

  public styleTextColor: string = '#000000';
  public styleFontWeight: number = 400;

  ngOnInit(): void {
  }

}
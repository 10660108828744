import { Component } from '@angular/core';
import { Const } from '@const/Const';
import { BaseList } from '@app/admin/base/list';
import { DrawerService } from '@app/drawers/drawer.service';
import { ImportManifest } from './import';
import dayjs from 'dayjs';

@Component({
  selector: '[warehouse-manifest]',
  templateUrl: './view.html',
  styleUrls: ['./style.scss', '../../list.scss']
})
export class WarehouseManifest extends BaseList {

  protected getApiUrl(): string {
    return Const.APIURI_WAREHOUSE_MANIFEST;
  }

  protected onGetDataSucceeded(resp) {
    for (let item of this.listData) {
      item.displayInfo = {
        warehouseName: item.warehouseName,
        clientName: item.client?.name || 'N/A',
        clientAddr: this.getAddressText(item.client?.address) || 'N/A',
        totalCartons: item.quantity?.totalCartons || 0,
        totalPallets: item.quantity?.totalPallets || 0,
        totalLoosedCartons: item.quantity?.totalLoosedCartons || 0,
        totalCartonsInPallet: (item.quantity?.totalCartons || 0) - (item.quantity?.totalLoosedCartons || 0),
      }
      if (item.intendedInjectionDate) {
        let d = dayjs(item.intendedInjectionDate, 'yyyy-MM-dd').toDate();
        item.displayInfo.intendedInjectionDate = dayjs(d).format(Const.FORMAT_GUI_DATE_V2);
      }
    }
  }

  getRouterLink(item) {
    return [this.routeAdminCrossdockWarehouseManifests, item.id]
  }

  onBtnImportManifest() {
    DrawerService.openFormDrawer1(ImportManifest, {
      nzContentParams: {
        createSuccess: this.onBtnRefresh.bind(this),
        closeOnSuccess: true
      },
      nzWidth: 410,
      nzWrapClassName: 'wrap-drawer import-manifest-drawer',
    });
  }

}

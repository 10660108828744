<form [formGroup]="formInput" nz-form style="margin: 0px; padding: 0px">
  <div style="display: flex; flex-wrap: wrap">
    <div class="form-item" style="width: 400px">
      <div class="form-label-v2">Keyword</div>
      <search-box
        #searchBox
        (doSearch)="doSearch($event)"
        [placeHolder]="'Search by name...'"
        [searchKeyword]="searchKeyword"
        style="width: 100%"
      ></search-box>
    </div>

    <div class="form-item" style="width: 120px">
      <div class="form-label-v2">Dedicated</div>
      <div>
        <nz-select
          nzBackdrop="true"
          nzAllowClear
          nzShowSearch
          [formControlName]="'isDedicated'"
          [nzPlaceHolder]="'Select'"
          (ngModelChange)="onChange($event, 'isDedicated')"
          style="width: 100%"
        >
          <nz-option *ngFor="let item of listDedicated" [nzValue]="item.id" [nzLabel]="item?.name">
          </nz-option>
        </nz-select>
      </div>
    </div>
    
    <div class="form-item" style="min-width: 250px; height: auto;">
      <div class="form-label-v2">Vehicles</div>
      <div>
        <nz-select
          nzBackdrop="true"
          nzAllowClear
          nzShowSearch
          nzMode="multiple"
          [formControlName]="'vehicleTypes'"
          [nzLoading]="filterLoading"
          [nzPlaceHolder]="'Select'"
          (ngModelChange)="onChange($event, 'vehicleTypes')"
          style="width: 100%"
        >
          <nz-option *ngFor="let item of listVehicles" [nzValue]="item.id" [nzLabel]="getVehicleName(item)">
          </nz-option>
        </nz-select>
      </div>
    </div>

    <!-- <div class="form-item" style="width: 250px">
      <div class="form-label-v2">Area</div>
      <div>
        <nz-select
          nzBackdrop="true"
          nzAllowClear
          nzShowSearch
          nzMode="multiple"
          [formControlName]="'areaIds'"
          [nzLoading]="filterLoading"
          [nzPlaceHolder]="'Select'"
          (ngModelChange)="onChange($event, 'areaIds')"
          style="width: 100%"
        >
          <nz-option *ngFor="let item of listAreas" [nzValue]="item.id" [nzLabel]="getAreaName(item)">
            
          </nz-option>
        </nz-select>
      </div>
    </div> -->
  </div>
</form>

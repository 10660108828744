import { Component, forwardRef, Inject, Injector, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Utils } from '@services/utils';
import { BaseInputComponent } from '../base-custom-input';
import { ApiService } from '@services/api.service';
import { Const } from '@const/Const';
import _ from "underscore";
import { InputHelper } from '@services/input-helper';

@Component({
  selector: 'app-form-driver',
  templateUrl: './view.html',
  styleUrls: ['style.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => FormDriverComponent), multi: true }],

})
export class FormDriverComponent extends BaseInputComponent {

  @Input() 
  public isLoading?: boolean = true;

  @Input() jobId;
  @Input() carrierId;
  @Input() isDisabled: boolean = false;
  public allDrivers = [];

  constructor(
    @Inject(Injector) protected injector: Injector,
    protected api: ApiService
  ) {
    super(injector)
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.onLoadData();
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  /**
   * Override
   * @param value 
   */
  writeValue(value: any): void {
    //khi update value từ form vào thì gọi lại api để update list driver cho carrier
    this.onLoadData();
    super.writeValue(value);
  }

  /**
   * Load driver list on init.
   */
  onLoadData() {
    this.isLoading = true;
    let url = `${Const.APIURI_DRIVERS}?status=${Const.DriverStatus.active}&limit=-1`;
    if (this.carrierId) {
      url += `&carrierId=${this.carrierId}`;
    }
    this.api.GET(url).subscribe((response) => {
      let drivers = _.get(response, ["data", "list_data"], []);
      this.allDrivers = this.buildDisplayInfo(drivers);
      this.isLoading = false;
    });
  }

  private buildDisplayInfo(drivers) {
    return drivers.filter(x => x).map(driver => {
      return {
        id: driver.id,
        label: this.getDriverNameWithPhone(driver),
        name: this.getFullName(driver),
        phone: this.formatPhone(driver.phone)
      }
    })
  }

  public getDriverNameWithPhone(driver): string {
    if (driver?.phone) return `${this.getFullName(driver)}, ${this.formatPhone(driver?.phone)}`
    return this.getFullName(driver) ?? '';
  }
  public getDriverName(driver): string {
    return this.getFullName(driver);
  }
  public getFullName(user) {
    if (!user) return '';
    if (user.fullName) return user.fullName;
    let text = user.firstName || '';
    if (user.lastName) {
      if (text) {
        text += ' ';
      }
      text += user.lastName;
    }
    return text;
  }
  formatPhone(phone: string) {
    return InputHelper.formatPhone(phone);
  }

}

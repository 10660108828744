<ng-container *ngIf="onProgress">
  <div class="nodata"><i nz-icon nzTheme="outline" nzType="loading"></i></div>
</ng-container>
<ng-container *ngIf="allDataLoaded">
  <div class="flex-column">
    <!--    warehouse info-->
    <div class="border-box flex-space-between center-vertical">
      <nz-breadcrumb>
        <nz-breadcrumb-item>
          <a [queryParams]="{ warehouseId: warehouseId }" [routerLink]="[routeAdminWarehouseRatePlan]">Rate Plans</a>
        </nz-breadcrumb-item>
        <nz-breadcrumb-item>Rates Configuration</nz-breadcrumb-item>
      </nz-breadcrumb>
      <div class="flex center-vertical">
        <span nz-icon nzTheme="outline" nzType="home"></span>
        <div class="left5">{{ warehouseName }}</div>
        <span class="left20" nz-icon nzTheme="outline" nzType="clock-circle"></span>
        <div class="left5">{{ timezoneText }}</div>
      </div>
    </div>
    <!--    header-->
    <div class="flex-space-between center-vertical top40">
      <!--      UOM type-->
      <div class="flex center-vertical">
        <ng-container *ngFor="let type of uomTypes">
          <div (click)="clickChangeUomType(type)" class="right10 uom-box click">
            <div [style.color]="type.value == selectedUomType?.value?'#1802D0':'black'"
                 class="uom-text">{{ type.label }}
            </div>
          </div>
        </ng-container>
        <div nz-popover
             nzPopoverContent="You can configure fees for each type of unit: pallet, carton, piece"
             nzPopoverPlacement="right"
             nzPopoverTitle="Unit of Measurement">
          <span nz-icon nzTheme="outline" nzType="info-circle" style="color: #6b6b6b"></span>
        </div>
      </div>
      <!--      Duration and status-->
      <div class="flex center-vertical">
        <div class="left5">Duration:</div>
        <div class="left5">{{ getDurationText() }}</div>
        <!--        <div *ngIf="!isAdminReadOnlyRole && canEditDuration" class="left5">-->
        <!--          <span (click)="clickEditDuration()" *ngIf="!loadingCheckEditDuration" class="accent click" nz-icon-->
        <!--                nzTheme="outline" nzType="edit"></span>-->
        <!--          <span *ngIf="loadingCheckEditDuration" nz-icon nzTheme="outline" nzType="loading"></span>-->
        <!--        </div>-->

        <nz-tag [nzColor]="getStatusColor()" class="left15">
          <div class="flex center-vertical">
            <span *ngIf="isApplying()" class="right5" nz-icon nzType="check"></span>
            <span>{{ statusText() }}</span>
          </div>
        </nz-tag>
      </div>
    </div>
    <!--    Fees-->
    <ng-container *ngFor="let typeItem of adjustmentTypes">
      <ng-container *ngIf="typeItem.type == 'table'">
        <div class="white-box top20">
          <div class="flex-space-between center-vertical">
            <div class="medium-title">{{ typeItem.label }}</div>
            <!--Add Fee Button-->
            <button (click)="clickAddFee(typeItem)" *ngIf="!isAdminReadOnlyRole && !isExpired" nz-button
                    nzType="primary">
              <span nz-icon nzTheme="outline" nzType="plus"></span>
              Add fee
            </button>
          </div>
          <div class="top15">
            <nz-table
              #feeTable
              [nzData]="getFeeList(typeItem)"
              [nzFrontPagination]="false"
              [nzShowPagination]="false"
              [nzSize]="'default'"
              nzBordered="true"
              nzSize="small"
            >
              <thead>
              <tr>
                <th nzWidth="60px">No</th>
                <th>Name</th>
                <th nzWidth="300px">Code</th>
                <th nzWidth="300px">Price per {{ selectedUomType?.label | lowercase }}</th>
                <th *ngIf="!isAdminReadOnlyRole&&isApplySoon()" nzWidth="150px">Action</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let feeItem of feeTable.data; let index = index">
                <td>{{ index + 1 }}</td>
                <td>{{ feeItem.name }}</td>
                <td>{{ feeItem.code }}</td>
                <td class="medium-title">${{ feeItem.price }}</td>
                <td *ngIf="!isAdminReadOnlyRole&&isApplySoon()">
                  <div class="flex">
                    <span (click)="clickEditFee(feeItem)" class="accent click" nz-icon nz-tooltip nzTheme="outline"
                          nzTooltipTitle="Edit fee" nzType="edit"></span>
                    <div (nzOnConfirm)="clickDeleteFee(feeItem)" *ngIf="!feeItem.deleting"
                         class="flex center-vertical left20 click red"
                         nz-popconfirm
                         nz-tooltip
                         nzPopconfirmPlacement="top"
                         nzPopconfirmTitle="Are you sure you want to delete this fee?"
                         nzTooltipTitle="Delete fee">
                      <span nz-icon nzTheme="outline" nzType="delete"></span>
                    </div>
                    <div *ngIf="feeItem.deleting" class="flex center-vertical left20">
                      <span nz-icon nzTheme="outline" nzType="loading"></span>
                    </div>
                  </div>
                </td>
              </tr>
              </tbody>
            </nz-table>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="typeItem.type == 'input'">
        <div (onDataUpdate)="onStorageFeeUpdate($event)" [canAdd]="!isAdminReadOnlyRole&&!isExpired"
             [canEdit]="!isAdminReadOnlyRole&&isApplySoon()"
             [feeType]="typeItem"
             [planId]="model.id" [rateAdjustment]="getStorageRateAdjustment(typeItem)"
             [uomType]="selectedUomType"
             [warehouseId]="warehouseId"
             warehouse-rate-adjustment-storage-fee></div>
      </ng-container>
    </ng-container>
  </div>
</ng-container>

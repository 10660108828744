import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'normalized-string',
  template: '{{ _str }}',
  styleUrls: []
})
export class NormalizedString {
    _str: string = null

    get str(): string {
        return this._str
    }
    @Input() set str(v: string) {
        if (v) {
            this._str = v.replace('_', ' ').replace('-', ' ').split(' ').map(it => it.substring(0, 1).toUpperCase() + it.substring(1).toLowerCase()).join(" ")
        } else {
            this._str = v
        }
    }
}
import {Component} from '@angular/core';
import {BaseList} from '@base/list';
import {Const} from '@const/Const';
import {DialogService} from '@dialogs/dialog.service';
import {AddPlan} from '@app/admin/crossdock/rate-configuration/add-plan';
import {EditPlanDuration} from '@app/admin/crossdock/rate-configuration/edit-plan-duration';
import moment from 'moment-timezone';
import {ClonePlan} from '@app/admin/crossdock/rate-configuration/clone-plan';
import {XDockDateHelper} from '@app/admin/crossdock/XDockDateHelper';

@Component({
  selector: '[warehouse-rate-plan-list]',
  templateUrl: './view.html',
  styleUrls: ['./style.scss']
})
export class WarehouseRatePlanList extends BaseList {
  public warehouseId = this.getQueryParam('warehouseId');
  public warehouse: any;
  public crossdockWarehouseList = []; //list warehouse hiển thị trong select box
  isLoadingWarehouseList = false;
  expiredWarehouseDate: string;

  get limit() {
    return -1; // get all at once
  }

  get warehouseTimezone() {
    return this.warehouse?.timeZone;
  }

  get timezoneText() {
    return XDockDateHelper.timezoneText(this.warehouseTimezone);
  }

  trackByWarehouse(index: number, item: any): string {
    return item.id;
  }

  onBtnRefresh() {
    this.selectedItems = [];
    this.getData();
  }

  ngOnInit(): void {
    this.getCrossdockWarehouseList();
  }

  onChangeWarehouse(value) {
    this.routeWithQueryUrl({warehouseId: value, page: 1});
  }

  clickButtonAdd() {
    DialogService.openFormDialog1(AddPlan, {
      nzComponentParams: {
        warehouseId: this.warehouseId,
        timezone: this.warehouseTimezone,
        lastPlan: {...this.getLastPlan()},
        closeOnSuccess: true,
        createSuccess: resp => {
          this.onBtnRefresh();
          const newPlanId = resp.data?.id;
          if (newPlanId) {
            this.clickViewDetail({id: newPlanId});
          }
        }
      },
      nzClassName: 'modal-no-padding warehouse-add-rate-plan-modal',
    });
  }

  clickButtonClone(item) {
    DialogService.openFormDialog1(ClonePlan, {
      nzComponentParams: {
        warehouseId: this.warehouseId,
        timezone: this.warehouseTimezone,
        clonePlan: {...item},
        lastPlan: {...this.getLastPlan()},
        closeOnSuccess: true,
        createSuccess: resp => {
          this.onBtnRefresh();
          const newPlanId = resp.data?.id;
          if (newPlanId) {
            this.clickViewDetail({id: newPlanId});
          }
        }
      },
      nzClassName: 'modal-no-padding warehouse-add-rate-plan-modal',
    });
  }

  public isApplying(item) {
    return item.status === 'applying';
  }

  public isApplySoon(item) {
    return item.status === 'apply_soon';
  }

  clickEditPlanDuration(item: any) {
    DialogService.openFormDialog1(EditPlanDuration, {
      nzComponentParams: {
        model: {...item},
        warehouseId: this.warehouseId,
        timezone: this.warehouseTimezone,
        closeOnSuccess: true,
        updateSuccess: resp => {
          this.onBtnRefresh();
        }
      },
      nzClassName: 'modal-no-padding warehouse-add-rate-plan-modal',
    });
  }

  clickDeleteRatePlan(item: any) {
    this.deleteItem(item);
  }

  clickViewDetail(item) {
    this.router.navigate([this.routeAdminWarehouseRatePlan, item.id]);
  }

  clickEditDurationLastPlan() {
    const lastPlan = this.getLastPlan();
    if (lastPlan) {
      this.clickEditPlanDuration(lastPlan);
    }
  }

  countDayToExpired() {
    if (!this.expiredWarehouseDate) {
      return '';
    }
    return this.getCountDayFromNow(this.expiredWarehouseDate) + '';
  }

  protected getApiUrl(): string {
    return Const.APIURI_WAREHOUSE_RATE_PLAN;
  }

  protected getDataByUrl(url: string): void {
    if (!this.warehouseId) {
      return;
    }
    this.expiredWarehouseDate = null;
    const options = {customHeaders: {warehouseId: this.warehouseId}};
    this.isLoading = true;
    this.subApi?.unsubscribe();
    this.subApi = this.api.GET(url, options).subscribe(
      resp => {
        resp.data?.list_data?.forEach(item => {
          item.durationText = this.getDurationText(item);
          item.statusColor = this.getStatusColor(item);
          item.statusText = this.statusText(item);
        });
        this.getDataDone(resp);
        this.isLoading = false;
      }, err => {
        this.showErr(err);
        this.isLoading = false;
      }
    );
  }

  protected getItemName(item): string {
    return `Plan ${this.getDurationText(item)}`;
  }

  protected onGetDataSucceeded(resp) {
    super.onGetDataSucceeded(resp);
    this.getWarehouseInfo();
    this.checkAllPlansExpiredSoon();
  }

  private statusText(item) {
    switch (item.status) {
      case 'apply_soon':
        return 'Apply Soon';
      case 'applying':
        return 'Applying';
      case 'expired':
        return 'Expired';
      default:
        return 'Unknown';
    }
  }

  private getStatusColor(item) {
    switch (item.status) {
      case 'apply_soon':
        return 'warning';
      case 'applying':
        return 'success';
      case 'expired':
        return 'default';
      default:
        return 'default';
    }
  }

  private getDurationText(item) {
    return XDockDateHelper.getDurationText(item?.startDate, item?.endDate, this.warehouseTimezone);
  }

  private getCrossdockWarehouseList() {
    this.isLoadingWarehouseList = true;
    this.api.GET(Const.APIURI_SELECT_WAREHOUSES).subscribe(
      resp => {
        const list = resp?.data?.list_data || [];
        this.crossdockWarehouseList = list.map((item: any) => {
          const {id, warpId, name} = item;
          return {
            id,
            warpId,
            name,
            label: `${warpId} - ${name}`,
            timeZone: item.pickAddr?.metadata?.timeZoneStandard
          };
        });
        this.getWarehouseInfo();
        this.isLoadingWarehouseList = false;
      }, err => {
        this.showErr(err);
        this.isLoadingWarehouseList = false;
      }
    );
  }

  private getWarehouseInfo() {
    this.warehouse = null;
    if (this.warehouseId) {
      const warehouse = this.crossdockWarehouseList?.find(item => item.id == this.warehouseId);
      if (warehouse) {
        this.warehouse = {
          warehouseId: warehouse.id,
          name: warehouse.name,
          timeZone: warehouse.timeZone,
        };
      }
    }
  }

  private getLastPlan() {
    if (!this.listData) {
      return undefined;
    }
    return this.listData[0];
  }

  private checkAllPlansExpiredSoon() {
    const lastPlan = this.getLastPlan();
    if (lastPlan) {
      const countDay = this.getCountDayFromNow(lastPlan.endDate);
      // hiện thông báo khi plan cuối cùng còn <=30 ngày là hết hạn
      if (countDay <= 30) {
        this.expiredWarehouseDate = lastPlan.endDate;
      }
    }
  }

  private getCountDayFromNow(warehouseDate): number {
    const nowMoment = moment.tz(new Date(), moment.tz.guess());
    const dateMoment = XDockDateHelper.getLocalMoment(warehouseDate, this.warehouseTimezone);
    return dateMoment.diff(nowMoment, 'days');
  }
}

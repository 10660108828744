import { BaseComponent } from "@abstract/BaseComponent";
import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: "[countdown-timer]",
    templateUrl: "./index.html",
    styleUrls: ["./index.scss"],
})
export class CountdownTimer{
    @Input() time: number;
    @Output() refreshDispatchService: EventEmitter<any> = new EventEmitter();
    displayTime: string;
 
    ngAfterViewInit() {
        this.countdown();
    }
    private countdownInterval;
    getTime() {
        let displayTime = this.time - Date.now() +1000;
        if (displayTime <= 0) {
            this.refreshDispatchService.emit();
            clearInterval(this.countdownInterval);
            return '';
        }
        return displayTime;
    }
    countdown() {
        this.countdownInterval = setInterval(() => {
            let displayTime = this.getTime();
            this.displayTime = this.formatTime(displayTime);
        }, 1000);
    }
    formatTime(time) {
        let hours = Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        let minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((time % (1000 * 60)) / 1000);
        if(time <= 0) {
            return ``;
        }
        return `${this.padZero(hours)}:${this.padZero(minutes)}:${this.padZero(seconds)}s`;
    }
    padZero(num) {
        return num < 10 ? `0${num}` : num;
    }
}

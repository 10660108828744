import { Component, Input } from "@angular/core";
import { Const } from "@const/Const";
import { Utils } from "@services/utils";
import { BaseFormDrawer } from "@app/drawers/base-form";
import { ApiMethod } from "@app/enum";


function isStringChanged(value1, value2) {
  if (value1 && value2 && value1 !== value2) return true;
  return false;
}

@Component({
  selector: "[warehouse-manifest-edit-pallet]",
  templateUrl: "./view.html",
  styleUrls: ["./index.scss"],
})
export class WarehouseManifestEditPallet extends BaseFormDrawer {

  protected formGroupDeclaration: FormGroupDeclaration = {
    label: {label: 'Label', required: false},
    weight: {label: 'Weight', type: 'number', required: false,},
    weightUnit: {label: '', initialValue: Const.WeightUnits[0], required: true, placeHolder: 'Unit', isChanged: isStringChanged},
    length: {label: 'Length', type: 'number', required: false},
    width: {label: 'Width', type: 'number', required: false},
    height: {label: 'Height', type: 'number', required: false},
    dimsUnit: {label: '', initialValue: Const.SizeUnits[0], required: true, placeHolder: 'Unit', isChanged: isStringChanged},
  };
  
  constructor() {
    super();
  }

  public weightUnits = Const.WeightUnits;
  public sizeUnits = Const.SizeUnits;

  @Input() manifestId;

  ngOnInit(): void {
    super.ngOnInit();
  }

  protected beforeBindModel(model: any = {}) {
    return model;
  }

  get isCreateNew(): boolean {
    return !this.model;
  }

  protected getFormData_JSON(isCreateNew) {
    return super.getFormData_JSON(true);
  }

  protected buildUrl(method: ApiMethod): string {
    let url = this.getApiUrl();
    if (!url) return url;
    let id = (<any>this.model)?.id || (<any>this.model)?._id;
    if (id) {
      url = `${url}/${id}`;
    }
    return url;
  }

  protected getApiUrl(): string {
    return `${Const.APIURI_WAREHOUSE_MANIFEST}/${this.manifestId}/pallets`
  }

  public onClose() {
    this.nzDrawer?.close();
  }

}

import { NgModule } from '@angular/core';

import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzIconModule } from 'ng-zorro-antd/icon';

import { DecimalNumber } from './decimal-number';
import { NormalizedString } from './normalized-string';
import { VehicleSelector } from './vehicle-selector';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TimeDuration } from './duration';
import { DateTimeStr } from './date-time';
import { MileageStr } from './mileage';
import { TimeWindowStr } from './time-window';
import { Address } from './address';

@NgModule({
    imports: [
        NzSelectModule,
        NzCheckboxModule,
        CommonModule,
        FormsModule,
        NzIconModule,
    ],
    declarations: [
        DecimalNumber,
        VehicleSelector,
        NormalizedString,
        TimeDuration,
        DateTimeStr,
        MileageStr,
        TimeWindowStr,
        Address,
    ],
    exports: [
        DecimalNumber,
        VehicleSelector,
        NormalizedString,
        TimeDuration,
        DateTimeStr,
        MileageStr,
        TimeWindowStr,
        Address,
    ],
    providers: []
})

export class UiCommonModule { }
import { ErrorHandler, Injectable } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  constructor(private modal: NzModalService) { }

  handleError(error: any): void {
   const chunkFailedMessage = /Loading chunk [\d]+ failed/;

    if (chunkFailedMessage.test(error.message)) {
      // window.location.reload();
      console.error(error)
      this.modal.error({
        nzTitle: 'Please refresh your browser',
        nzContent: 'It seems that you are using an outdated version. Please refresh your browser to use the latest version.',
        nzOkText: 'Refresh',
        nzOnOk: () => {
          window.location.reload();
        }
      });
      return;
    }
    // https://angular.io/api/core/ErrorHandler
    // The default implementation of ErrorHandler prints error messages to the console
    console.error(error);
  }
}
import { Component, Input } from '@angular/core';
import { ModalHelper } from "@wearewarp/ng-antd";
import { BaseComponent } from '@abstract/BaseComponent';
import { ActivatedRoute } from '@angular/router';
import { DateUtil } from '@services/date-utils';
import { MasterData } from '@services/master.data';
import { Utils } from '@services/utils';

@Component({
  selector: '[job-stop-timeline]',
  templateUrl: './view.html',
  styleUrls: ['./style.scss']
})
export class JobStopTimeline extends BaseComponent {

  constructor(
    protected activatedRoute: ActivatedRoute,
    private modalHelper: ModalHelper
  ) {
    super(activatedRoute)
  }

  private _stops = [];
  @Input() set stops(value) {
    this._stops = value ?? [];
    this.buildDisplayInfo();
  }
  get stops() { return this._stops }
  @Input() mileage;
  public displayInfo: any = {};
  positionMileage;

  ngOnInit(): void {
    super.ngOnInit();
    this.positionMileage = this.getDisplayPositionMileage();
  }

  private buildDisplayInfo() {
    if (!this.stops.length) return;
    const firstStop = this.stops[0];
    const lastStop = this.stops[this.stops.length - 1];
    this.displayInfo = {
      numOfStops: this.stops.length,
      firstStopInfo: firstStop?.info?.addr,
      lastStopInfo: lastStop?.info?.addr,
      firstStopAddr: this.getStopAddressText(firstStop?.info?.addr),
      firstStopAddrFull: this.getStopAddressFullText(firstStop?.info?.addr),
      firstStopScheduled: this.getDisplayTime(firstStop),
      lastStopAddr: this.getStopAddressText(lastStop?.info?.addr),
      lastStopAddrFull: this.getStopAddressFullText(lastStop?.info?.addr),
      lastStopScheduled: this.getDisplayTime(lastStop),
    }
  }

  private getDisplayPositionMileage() {
    if (this.stops.length > 2) {
      return { top: '35px', width: '182px'}
    } else {
      return { top: '26px', width: '152px'}
    }
  }

  copyAddress(content) {
    Utils.copyTextToClipboard(content, () => {
      this.notification.success('Copy address', 'Address has been copied to clipboard')
    })
  }

  private getDisplayTime(stop) {
    const timezone = stop?.info?.addr?.metadata?.timeZoneStandard ?? 'America/Los_Angeles';
    const appointment = this.getAppointment(stop);
    return DateUtil.displayTimeWindow(appointment, {
      timezone: timezone,
      formatDateOnly: 'MMM D, YYYY',
      format: "MMM D, YYYY HH:mm",
    })
  }

  private getAppointment(stop) {
    const appointment = stop.info?.appointmentInfo;
    if (appointment?.from) return appointment

    const timeWindow = stop.info?.windows?.[0]
    if (timeWindow?.from) return timeWindow;
  }

  private getStopAddressText(addr) {
    if (!addr) return '';
    let addrArray = [addr.city, addr.state, addr.zipcode];
    return addrArray.filter(it => it).join(', ')
  }

  private getStopAddressFullText(addr) {
    if (!addr) return '';
    let addrArray = [addr.street, addr.city, addr.state, addr.zipcode];
    return addrArray.filter(it => it).join(', ')
  }

}
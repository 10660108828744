import { Component, OnInit } from "@angular/core";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";

@Component({
  selector: "app-downloading",
  templateUrl: "./downloading.component.html",
  styleUrls: ["./downloading.component.scss"],
})
export class DownloadingComponent extends BaseFormDialog1 implements OnInit {
  constructor() {
    super();
  }

  ngOnInit(): void {}
}

<div class="white-box">
  <div class="select-warehouse-container" nz-col nzSpan="8">
    <nz-select
      (ngModelChange)="onChangeWarehouse($event)"
      [(ngModel)]="warehouseId"
      [nzLoading]="isLoadingWarehouseList"
      [nzPlaceHolder]="'Please select a Warehouse'"
      class="full-width"
      nzAllowClear="false"
      nzShowSearch
    >
      <nz-option
        *ngFor="let item of crossdockWarehouseList ; trackBy: trackByWarehouse"
        [nzLabel]="item?.label || item?.name"
        [nzValue]="item.id"
      ></nz-option>
    </nz-select>
    <button (click)="onBtnRefresh()" [disabled]="!warehouseId" class="left10" nz-button>
      <i *ngIf="isLoading" nz-icon nzTheme="outline" nzType="loading"></i>
      <span *ngIf="!isLoading" nz-icon nzTheme="outline" nzType="reload"></span> Refresh
    </button>
  </div>
</div>
<!--warning box-->
<div *ngIf="expiredWarehouseDate" class="flex top20 warning-box">
  <span class="warning-icon" nz-icon nzTheme="fill" nzType="info-circle"></span>
  <div class="flex-column flex1 left10">
    <div class="medium-text">Rate plan ending soon!</div>
    <div class="top10">Your current plan is ending in {{ countDayToExpired() }} days.
      After it expires, all upcoming orders will not
      be charged.
      <a (click)="clickEditDurationLastPlan()" class="underline">Extend the current plan duration</a> or <a
        (click)="clickButtonAdd()" class="underline">Add a new plan</a> now.
    </div>
  </div>
</div>
<!--Buttons-->
<div class="flex button-box center-vertical">
  <div *ngIf="warehouse" class="flex left5 center-vertical" style="color: #6b6b6b">
    <span nz-icon nzTheme="outline" nzType="clock-circle"></span>
    <div class="left5">{{ timezoneText }}</div>
  </div>
  <div></div>
  <button (click)="clickButtonAdd()" *ngIf="!isAdminReadOnlyRole" [disabled]="!warehouseId" class="button"
          nz-button nzType="primary">
    <i nz-icon nzTheme="outline" nzType="plus"></i>Add Plan
  </button>
</div>
<!--Plan list-->
<div class="white-box">
  <nz-table
    (nzPageIndexChange)="onDataListPageChanged($event)"
    [nzData]="listData"
    [nzFrontPagination]="false"
    [nzHideOnSinglePage]="true"
    [nzLoadingIndicator]="tplLoading"
    [nzLoading]="isLoading"
    [nzPageSize]="limit"
    [nzShowPagination]="totalCount > listData.length"
    [nzTotal]="totalCount"
    nzBordered="true"
  >
    <ng-template #tplLoading>
      <div class="nodata">
        <i nz-icon nzTheme="outline" nzType="loading"></i>
      </div>
    </ng-template>
    <thead>
    <tr>
      <th nzWidth="300px">Duration</th>
      <th nzWidth="200px">Status</th>
      <th>Action</th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let item of listData ; let index = index">
      <tr>
        <td>
          <div class="flex-space-between">
            <div *ngIf="warehouse">{{ item.durationText }}</div>
            <span (click)="clickEditPlanDuration(item)" *ngIf="index == 0 && !isAdminReadOnlyRole" class="click accent"
                  nz-icon
                  nz-tooltip nzTheme="outline" nzTooltipTitle="Edit plan duration" nzType="edit"></span>
          </div>
        </td>
        <td>
          <nz-tag [nzColor]="item.statusColor">
            <div class="flex center-vertical">
              <span *ngIf="isApplying(item)" class="right5" nz-icon nzType="check"></span>
              <span>{{ item.statusText }}</span>
            </div>
          </nz-tag>
        </td>
        <td>
          <div class="flex">
            <div (click)="clickViewDetail(item)" class="flex center-vertical click accent" nz-tooltip
                 nzTooltipTitle="View details and configure rates">
              <span class="right5" nz-icon nzType="eye"></span>
              View Rates
            </div>
            <div (click)="clickButtonClone(item)" *ngIf="!isAdminReadOnlyRole"
                 class="flex center-vertical left20 click accent"
                 nz-tooltip
                 nzTooltipTitle="Clone new rate plan">
              <span class="right5" nz-icon nzType="copy"></span>
              Clone
            </div>
            <div (nzOnConfirm)="clickDeleteRatePlan(item)"
                 *ngIf="!isAdminReadOnlyRole && index == 0 && isApplySoon(item)"
                 class="flex center-vertical left20 click red"
                 nz-popconfirm
                 nz-tooltip
                 nzPopconfirmPlacement="top"
                 nzPopconfirmTitle="Are you sure you want to delete this plan?"
                 nzTooltipTitle="Delete rate plan">
              <span class="right5" nz-icon nzType="delete"></span>
              Delete
            </div>
          </div>
        </td>
      </tr>
    </ng-container>
    </tbody>
  </nz-table>
</div>

import {Component, Input} from '@angular/core';
import {BaseFormDialog1} from '@dialogs/base-form-dlg1';
import {Const} from '@const/Const';
import {FormAddRate} from '@app/admin/crossdock/rate-configuration/add-rate/FormAddRate';
import {Validators} from '@angular/forms';

@Component({
  selector: '[warehouse-add-rate-adjustment]',
  templateUrl: './view.html',
  styleUrls: ['index.scss']
})
export class AddRateAdjustment extends BaseFormDialog1<FormAddRate> {
  @Input() warehouseId: string;
  @Input() planId: string;
  @Input() unit: string;
  @Input() categoryCode: string;
  @Input() categoryName: string;
  protected formGroupDeclaration: FormGroupDeclaration = {
    name: {label: 'Fee name', type: 'string', inputType: 'text', notAcceptEmpty: true, required: true},
    code: {label: 'Fee code', type: 'string', inputType: 'text', notAcceptEmpty: true, required: true},
    price: {
      label: 'Unit price',
      type: 'number',
      inputType: 'number',
      notAcceptEmpty: true,
      required: true,
      validators: [Validators.min(0.01), Validators.maxLength(6)]
    },
  };

  constructor() {
    super();
  }

  get isCreateNew(): boolean {
    return true;
  }

  get shouldCreateFormImmediately() {
    return true;
  }

  get headerText() {
    return `Add ${this.categoryName}`;
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  clickAdd() {
    if (!this.warehouseId) {
      this.showErr('Missing warehouseId!');
      return;
    }
    if (!this.planId) {
      this.showErr('Missing planId!');
      return;
    }
    if (!this.unit) {
      this.showErr('Missing unit!');
      return;
    }
    if (!this.categoryCode) {
      this.showErr('Missing categoryCode!');
      return;
    }
    this.onProgress = true;
    const url = `${Const.APIURI_WAREHOUSE_RATE_PLAN}/${this.planId}/adjustments`;
    const body = this.getFormData_JSON(true);
    body.unit = this.unit;
    body.categoryCode = this.categoryCode;
    const options = {customHeaders: {warehouseId: this.warehouseId}};
    this.api.POST(url, body, options).subscribe(
      resp => {
        this.onProgress = false;
        this.onCreateSuccess(resp);
      }, err => {
        this.showErr(err);
        this.onProgress = false;
      }
    );
  }
}

<form class="form-detail" [formGroup]="formInput" nz-form>
  <div class="group no-padding">
    <!-- header -->
    <div class="group-header" style="display: flex; align-items: center">
      <div style="text-wrap: nowrap !important">Parent Company</div>
      <div style="width: 100%"></div>
      <!-- edit button -->
<!--      <button nz-button (click)="onBtnViewParentCompany()" class="btn-primary">-->
<!--        <span nz-icon nzType="eye" nzTheme="outline"></span>-->
<!--        View More-->
<!--      </button>-->
      <!-- save button -->
      <button
        nz-button
        (click)="onBtnSave()"
        class="btn-primary"
        *ngIf="isEditing"
        [disabled]="onProgress || !needUpdate"
        [nzLoading]="onProgress"
      >
        <i nz-icon nzType="save" nzTheme="outline"></i>
        Save
      </button>
    </div>
    <!-- form -->
    <div class="group-content">
      <div class="tdm-row cell-spacing">
        <div class="tdm-col tdm-col-2" *ngFor="let key of ['name', 'website']">
          <div>
            <div class="form-label-v2">
              {{getLabel(key)}}
            </div>
            <nz-form-item>
              <nz-form-control>
                <input
                  nz-input
                  [formControlName]="key"
                  [type]="getInputType(key)"
                  [placeholder]="getPlaceHolder(key)"
                  (input)="onInputChanged($event, key)"
                />
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
      </div>

      <ng-container *ngFor="let key of ['address']">
        <div class="form-label-v2">
          {{getLabel(key)}}
        </div>
        <nz-form-item>
          <nz-form-control>
            <form-address [formControlName]="key" [disableInput]="!isEditing"></form-address>
          </nz-form-control>
        </nz-form-item>
      </ng-container>
    </div>
  </div>
</form>

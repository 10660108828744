<div class="dashboard-child-container no-padding list">
  <div class="list-header">
    <div *ngIf="customer">Manage locations for customer: <b>{{customer?.name}}</b></div>
    <div class="flex1"></div>
    <button *ngIf="!isAdminReadOnlyRole" nz-button (click)="onBtnDetail()" [disabled]="!customer"><i nz-icon nzType="plus" nzTheme="outline"></i>Add location</button>
  </div>
  <div class="list-body">
    <nz-table
      #nzTable nzBordered="true"
      [nzData]="listData" nzSize="small"
      [nzTotal]="totalCount" [nzFrontPagination]="false"
      [nzShowPagination]="totalCount > listData.length"
      [nzPageSize]="totalCount"
      [nzLoading]="isLoading"
      [nzLoadingIndicator]="tplLoading"
      [nzNoResult]="tplNoData"
      [nzSize]="'default'">
      <ng-template #tplNoData>
        <div *ngIf="!isLoading" class="nodata"><i nz-icon nzType="search" nzTheme="outline"></i>No data</div>
      </ng-template>
      <ng-template #tplLoading>
        <div class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
      </ng-template>
      <thead>
        <tr>
          <th nzWidth="80px">ID</th>
          <th>Name</th>
          <th colspan="2">Address</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of listData; let i = index">
          <td>{{item.warpId}}</td>
          <td class="col-client-first"><a (click)="onBtnDetail(item)">{{item.name}}</a></td>
          <td class="border-right-none">{{getAddressText(item.pickAddr)}}</td>
          <td class="actions w50">
            <button *ngIf="!isAdminReadOnlyRole" (click)="onBtnDel(item)" nz-button nzType="text" nzShape="circle" nzDanger><i nz-icon nzType="delete" nzTheme="outline"></i></button>
          </td>
        </tr>
      </tbody>
    </nz-table>

  </div>
</div>
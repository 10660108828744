import { Component, forwardRef, Inject, Injector } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Utils } from '@services/utils';
import { BaseInputComponent } from '../base-custom-input';
import { Const } from '@const/Const';

@Component({
  selector: 'form-location-settings',
  templateUrl: './view.html',
  styleUrls: ['./style.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => FormLocationSettings), multi: true }],

})
export class FormLocationSettings extends BaseInputComponent {

  constructor(
    @Inject(Injector) protected injector: Injector,
  ) {
    super(injector)
  }

  ngOnInit(): void {
    super.ngOnInit()
  }

  readonly useGeoFencingType = {
    force: 'force',
    alert: 'alert',
  }

  get value() {
    return this.selected
  }

  set value(value) {
    if (Utils.isBoolean(value?.scanBarcodeRequired)) {
      this.scanBarcodeRequired = value.scanBarcodeRequired;
    }
    if (Utils.isBoolean(value?.signatureRequired)) {
      this.signatureRequired = value.signatureRequired;
    }
    if (Utils.isBoolean(value?.photoRequired)) {
      this.photoRequired = value.photoRequired;
    }
    if (Utils.isBoolean(value?.bolRequired)) {
      this.bolRequired = value.bolRequired;
    }
    if (Utils.isBoolean(value?.podAtLocationLevel)) {
      this.podAtLocationLevel = value.podAtLocationLevel;
    }
    this.useGeoFencing = value?.useGeoFencing ?? null;
    this.onValueChange();
  }

  _scanBarcodeRequired = Const.DefaultLocationSettings.scanBarcodeRequired; 
  _signatureRequired = Const.DefaultLocationSettings.signatureRequired; 
  _photoRequired = Const.DefaultLocationSettings.photoRequired; 
  _bolRequired = Const.DefaultLocationSettings.bolRequired; 
  _podAtLocationLevel = Const.DefaultLocationSettings.podAtLocationLevel;
  _useGeoFencing = null;

  get scanBarcodeRequired() { return this._scanBarcodeRequired }
  set scanBarcodeRequired(value) {
    this._scanBarcodeRequired = value;
    this.onValueChange();
  }

  get signatureRequired() { return this._signatureRequired }
  set signatureRequired(value) {
    this._signatureRequired = value;
    this.onValueChange();
  }

  get photoRequired() { return this._photoRequired }
  set photoRequired(value) {
    this._photoRequired = value;
    this.onValueChange();
  }

  get bolRequired() { return this._bolRequired }
  set bolRequired(value) {
    this._bolRequired = value;
    this.onValueChange();
  }

  get podAtLocationLevel() { return this._podAtLocationLevel }
  set podAtLocationLevel(value) {
    this._podAtLocationLevel = value;
    this.onValueChange();
  }

  get useGeoFencing() { return this._useGeoFencing; }
  set useGeoFencing(value) {
    this._useGeoFencing = value;
    this.onValueChange();
  }

  onValueChange() {
    this.selected = {
      scanBarcodeRequired: this.scanBarcodeRequired,
      signatureRequired: this.signatureRequired,
      photoRequired: this.photoRequired,
      bolRequired: this.bolRequired,
      podAtLocationLevel: this.podAtLocationLevel,
      useGeoFencing: this.useGeoFencing
    }
    this.onChange(this.selected);
    this.onTouch(this.selected);
  }

}

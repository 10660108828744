import {Component, Input} from '@angular/core';
import { Const } from "@const/Const";
import { BaseFormItem } from "@app/admin/base/form-item";

@Component({
  selector: "[client-detail-label-template]",
  templateUrl: "./index.html",
  styleUrls: [
    "./index.scss",
    "../../../../detail.scss",
    "../../../../../../styles/row-col.scss",
    "../../../../../../styles/form-v2.scss",
  ],
})
// Label Template
export class ClientDetailLabelTemplate extends BaseFormItem {
  protected formGroupDeclaration: FormGroupDeclaration = {
    palletTemplateId: { label: "Pallet Label" },
    sortingTemplateId: { label: "Sorting Label" },
  };

  public palletTemplates = [];
  public sortingTemplates = [];

  private originModel;
  @Input() set myModel(value) {
    let currentModel = this.model;
    this.model = value;
    this.originModel = {...value};
    if(currentModel) this.bindDataModel(value);
  }
  disableEditing() {
    this.setEnableFormGroup(false);
    this.isEditing = false;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.disableEditing();
    this.getLabelTemplates();
  }

  protected getApiUrl(): string {
    return Const.APIURI_CLIENTS;
  }

  protected onUpdateSuccess(resp) {
    super.onUpdateSuccess(resp);
    this.disableEditing();
    this.originModel = {...this.model};
  }

  public isLoadingTemplates = false;
  private getLabelTemplates() {
    this.isLoadingTemplates = true;
    this.api.GET(`${Const.APIURI_LABEL_TEMPLATES}?limit=-1`).subscribe(
      (resp) => {
        this.isLoadingTemplates = false;
        let data = resp.data.list_data || [];
        this.palletTemplates = data.filter((lt) => lt.type === Const.LabelTemplateTypes.PALLET);
        this.sortingTemplates = data.filter((lt) => lt.type === Const.LabelTemplateTypes.SORT);
      },
      (err) => {
        this.isLoadingTemplates = false;
        this.showErr(err);
      }
    );
  }
   onBtnCancel() {
    this.myModel = {...this.originModel};
    this.createFormInput(this.model);
    this.disableEditing();
  }

  isInputsChange() {
    return this.isFormDataChanged();
  }

}

import { Component, Input } from '@angular/core';
import { BaseForm, FormGroupDeclaration } from '@wearewarp/ng-form';
import { ModalFormInterface } from '@wearewarp/ng-antd';
import { Validators } from '@angular/forms';

export interface FormDataAddQuickOrderSetting {
  email: string,
}

@Component({
  selector: 'add-quick-order-setting',
  templateUrl: './view.html',
  styleUrls: []
})
export class AddQuickOrderSetting extends BaseForm<FormDataAddQuickOrderSetting> implements ModalFormInterface<FormDataAddQuickOrderSetting> {

  @Input() type;
  protected formGroupDeclaration: FormGroupDeclaration = {
    email: {label: 'Email', required: true, validators: Validators.email},
  }

}

<div>
  <div class="group-header bottom20 flex">
    <div>
      <nz-select nzBackdrop="true" [(ngModel)]="searchCarrierData" nzMode="multiple" 
        nzShowSearch nzAllowClear 
        nzPlaceHolder="Select Carrier"
        (ngModelChange)="onSearchCarrierChanged($event)"
        style="min-width: 250px;">
        <nz-option *ngFor="let option of listOfOptionCarrier" [nzLabel]="option" [nzValue]="option"></nz-option>
      </nz-select>
    </div>
    <div class="left20">
      <nz-select nzBackdrop="true" [(ngModel)]="searchConsigneeData" nzMode="multiple" 
        nzShowSearch nzAllowClear 
        nzPlaceHolder="Select Consignee"
        (ngModelChange)="onSearchConsigneeChanged($event)"
        style="min-width: 250px;">
        <nz-option *ngFor="let option of listOfOptionConsignee" [nzLabel]="option" [nzValue]="option"></nz-option>
      </nz-select>
    </div>
  </div>
  <div class="group-body">
    <nz-table #nzTable nzBordered="true"
      [nzData]="listOfData"
      [nzHideOnSinglePage]="true"
      [nzNoResult]="tplNoData"
      [nzSize]="'default'">
      <ng-template #tplNoData>
        <div class="nodata"><i nz-icon nzType="search" nzTheme="outline"></i>No data</div>
      </ng-template>
      <thead>
        <tr>
          <th [nzSortOrder]="null" [nzSortDirections]="['ascend', 'descend', null]" [nzSortFn]='sortName'>Name</th>
          <th [nzSortOrder]="null" [nzSortDirections]="['ascend', 'descend', null]" [nzSortFn]='sortLabel'>Label</th>
          <th [nzSortOrder]="null" [nzSortDirections]="['ascend', 'descend', null]" [nzSortFn]='sortWeight'>Weight</th>
          <th>DIM (LxWxH)</th>
          <th [nzSortOrder]="null" [nzSortDirections]="['ascend', 'descend', null]" [nzSortFn]='sortItem'>Item</th>
          <th [nzSortOrder]="null" [nzSortDirections]="['ascend', 'descend', null]" [nzSortFn]='sortCarrier'>Carrier</th>
          <th [nzSortOrder]="null" [nzSortDirections]="['ascend', 'descend', null]" [nzSortFn]='sortConsignee'>Consignee</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of nzTable.data; let i = index">
          <td>
            <a [routerLink]="getRouterLink(item)">{{ item.name }}</a>
          </td>
          <td>
            <div *ngIf="item.label">{{ item.label }}</div>
            <div *ngIf="!item.label">
              <nz-tag nzColor="gold">To label</nz-tag>
            </div>
          </td>
          <td>
            <div *ngIf="item.weight">{{ getWeightValue(item) }}</div>
            <div *ngIf="!item.weight">
              <nz-tag nzColor="gold">To weight</nz-tag>
            </div>
          </td>
          <td>
            <div *ngIf="getDimValue(item)">{{ getDimValue(item) }}</div>
            <div *ngIf="!getDimValue(item)">
              <nz-tag nzColor="gold">To DIM</nz-tag>
            </div>
          </td>
          <td>
            {{ item.itemIds?.length || 0 }} items
          </td>
          <td>
            {{ item.carrier || 'N/A' }} 
          </td>
          <td>
            {{ item.consignee || 'N/A' }} 
          </td>
          <td>
            <div>
              <span class="btn-edit" (click)="onBtnEditPallet(item)">Edit</span>
              <span *ngIf="shouldShowBtnDelete(item)" class="btn-delete" (click)="onBtnDeletePallet(item)">Delete</span>
            </div>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</div>
import { CompositeLayer, TripsLayer, PathLayer } from 'deck.gl';
import _ from 'underscore'

export default class RoutesTripLayer {
    id: string
    static layerName = "RoutesTripLayer"
    routes: any[] = []
    currentTime: number = 0
    selectRoute: (s: string) => void
    options: any = {}
    _pathLayer: any = null
    _tripLayer: any = null

    get deckglLayers() {
        return [this._pathLayer, this._tripLayer]
    }

    tic() {
        if (!this.options?.animation) return false
        this.currentTime += 50
        if (this.currentTime % 500 == 0) {
            this._tripLayer = this.createRouteLayer()
            return true
        }
        return false
    }

    constructor(id, data: {routes: any[], currentTime?: number}, options: any = {}) {
        this.id = id
        this.routes = data.routes
        this.currentTime = data.currentTime || 0
        this.options = options
        this.selectRoute = options?.selectRoute
        this._pathLayer = this.createPathLayer()
        this._tripLayer = this.createRouteLayer()
    }

    static getInfo(object) {
        if (!object) return null
        const { totalTime, route } = object

        const { stops, cost, name } = route || {}

        return (name ? `Route ${name}\n` : '') +
        `Shipments: ${stops.length / 2}\nMileage: ${(cost.travelDistance / 1609.34).toFixed(1)}mi \nHours: ${(totalTime / 3600).toFixed(1)}`
    }

    static prepareRouteLayerData(route) {
        if (!route) return null
        const { stops, start, startLocation } = route || {}
        const startTime = route.startTime ?? (start?.start ?? (Date.now() / 1000))
        if (!stops?.length) return null
        let coordinates = stops.map(it => {
            const { location } = it || {}
            if (location?.latitude) {
                return [location.longitude, location.latitude]
            }
            const { lat, lng } = location?.latlng || {}
            return [lng, lat]
        })
        let times = stops.map(it => (it.arrTime ?? it.timeWindow.start) - startTime)
        if (startLocation?.latlng?.lat && stops[0].type !== 'PICKUP') {
            const { latlng } = startLocation || {}
            coordinates = [[latlng.lng, latlng.lat]].concat(coordinates)
            times = [0].concat(times)
        }
        const totalTime = times[times.length - 1] - times[0]

        return {
            type: 'ROUTE',
            route: route,
            coordinates,
            times,
            getInfo: RoutesTripLayer.getInfo,
            totalTime
        }
    }

    createPathLayer() {
        return new PathLayer({
            id: this.id + '-routes-path',
            data: this.routes,
            getPath: d => d.coordinates,
            getColor: d => [...d.color, 100],
            widthMinPixels: this.options.minWidth || 4,
            jointRounded: true,
            capRounded: true,
            pickable: true,
        });
    }

    createRouteLayer() {
        const trailLength = this.routes?.length ? _.max(this.routes.map(it => it.totalTime)) : 0
        return new TripsLayer({
            id: this.id + 'routes-trip',
            data: this.routes,
            getPath: d => d.coordinates,
            getTimestamps: d => d.times,
            getColor: d => d.color,
            // opacity: 0.8,
            widthMinPixels: this.options.minWidth || 4,
            jointRounded: true,
            capRounded: true,
            fadeTrail: this.options.fading ?? true,
            trailLength: trailLength * 1.2,
            currentTime: this.currentTime % (trailLength + 100),
            transitions: {
                currentTime: 500
            },
            updateTriggers: {
                getPath: [this.routes]
            },
        });
    }
}
import { CompositeLayer } from 'deck.gl';
import { ScatterplotLayer } from '@deck.gl/layers'
import _ from 'underscore'


export default class HighlightedLocationsLayer extends CompositeLayer {
    id: string
    static layerName = "Highlighted-Locations-Layer"
    locations: any[] = []

    constructor(id, locations) {
        super({id, data: locations})
        this.id = id
        this.locations = locations
    }

    createLocationPinLayer() {
        if (!this.locations?.length) return null
        const lnglats = this.locations.map(it => [it.longitude, it.latitude])
        return new ScatterplotLayer({
            id: 'highlighted-dot-layer',
            data: lnglats,
            pickable: true,
            opacity: 1,
            stroked: true,
            filled: true,
            radiusScale: 6,
            radiusMinPixels: 12,
            radiusMaxPixels: 12,
            lineWidthMinPixels: 1,
            lineWidthMaxPixels: 1,
            getPosition: d => d,
            // getRadius: d => Math.sqrt(d.exits),
            getFillColor: d => [10, 250, 0, 100],
            getLineColor: d => [0, 100, 0]
        });
    }

    renderLayers() {
        return [
            this.createLocationPinLayer(),
        ];
    }

}
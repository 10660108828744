import { Injectable } from "@angular/core";
import { MasterData } from "@services/master.data";
import mapboxgl from "mapbox-gl";
import { customMapboxConfig } from "./custom-mapbox";

@Injectable({
  providedIn: 'root'
})
export class MapboxStaticService {
  private map: mapboxgl.Map;
  private sourceIds: Set<string> = new Set();
  private layerIds: Set<string> = new Set();
  private controls: any[] = [];

  public mapElements: any[] = [];

  constructor() {
    this.init();
  }
  init() {
    console.log('MapboxStaticService init');
    // if (this.map) return this
    mapboxgl.accessToken = MasterData.mapboxToken;
    customMapboxConfig(mapboxgl);

    this.map = new mapboxgl.Map({
      container: this.createMapContainer(),
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [-74.5, 40],
      zoom: 9
    });
    (this.map as any).isMapReady = false;
    (this.map as any).ready = () => (this.map as any).isMapReady;
    this.map.once('load', () => (this.map as any).isMapReady = true);
    this.customMapFunctions();
    return this;
  }

  createMapContainer() {
    if(document.getElementById('map-container-static')) return document.getElementById('map-container-static');
    var mapContainer = document.createElement('div');
    mapContainer.id = 'map-container-static';
    mapContainer.style.width = '100%';
    mapContainer.style.height = '100%';
    // mapContainer.style.display = 'none';
    document.body.appendChild(mapContainer);
    return mapContainer;
  }

  getMap() {
    // this.clear();
    //disable static map để giải quyết vấn đề memory leak.
    //hiện tại đã sử dụng proxy-mode để giải quyết vấn đề map.load
    (this.map as any).destroy();
    this.init();
    return this.map;
  }
  getMapContainer() {
    return this.map.getContainer();
  }

  attachMap(element: HTMLElement) {
    element.appendChild(this.getMapContainer());
    this.map.resize();
  }
  detachMap() {
    document.body.appendChild(this.getMapContainer());
  }

  customMapFunctions() {
    const _addSource = this.map.addSource.bind(this.map);
    const _addLayer = this.map.addLayer.bind(this.map);
    const _removeLayer = this.map.removeLayer.bind(this.map);
    const _removeSource = this.map.removeSource.bind(this.map);
    const _addControl = this.map.addControl.bind(this.map);
    const _removeControl = this.map.removeControl.bind(this.map);
    const _remove = this.map.remove.bind(this.map);

    //source
    this.map.addSource = (id: string, source: mapboxgl.AnySourceData) => {
      this.sourceIds.add(id);
      _addSource(id, source);
      return this.map
    }
    this.map.removeSource = (id: string) => {
      try{
      this.sourceIds.delete(id);
      _removeSource(id);
      }catch(e){}
      return this.map
    }

    //layer
    this.map.addLayer = (layer: mapboxgl.AnyLayer, before?: string) => {
      this.layerIds.add(layer.id);
      _addLayer(layer, before);
      return this.map
    }
    this.map.removeLayer = (id: string) => {
      try{
      this.layerIds.delete(id);
      _removeLayer(id);
      }catch(e){
      }
      return this.map
    }

    //control
    this.map.addControl = (control: mapboxgl.Control, position?: string) => {
      console.log('addControl', control);
      this.controls.push(control);
      _addControl(control, position);
      return this.map
    }
    this.map.removeControl = (control: mapboxgl.Control) => {
      _removeControl(control);
      return this.map
    }

    (this.map as any).destroy = () => {
      _remove();
    }
    this.map.remove = () => {
      this.clear();
      return this.map
    }
  }

  clear() {
    this.controls.forEach(control => this.map.removeControl(control));
    this.layerIds.forEach(id => this.map.removeLayer(id));
    this.mapElements.map(element => element.remove());
    this.sourceIds.forEach(id => this.map.removeSource(id));
    this.controls = []
    this.layerIds.clear();
    this.mapElements = [];
    this.sourceIds.clear();
  }
}
import { BaseComponent } from "@abstract/BaseComponent";
import { Component, Input, OnInit } from "@angular/core";
import { environment } from "@env/environment";
import { EventService } from "@services/event.service";

@Component({
  selector: "events-container",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})
export class EventsContainer extends BaseComponent {
    @Input() type: string
    eventService: EventService
    _id: string
    loading: boolean = false
    events: any = {}
    showAll: boolean = false
    get id() {
        return this._id
    }
    @Input() set id(v) {
        this._id = v
        this.loadEvent()
    }

    constructor() {
        super()
        this.eventService = new EventService(this.api)
    }

    loadEvent() {
        this.loading = true
        this.eventService.listEvents(`${this.type}_${this._id}`).subscribe(resp => {
            this.loading = false
            if (resp) {
                this.events = this.processData(resp)
            }
        },
        err => {
            this.events = {}
            this.loading = false
        })
    }
    processData(events) {
        // process data
        // filter
        const excluded = [
            'JOB.DELIVERY.LOCATION_UPDATED',
            'JOB.DELIVERY.TRAFFIC_UPDATE',
        ]
        const items = this.showAll ? events.items : events.items.filter(it => it.action && excluded.indexOf(it.action) < 0)
        const eventMap = {}
        const wrappers = items.map(it => Object.assign({event: it, subs: [], parent: null}))
        for (let e of wrappers) {
            eventMap[e.event.id] = e
        }
        for (let e of wrappers) {
            if (e.event.origin) {
                const parent = eventMap[e.event.origin]
                if (parent) {
                    e.parent = parent.event.id
                    parent.subs.push(e)
                }
            }
        }
        const filtered = wrappers.filter(it => !it.parent)
        return {
            items: filtered,
            count: items.length
        }
    }
}
<style type="text/css">
  .model-btns {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .title {
    font-weight: 600;
    font-size: 16px;
  }
  .dlg-success {
    width: 437px;
  }
  .dlg-items {
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>

<div>
  <div class="top20 dlg-items">
    <img class="bottom20" width="83px" height="83px" src="/assets/img/ic_success.png" />
  </div>
  <div class="title dlg-items bottom20">Create Route Successfully</div>
  <div>
    <div class="bottom10" style="text-align: center">Route Code: {{ route?.code }}</div>
    <div class="bottom10" style="text-align: center">Shipment WARP ID: {{ getShipmentInfo() }}</div>
    <div class="dlg-items">
      <a [href]="getDispatchLink().hyperLinkUrl" target="_blank">{{ getDispatchLink().hyperLinkText }}</a>
      <span
        nz-icon
        nzType="copy"
        nzTheme="outline"
        (click)="copyTrackingLink()"
        nz-tooltip
        nzTooltipTitle="Copy Dispatch URL"
        style="cursor: pointer; margin-left: 8px"
      ></span>
    </div>
  </div>

  <div class="top20 bottom20 model-btns">
    <button nz-button nzType="primary" style="width: 70px" (click)="onDone()">Done</button>
  </div>
</div>

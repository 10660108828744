
<div nz-row>
  <div nz-col nzSpan="11" class="map-background" style="text-align: center;">

    <nz-empty class="no-data" *ngIf="isShowNoData"></nz-empty>

    <i *ngIf="isLoadingMap" class="loading-icon" nz-icon nzType="loading" nzTheme="outline"></i>

    <div id="mapArea"></div>

  </div>
  <div nz-col nzSpan="12" [nzOffset]="1" class="map-background" style="height: fit-content;">

    <i *ngIf="isLoadingInventoryInfo || isLoadingMap" class="loading-icon" nz-icon nzType="loading" nzTheme="outline"></i>

    <div *ngIf="isShowWarehouseOverview">
      <div class="label" style="margin-bottom: 15px">Warehouse Name: {{this.model?.name || 'N/A'}} </div>
        <div class="detail" style="margin-bottom: 6px">
          <span nz-icon nzType="build" nzTheme="outline"></span>
          Crossdock Inventories: {{this.model?.inventories?.length || 'N/A'}}
        </div>
        <div class="detail"><span nz-icon nzType="environment" nzTheme="outline"></span> 
          Address: {{getAddressText(this.model?.pickAddr) || 'N/A'}}
        </div>
    </div>

    <div *ngIf="isShowInventoryDetail">
      <div class="label" style="margin-bottom: 15px">
        <img src="/assets/img/crossdock-id-icon.svg" /> 
        {{getDetailLabelText()}}
      </div>

      <nz-table #nzTable [nzData]="selectedInventoryInfo?.orderItemInfos" [nzPageSize]="10" [nzScroll]="{ x: '600px' }">
        <thead>
          <tr>
            <th nzWidth="100px">Item ID</th>
            <!-- <th nzWidth="100px">Route ID</th> -->
            <th nzWidth="170px">Barcode</th>
            <th nzWidth="100px">Weight</th>
            <th nzWidth="130px">Shipment ID</th>
            <th nzWidth="100px">Leg ID</th>
            <th nzWidth="150px">To City</th>
            <th nzWidth="200px">Date Received</th>
            <th nzWidth="120px">Action</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let item of nzTable.data; let i = index">
            <tr>
              <td>{{getItemWarpId(item)}}</td>
              <!-- <td></td> -->
              <td>{{getItemBarcode(item)}}</td>
              <td>{{getItemWeight(item)}}</td>
              <td>
                <div *ngIf="item?.shipmentWarpId" class="detail"> 
                  <a [href]="getLinkToShipmentDetail(item)" target="_blank">{{getItemShipmentId(item)}}</a>
                </div>
                <div *ngIf="!item?.shipmentWarpId">{{naText}}</div>
              </td>
              <td>{{getItemLegId(item)}}</td>
              <td>{{getItemToCity(item)}}</td>
              <td>{{getItemDateReceived(item)}}</td>
              <td>
                <div class="remove-item">
                  <a (click)="removeItem(selectedInventoryInfo, item)">
                    <span nz-icon nzType="logout" nzTheme="outline"></span>
                     Remove
                  </a>
                </div>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </nz-table>
    </div>

    <nz-empty class="no-data" *ngIf="isShowNoData"></nz-empty>

  </div>
</div>
<ng-template [nzModalTitle]>
  <div class="flex-space-between">
    <div>{{ headerText }}</div>
  </div>
</ng-template>
<div>
  <form [formGroup]="formInput" class="form-detail" nz-form>
    <div>
      <div>
        {{ getLabel("name") }}
        <span *ngIf="isRequired('name')" class="label-mark-required"></span>
      </div>
      <div class="top10">
        <input class="full-w" formControlName="name" nz-input placeholder="Enter here">
      </div>
    </div>
    <div class="top20">
      <div>
        {{ getLabel("code") }}
        <span *ngIf="isRequired('code')" class="label-mark-required"></span>
      </div>
      <div class="top10">
        <input class="full-w" formControlName="code" nz-input placeholder="Enter here">
      </div>
    </div>
    <div class="top20">
      <div>
        {{ getLabel("price") }}
        <span *ngIf="isRequired('price')" class="label-mark-required"></span>
      </div>
      <div class="top10">
        <nz-input-group nzPrefix="$">
          <input [max]="999999" [min]="0.01" [step]="0.1" [type]="getInputType('price')" class="full-w"
                 formControlName="price" nz-input placeholder="Enter here">
        </nz-input-group>
      </div>
    </div>
  </form>
</div>
<ng-template [nzModalFooter]>
  <div>
    <div (onCancel)="closeDialog()" (onOK)="clickEdit()"
         [canClickCancel]="!onProgress" [canClickOK]="needUpdate && !onProgress"
         [labelOK]="'Save'"
         [nzIconCancel]="null" [nzIconOK]="null"
         [onProgress]="onProgress" form-footer></div>
  </div>
</ng-template>

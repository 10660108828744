import { getInjector } from "@services/injector";
import { IssueInfo } from "@wearewarp/types/data-model/types/Job";

export default class IssueEntity {
  private data: IssueInfo;
  private index: number;

  constructor() {
    const injector = getInjector();
  }

  async init(data) {
    this.data = data;
    return this;
  }

  getStopId() {
    return this.data.stopId
  }

  getType() {
    return this.data.type
  }

  getStatus() {
    return this.data.status
  }

  toJSON(){
    return this.data
  }
}

import { Component, forwardRef, Inject, Injector, Input, OnInit } from "@angular/core";
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from "@angular/forms";
import { MasterData } from "@services/master.data";
import { BaseInputComponent } from "../base-custom-input";

interface EquipmentOptionType {
  _id: string;
  name: string;
}
@Component({
  selector: "app-form-equipments",
  templateUrl: "./form-equipments.component.html",
  styleUrls: ["./form-equipments.component.scss"],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => FormEquipmentsComponent), multi: true }],
})
export class FormEquipmentsComponent extends BaseInputComponent {
  public options: {
    name: string;
    equipments: EquipmentOptionType[];
  }[] = [];

  @Input() mode: "default" | "multiple" = "multiple";
  @Input() readonly: boolean = false;

  constructor(@Inject(Injector) protected injector: Injector) {
    super(injector)
  }
  ngOnInit(): void {
    super.ngOnInit()
    this.onOptions();
  }

  onOptions() {
    this.options = MasterData.getAllShipmentModesV2();
  }
}

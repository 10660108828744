import { Component, forwardRef, HostBinding, EventEmitter, Input, OnInit, Output, Inject, Injector } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { MasterData } from "@services/master.data";
import { ServiceOptionType } from "@wearewarp/types/rest-api";
import { BaseInputComponent } from "../base-custom-input";

@Component({
  selector: "app-form-service-options",
  templateUrl: "./form-service-options.component.html",
  styleUrls: ["./form-service-options.component.scss"],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => FormServiceOptionsComponent), multi: true }],
})
export class FormServiceOptionsComponent extends BaseInputComponent {
  public options: {
    label: string;
    items: ServiceOptionType[];
  }[] = [];
  public selected: any = [];

  @Input() mode: "default" | "multiple" = "multiple";
  @Input() shows?: ("pickup" | "delivery" | "additonal")[] = ["pickup", "delivery", "additonal"];

  constructor(@Inject(Injector) protected injector: Injector) {
    super(injector)
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.onLoadServiceOptions();
  }

  onLoadServiceOptions() {
    for (let showGroup of this.shows) {
      switch (showGroup) {
        case "additonal":
          this.options.push({ label: "Additional services", items: MasterData.ShipmentServiceOptionsAddition });
          break;
        case "pickup":
          this.options.push({ label: "Pickup services", items: MasterData.ShipmentServiceOptionsPickup });
          break;
        case "delivery":
          this.options.push({ label: "Delivery services", items: MasterData.ShipmentServiceOptionsDelivery });
          break;
      }
    }
  }
}

import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { BaseComponent } from '@abstract/BaseComponent';
import { VehicleTypeService } from '@services/vehicle.service';
import { VehicleType } from '@wearewarp/types/data-model';
import _ from 'underscore'
import { NzSelectModeType } from 'ng-zorro-antd/select';

@Component({
  selector: '[vehicle-selector]',
  templateUrl: './index.html',
  styleUrls: ['./index.scss',]
})
export class VehicleSelector extends BaseComponent {
    types: any[] = []
    vehicleTypeService: VehicleTypeService = null
    vehicleType = null
    selectedVehicle: any = null
    public vehicles: any[] = []
    public vehicleSelectOptions: any[] = []
    loading: boolean = false
    options: any[] = []
    @Input() quoting: boolean = false
    @Input() mode: NzSelectModeType = 'default'
    @Input() withOptions: boolean = false
    @Input() isDisabled: boolean = false
    @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();


    _value = null
    get value() {
        return  this._value
    }
    @Input() set value(v) {
        if (this.mode == 'default') {
            this._value = v
            this.vehicleType = v?.code
            this.options = v?.options || []
            this.selectedVehicle = this.vehicleType ? this.vehicles.filter(it => it.code === this.vehicleType)[0] : null
        } else {
            this._value = (v ?? []);
            this.vehicleType = this._value;
        }
        
    }

    constructor() {
        super()
        this.vehicleTypeService = new VehicleTypeService(this.api)
        this.loadVehicles()
        // this.vehicleType = this.value?.code
        // this.options = this.value?.options || []
        // this.selectedVehicle = this.vehicleType ? this.vehicles.filter(it => it.code === this.vehicleType)[0] : null

        // console.log('Init', this.value, this.quoting, this.withOptions)
    }

    ngOnChanges(): void {
        if (this.mode == 'default') {
            this.vehicleType = this.value?.code
            this.options = this.value?.options || []
            this.selectedVehicle = this.vehicleType ? this.vehicles.filter(it => it.code === this.vehicleType)[0] : null
        } else {
            this.vehicleType = this.value;
        }
        
    }

    onValueChange(event) {
        if (this.mode == 'default') {
            this.selectedVehicle = this.vehicles.filter(it => it.code === this.vehicleType)[0]
            const selectableOptions = this.selectedVehicle?.options || []
            this._value = {
                code: this.vehicleType,
                name: this.selectedVehicle?.name,
                palletCapacity: this.selectedVehicle?.palletCapacity,
                metadata: this.selectedVehicle?.metadata,
                options: this.options.filter(it => selectableOptions.indexOf(it) >= 0),
            }
            this.valueChange?.emit(this._value)
        } else {
            this.valueChange?.emit(event)
        }
        
    }

    onOptionChange(type, event) {
        if (event) {
            if (this.options.indexOf(type) < 0)
                this.options.push(type)
        } else {
            this.options = this.options.filter(it => it !== type)
        }
        const selectableOptions = this.selectedVehicle?.options || []
        this._value = {
            ...this._value,
            options: this.options.filter(it => selectableOptions.indexOf(it) >= 0),
        }
        this.valueChange?.emit(this._value)
    }

    loadVehicles() {
        this.loading = true
        this.vehicleTypeService.listAll(
            (data) => {
                this.loading = false
                this.types = data.filter(it => it.active !== false)
                let allTypes = {}
                for (let t of this.types) {
                    allTypes[t.code] = t
                }
                this.vehicles = _.sortBy(this.types.filter(it => !!it.selectable), "order")
                this.vehicleSelectOptions = this.vehicles.filter(it => !this.quoting || it.quotable !== false).map(t => Object.assign({}, {
                    label: t.name,
                    groupLabel: allTypes[t.parent]?.name,
                    value: t.code,
                    disabled: !t.selectable
                }))

                this.selectedVehicle = this.vehicleType ? this.vehicles.filter(it => it.code === this.vehicleType)[0] : null
            },
            () => {
                this.loading = false
                this.types = []
                this.vehicles = []
                this.vehicleSelectOptions = []
                this.selectedVehicle = null
            }
        )
    }
}
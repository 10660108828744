import {Component} from '@angular/core';
import {BaseDetail} from '../../base/detail';
import {Const} from '@const/Const';

@Component({
  selector: '[client-detail]',
  templateUrl: './index.html',
  styleUrls: [
    './index.scss',
    '../../detail.scss',
    '../../../../styles/row-col.scss',
    '../../../../styles/form-v2.scss',
  ],
})
export class ClientDetail extends BaseDetail {
  selectedTabIndex = 0;

  get routerUrlBack() {
    return this.routeAdminClientList;
  }

  isEnableQuickOrder() {
    return this.model?.quickOrderInfo?.isEnable;
  }

  getParentId() {
    return this.model?.metadata?.parentClient;
  }

  hasParentCompany() {
    return this.getParentId();
  }

  protected getApiUrl(): string {
    return Const.APIURI_CLIENTS;
  }

  protected canDelete(model: any): boolean {
    return this.isAdmin;
  }

  protected handleNavigationEnd(url: string, prevQueryParam: any) {
    super.handleNavigationEnd(url, prevQueryParam);
    let tab = this.queryParams['tab'];
    if (tab == 'sub-account' && !this.hasParentCompany()) {
      this.selectedTabIndex = 1;
    }
  }
}

import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: '[decimal]',
  template: '<span>{{display}}</span>',
  styleUrls: []
})
export class DecimalNumber extends Component implements OnChanges  {
    @Input() value: number | null = null
    @Input() decimals: number = 1
    @Input() default: string = null

    display: string | null = null

    ngOnChanges(changes: SimpleChanges): void {
        this.display = this.value ? Number(this.value).toFixed(this.decimals) : this.default
    }
    
}
<div class="dashboard-child-container no-padding list">
  <div class="list-header flex no-padding" style="flex-direction: column; width: 100%;background-color: unset">
    <div dat-truck-filter (onSearch)="onSearch($event)" [equipmentTypes]="equipmentTypes" [loadTypes]="loadTypes"
      style="width: 100%;"></div>
    <div style="margin-top: 20px;display: flex;width: 100%;align-items: center;justify-content: space-between">
      <div style="margin-left: 4px">Total: {{ totalCount }}</div>
    </div>
  </div>

  <div class="list-body">
    <nz-table #nzTable (nzPageIndexChange)="onDataListPageChanged($event)" [nzData]="listData"
      [nzFrontPagination]="false" [nzLoadingIndicator]="tplLoading" [nzLoading]="isLoading" [nzNoResult]="tplNoData"
      [nzPageIndex]="pageIndex" [nzPageSize]="limit" [nzShowPagination]="totalCount > listData.length"
      [nzSize]="'default'" [nzTotal]="totalCount" nzBordered="true" nzSize="small">
      <ng-template #tplNoData>
        <div *ngIf="!isLoading" class="nodata"><i nz-icon nzTheme="outline" nzType="search"></i>
          No results. Please try moving to the other tabs for more results.
        </div>
      </ng-template>
      <ng-template #tplLoading>
        <div class="nodata"><i nz-icon nzTheme="outline" nzType="loading"></i></div>
      </ng-template>
      <thead (nzSortOrderChange)="onSortOrderChange($event)">
        <tr>
          <th nzWidth="60px"></th>
          <th [(nzSortOrder)]="sortOrderAge" [nzSortFn]="true" nzColumnKey="age" nzWidth="40px">AGE</th>
          <th [(nzSortOrder)]="sortOrderRate" [nzSortFn]="true" nzColumnKey="rate" nzWidth="40px">RATE</th>
          <th [(nzSortOrder)]="sortOrderAvailable" [nzSortFn]="true" nzColumnKey="available" nzWidth="20px">AVAILABLE
          </th>
          <th [(nzSortOrder)]="sortOrderTrip" [nzSortFn]="true" nzColumnKey="trip" nzWidth="30px">TRIP</th>
          <th [(nzSortOrder)]="sortOrderOrigin" [nzSortFn]="true" nzColumnKey="origin" nzWidth="60px">ORIGIN</th>
          <th [(nzSortOrder)]="sortOrderDho" [nzSortFn]="true" nzColumnKey="dho" nzWidth="50px">DHO</th>
          <th [(nzSortOrder)]="sortOrderDestination" [nzSortFn]="true" nzColumnKey="destination" nzWidth="50px">
            DESTINATION
          </th>
          <!-- <th [(nzSortOrder)]="sortOrderDhd" [nzSortFn]="true" nzColumnKey="dhd" nzWidth="50px">DHD</th> -->
          <th nzColumnKey="equipment">EQUIPMENT</th>
          <th nzColumnKey="company">COMPANY</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let item of listData; let i = index">
          <tr [ngClass]="{'deleted': item.delete}">
            <td [nzExpand]="expandSet.has(item.matchId)" (nzExpandChange)="onExpandChange(item.matchId, $event)"></td>
            <!--          AGE-->
            <td>
              <div>{{ getEventDuration(item.servicedWhen) }}</div>
            </td>

            <!--          RATE-->
            <td>
              <div>-</div>
            </td>

            <!--          AVAILABLE-->
            <td>
              <div>{{getDate(item.availability?.earliestWhen)}} - {{getDate(item.availability?.latestWhen)}}</div>
            </td>

            <!--          TRIP-->
            <td>
              <div>{{item.tripLength?.miles || '-'}}</div>
            </td>

            <!--          ORIGIN-->
            <td>
              <div>{{getAddress(item.matchingAssetInfo?.origin)}}</div>
            </td>

            <!--          DHO-->
            <td>
              <div>{{item.originDeadheadMiles?.miles}}</div>
            </td>

            <!--          DESTINATION-->
            <td>
              <div>{{getAddress(item.matchingAssetInfo?.destination)}}</div>
            </td>

            <!--          DHD-->
            <!-- <td>
          <div>{{item.destinationDeadheadMiles?.miles}}</div>
        </td> -->

            <!--          EQUIPMENT-->
            <td>
              <div>{{getEquipment(item.matchingAssetInfo?.equipmentType)}}</div>
              <div>{{getCapacity(item.matchingAssetInfo?.capacity?.truck)}}</div>
            </td>

            <!--          COMPANY-->
            <td>
              <div>
                {{item.posterInfo?.companyName}} ({{getPosterState(item.posterInfo)}})
              </div>
              <div *ngIf="item.posterInfo?.contact?.phone">
                <p nz-typography nzCopyable [nzCopyText]="item.posterInfo?.contact?.phone">{{displayPhone(item.posterInfo?.contact?.phone)}}</p>
              </div>
              <div *ngIf="item.posterInfo?.contact?.email">
                <p nz-typography nzCopyable [nzCopyText]="item.posterInfo?.contact?.email">{{item.posterInfo?.contact?.email}}</p>
              </div>
            </td>
          </tr>
          <tr [nzExpand]="expandSet.has(item.matchId)">
            <dat-truck-detail [item]="item" [equipmentTypes]="equipmentTypes" [loadTypes]="loadTypes"></dat-truck-detail>
          </tr>
        </ng-container>
      </tbody>
    </nz-table>
  </div>
</div>
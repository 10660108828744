import { Component } from "@angular/core";
import { DateUtil } from "@services/date-utils";
import { Const } from "@const/Const";
import { BaseFormDialog1 } from '@dialogs/base-form-dlg1';

@Component({
  selector: '[comm-log]',
  templateUrl: './index.html',
  styleUrls: ['../../../dialogs/dialogs.scss', './index.scss']
})

export class CommLogList extends BaseFormDialog1 {
  public isLoading: boolean = false;
  public isError: boolean = false;
  private timezone: string = DateUtil.getLocalTimeZone();
  public selectedId = '';
  private statuses = Const.CommLogStatuses;

  ngOnInit(): void {
    this.selectedId = this.model.histories[0]?.logId;
  }

  public getType(type) {
    return type === 'email' ? 'Email' : 'SMS';
  }

  public getReceiver(data) {
    return data.type === 'email' ? data.email : data.phone;
  }

  $asSentStatusText = (item) => {
    let { status, type } = item;
    const statuses = {
      pending: 'pending',
      sent: 'sent',
      failed: 'failed',
      success: 'success',
      delivered: 'delivered',
      opened: 'opened',
      clicked: 'clicked',
      unsubscribed: 'unsubscribed',
      undelivered: 'undelivered',
      invalid: 'invalid',
      unreachable: 'unreachable',
      unknown: 'unknown'
    }
    return this.capitalizeFirstLetter(statuses[status.toLowerCase()] || 'N/A');

    if(!status) return 'N/A';
    status = status.toLowerCase();
    if(!status) return 'N/A';
    let rs = this.statuses.find(it => it.type === type && it.value === status);
    return rs ? rs.text : 'N/A';
  };

  makeColor(item) {
    let { status, type } = item;
    switch (status.toLowerCase()) {
      case 'pending':
        return 'gray';
      case 'sent':
      case 'success':
        return 'green';
      case 'failed':
      case 'invalid':
        return 'red';
      case 'delivered':
      case 'opened':
      case 'clicked':
        return 'blue';
      case 'undelivered':
      case 'unreachable':
      case 'unknown':
        return 'orange';
      case 'unsubscribed':
        return 'black';
      default:
        return '';
    }

    if(!status) return '';
    status = status.toLowerCase();
    let rs = this.statuses.find(it => it.type === type && it.value === status);
    return rs ? rs.color : ''
  }

  chooseItem(logId: string) {
    this.selectedId = logId;
  }

  formatDate(time: string) {
    return DateUtil.displayTimeWindow({ from: time, to: time }, {
      timezone: this.timezone,
      formatDateOnly: 'MM/DD/YY',
      format: "MM/DD/YY h:mm A",
    });
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { Const } from '@const/Const';
import { BaseDialog } from '@dialogs/base-dlg';
import to from 'await-to-js';

@Component({
  selector: 'dat-post-detail',
  templateUrl: './dat-post-detail.component.html',
  styleUrls: ['./dat-post-detail.component.scss']
})
export class DatPostDetailComponent extends BaseDialog {

  @Input() datLoad;
  @Input() onDeleteSuccess: () => void = () => {}; 
  public deleteLoading = false;
  ngOnInit(): void {

  }

  async onDeletePost() {
    this.deleteLoading = true
    const [error, resp] = await to(this.api.POST(`${Const.APIURI_CARRIER_BIDS}/delete-dat-post`, {
      datId: this.datLoad?.id
    }).toPromise())
    if (error) {
      this.showErr(error);
      this.deleteLoading = false
      return;
    }
    this.onDeleteSuccess()
    this.deleteLoading = false
    this.closeDialog()
  }

  onClose(): void {
    this.closeDialog()
  }
}

<div *ngIf="isLoading" class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
<div *ngIf="isError" class="nodata">Cannot fetch data, please try again later</div>

  <div class="carrier-selection">
    <div class="f20 bottom20">Add carrier</div>
    <div class="group-filter">
      <div nz-row [nzGutter]="12">
        <div nz-col [nzSpan]="12">
          <nz-input-group [nzPrefix]="suffixIconSearch">
            <input type="text" nz-input placeholder="Search by Carrier Name, MC Number or DOT Number" (input)="onSearch($event.target.value)" />
          </nz-input-group>
          <ng-template #suffixIconSearch>    
            <span nz-icon nzType="search"></span>
          </ng-template>
        </div>
        <!-- <div nz-col [nzSpan]="8">
          <nz-select nzBackdrop="true" nzShowSearch nzAllowClear [nzLoading]="isLoadingPool"
            nzPlaceHolder="Filter by Pool" style="width: 100%;" 
            (ngModelChange)="onPoolChange($event)"
            [ngModel]="poolSelected"
          >
            <nz-option *ngFor="let item of pools" [nzLabel]="item.name" [nzValue]="item.id"></nz-option>
          </nz-select>
        </div> -->
      </div>
    </div>
    <nz-table
    #rowSelectionTable
    nzShowPagination
    nzShowSizeChanger
    [nzData]="listOfData"
    (nzCurrentPageDataChange)="onCurrentPageDataChange($event)"
  >
    <thead>
      <tr>
        <th [nzChecked]="checked" [nzIndeterminate]="indeterminate" (nzCheckedChange)="onAllChecked($event)"></th>
        <th>Carrier Name</th>
        <th>Status</th>
        <th>Pool</th>
        <th>Type</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of rowSelectionTable.data">
        <td
          [nzChecked]="setOfCheckedId.has(data.id)"
          [nzDisabled]="data.isSelected"
          (nzCheckedChange)="onItemChecked(data.id, $event)"
        ></td>
        <td *ngIf="!data.isSelected">{{ data.title }}</td>
        <td *ngIf="data.isSelected"><span nz-typography nzType="secondary">{{ data.title }} (Added)</span></td>
        <td>
          <ng-container *ngIf="data.status != 1">
            <nz-tag nzColor="warning">{{$asCarrierStatusText(data.status)}}</nz-tag>
          </ng-container>
          <ng-container *ngIf="data.status == 1">
            <nz-tag nzColor="success">Active</nz-tag>
          </ng-container>
        </td>
        <td>
          <ng-container *ngFor="let item of getPoolTags(data.id)">
            <nz-tag>{{ item }}</nz-tag>
          </ng-container>
        </td>
        <td>
          <nz-tag [nzColor]="$asCarrierMatchingType(data.tag).color">{{ $asCarrierMatchingType(data.tag).label }}
          </nz-tag>
        </td>      
      </tr>
    </tbody>
  </nz-table>
  </div>
  <div form-footer [onProgress]="onProgress" [nzIconCancel]="null" [nzIconOK]="null"
  [canClickOK]="setOfCheckedId.size > 0 && !onProgress && !isLoading" [canClickCancel]="!onProgress" labelOK="Add"
  (onOK)="updateCarrier()" (onCancel)="closeDialog()"></div>

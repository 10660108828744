<form [formGroup]="formInput" nz-form style="margin: 0px; padding: 0px;">
  <div style="display: flex; flex-wrap: wrap;">
  <div class="form-item" style="width: 400px;">
      <div class="form-label-v2">Keyword</div>
      <search-box #searchBox (doSearch)="doSearch($event)" [placeHolder]="'Search by ID, name or phone'" [searchKeyword]="searchKeyword" style="width: 100%;"></search-box>
  </div>
  <div class="form-item" style="width: 250px;">
    <div class="form-label-v2">Carrier</div>
    <div>
      <nz-select nzBackdrop="true" nzAllowClear nzShowSearch
        [formControlName]="'carrierId'" [nzLoading]="isFetchingCarrier"
        [nzPlaceHolder]="'Select'"
        (ngModelChange)="onChange($event, 'carrierId')" style="width: 100%;">
        <nz-option *ngFor="let item of listCarriers" 
          [nzValue]="item.id" [nzLabel]="getCarrierName(item)">
        </nz-option>
      </nz-select>

    </div>
</div>
</div>
</form>

<div *ngIf="!model" class="nodata">
  <i nz-icon nzType="loading" nzTheme="outline"></i>
</div>
<div *ngIf="model">
  <div warehouse-manifest-navigation-bar 
    [manifestModel]="{ id: manifestId, mblNumber: model.mblNumber }"
    [palletModel]="{ id: palletId, name: model.palletName }">
  </div>
  <div class="pallet-detail-summary top20">
    <div class="flex-space-between bottom5">
      <div class="flex" style="align-items: center;">
        <div class="title">Pallet details</div>
        <div class="left15">
          <button nz-button (click)="onBtnRefreshDataItems()">
            <i *ngIf="isLoading" nz-icon nzType="loading" nzTheme="outline"></i>
            <span *ngIf="!isLoading" nz-icon nzType="reload" nzTheme="outline"></span> Refresh
          </button>
        </div>
      </div>
      <div>
        <button nz-button (click)="onBtnEditPallet()">
          <i nz-icon nzType="edit" nzTheme="outline"></i>Edit
        </button>
      </div>
    </div>
    <div nz-row nzGutter="16">
      <div nz-col nzMd="12">
        <div nz-row class="bottom5">
          <div nz-col nzSm="12" class="label">Name</div>
          <div nz-col nzSm="12">{{ model.name || 'N/A' }}</div>
        </div>
        <div nz-row class="bottom5">
          <div nz-col nzSm="12" class="label">Label</div>
          <div nz-col nzSm="12">{{ model.label || 'N/A' }}</div>
        </div>
        <div nz-row class="bottom5">
          <div nz-col nzSm="12" class="label">Weight</div>
          <div nz-col nzSm="12">{{ model.displayInfo?.weight || 'N/A' }}</div>
        </div>
        <div nz-row class="bottom5">
          <div nz-col nzSm="12" class="label">DIM</div>
          <div nz-col nzSm="12">{{ model.displayInfo?.dim || 'N/A' }}</div>
        </div>
      </div>
      <div nz-col nzMd="12">
        <div nz-row class="bottom5">
          <div nz-col nzSm="12" class="label">Carrier</div>
          <div nz-col nzSm="12">{{ model.carrier || 'N/A' }}</div>
        </div>
        <div nz-row class="bottom5">
          <div nz-col nzSm="12" class="label">Consignee</div>
          <div nz-col nzSm="12">{{ model.consignee || 'N/A' }}</div>
        </div>
        <div nz-row class="bottom5">
          <div nz-col nzSm="12" class="label">Created by</div>
          <div nz-col nzSm="12">{{ model.displayInfo?.createdBy || 'N/A' }}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="pallet-detail-items">
    <div #manifestItemsListCartonComponent manifest-items-list-carton 
      [listItems]="listOfCartons" 
      [manifestId]="manifestId" 
      [palletId]="palletId" 
      [canRemoveCarton]="model.displayInfo?.canRemoveCarton" 
      (onRefresh)="onRefreshData()">
    </div>
  </div>
</div>
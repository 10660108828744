<div>
  <div><b>ID:</b> {{datLoad?.id}}</div>
  <div><b>Reference Id:</b> {{datLoad?.referenceId}}</div>
  <div><b>Equipment Type:</b> {{datLoad?.freight?.equipmentType}}</div>
  <div><b>Shipment Type:</b> {{datLoad?.freight?.fullPartial}}</div>
  <div><b>Start Visible When:</b> {{datLoad?.exposure?.startWhen}}</div>
  <div><b>End Visible When:</b> {{datLoad?.exposure?.endWhen}}</div>
  <div><b>Pickup Start When:</b> {{datLoad?.exposure?.earliestAvailabilityWhen}}</div>
  <div><b>Pickup End When:</b> {{datLoad?.exposure?.latestAvailabilityWhen}}</div>
  <div style="margin-top: 20px;">
    <button nz-button nzType="default"  (click)="onClose()">
      <span nz-icon nzType="close" nzTheme="outline"></span>
      Close
    </button>
    <button nz-button nzType="default" [nzLoading]="deleteLoading" nzDanger (click)="onDeletePost()" style="margin-left: 16px;">
      <span nz-icon nzType="delete" nzTheme="outline"></span>
      Delete DAT Post
    </button>
  </div>
  
</div>
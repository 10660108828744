<div *ngIf="isLoading" class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
<div *ngIf="isError" class="nodata">Cannot fetch data, please try again later</div>

<form nz-form *ngIf="!isLoading" [formGroup]="formInput" class="form-detail" [formGroup]="formInput"
  nzLayout="vertical">

  <div class="carrier-selection">
    <div class="f20 bottom20">Edit Note</div>
        <div nz-row [nzGutter]="12">
          <div nz-col nzSpan="24">
            <nz-form-item>
              <nz-form-label>{{getLabel("note")}}</nz-form-label>
              <nz-form-control>
                <textarea nz-input [placeholder]="getPlaceHolder('note')"
                [nzAutosize]="{ minRows: 4, maxRows: 10 }"
                  formControlName="note"></textarea>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>

  </div>
</form>

<div form-footer [onProgress]="onProgress" [nzIconCancel]="null" [nzIconOK]="null"
  [canClickOK]="needUpdate && !onProgress && !isLoading" [canClickCancel]="!onProgress" labelOK="OK"
  (onOK)="onBtnSave()" (onCancel)="closeDialog()"></div>

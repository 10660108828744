import { BaseComponent } from "@abstract/BaseComponent";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Const } from "@const/Const";
import { DialogService } from "@dialogs/dialog.service";
import { Utils } from "@services/utils";
import { EMPTY, Subject } from "rxjs";
import { debounceTime, switchMap } from "rxjs/operators";
import { ManifestUploadLabelSKU } from "../upload-label-sku";
import _ from 'underscore';

@Component({
  selector: '[manifest-items-list-carton]',
  templateUrl: './index.html',
  styleUrls: ['./index.scss',]

})
export class ManifestItemsListCarton extends BaseComponent {

  constructor() {
    super();
  }

  private searchSubject$ = new Subject<string>();
  filterData: any = {
    cartonName: '',
    skuNumber: [],
    carrierName: [],
    consigneeName: [],
  }

  public limit = 10;
  public pageIndex: number = 1;
  public get numOfItemSelected() {
    return this.listOfData.filter(it => it.checked).length;
  }
  public get numOfItemShowing() {
    if (Number.isInteger(this.totalCount / this.limit)) {
      return this.limit;
    } else {
      let totalPage = Math.floor(this.totalCount / this.limit) + 1;
      return totalPage !== this.pageIndex ? this.limit : this.totalCount - this.limit * (this.pageIndex - 1);
    }
  }
  allChecked = false;
  indeterminate = false;

  listOfFilterCarrierOption: string[] = []
  listOfFilterConsigneeOption: string[] = []
  listOfFilterSKUOption: string[] = []

  public listOfData: any[] = [];
  public listOfDataOriginal: any[] = [];
  public displayData: any[] = [];
  @Input() palletId: string;
  @Input() canRemoveCarton: boolean = false;
  @Input() manifestId: string;
  @Input() set listItems(value) {
    this.processListItems(value);
    this.listOfDataOriginal = value || [];
    this.listOfDataOriginal.map(it => it.checked = false);
    this.listOfData = Utils.cloneObject(this.listOfDataOriginal);
    this.listOfData.map(it => it.checked = false)
    let listOfFilterSKUOption = [];
    this.listOfData.map(it => {
      if (Utils.isArrayNotEmpty(it.sku)) {
        listOfFilterSKUOption = listOfFilterSKUOption.concat(it.sku);
      }
    })
    this.listOfFilterSKUOption = Utils.uniqElementsArray(listOfFilterSKUOption.filter(x=>x));
    let listOfFilterCarrierOption = this.listOfData.map(it => it.carrier).filter(x => x);
    this.listOfFilterCarrierOption = Utils.uniqElementsArray(listOfFilterCarrierOption);
    let listOfFilterConsigneeOption = this.listOfData.map(it => it.consignee).filter(x => x);
    this.listOfFilterConsigneeOption = Utils.uniqElementsArray(listOfFilterConsigneeOption);
  }

  @Output() onRefresh: EventEmitter<any> = new EventEmitter<any>();

  public get totalCount() {
    return this.listOfData.length || 0;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.searchSubject$.pipe(
      debounceTime(150),
      switchMap((input) => {
        this.onFilterData();
        return EMPTY;
      })
    ).subscribe(result => {});
  }

  private processListItems(cartons) {
    for (let carton of cartons) {
      carton.sku = carton.items?.map(item => item.sku)?.filter(x=>x);
      let tasks = [];
      (carton.items || []).map(item => {
        tasks = tasks.concat(item.tasks || [])
      });
      tasks = Utils.uniqElementsArray(tasks).filter(x=>x);
      if (tasks.length) {
        const isDone = tasks.every(item => item.status == Const.WarehouseTaskStatus.completed);
        carton.taskStatus = isDone ? Const.WarehouseTaskStatus.completed : Const.WarehouseTaskStatus.pending;
      }
    }
  }

  onDataListPageChanged(page) {
    this.pageIndex = page;
  }

  currentPageDataChange(event): void {
    this.displayData = event;
    this.refreshStatus();
  }

  refreshStatus(): void {
    const validData = this.displayData.filter(value => !value.disabled);
    const allChecked = validData.length > 0 && validData.every(value => value.checked === true);
    const allUnChecked = validData.every(value => !value.checked);
    this.allChecked = allChecked;
    this.indeterminate = !allChecked && !allUnChecked;
  }

  checkAll(value: boolean): void {
    this.displayData.forEach(data => {
      if (!data.disabled) {
        data.checked = value;
      }
    });
    this.refreshStatus();
  }

  onFilterChange(key, event) {
    this.searchSubject$.next(event);
  }

  onFilterData() {
    let listOfData = Utils.cloneObject(this.listOfDataOriginal);
    listOfData = listOfData.filter(it => this.filterCartonName(this.filterData.cartonName, it));
    listOfData = listOfData.filter(it => this.filterSKUNumber(this.filterData.skuNumber, it));
    listOfData = listOfData.filter(it => this.filterCarrierName(this.filterData.carrierName, it));
    listOfData = listOfData.filter(it => this.filterConsigneeName(this.filterData.consigneeName, it));
    this.listOfData = listOfData;
  }

  filterCartonName(inputValue: string, item: any): boolean {
    if (!inputValue) return true;
    inputValue = inputValue.trim();
    const regexCartonName = new RegExp(inputValue, "i");
    return regexCartonName.test(item.name) || regexCartonName.test(item.fulfillmentId)
  }

  filterSKUNumber(inputValue: string[], item): boolean {
    if (!Utils.isArrayNotEmpty(inputValue)) return true;
    for (let it of item.sku) {
      if (inputValue.includes(it)) {
        return true;
      }
    }
    return false;
  }

  filterCarrierName(inputValue: string[], item) {
    if (!Utils.isArrayNotEmpty(inputValue)) return true;
    return inputValue.includes(item.carrier);
  }

  filterConsigneeName(inputValue: string[], item) {
    if (!Utils.isArrayNotEmpty(inputValue)) return true;
    return inputValue.includes(item.consignee);
  }

  clearSearchKeyword(key) {
    this.filterData[key] = '';
    this.searchSubject$.next('');
  }

  getRouterLinkCartonDetail(item) {
    return [this.routeAdminCrossdockWarehouseManifests, this.manifestId, 'cartons', item.id]
  }

  getRouterLinkPalletDetail(item) {
    return [this.routeAdminCrossdockWarehouseManifests, this.manifestId, 'pallets', item.palletId]
  }

  onBtnRemoveCartonFromPallet(item) {
    if (!this.canRemoveCarton) return;
    this.confirmDeletion({message: `Are you sure you want to remove this carton ${item.name} from the pallet?`, txtBtnOk: 'Remove', fnOk: () => {
      let url = `${Const.APIURI_WAREHOUSE_MANIFEST}/${this.manifestId}/pallets/${this.palletId}/cartons/${item.id}`;
      this.api.DELETE(url).subscribe(
        (resp) => {
          this.showSuccess("The carton has been removed successfully.");
          this.onRefreshData();
        },
        (err) => {
          this.showErr(err);
        }
      );
    }});
  }

  onRefreshData() {
    this.onRefresh.emit();
  }

  get isComeFromPalletDetail() {
    return !!this.palletId;
  }

  get colSpanCommon() {
    return this.palletId ? 8 : 6;
  }

  get skuSelectDesc() {
    let count = this.filterData?.skuNumber?.length ?? 0;
    if (!count) {
      return;
    } else if (count == 1) {
      return this.filterData.skuNumber[0];
    } else return `${count} options`;
  }

  get carrierSelectDesc() {
    let count = this.filterData?.carrierName?.length ?? 0;
    if (!count) {
      return;
    } else if (count == 1) {
      return this.filterData.carrierName[0];
    } else return `${count} options`;
  }

  get consigneeSelectDesc() {
    let count = this.filterData?.consigneeName?.length ?? 0;
    if (!count) {
      return;
    } else if (count == 1) {
      return this.filterData.consigneeName[0];
    } else return `${count} options`;
  }

  onBtnUploadLabeledSKU() {
    let listOfItems = [];
    let cartonSelected = this.listOfData.filter(it => it.checked);
    cartonSelected.forEach(it => {
      listOfItems = listOfItems.concat(it.items || []);
    })
    listOfItems = Utils.uniqElementsArray(listOfItems).filter(it => it.sku);
    if (!listOfItems.length) {
      this.showWarning('Upload SKU label', 'These selected cartons do not exist SKU.');
      return;
    }
    let groupSku = _.groupBy(listOfItems, 'sku');
    let listOfSKU = [];
    for (const [key, value] of Object.entries(groupSku)) {
      let obj = {
        sku: key,
        itemIds: (value as any[]).map(item => item.id),
        sumOfQuantity: (value as any[]).reduce((sum, item) => sum + parseInt(item.sumOfQuantity), 0)
      }
      listOfSKU.push(obj);
    }
    DialogService.openFormDialog1(ManifestUploadLabelSKU, {
      nzComponentParams: {
        numberOfCartons: cartonSelected.length,
        manifestId: this.manifestId,
        listOfSKU,
        createSuccess: resp => {
          this.onRefreshData();
        },
        closeOnSuccess: true,
      },
      nzClassName: 'modal-no-padding',
      nzCentered: true,
    });
  }

  onBtnSelectAllItems() {
    this.listOfData.map(it => it.checked = true);
    this.refreshStatus();
  }

  onBtnUnSelectAllItems() {
    this.listOfData.map(it => it.checked = false)
    this.refreshStatus();
  }

  sortSKU = (a, b) => {
    let skuA = a?.sku?.join(', ');
    let skuB = b?.sku?.join(', ');
    return skuA?.localeCompare(skuB)
  } 
  sortPallet = (a, b) => {
    let palletNameA = a?.palletId ? a?.palletName : '';
    let palletNameB = b?.palletId ? b?.palletName : '';
    return palletNameA?.localeCompare(palletNameB)
  }
  sortCarrier = (a, b) => a?.carrier?.localeCompare(b?.carrier);
  sortConsignee = (a, b) => a?.consignee?.localeCompare(b?.consignee);

  getColor(status) {
    switch (status) {
      case Const.WarehouseTaskStatus.pending:
        return "gold"
      case Const.WarehouseTaskStatus.completed:
        return "green"
      default:
        return "grey"
    }
  }

  getStatusText(status) {
    switch (status) {
      case Const.WarehouseTaskStatus.pending:
        return "Pending"
      case Const.WarehouseTaskStatus.completed:
        return "Done"
      default:
        return ""
    }
  }

  // gọi từ comp cha, để update lại data mới nhất cho những carton đang view
  public refeshDataListItems(value) {
    this.processListItems(value);
    this.listOfDataOriginal = value || [];
    this.listOfDataOriginal.map(it => it.checked = false);
    let listOfData = Utils.cloneObject(this.listOfDataOriginal);
    listOfData.map(it => it.checked = false);
    let cartonDic = {};
    listOfData.map(it => cartonDic[it.id] = it);
    for (let item of this.listOfData) {
      const cartonNewData = cartonDic[item.id];
      if (!cartonNewData) continue;
      item.tasks = cartonNewData.tasks || [];
      if (item.taskStatus != cartonNewData.taskStatus) {
        item.taskStatus = cartonNewData.taskStatus
      }
    }
  }

}

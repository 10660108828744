import {Component, Input} from '@angular/core';
import { Const } from "@const/Const";
import { Validators } from "@angular/forms";
import { InputHelper } from "@services/input-helper";
import { BaseFormItem } from "@app/admin/base/form-item";

@Component({
  selector: "[client-detail-notification-recipient]",
  templateUrl: "./index.html",
  styleUrls: [
    "./index.scss",
    "../../../../detail.scss",
    "../../../../../../styles/row-col.scss",
    "../../../../../../styles/form-v2.scss",
  ],
})
// Notification recipient
export class ClientDetailNotificationRecipient extends BaseFormItem {
  protected formGroupDeclaration: FormGroupDeclaration = {
    contacts: {
      label: "",
      type: "formArray",
      initialValue: [{}],
      childItem: {
        firstName: { label: "First Name" },
        lastName: { label: "Last Name" },
        phone: {
          label: "Phone",
          inputType: "tel",
          getValue: InputHelper.getValuePhone,
          formatValue: InputHelper.formatPhone,
        },
        email: { label: "Email", validators: Validators.email },
      },
    },
  };
  private originModel;

  @Input() set myModel(value) {
    let currentModel = this.model;
    this.model = value;
    this.originModel = {...value};
    if(currentModel) this.bindDataModel(value);
  }

  disableEditing() {
    this.setEnableFormGroup(false);
    this.isEditing = false;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.disableEditing();
  }

  protected getApiUrl(): string {
    return Const.APIURI_CLIENTS;
  }

  protected onUpdateSuccess(resp) {
    super.onUpdateSuccess(resp);
    this.disableEditing();
    this.originModel = {...this.model};
  }

  protected beforeBindModel(model: any) {
    if (model?.contacts) {
      model.contacts = (model.contacts || []).length ? model.contacts : [{}];
    }
    return model;
  }

  onBtnCancel() {
    this.myModel = {...this.originModel};
    this.createFormInput(this.model);
    this.disableEditing();
  }

  isInputsChange() {
    return this.isFormDataChanged();
  }

}

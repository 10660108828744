import { Const } from "@const/Const";
import { ApiService } from "@services/api.service";
import { getInjector } from "@services/injector";
import { MasterData } from "@services/master.data";
import { FormCarrierCost, ModalHelper, UpdateServiceOptions } from "@wearewarp/ng-antd";
import { CarrierCost, ServiceOptionCost } from '@wearewarp/types/data-model/types/AssignedCarrier';
import { Utils } from "@services/utils";
import { map } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { TaskType } from "@wearewarp/types";

interface DataForCarrierCost {
  assignedCarrierCost: CarrierCost,
  isRequiredBill: boolean,
  listStops: {stopId: string, type: TaskType, label: string}[],
}

interface InputModalCarrierCost {
  jobId?: string,
  title?: string,
  currentData?: CarrierCost,
  onSuccess?: (resp) => void,
  onError?: (err) => void,
  submit?: (jobId: string, data: CarrierCost) => Observable<any>
}

interface InputModalServiceOptions {
  jobId: string,
  stopId: string,
  type: TaskType,
  title?: string,
  onSuccess?: Function,
}

export class CarrierCostHelper {
  public static openModalCarrierCost(input: InputModalCarrierCost) {
    new CarrierCostHelper().showModalCarrierCost(input);
  }

  public static openModalServiceOptions(input: InputModalServiceOptions) {
    new CarrierCostHelper().showModalServiceOptions(input);
  }

  private showModalCarrierCost(input: InputModalCarrierCost) {
    const modalHelper: ModalHelper = getInjector().get(ModalHelper);
    const jobId = input.jobId;
    const serviceOptions = {
      pickup: MasterData.ShipmentServiceOptionsPickup,
      delivery: MasterData.ShipmentServiceOptionsDelivery,
      addition: MasterData.ShipmentServiceOptionsAddition,
    }
    const params: Partial<FormCarrierCost> = {
      dataServiceOptions: serviceOptions,
      waitingForData: () => {
        if (jobId) {
          // mặc định là lấy dữ liệu từ route
          return this.getDataForCarrierCost(jobId);
        } else {
          return of({assignedCarrierCost: input.currentData ?? {}, listStops: [], isRequiredBill: false});
        }
      },
      submit: (data) => {
        if (typeof input.submit == 'function') {
          return input.submit(jobId, data);
        } else {
          // mặc định là cập nhật carrier cost cho route
          return this.saveDataForCarrierCost(jobId, data);
        }
      },
    };
    FormCarrierCost.openModal(modalHelper, {
      onSubmitError: (err) => input?.onError ? input.onError(err) : console.error(err),
      onSubmitSucceeded: (resp) => input?.onSuccess?.(resp),
      nzTitle: input.title ?? 'Carrier Cost',
      nzComponentParams: params,
    });
  }

  // Chỉ update riêng mảng service options cho từng stop
  private showModalServiceOptions(input: InputModalServiceOptions) {
    const modalHelper: ModalHelper = getInjector().get(ModalHelper);
    const {jobId, stopId, type} = input;
    const serviceOptions = {
      pickup: MasterData.ShipmentServiceOptionsPickup,
      delivery: MasterData.ShipmentServiceOptionsDelivery,
      addition: MasterData.ShipmentServiceOptionsAddition,
    };
    UpdateServiceOptions.openModal(modalHelper, {
      onSubmitError: (err) => console.error(err),
      onSubmitSucceeded: (resp) => input?.onSuccess?.(),
      nzTitle: input.title ?? 'Service Options',
      nzComponentParams: {
        dataServiceOptions: serviceOptions,
        stopId: stopId,
        type: type,
        waitingForData: () => this.getDataForCarrierCost(jobId),
        submit: (data) => {
          let carrierCostData = this.cacheData[jobId];
          let oldServiceOptionsCost = this.getTotalServiceOptionsCost(carrierCostData.serviceOptions ?? []);
          let newServiceOptionsCost = this.getTotalServiceOptionsCost(data.serviceOptions ?? []);
          carrierCostData.serviceOptions = data.serviceOptions;
          carrierCostData.grandTotal += newServiceOptionsCost - oldServiceOptionsCost;
          return this.saveDataForCarrierCost(jobId, carrierCostData);
        },
      }
    });
  }

  private getTotalServiceOptionsCost(list: ServiceOptionCost[]): number {
    let total = 0;
    for (let item of list) {
      if (MasterData.isServiceOptionTypeNegative(item.id)) {
        total = total - (item.total || 0);
      } else {
        total += (item.total ?? 0);
      }
    }
    return total;
  }

  private cacheData: {[key: string]: CarrierCost} = {};

  private getDataForCarrierCost(jobId?: string): Observable<DataForCarrierCost> {
    const data: DataForCarrierCost = {
      assignedCarrierCost: {},
      isRequiredBill: false,
      listStops: [],
    };
    if (!jobId) {
      return of(data);
    }
    const url = Const.API_GET_DETAIL_FOR_CARRIER_COST(jobId);
    return ApiService.instance.GET(url).pipe(map(resp => {
      if (resp.data?.assignedCarrier?.cost) {
        data.assignedCarrierCost = resp?.data?.assignedCarrier?.cost ?? {};
        if (Utils.isArrayNotEmpty(data.assignedCarrierCost.serviceOptions)) {
          for (let item of data.assignedCarrierCost.serviceOptions) {
            if (!(<any>item).id && item._id) {
              (<any>item).id = item._id;
            }
          }
        }
        this.cacheData[jobId] = Utils.cloneObject(data.assignedCarrierCost);
      }
      if (resp.data?.carrier?.isRequiredBill) {
        data.isRequiredBill = resp.data.carrier.isRequiredBill;
      }
      if (Utils.isArrayNotEmpty(resp.data?.stops)) {
        data.listStops = [];
        let getDisplayType = (stopType) => {
          switch (stopType) {
            case Const.TaskType.PICKUP: return "PU"
            case Const.TaskType.RETURN: return "RE"
            default: return "DO"
          }
        };
        for (let i = 0; i < resp.data.stops.length; i++) {
          let stop = resp.data.stops[i];
          let shortType = getDisplayType(stop.type);
          data.listStops.push({
            stopId: stop.id,
            type: stop.type,
            label: `${shortType} - Stop ${i + 1}`
          });
        }
      }
      return data;
    }));
  }

  private saveDataForCarrierCost(jobId: string, data: CarrierCost) {
    const url = `${Const.APIV2(Const.APIURI_JOBS)}/${jobId}/carrier_cost`;
    return ApiService.instance.PUT(url, data);
  }
}
import { Component, Input } from '@angular/core';
import { InputHelper } from '@services/input-helper';
import { MasterData } from '@services/master.data';
import { CarrierCost, ServiceOptionCost } from '@wearewarp/types/data-model';

@Component({
  selector: '[display-carrier-cost-fin]',
  templateUrl: './view.html',
  styleUrls: ['./style.scss']
})
export class DisplayCarrierCostFin {
  @Input() data: CarrierCost;

  get serviceOptions(): ServiceOptionCost[] { return this.data.serviceOptions ?? [] }

  formatMoney(amt: number | string) {
    return InputHelper.formatMoney2(`${amt || 0}`);
  }

  getServiceOptionMoneyValue(serviceOptionItem) {
    let text = InputHelper.formatMoney2(`${serviceOptionItem.total}`);
    if (MasterData.isServiceOptionTypeNegative(serviceOptionItem._id)) {
      return `-${text}`;
    }
    return text;
  }

  getServiceOptionName(id): string {
    return MasterData.getServiceOptionName(id);
  }
}
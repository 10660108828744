import { Component, Input } from "@angular/core";
import { Const } from "@const/Const";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { FormControl } from "@angular/forms";
import { InputHelper } from "@services/input-helper";
import { Observable } from "rxjs";

@Component({
  selector: "edit-carrier-bid-settings",
  templateUrl: "./index.html",
  styleUrls: [
    "./index.scss",
  ],
})
export class EditCarrierBidSettings extends BaseFormDialog1 {
  @Input() onSave: (data) => Observable<any>;
  @Input() isSourceMarketPlace: boolean = false;

  protected formGroupDeclaration: FormGroupDeclaration = {
    basePrice: {
      label: "Base Price",
      placeHolder: "Enter base price",
      initialValue: 0,
      required: true,
      getValue: InputHelper.getValueMoney, formatValue: InputHelper.formatMoney2
    },
    isShowBasePrice: {
      label: "Show Base Price For Carrier",
      placeHolder: "",
      // required: true,
      initialValue: false
    },
    isAllowPlaceBid: {
      label: "Allow the carrier place bid",
      placeHolder: "",
      initialValue: false
    },
    isShowOnBidBoard : {
      label: "Show on Bid Board",
      placeHolder: "",
      initialValue: false
    },
     isRequireCarrierAcceptLoadTender : {
      label: "Carrier needs to accept load tender after route assignment",
      placeHolder: "",
      initialValue: false
    },
    type: {
      label: "Bid Mode",
      required: true,
      initialValue: Const.CarrierBidTypes.manual
    }
  }

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  protected beforeBindModel(model): any {
    if (this.isSourceMarketPlace) {
      this.formGroupDeclaration.type.readOnly = true;
      this.formGroupDeclaration.type.submitReadOnly = true;
      this.formGroupDeclaration.isAllowPlaceBid.readOnly = true;
      this.formGroupDeclaration.isAllowPlaceBid.submitReadOnly = true;
      this.formGroupDeclaration.isShowBasePrice.readOnly = true;
      this.formGroupDeclaration.isShowBasePrice.submitReadOnly = true;
    }
    return model;
  }

  get needUpdate() {
    if (!this.selectedType) return false //phải chọn bid mode

    if (!this.isAllowPlaceBid && !this.isShowWarpOffer) return false //phải tích chọn 1 trong 2 check box
    if (this.isShowWarpOffer && !this.basePriceInput) return false //nếu chọn show offer thì phải điền số tiền

    return super.needUpdate;
  }

  carrierBIDType = Object.values(Const.CarrierBidTypes);

  get selectedType() {
    return this?.formInput?.get('type')?.value;
  }

  get isAllowPlaceBid() {
    return this?.formInput?.get('isAllowPlaceBid')?.value;
  }

  get isShowWarpOffer() {
    return this?.formInput?.get('isShowBasePrice')?.value;
  }

  get basePriceInput() {
    return this?.formInput?.get('basePrice')?.value;
  }
  get isShowOnBidBoard(){
    return this?.formInput?.get('isShowOnBidBoard')?.value;
  }

  onInputChanged(event, key) {
    switch (key) {
      case 'basePrice': return InputHelper.handleInputChangeMoney(event, <FormControl>this.formInput.get(key), true);
      case 'type': return this.onTypeChanged(event);
    }
  }

  onTypeChanged(value) {
    if (value == Const.CarrierBidTypes.direct) {
      this.getItemByKey("isAllowPlaceBid").disable();
      this.getItemByKey("isShowBasePrice").disable();
      this.setItemValue("isAllowPlaceBid", false);
      this.setItemValue("isShowBasePrice", true);
    }
    else {
      this.getItemByKey("isAllowPlaceBid").enable();
      this.getItemByKey("isShowBasePrice").enable();
    }
  }

  getLabelType(key) {
    switch (key) {
      case Const.CarrierBidTypes.direct: return 'Auto assign when carrier accept load (Direct)';
      case Const.CarrierBidTypes.manual: return 'Manual review (Indirect)';
      default: return 'Manual';
    }
  }

  onBtnSave(): void {
    if (!this.needUpdate) return;
    let data = this.getFormData_JSON(true);
    this.onProgress = true;
    this.onSave(data).subscribe(
      resp => {
        super.onUpdateSuccess(resp);
        this.closeDialog();
        this.onProgress = false;
      }, err => {
        this.showErr(err);
        this.onProgress = false;
      }
    );
  }

}

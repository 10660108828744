import { Component, Input, ViewChild } from "@angular/core";
import { Const } from "@const/Const";
import { NzTimelineMode } from 'ng-zorro-antd/timeline';
import { DateUtil } from "@services/date-utils";
import { DialogService } from "@dialogs/dialog.service";
import { DetailLog } from "./detail/log-detail";
import { BaseDetail } from "@app/admin/base/detail";
import { ActivatedRoute } from "@angular/router";
import { ApiMethod } from "@app/enum";
import {Location} from '@angular/common';
import { AuditLogList } from "./list";

@Component({
  selector: '[shipment-log]',
  templateUrl: './audit-log.html',
  styleUrls: ['../../../dialogs/dialogs.scss', './audit-log.scss']
})

export class AuditLog extends BaseDetail {

  entity: string;

  public listChange = [];
  public isLoading = false;
  public header: string = '';
  public sub;
  
  @ViewChild('auditLogList') auditLogList: AuditLogList;

  constructor(protected activatedRoute: ActivatedRoute, private _location: Location) {
    super(activatedRoute);
    this.sub = this.activatedRoute.params.subscribe(params => {
      super.handleNavigationEnd(activatedRoute.toString(), activatedRoute.queryParamMap);
    })
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.entity = this.getQueryParam('entity');
  }

  protected getApiUrl(method?: ApiMethod): string {
    return '';
  }
  onGetHeader(value) {
    this.header = value;
  }
  
  onBack(){
    this._location.back();
  }
}
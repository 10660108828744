import { Shipment } from '@wearewarp/types/data-model';
import { CompositeLayer } from 'deck.gl';
import { ScatterplotLayer } from '@deck.gl/layers'
import _ from 'underscore'

export default class ShipmentDotLayer extends CompositeLayer {
    id: string
    static layerName = "ShipmentDotLayer"
    shipments: Shipment[]
    locationMap: any = {}

    constructor(id, shipments) {
        super({id, data: {shipments}})
        this.id = id
        this.shipments = shipments
        this.locationMap = this.processLocationMap(shipments)
    }

    getLocationId(info) {
        if (info.warehouseId) return info.warehouseId
        const { metadata } = info.addr || {}
        const { latitude, longitude } = metadata
        return `${latitude?.toFixed(4)}::${longitude?.toFixed(4)}`
    }

    processLocationMap(shipments) {
        const map: any = {}
        const processed: Set<string> = new Set()
        for (let shipment of shipments) {
            for (let info of shipment.deliveryInfos) {
                const key = `${info.type}-${shipment.id}`
                if (processed.has(key)) continue
                if (info.type !== 'PICKUP' && info.type !== 'DROPOFF') continue
                if (!info.addr?.metadata) continue
                let locationId = this.getLocationId(info)
                let l = map[locationId] || []
                const type = info.type.substring(0, 1) + info.type.substring(1).toLowerCase()
                l.push({
                    type,
                    id: shipment.id,
                    warpId: shipment.warpId,
                    workload: shipment.metadata?.workload
                })
                processed.add(key)
                map[locationId] = l
            }
        }
        return map
    }

    getDeliveryMapInfo(info, mile) {
        if (!info?.addr?.metadata?.longitude) return null
        const shipments = this.locationMap[this.getLocationId(info)]
        const displayInfos = (shipments || []).map(it => `${it.type} ${it.warpId}` + (it.workload ? ` [${it.workload} Pallets]` : ''))
        const display = displayInfos.length > 10 ? displayInfos.slice(0, 8).join('\n') + `\n... ${displayInfos.length - 8} more` : displayInfos.join('\n')
        return {
            name: info.locationName,
            locationId: info.warehouseId,
            coordinates: [info.addr.metadata.longitude, info.addr.metadata.latitude],
            addr: info.addr,
            info: `${info.locationName || ''}\n${info.addr.street}\n${info.addr.state} ${info.addr.zipcode}\n` + display,
            color: info.type === 'PICKUP' ? (mile === 'last' ? [14, 160, 98] : [44, 68, 230]) : (mile === 'first' ? [14, 160, 98] : [200, 90, 46])
        }
    }

    createShipmentDotLayer() {
        const locations = _.flatten(this.shipments.map(it => {
            const metadata: any = it?.metadata
            const { mile } = metadata || {}
            const pickup = it.deliveryInfos.filter(x => x.type === 'PICKUP')[0]
            const dropoff = it.deliveryInfos.filter(x => x.type === 'DROPOFF')[0]
            return [
                this.getDeliveryMapInfo(pickup, mile),
                this.getDeliveryMapInfo(dropoff, mile)
            ]
        })).filter(it => it)

        return new ScatterplotLayer({
            id: `${this.id}-scatterplot-layer`,
            data: locations,
            pickable: true,
            opacity: 1,
            stroked: true,
            filled: true,
            radiusScale: 6,
            radiusMinPixels: 6,
            radiusMaxPixels: 6,
            lineWidthMinPixels: 1,
            lineWidthMaxPixels: 1,
            getPosition: d => d.coordinates,
            getFillColor: d => d.color,
            getLineColor: d => [0, 0, 0]
        });
    }

    renderLayers() {
        return [
            this.createShipmentDotLayer()
        ];
    }
}
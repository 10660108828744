<div *ngIf="isLoading" class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
<div *ngIf="isError" class="nodata">Cannot fetch data, please try again later</div>

<div class="comm-log-selection" *ngIf="!isLoading">
  <div class="group-header">
    <div class="f20">Event History</div>
    <span nzDanger (click)="closeDialog()">
      <i nz-icon nzType="close" nzTheme="outline"></i>
    </span>
  </div>

  <div class="content">
    <div class="group-left">
      <div class="list-com-log">
        <ng-container *ngFor="let h of model.histories">
          <div class="item-comm" (click)="chooseItem(h.logId)" [ngClass]="{active: selectedId == h.logId}">
            <div class="title">{{ getType(h.type) }} to {{ getReceiver(h) }}</div>
            <div class="time">{{ formatDate(h.when) }}</div>
            <div class="status {{ makeColor(h) }}">{{ $asSentStatusText(h) }}</div>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="group-right">
      <div nz-row [nzGutter]="12">
        <div nz-col nzSpan="16" nzOffset="4">
          <div comm-log-detail [logId]="selectedId"></div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!model" class="nodata">
  <i nz-icon nzType="loading" nzTheme="outline"></i>
</div>
<div *ngIf="model">
  <div warehouse-manifest-navigation-bar class="bottom20"
    [manifestModel]="{ id: manifestId, mblNumber: model.mblNumber }"
    [cartonModel]="{ id: cartonId, name: model.name }">
  </div>
  <div nz-row [nzGutter]="{ md: 16 }">
    <div nz-col nzSm="24" nzLg="24" nzXl="24" nzXXl="16">
      <nz-table #nzTable nzBordered="true"
        [nzData]="listOfSKU"
        [nzHideOnSinglePage]="true"
        [nzPageSize]="10"
        [nzNoResult]="tplNoData"
        [nzSize]="'default'">
        <ng-template #tplNoData>
          <div class="nodata"><i nz-icon nzType="search" nzTheme="outline"></i>No data</div>
        </ng-template>
        <thead>
          <tr>
            <th>SKU</th>
            <th>Product ID</th>
            <th>Quantity</th>
            <th>Weight</th>
            <th>SKU label</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of nzTable.data; let i = index">
            <td>
              {{ item.sku }}
            </td>
            <td>
              {{ item.productId }}
            </td>
            <td>
              {{ item.quantity }}
            </td>
            <td>
              {{ item.weight }} {{ item.weightUnit }}
            </td>
            <td>
              <div *ngIf="!item.printLabelTask?.status">
                <input #inputFileSKULabeling type="file" hidden accept=".pdf,.png,.jpg,.jpeg" (change)="onSKULabelFileSelected(item, inputFileSKULabeling)" id="uploadSKULabelingInput-{{i}}">
                <a (click)="onBtnUploadSKULabel(i)">Upload label</a>
              </div>
              <div *ngIf="item.printLabelTask?.status" class="flex-space-between">
                <div>
                  <a [href]="attachedFileUrl(item.labelFile)" target="_blank">
                    <i nz-icon nzType="paper-clip" nzTheme="outline"></i>View
                  </a>
                </div>
                <div class="left10">
                  <a (click)="onBtnPrintLabel(item)">
                    <i nz-icon nzType="printer" nzTheme="outline" style="margin-right: 3px;"></i>Print
                  </a>
                </div>
                <div *ngIf="item.printLabelTask.status != 'completed'" class="left10 btn-delete-label">
                  <div (click)="onBtnDeleteSKULabel(item)"><span nz-icon nzType="delete" nzTheme="outline"></span> Delete</div>
                </div>
              </div>
            </td>
            <td>
              <div *ngIf="item.printLabelTask?.status" class="flex">
                <nz-tag [nzColor]="getColor(item.printLabelTask.status)">{{ getStatusText(item.printLabelTask.status) }}</nz-tag>
                <div *ngIf="item.printLabelTask.status == 'pending'" class="left10 pointer" (click)="onBtnConfirmLabelDone(item)">
                  <i nz-icon nzType="edit" nzTheme="outline"></i>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </nz-table>
      <div class="group-note top15">
        <div><i nz-icon nzType="info-circle" nzTheme="outline" class="icon-note"></i></div>
        <div class="left5">
          <div class="title">Note</div>
          <div [innerHTML]="model.displayInfo?.note" style="line-height: 22px;">
          </div>
        </div>
      </div>
    </div>
    <div nz-col nzSm="24" nzLg="24" nzXl="24" nzXXl="8">
      <div nz-row [nzGutter]="{ md: 16 }">
        <div nz-col nzSm="24" nzLg="12" nzXl="12" nzXXl="24">
          <div class="group-info">
            <div class="title">Carton Metric</div>
            <div class="body">
              <div nz-row [nzGutter]="{ md: 8 }" class="row-item">
                <div nz-col nzSm="8" class="label">Name</div>
                <div nz-col nzSm="16">{{ model.displayInfo?.cartonName }}</div>
              </div>
              <div nz-row [nzGutter]="{ md: 8 }" class="row-item">
                <div nz-col nzSm="8" class="label">DIM</div>
                <div nz-col nzSm="16">{{ model.displayInfo?.dim }}</div>
              </div>
              <div nz-row [nzGutter]="{ md: 8 }" class="row-item">
                <div nz-col nzSm="8" class="label">Commodity</div>
                <div nz-col nzSm="16">{{ model.displayInfo?.commodity }}</div>
              </div>
            </div>
          </div>
          <div class="group-info">
            <div class="title">Delivery</div>
            <div class="body">
              <div nz-row [nzGutter]="{ md: 8 }" class="row-item">
                <div nz-col nzSm="8" class="label">Carrier</div>
                <div nz-col nzSm="16">{{ model.displayInfo?.carrier }}</div>
              </div>
              <div nz-row [nzGutter]="{ md: 8 }" class="row-item">
                <div nz-col nzSm="8" class="label">Consignee</div>
                <div nz-col nzSm="16">{{ model.displayInfo?.consignee }}</div>
              </div>
              <div nz-row [nzGutter]="{ md: 8 }" class="row-item">
                <div nz-col nzSm="8" class="label">Address</div>
                <div nz-col nzSm="16">{{ model.displayInfo?.consigneeAddress }}</div>
              </div>
              <div nz-row [nzGutter]="{ md: 8 }" class="row-item">
                <div nz-col nzSm="8" class="label">Contact</div>
                <div nz-col nzSm="16">{{ model.displayInfo?.consigneeContact }}</div>
              </div>
            </div>
          </div>
        </div>
        <div nz-col nzSm="24" nzLg="12" nzXl="12" nzXXl="24">
          <div class="group-info">
            <div class="title">Tracking Info</div>
            <div class="body">
              <div nz-row [nzGutter]="{ md: 8 }" class="row-item">
                <div nz-col nzSm="8" class="label">Order #</div>
                <div nz-col nzSm="16">{{ model.displayInfo?.orderNo }}</div>
              </div>
              <div nz-row [nzGutter]="{ md: 8 }" class="row-item">
                <div nz-col nzSm="8" class="label">Fulfillment ID</div>
                <div nz-col nzSm="16">{{ model.displayInfo?.fulfillmentId }}</div>
              </div>
              <div nz-row [nzGutter]="{ md: 8 }" class="row-item">
                <div nz-col nzSm="8" class="label">Package #</div>
                <div nz-col nzSm="16">{{ model.displayInfo?.packageNo }}</div>
              </div>
              <div nz-row [nzGutter]="{ md: 8 }" class="row-item">
                <div nz-col nzSm="8" class="label">CNTR #</div>
                <div nz-col nzSm="16">{{ model.displayInfo?.cntrNo }}</div>
              </div>
            </div>
          </div>
          <div class="group-info">
            <div class="flex-space-between">
              <div class="title">Pallet</div>
              <a *ngIf="model.displayInfo?.palletUrl" [routerLink]="model.displayInfo.palletUrl">View pallet</a>
            </div>
            <div class="body">
              <div nz-row [nzGutter]="{ md: 8 }" class="row-item">
                <div nz-col nzSm="8" class="label">Pallet name</div>
                <div nz-col nzSm="16">{{ model.displayInfo?.palletName }}</div>
              </div>
              <div nz-row [nzGutter]="{ md: 8 }" class="row-item">
                <div nz-col nzSm="8" class="label">Pallet label</div>
                <div nz-col nzSm="16">{{ model.displayInfo?.palletLabel }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
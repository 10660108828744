import { BaseComponent } from "@abstract/BaseComponent";
import { Component, Input, ViewChild } from "@angular/core";
import { Const } from "@const/Const";
import _ from 'underscore'
import { ManifestItemsListCarton } from "../list-carton";
import { ManifestItemsListPallet } from "../list-pallet";

@Component({
  selector: '[manifest-detail-items]',
  templateUrl: './index.html',
  styleUrls: ['./index.scss',]

})
export class ManifestDetailItems extends BaseComponent {

  public selectedQtyUnit: string = Const.ShipmentItemUnit.carton;
  public isLoading: boolean = false;
  public listOfPallets: any[] = [];
  public listOfCartons: any[] = [];
  private _manifestId: string;

  @ViewChild('manifestItemsListCartonComponent') manifestItemsListCartonComponent: ManifestItemsListCarton;
  @ViewChild('manifestItemsListPalletComponent') manifestItemsListPalletComponent: ManifestItemsListPallet;

  constructor() {
    super();
  }

  @Input() set manifestId(value) {
    this._manifestId = value;
  }
  get manifestId() { return this._manifestId }

  public itemQtyUnitSelect = [
    Const.ShipmentItemUnit.pallet, Const.ShipmentItemUnit.carton
  ]

  ngOnInit(): void {
    super.ngOnInit(); 
    this.getData();
  }

  private getData() {
    if (!this.manifestId) return;
    this.isLoading = true;
    this.api.GET(`${Const.APIURI_WAREHOUSE_MANIFEST}/${this.manifestId}/data`).subscribe(
      resp => {
        this.listOfPallets = resp?.data?.pallets || [];
        let listOfItems = resp?.data?.items || [];
        let listOfCartons = resp?.data?.cartons || [];
        this.processListItems(listOfItems, listOfCartons, this.listOfPallets);
        this.listOfCartons = listOfCartons;
        this.isLoading = false;
      }, err => {
        this.showErr(err);
        this.isLoading = false;
        this.listOfPallets = [];
        this.listOfCartons = [];
      }
    );
  }

  private processListItems(items = [], cartons = [], pallets = []) {
    let itemDic = {};
    items.map(it => itemDic[it.id] = it);
    for (let carton of cartons) {
      carton.items = carton.itemIds.map(itemId => itemDic[itemId]);
    }
  }

  onViewByQtyUnitChange(event) {
    this.getData();
  }

  onBtnRefresh() {
    this.onBtnRefreshDataItems();
  }

  onBtnRefreshDataItems() {
    this.isLoading = true;
    this.api.GET(`${Const.APIURI_WAREHOUSE_MANIFEST}/${this.manifestId}/data`).subscribe(
      resp => {
        let listOfPallets = resp?.data?.pallets || [];
        let listOfItems = resp?.data?.items || [];
        let listOfCartons = resp?.data?.cartons || [];
        this.processListItems(listOfItems, listOfCartons, listOfPallets);
        if (this.selectedQtyUnit == 'Pallet') {
          this.manifestItemsListPalletComponent?.refeshDataListItems(listOfPallets);
        } else {
          this.manifestItemsListCartonComponent?.refeshDataListItems(listOfCartons);
        }
        this.isLoading = false;
      }, err => {
        this.isLoading = false;
      }
    );
  }

}

import { Component, Input } from "@angular/core";
import { Const } from "@const/Const";
import { ActivatedRoute } from "@angular/router";
import { environment } from "@env/environment";
import { ModalHelper } from "@wearewarp/ng-antd";
import { AddQuickOrderSetting, FormDataAddQuickOrderSetting } from "../../.././quick-order-settings";
import { Observable } from "rxjs";
import { UIHelper } from "@services/UIHelper";
import { BaseComponent } from "@abstract/BaseComponent";

@Component({
  selector: "[client-detail-quick-order-setting]",
  templateUrl: "./index.html",
  styleUrls: [
    "./index.scss",
    "../../../../detail.scss",
    "../../../../../../styles/row-col.scss",
    "../../../../../../styles/form-v2.scss",
  ],
})
// WARP Quick Order Settings
export class ClientDetailQuickOrderSetting extends BaseComponent {
  private _model: any;
  @Input() set model(value) {
    this._model = value;
  }
  get model() {
    return this._model;
  }

  constructor(protected activatedRoute: ActivatedRoute, private modalHelper: ModalHelper) {
    super(activatedRoute);
  }

  getQuickOrderUrl(info) {
    if (!info?.token) return "";
    return `${environment.customerWebUrl}/public/quick-order/${info.token}`;
  }

  onBtnAddQuickOrderURL() {
    this.modalHelper.openForm(AddQuickOrderSetting, {
      nzTitle: `Add Quick Order Settings`,
      labelBtnOK: "Generate URL",
      onSubmitError: (err) => {
        UIHelper.showErr(err);
      },
      onSubmitSucceeded: (resp) => {
        UIHelper.showSuccess(resp);
        this.refreshData();
      },
      nzComponentParams: {
        submit: (data: FormDataAddQuickOrderSetting) => this.createQuickOrderSetting(data),
      },
    });
  }

  private createQuickOrderSetting(data): Observable<any> {
    const url = `${Const.APIURI_CLIENTS}/${this.model.id}/generate-url-quick-order`;
    return this.api.POST(url, data);
  }

  isGenerating = false;
  onBtnReGenerateQuickOrderURL(quickOrderAccess) {
    let url = `${Const.APIURI_CLIENTS}/${this.model.id}/re-generate-url-quick-order`;
    let data = {
      email: quickOrderAccess.email,
      token: quickOrderAccess.token,
    };
    this.isGenerating = true;
    this.api.POST(url, data).subscribe(
      (resp) => {
        this.isGenerating = false;
        this.refreshData();
      },
      (err) => {
        this.showErr(err);
        this.isGenerating = false;
      }
    );
  }

  getBtnLabelSendEmailQuickOrder(quickOrderAccess) {
    return quickOrderAccess?.isTokenExpired ? "Re-Send Email" : "Send Email";
  }

  onBtnSendEmailQuickOrder(quickOrderAccess) {
    this.modalHelper.confirmYesNo(`Do you really want to send Quick Order email to ${quickOrderAccess?.email} ?`, () =>
      this.sendEmailQuickOrder(quickOrderAccess)
    );
  }

  isSendingEmail = false;
  private sendEmailQuickOrder(quickOrderAccess) {
    let data = {
      email: quickOrderAccess.email,
      token: quickOrderAccess.token,
    };
    this.isSendingEmail = true;
    this.api.POST(`${Const.APIURI_CLIENTS}/${this.model.id}/send_quick_order_email`, data).subscribe(
      (resp) => {
        this.isSendingEmail = false;
        this.showSuccess("Quick Order email has been sent successfully.");
        this.refreshData();
      },
      (err) => {
        this.showErr(err);
        this.isSendingEmail = false;
      }
    );
  }

  isRemoving = false;
  onBtnRemoveQuickOrderSetting(quickOrderAccess) {
    this.modalHelper.confirmYesNo(`Do you really want to remove setting for email ${quickOrderAccess?.email} ?`, () => {
      let url = `${Const.APIURI_CLIENTS}/${this.model.id}/remove-quick-order-access`;
      let data = {
        email: quickOrderAccess.email,
        token: quickOrderAccess.token,
      };
      this.isRemoving = true;
      this.api.POST(url, data).subscribe(
        (resp) => {
          this.isRemoving = false;
          this.refreshData();
        },
        (err) => {
          this.showErr(err);
          this.isRemoving = false;
        }
      );
    });
  }

  isRefreshingData = false;
  refreshData() {
    let url = `${Const.APIURI_CLIENTS}/${this.model.id}`;
    this.isRefreshingData = true;
    this.api.GET(url).subscribe(
      (resp) => {
        this.model = resp.data;
        this.isRefreshingData = false;
      },
      (err) => {
        this.showErr(err);
        this.isRefreshingData = false;
      }
    );
  }
}

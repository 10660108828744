import { BaseComponent } from "@abstract/BaseComponent";
import { Component, Input, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Const } from "@const/Const";
import { DateUtil } from "@services/date-utils";
import { WarehouseManifestEditPallet } from "../edit-pallet";
import { DrawerService } from "@app/drawers/drawer.service";
import { ManifestItemsListCarton } from "../list-carton";

@Component({
  selector: '[warehouse-manifest-pallet-detail]',
  templateUrl: './index.html',
  styleUrls: [
    './index.scss',
  ]

})
export class WarehouseManifestPalletDetail extends BaseComponent {

  @ViewChild('manifestItemsListCartonComponent') manifestItemsListCartonComponent: ManifestItemsListCarton;

  constructor(protected route: ActivatedRoute) {
    super();
  }

  public manifestId;
  public palletId;
  public isLoading: boolean = true;
  public model: any;
  public listOfCartons: any[] = [];

  ngOnInit(): void {
    super.ngOnInit();
    this.route.params.subscribe(params => {
      this.manifestId = params['id'];
      this.palletId = params['itemId'];
      this.getData();
    });
  }

  private getData() {
    let url = `${Const.APIURI_WAREHOUSE_MANIFEST}/${this.manifestId}/pallets/${this.palletId}`;
    this.isLoading = true;
    this.api.GET(url).subscribe(
      resp => {
        let { data, listOfCartons } = this.onGetDetailSuccess(resp.data);
        this.model = data;
        this.listOfCartons = listOfCartons;
        this.isLoading = false;
      }, err => {
        this.showErr(err);
        this.isLoading = false;
      }
    );
  }

  onGetDetailSuccess(data) {
    let listOfItems = data?.items || [];
    let listOfCartons = data?.cartons || [];
    let itemDic = {};
    listOfItems.map(it => itemDic[it.id] = it);
    for (let carton of listOfCartons) {
      carton.items = (carton.itemIds || []).map(itemId => itemDic[itemId]);
    }
    let displayInfo: any = {
      createdBy: data.type == "CREATED_BY_CUSTOMER" ? 'Customer' : `Warp on ${DateUtil.displayLocalTime(data.insert.when,{ format: Const.FORMAT_GUI_DATE})}`,
      canRemoveCarton: data.type != "CREATED_BY_CUSTOMER",
      weight: this.getWeightValue(data),
      dim: this.getDimValue(data),
    };
    data.displayInfo = displayInfo;
    return { data, listOfCartons }
  }

  onBtnEditPallet() {
    DrawerService.openFormDrawer1(WarehouseManifestEditPallet, {
      nzContentParams: {
          updateSuccess: this.onRefreshData.bind(this),
          manifestId: this.manifestId,
          model: this.model,
          closeOnSuccess: true
      },
      nzWidth: 410,
      nzWrapClassName: 'wrap-drawer import-manifest-drawer',
    });
  }

  onRefreshData() {
    this.getData();
  }

  getWeightValue(item) {
    let weight = 'N/A';
    if (item.weight) {
      weight = item.weight;
      if (item.weightUnit) {
        weight = `${weight} ${item.weightUnit}`;
      }
    }
    return weight;
  }

  getDimValue(item) {
    let dim = 'N/A';
    if (item.length || item.width || item.height) {
      dim = `${item.length || 'N/A'}x${item.width || 'N/A'}x${item.height || 'N/A'}`;    // (LxWxH)
      if (item.dimsUnit) {
        dim = `${dim} ${item.dimsUnit}`;
      }
    }
    return dim;
  }

  onBtnRefreshDataItems() {
    let url = `${Const.APIURI_WAREHOUSE_MANIFEST}/${this.manifestId}/pallets/${this.palletId}`;
    this.isLoading = true;
    this.api.GET(url).subscribe(
      resp => {
        let { data, listOfCartons } = this.onGetDetailSuccess(resp.data);
        this.model = data;
        this.manifestItemsListCartonComponent?.refeshDataListItems(listOfCartons);
        this.isLoading = false;
      }, err => {
        this.showErr(err);
        this.isLoading = false;
      }
    );
  }
  
}

import { Component, ViewChild } from '@angular/core';
import { SidebarComponent } from './sidebar/sidebar.component';
import { TopnavAdminComponent } from './topnav/topnav.component';
import { Const } from '@const/Const';
import { BaseComponent } from '@abstract/BaseComponent';
import { PermissionManager } from '@services/permission-manager';
import { ActivatedRoute } from '@angular/router';
import { BaseDetail } from './base/detail';
import { Utils } from '@services/utils';
import { setDashboard } from '@services/index';
import { MasterData } from '@services/master.data';
import { sideMenu } from './sidebar/side-menu';
import { verifyWhileUsingDashboard } from '@services/auth.check-role';
import { getFeatureFlags } from '@services/feature-flag.service';
// import { mixinHTMLElementWidthChangedNotifier } from '@abstract/HTMLElementWidthChangedNotifier';

// media-breakpoint-down(md) means max-width: 991px
const MEDIA_BREAKPOINT_DOWN_MD_WIDTH = 1199;

@Component({
  selector: '[app-dashboard]',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent extends BaseComponent {
  private widthSmall: boolean = false;
  private activatedComponent;
  private pendingNavigationEndUrl;
  public isLoadingMasterData;
  public sidebarV2: boolean = false;
  
  @ViewChild('topBar', {static: true}) topBar: TopnavAdminComponent;
  @ViewChild('sideBar', {static: false}) sideBar: SidebarComponent;
  // @ViewChild('mainContainer', {static: false}) mainContainer: ElementRef<HTMLDivElement>;
  // protected get contentElement(): HTMLElement { return this.mainContainer?.nativeElement }

  constructor(protected activatedRoute: ActivatedRoute) {
    super();
    window.addEventListener('beforeunload', (e) => {
      if (this.activatedComponent instanceof BaseDetail && (<BaseDetail>this.activatedComponent).isEditOrCreate) {
        e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
        e.returnValue = ''; // Chrome requires returnValue to be set
      }
    });
    setDashboard(this);
  }
  
  public onNavigationEnd(url) {
    if (!this.activatedComponent) {
      if (url.length > '/'.length) {
        this.pendingNavigationEndUrl = url;
      }
    } else if (this.activatedComponent instanceof BaseComponent) {
      (<BaseComponent>this.activatedComponent).onNavigationEnd(url);
    }
  }
  
  public doInitialRouting() {
    let link = this.getInitialLink(this.authUser);
    if (link) {
      this.router.navigate([link]);
    }
  }

  ngOnInit() {
    if (!this.isLoggedIn) {
      return this.goLogin();
    }
    verifyWhileUsingDashboard(this.authUser, this.router);
    getFeatureFlags().isFlagSetNonBlocking('SIDEBAR_V2').subscribe((res) => {
      this.sidebarV2 = res
      if (res && this.sideBar) {
          this.sideBar.isSmallWidth = true
      }
    })
    this.getMasterData();
    super.ngOnInit();
    if (this.router.url === (this.routeDashboard)) {
      this.doInitialRouting();
    }
  }
  
  ngAfterViewInit() {
    super.ngAfterViewInit();
    setTimeout(() => {
      this.topBar.onActiveComponentChanged(this.activatedComponent);
      this.setWidthSmall(window.innerWidth <= MEDIA_BREAKPOINT_DOWN_MD_WIDTH);
    }, 1);
  }
  
  // Dùng để redirect khi user chỉ nhập domain hoặc là chỉ nhập domain/dashboard
  private getInitialLink(authUser) {
    for (let item of sideMenu) {
      if (item.permissions) {
        if (!PermissionManager.userHasOneOfPermissions(authUser, item.permissions)) continue;
      }
      if (!item.children) {
        return `dashboard/${item.link}`;
      }
      for (let child of item.children) {
        if (PermissionManager.userHasOneOfPermissions(authUser, child.permissions) || (!child.permissions)) {
          let path = this.getRouterLink(item, child);
          return `dashboard/${path}`;
        }
      }
    }
    return null;
  }

  getRouterLink(item, child) {
    if (child.link === 'quick-upload-pod') return `finance-v2/${child.link}`;
    return child.isUseParentPath ? `${item.link}/${child.link}` : child.link;
  }

  setupLanguage() {
  }

  onResize(event) {
    if (event.target.innerWidth <= MEDIA_BREAKPOINT_DOWN_MD_WIDTH && !this.widthSmall) {
      this.setWidthSmall(true);
    } else if (event.target.innerWidth > MEDIA_BREAKPOINT_DOWN_MD_WIDTH && this.widthSmall) {
      this.setWidthSmall(false);
    }
  }

  updatePageTitle(str: string){
    this.topBar.setTopbarTitleFixed(str);
  }

  private setWidthSmall(value: boolean) {
    if (this.widthSmall == value) {
      return;
    }
    this.widthSmall = value;
    if (this.sideBar) {
      this.sideBar.isSmallWidth = value;
    }
  }

  onRouterActivate(compRef) {
    compRef.parentComponent = this;   // necessary for HTMLElementWidthChangedListener
    this.activatedComponent = compRef;
    if (this.topBar) {
      this.topBar.onActiveComponentChanged(this.activatedComponent);
    }
    if (this.pendingNavigationEndUrl) {
      if (Utils.isFunction(this.activatedComponent.onNavigationEnd)) {
        this.activatedComponent.onNavigationEnd(this.pendingNavigationEndUrl);
      }
      this.pendingNavigationEndUrl = null;
    }
  }

  onRouterDeactivate(compRef) {
  }

  public isOverFlowXHidden = false;

  public setOverFlowXHidden(value: boolean) {
    this.isOverFlowXHidden = value;
  }


  private getMasterData() {
    this.isLoadingMasterData = true;
    this.api.GET(Const.API_PUBLIC('masters')).subscribe(
      resp => { 
        MasterData.set(resp.data);
        this.isLoadingMasterData = 'OK';
      }, err => {
        this.showErr(err);
        this.isLoadingMasterData = 'NG';
      });
  }

  setFullSidebar(full) {
    if (this.sidebarV2)
      this.sideBar.isSmallWidth = !full
  }
}

<div class="form-header">
  <div style="align-self: center;">
    <span class="form-title-main">Edit Pallet {{ model?.palletName ?? 'detail' }}</span>
  </div>
  <div>
    <i class="ic-right-close clickable" nz-icon nzType="close" nzTheme="outline" (click)="onClose()"></i>
  </div>
</div>
<div class="form-body">
  <form class="form-detail" [formGroup]="formInput" nz-form>
    <div nz-row nzGutter="8">
      <div nz-col nzSm="12" *ngFor="let key of ['label']">
        <div class="form-label-v2 bottom5 medium">
          {{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span>
        </div>
        <nz-form-item>
          <nz-form-control>
            <input nz-input [formControlName]="key" style="width: 100%;"
              [type]="getInputType(key)" 
              [placeholder]="getPlaceHolder(key)"
              (input)="onInputChanged($event, key)" 
              (keypress)="onInputKeyPress($event, key)">
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <div nz-row nzGutter="8">
      <div nz-col nzSm="6" *ngFor="let key of ['weight']">
        <div class="form-label-v2 bottom5 medium">
          {{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span>
        </div>
        <nz-form-item>
          <nz-form-control>
            <input nz-input [formControlName]="key" style="width: 100%;"
              [type]="getInputType(key)" 
              [placeholder]="getPlaceHolder(key)"
              (input)="onInputChanged($event, key)" 
              (keypress)="onInputKeyPress($event, key)">
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzSm="6" *ngFor="let key of ['weightUnit']">
        <div class="form-label-v2 bottom5">&nbsp;</div>
        <nz-form-item>
          <nz-form-control>
            <nz-select nzBackdrop="true" [formControlName]="key" style="width: 100%;">
              <nz-option *ngFor="let item of weightUnits" [nzValue]="item" [nzLabel]="item"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    
    <div class="medium bottom5">DIM</div>
    <div nz-row nzGutter="8">
      <div nz-col nzSm="6" *ngFor="let key of ['length', 'width', 'height']">
        <div class="form-label-v2 bottom5">
          {{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span>
        </div>
        <nz-form-item>
          <nz-form-control>
            <input nz-input [formControlName]="key" style="width: 100%;"
              [type]="getInputType(key)" 
              [placeholder]="getPlaceHolder(key)"
              (input)="onInputChanged($event, key)" 
              (keypress)="onInputKeyPress($event, key)">
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzSm="6" *ngFor="let key of ['dimsUnit']">
        <div class="form-label-v2 bottom5">&nbsp;</div>
        <nz-form-item>
          <nz-form-control>
            <nz-select nzBackdrop="true" [formControlName]="key" style="width: 100%;">
              <nz-option *ngFor="let item of sizeUnits" [nzValue]="item" [nzLabel]="item"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
  </form>
</div>
<div class="form-footer">
  <div form-footer [onProgress]="onProgress" 
    [canClickOK]="needUpdate && !onProgress" [canClickCancel]="!onProgress" 
    [labelOK]="'Save'" (onOK)="onBtnSave()" 
    (onCancel)="onClose()"></div>
</div>

import { BaseComponent } from "@abstract/BaseComponent";
import { Component, Input } from "@angular/core";
import { Const } from "@const/Const";
import { environment } from "@env/environment";
import { DateUtil } from "@services/date-utils";
import dayjs from "dayjs";

@Component({
    selector: '[delivery-eta]',
    templateUrl: './index.html',
    styleUrls: ['./index.scss']
})
export class DeliveryEta extends BaseComponent {
    interval: number = 30000
    lastLoaded: number = 0
    _id: string = null
    get id() {
        return this._id
    }
    @Input() set id(v) {
        this._id = v

        this.lastLoaded = 0
        this.interval = 30000
        this.loadEta()
        if (!this.timer)
            this.timer = setInterval(this.loadEta, 10000)
    }
    @Input() compact = false;
    @Input() overflow = false;
    @Input() tz = null;

    public _eta = null;
    get eta() {
        return this._eta
    }
    set eta(v) {
        if (
            v?.eta?.window?.from === this._eta?.eta?.window?.from &&
            v?.eta?.window?.to === this._eta?.eta?.window?.to
        ) {
            this.interval = Math.min(180000, this.interval + 30000)
        } else {
            this.interval = 30000
        }
        this._eta = v
        this.processData()
    }
    public reported = null
    public reportedTs = null
    public mileOut = null
    public timeToDestination = null
    public window = null
    public break = null
    public loading = false
    public expanded = false
    timer = null
    displayTz = null
    showEta = null

    constructor() {
        super()
        this.loadEta = this.loadEta.bind(this)
        this.reset()
    }

    ngOnDestroy(): void {
        this.timer && clearInterval(this.timer)
        this.timer = null
    }

    ngOnChanges(): void {
        this.displayTz = this.tz ? DateUtil.timezoneStandardToUsShort(this.tz) : null
        this.displayTz = this.displayTz || this.tz
    }

    onToggleExpanded() {
        this.expanded = !this.expanded
    }

    reset() {
        this.expanded = false
        this._eta = null
        this.reported = null
        this.mileOut = null
        this.timeToDestination = null
        this.break = null
        this.window = null
        this.reportedTs = null
        this.showEta = null
    }

    seconds2hours(seconds) {
        const s = seconds % 60
        const minutes = (seconds - s) / 60
        const m = minutes % 60
        const h = (minutes - m) / 60
        if (h > 0) return `${h}h ` + (m > 0 ? `${m}m` : '')
        return `${m} minutes`
    }

    processData() {
        if (!this._eta) return
        const { ts } = this._eta
        const { window, distance, time, idleTime } = this._eta.eta || {}
        const { from, to } = window || {}

        this.reported = dayjs(ts).fromNow()
        this.reportedTs = dayjs(ts).format(Const.FORMAT_GUI_DATETIME)
        if (distance) {
            this.mileOut = `${(distance / 1609.34).toFixed(1)} mi`
        }
        if (time) {
            this.timeToDestination = this.seconds2hours(time)
            const eta = ts + 1000 * (time || 0) + 1000 * (idleTime || 0)
            this.showEta = DateUtil.displayLocalTime(eta, {
                format: Const.FORMAT_GUI_DATETIME_V5,
                timezone: this.tz
            })
        }
        if (idleTime) {
            this.break = this.seconds2hours(idleTime)
        }
        if (from && to) {
            this.window = DateUtil.displayTimeWindow(window, {
                timezone: this.tz,
                format: Const.FORMAT_GUI_DATETIME_V5,
                formatDateOnly: Const.FORMAT_GUI_DATE_SHORT
            })
        }
    }

    loadEta() {
        if (!environment.eventUrl) return
        if (this.lastLoaded > Date.now() - this.interval) return
        this.lastLoaded = Date.now()
        this.loading = true
        let url = `${environment.eventUrl}/delivery/${this.id}/ETA?limit=1`;
        this.api.GET(url).subscribe(resp => {
            this.loading = false
            if (resp && resp.length > 0) {
                this.eta = resp[0]
            }
        },
        err => {
            this.loading = false
        })
    }

}
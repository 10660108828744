<ng-template [nzModalTitle]>
  <div class="flex-space-between">
    <div>Upload SKU label</div>
    <div>
      <span nz-icon nzType="close" nzTheme="outline" class="clickable" (click)="closeDialog()"></span>
    </div>
  </div>
</ng-template>
<div class="bottom20">
  <form nz-form class="form-detail" [formGroup]="formInput" nzLayout="vertical">
    <div *ngIf="isSingleSKU">
      <div>This will upload label to SKU {{ listOfSKU[0].sku }} in {{ numberOfCartons }} selected {{ numberOfCartons == 1 ? 'carton' : 'cartons' }}</div>
      <div class="top10">Total quantity: {{ listOfSKU[0].sumOfQuantity }} pieces</div>
    </div>
    <div *ngIf="isMultiSKU">
      <div>We found {{ listOfSKU.length }} SKUs in your selected {{ numberOfCartons }} {{ numberOfCartons == 1 ? 'carton' : 'cartons' }}. Which SKU you want to upload label for?</div>
      <div *ngFor="let key of ['sku']" class="top10">
        <nz-form-item>
          <nz-form-control>
            <nz-radio-group style="width: 100%;"
              (ngModelChange)="onInputSKUChange($event)"
              [formControlName]="key">
              <ng-container *ngFor="let item of listOfSKU">
                <div nz-radio [nzValue]="item.sku" class="radio-item">
                  {{ item.sku }} ({{ item.sumOfQuantity }} pieces)
                </div>
              </ng-container>
            </nz-radio-group>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <div *ngFor="let key of ['labelFile']" class="top15 bottom10">
      <input #inputFile id="uploadLabelInput" type="file" hidden accept=".pdf,.png,.jpg,.jpeg" (change)="onFileSelected(key, inputFile.files)">
      <div>
        <div *ngIf="!hasAttachedFile(key)" class="flex-space-between">
          <div>
            <div class="form-label-v2">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
          </div>
          <div>
            <button nz-button nzShape="round" nzType="dashed" (click)="inputFile.click()">
              <i nz-icon nzType="upload" nzTheme="outline"></i>Select file
            </button>
          </div>
        </div>
        <div *ngIf="hasAttachedFile(key)">
          <div class="form-label-v2">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
          <div class="flex-space-between" style="align-items: center;">
            <div class="file-selected">
              <i nz-icon nzType="paper-clip" nzTheme="outline"></i>{{ labelSelectFile }}
            </div>
            <button nz-button nzType="text" (click)="delFile(key, inputFile)">
              <i nz-icon nzType="delete"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<div form-footer [onProgress]="onProgress" 
  [canClickOK]="needUpdate && !onProgress" [canClickCancel]="!onProgress" 
  [labelOK]="'Save'" (onOK)="onBtnSave()" 
  (onCancel)="closeDialog()"></div>
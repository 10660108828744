import {Component, Input} from '@angular/core';
import {BaseFormDialog1} from '@dialogs/base-form-dlg1';
import {Const} from '@const/Const';
import {FormEditRate} from '@app/admin/crossdock/rate-configuration/edit-rate/FormEditRate';
import {Validators} from '@angular/forms';

@Component({
  selector: '[warehouse-edit-rate-adjustment]', templateUrl: './view.html', styleUrls: ['index.scss']
})
export class EditRateAdjustment extends BaseFormDialog1<FormEditRate> {
  @Input() warehouseId: string;
  protected formGroupDeclaration: FormGroupDeclaration = {
    name: {label: 'Fee name', type: 'string', inputType: 'text', notAcceptEmpty: true, required: true},
    code: {label: 'Fee code', type: 'string', inputType: 'text', notAcceptEmpty: true, required: true},
    price: {
      label: 'Unit price',
      type: 'number',
      inputType: 'number',
      notAcceptEmpty: true,
      required: true,
      validators: [Validators.min(0.01), Validators.maxLength(6)]
    },
  };

  get shouldCreateFormImmediately() {
    return true;
  }

  get headerText() {
    return `Edit ${this.model?.name}`;
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  clickEdit() {
    if (!this.warehouseId) {
      this.showErr('Missing warehouseId!');
      return;
    }
    this.onProgress = true;
    const url = Const.API_WAREHOUSE_MANAGEMENT(`rate_adjustments/${this.model?.id}`);
    const body = {
      name: this.model.name,
      code: this.model.code,
      price: this.model.price,
      ...this.getFormData_JSON(true)
    };
    const options = {customHeaders: {warehouseId: this.warehouseId}};
    this.api.PUT(url, body, options).subscribe(resp => {
      this.onProgress = false;
      this.onUpdateSuccess({...this.model, ...body});
    }, err => {
      this.showErr(err);
      this.onProgress = false;
    });
  }
}

<nz-input-group nzCompact>
    <nz-select nzBackdrop="true" nzShowSearch nzServerSearch [nzPlaceHolder]="placeHolder" [(ngModel)]="value" [nzShowArrow]="false" [nzDisabled]="isDisabled"
        style="width: calc(100% - 70px)" [nzFilterOption]="nzFilterOption" (nzOnSearch)="search($event)"
        (nzFocus)="onSelectEvent('focus', true)" (nzBlur)="onSelectEvent('focus', false)" [nzLoading]="isLoading"
        (mouseover)="onSelectEvent('hover', true)"  (mouseout)="onSelectEvent('hover', false)"
        [nzDropdownRender]="tplBtnSelectAddressFromMap"
         [compareWith]="isSelectedLocation" nz-popover
        [nzPopoverContent]="tooltipTemplate" nzPopoverOverlayClassName="tooltip-addr"
        [nzDisabled]="isDisabled" [nzPopoverVisible]="shouldShowDetail"
        [nzPopoverTrigger]="shouldShowDetail === undefined ?'hover': null">
        <ng-container *ngIf="!isLoading">
            <nz-option *ngFor="let option of options" [nzLabel]="getLabelOption(option)" [nzValue]="option">
            </nz-option>
        </ng-container>
        <nz-option *ngIf="isLoading" nzDisabled nzCustomContent>
            <span nz-icon nzType="loading" class="loading-icon"></span>
            Loading Data...
        </nz-option>
    </nz-select>
    <nz-select nzBackdrop="true" [(ngModel)]="selectedCountry" nzShowSearch style="width: 70px;" class="country" nz-tooltip
        nzTooltipTitle="Select country" [nzDisabled]="isDisabled">
        <nz-option *ngFor="let item of country" [nzLabel]="item.code" [nzValue]="item.code"></nz-option>
    </nz-select>
    <ng-template #tplBtnSelectAddressFromMap>
      <div class="select-address-from-map" (click)="onBtnSelectAddressFromMap()">
        <a >
          <span nz-icon nzType="plus"></span>
          can't find the address?
        </a>
      </div>
    </ng-template>
</nz-input-group>
<ng-template #tooltipTemplate>

    <ng-container *ngIf="!this.selected?.metadata">
        Please enter something to search for the address
    </ng-container>
    <nz-descriptions *ngIf="this.selected?.metadata" nzBordered [nzColumn]="1" nzSize="small">
        <nz-descriptions-item nzTitle="Address">
            {{getLabelOption(this.selected)}}
            <span nz-icon nzType="copy" nzTheme="outline" (click)="copyAddress($event)" nz-tooltip
                nzTooltipTitle="Copy address" style="cursor: pointer;"></span>
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Country">
            {{this.selected?.countryAlpha2Code || 'US'}}
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="TimeZone">
            {{this.selected?.metadata?.timeZone}} ({{this.selected?.metadata?.timeZoneStandard}})
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="GPS Location">
            {{this.selected?.metadata?.latitude}},{{this.selected?.metadata?.longitude}}
        </nz-descriptions-item>
        <!-- <nz-descriptions-item nzTitle="Source">
            {{this.selected?.from}}
        </nz-descriptions-item> -->
    </nz-descriptions>
</ng-template>

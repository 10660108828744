<span [style.font-weight]="styleFontWeight" [style.color]="styleTextColor" >
  <span>{{displayInfo.createdWhen}}</span>
  by
  <a [routerLink]="[displayInfo.hyperLinkCreatedBy]" 
    [style.color]="styleTextColor" 
    [style.font-weight]="styleFontWeight" 
    target="_blank">
    {{displayInfo.createdBy}}
  </a>
</span>

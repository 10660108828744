import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import {BaseFormDialog1} from '@dialogs/base-form-dlg1';
import moment from 'moment';
import {Validators} from '@angular/forms';

@Component({
  selector: 'app-detail-document',
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})
export class CarrierDetailDocumentComponent extends BaseFormDialog1 {
  public isLoading = false;
  public fileArrs = [];
  @Input() onSave: (data) => void = null;
  @Input() key: string = null;
  @Input() modalTitle: string = 'Add Document';
  @Input() requireDate = false;
  @ViewChild('inputFile') inputFile: ElementRef;
  protected formGroupDeclaration: FormGroupDeclaration = {
    startDate: {label: 'Insurance Start Date', type: 'date'},
    endDate: {label: 'Insurance End Date', type: 'date'},
  };

  get shouldShowDate(): boolean {
    return this.key !== 'w9Document';
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.fileArrs = [...this.model?.docs ?? []];
    if(this.requireDate){
      this.formInput.get('startDate').addValidators(Validators.required);
      this.formInput.get('endDate').addValidators(Validators.required);
    }
  }

  onBtnSave() {
    const data = this.getFormData_JSON(true);
    const {startDate = null, endDate = null}: any = data;
    const params = {
      docs: this.fileArrs,
      uploadIds: this.model?.uploadIds ?? [],
      insurance: {startDate, endDate}
    };
    this.onSave(params);
    if (this.closeOnSuccess) {
      this.closeDialog();
    }
  }

  delFile(index) {
    const file = this.fileArrs[index];
    if (this.isFile(file)) {
      this.confirmDeletion({
        message: `Delete file ${file.name}?`, txtBtnOk: 'Delete', fnOk: () => {
          this.fileArrs.splice(index, 1);
        }
      });
    } else {
      file.deleted = true;
    }
    this.inputFile.nativeElement.value = '';
  }

  undoDeleteFile(index) {
    const file = this.fileArrs[index];
    delete file.deleted;
  }

  public isFileDeleted(index) {
    const file = this.fileArrs[index];
    return file.deleted == true;
  }

  getAttachedFileDesc(item) {
    if (!item) {
      return '';
    }
    return `${item.name} (${this.displayFileSize(item.size)})`;
  }

  onFileSelected(files: FileList) {
    if (files.length == 0) {
      return;
    }

    const file = files[0];
    this.checkExistsDocumentArray(file);
  }

  isFile(item): boolean {
    return item instanceof File;
  }

  public disabledEndDate = (value: Date): boolean => {
    let startDateInput = this.getItemValue('startDate');
    if (startDateInput) {
      const startDate = moment(startDateInput).endOf('day').toDate();
      return moment(value).endOf('day').toDate().getTime() <= startDate.getTime();
    }
    return false;
  };

  public disabledStartDate = (value: Date): boolean => {
    let endDateInput = this.getItemValue('endDate');
    if (endDateInput) {
      const endDate = moment(endDateInput).endOf('day').toDate();
      return moment(value).endOf('day').toDate().getTime() >= endDate.getTime();
    }
    return false;
  };

  protected beforeBindModel(model): any {
    if (model?.insurance?.startDate) {
      model.startDate = model?.insurance?.startDate;
    }
    if (model?.insurance?.endDate) {
      model.endDate = model?.insurance?.endDate;
    }
    return model;
  }

  private checkExistsDocumentArray(file) {
    for (let item of this.fileArrs) {
      let isEqual = file.name == item.name && file.size == item.size && file.type == item.type;
      if (isEqual) {
        return;
      }
    }

    this.fileArrs.push(file);
  }
}

<div *ngIf="isLoading" class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>

<nz-alert *ngIf="!isLoading" [nzType]="matchedCount == 0 ? 'error' : 'success'" [nzMessage]="matchingResult" style="margin-bottom: 10px"></nz-alert>

<!-- <nz-alert *ngIf="error" nzType="error" [nzMessage]="error" style="margin-bottom: 10px"></nz-alert> -->

<nz-transfer *ngIf="!isLoading" [nzDataSource]="list" [nzDisabled]="isDisabled"
  [nzShowSelectAll]="false" [nzRenderList]="[renderList, renderList]"
  [nzItemUnit]="'carrier'" [nzItemsUnit]="'carriers'" (nzChange)="change($event)"
>
  <ng-template #renderList let-items let-direction="direction" let-stat="stat" let-disabled="disabled"
    let-onItemSelectAll="onItemSelectAll" let-onItemSelect="onItemSelect">
    <div class="group-filter">
      <div nz-row [nzGutter]="16">
        <div nz-col [nzSpan]="16">
          <nz-input-group [nzPrefix]="suffixIconSearch">
            <input type="text" nz-input placeholder="Search by Carrier Name, MC Number or DOT Number" (input)="onSearch($event.target.value, direction)" />
          </nz-input-group>
          <ng-template #suffixIconSearch>
            <span nz-icon nzType="search"></span>
          </ng-template>
        </div>
        <div nz-col [nzSpan]="8">
          <nz-select nzBackdrop="true" nzShowSearch nzAllowClear 
            nzPlaceHolder="Filter by Pool" style="width: 100%;" 
            (ngModelChange)="onPoolChange($event, direction)"
            [ngModel]="poolSelected"
          >
            <nz-option *ngFor="let item of pools" [nzLabel]="item.name" [nzValue]="item.id"></nz-option>
          </nz-select>
        </div>
      </div>
    </div>
    
    <nz-table #t [nzData]="$asTransferItems(items)" nzSize="small" [nzHideOnSinglePage]="true">
      <thead>
        <tr>
          <th [nzDisabled]="disabled" [nzChecked]="stat.checkAll" [nzIndeterminate]="stat.checkHalf"
            (nzCheckedChange)="onItemSelectAll($event)"></th>
          <th>Carrier Name</th>
          <th>Status</th>
          <th>Pools</th>
          <th>Type</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of t.data" (click)="onItemSelect(data)">
          <td [nzChecked]="!!data.checked" [nzDisabled]="disabled || data.disabled"
            (nzCheckedChange)="onItemSelect(data)"></td>
          <td>{{ data.title }}</td>
          <td>
            <ng-container *ngIf="data.status != 1">
              <nz-tag nzColor="warning">{{$asCarrierStatusText(data.status)}}</nz-tag>
            </ng-container>
            <ng-container *ngIf="data.status == 1">
              <nz-tag nzColor="success">Active</nz-tag>
            </ng-container>
          </td>
          <td>
            <ng-container *ngFor="let item of getTags(data.id)">
              <nz-tag>{{ item }}</nz-tag>
            </ng-container>
          </td>
          <td>
            <nz-tag [nzColor]="$asCarrierMatchingType(data.tag).color">{{ $asCarrierMatchingType(data.tag).label }}
            </nz-tag>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </ng-template>
</nz-transfer>

import { Component, forwardRef, Inject, Injector, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Utils } from '@services/utils';
import { BaseInputComponent } from '../base-custom-input';
import { ApiService } from '@services/api.service';
import { Const } from '@const/Const';
import _ from "underscore";
import { InputHelper } from '@services/input-helper';

@Component({
  selector: 'app-form-carrier',
  templateUrl: './view.html',
  styleUrls: ['style.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => FormCarrierComponent), multi: true }],

})
export class FormCarrierComponent extends BaseInputComponent {

  @Input() 
  public isLoading?: boolean = true;

  @Input() jobId;
  @Input() isDisabled: boolean = false;

  public allCarriers = [];

  constructor(
    @Inject(Injector) protected injector: Injector,
    protected api: ApiService
  ) {
    super(injector)
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.onLoadData();
  }

  /**
   * Load carrier list on init.
   */
  onLoadData() {
    this.isLoading = true;
    let url = `${Const.APIURI_CARRIERS}/list/all_for_filter`;
    this.api.GET(url).subscribe((response) => {
      let carriers = _.get(response, ["data", "list_data"], []);
      this.allCarriers = this.buildDisplayInfo(carriers);
      this.isLoading = false;
    });
  }

  private buildDisplayInfo(carriers) {
    return carriers.filter(x => x).map(carrier => {
      return {
        id: carrier.id,
        label: this.getCarrierNameWithMCandDOT(carrier),
        name: this.getCarrierName(carrier),
        basicInfoMC: this.getCarrierMC(carrier),
        basicInfoDOT: this.getCarrierDOT(carrier),
      }
    })
  }

  private getCarrierName(carrier): string {
    return carrier?.basicInfo?.name ?? '';
  }
  private getCarrierMC(carrier): string {
    return carrier?.basicInfo?.mc ?? '';
  }
  private getCarrierDOT(carrier): string {
    return carrier?.basicInfo?.dot ?? '';
  }
  private getCarrierNameWithMCandDOT(carrier): string {
    if(carrier?.basicInfo?.mc && carrier?.basicInfo?.dot)
    return `${carrier?.basicInfo?.name} (MC:${carrier?.basicInfo?.mc}/DOT:${carrier?.basicInfo?.dot})`
    if (carrier?.basicInfo?.mc) return `${carrier?.basicInfo?.name} (MC:${carrier?.basicInfo?.mc})`
    if (carrier?.basicInfo?.dot) return `${carrier?.basicInfo?.name} (DOT:${carrier?.basicInfo?.dot})`
    return carrier?.basicInfo?.name ?? '';
  }

}

<div class="white-box">
  <div class="flex-space-between center-vertical">
    <div class="medium-title">{{ feeType.label }}</div>
    <div class="flex">
      <div class="medium-title">Price unit:</div>
      <div class="left5">Per {{ uomType?.label | lowercase }} per day</div>
    </div>
  </div>
  <div class="flex top20">
    <form [formGroup]="myForm" class="flex-column flex1">
      <div class="fee-box flex-column">
        <div class="flex">
          <div class="tier-left-space top8">Starting Price</div>
          <nz-form-item class="flex1">
            <nz-form-control [nzErrorTip]="getStartingPriceErrorMsg()" [nzValidateStatus]="getStartingPriceControl()">
              <nz-input-group nzPrefix="$">
                <input [formControl]="getStartingPriceControl()" [max]="999999" [min]="0" [step]="0.1" nz-input
                       placeholder="Enter here"
                       type="number">
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
          <div *ngIf="isEditing" class="tier-right-space"></div>
        </div>
        <div formArrayName="tierArray">
          <ng-container *ngFor="let inputTierGroup of tierList.controls ; let index = index; trackBy: trackByFn">
            <div [formGroup]="inputTierGroup" class="flex">
              <div class="tier-left-space top8">From day</div>
              <nz-form-item class="flex1">
                <nz-form-control [nzErrorTip]="getFromDayErrorMsg(inputTierGroup, index)"
                                 [nzValidateStatus]="getFromDayControl(inputTierGroup)">
                  <input [formControl]="getFromDayControl(inputTierGroup)" [max]="999999" [min]="0" [step]="1"
                         nz-input
                         placeholder="Enter here"
                         type="number">
                </nz-form-control>
              </nz-form-item>

              <div class="left20 top8">Price</div>
              <nz-form-item class="flex1 left10">
                <nz-form-control [nzErrorTip]="getPriceErrorMsg(inputTierGroup)"
                                 [nzValidateStatus]="getPriceControl(inputTierGroup)">
                  <nz-input-group nzPrefix="$">
                    <input [formControl]="getPriceControl(inputTierGroup)" [max]="999999" [maxlength]="5" [min]="0"
                           [step]="0.1" nz-input
                           placeholder="Enter here" type="number">
                  </nz-input-group>
                </nz-form-control>
              </nz-form-item>
              <div *ngIf="isEditing" class="tier-right-space top8">
                  <span (click)="clickDeleteTier(index)"
                        *ngIf="(canEdit || index>=(originalFormValue?.tierArray?.length || 0))"
                        class="click red left15"
                        nz-icon
                        nz-tooltip
                        nzTheme="outline" nzTooltipTitle="Delete tier"
                        nzType="delete"></span>
              </div>

            </div>
          </ng-container>
        </div>
        <button (click)="clickAddTier()" *ngIf="isEditing" [disabled]="isLoading || !canAddTier"
                class="add-tier-button medium-title" nz-button nzType="default">
          <span nz-icon nzTheme="outline" nzType="plus"></span>
          Add Tier
        </button>
      </div>
      <div class="flex top15 flex-end">
        <button (click)="clickEdit()" *ngIf="(canEdit|| canAdd) && !isEditing"
                class="action-button" nz-button nzType="primary">
          <span nz-icon nzTheme="outline" nzType="edit"></span>
          Edit
        </button>
        <button (click)="clickCancel()" *ngIf="isEditing" [disabled]="isLoading" class="right10 action-button" nz-button
                nzType="default">
          <span nz-icon nzTheme="outline" nzType="close"></span>
          Cancel
        </button>
        <button (click)="clickSave()" *ngIf="isEditing" [disabled]="!canSave || isLoading" class="action-button"
                nz-button
                nzType="primary">
          <span *ngIf="!isLoading" nz-icon nzTheme="outline" nzType="save"></span>
          <span *ngIf="isLoading" nz-icon nzTheme="outline" nzType="loading"></span>
          Save
        </button>
      </div>
    </form>
    <div class="flex-column live-explanation-box left20">
      <div class="medium-title">Live Explanation</div>
      <div [innerHTML]="liveExplanationText" class="top10 explanation-content"></div>
    </div>
  </div>
</div>

<div nz-col nzSpan="8">
  <div class="select-warehouse-container">
    <nz-select
      nzAllowClear
      nzShowSearch
      [(ngModel)]="warehouseId"
      [nzLoading]="isLoadingWarehouseList"
      nzAllowClear="false"
      [nzPlaceHolder]="'Please select a warehouse'"
      (ngModelChange)="onChangeWarehouse($event)"
      style="width: 100%"
    >
      <nz-option
        *ngFor="let item of crossdockWarehouseList"
        [nzValue]="item.id"
        [nzLabel]="item?.label || item?.name"
      ></nz-option>
    </nz-select>
    <button class="left10" nz-button (click)="onBtnRefresh()">
      <i *ngIf="isLoading" nz-icon nzType="loading" nzTheme="outline"></i>
      <span *ngIf="!isLoading" nz-icon nzType="reload" nzTheme="outline"></span> Refresh
    </button>
  </div>
</div>

<div
  warehouse-shipment-filter
  [clients]="options.clients"
  [pickupLocations]="options.pickupLocations"
  [deliveryLocations]="options.deliveryLocations"
  [statusOptions]="options.status"
  [deliveryStates]="options.deliveryStates"
  [pickupStates]="options.pickupStates"
  [finalDeliveryLocations]="options.finalDeliveryLocations"
  [originalPickupLocations]="options.originalPickupLocations"
></div>

<div *ngIf="getSelectedItems().length" class="action-container">
  <div>
    <button (click)="exportExcel()" nz-button nzType="default">
      <span nz-icon nzType="file-excel" nzTheme="outline"></span> Export Manifest ({{getSelectedItems().length}})
    </button>
    <button (click)="onBtnDownloadBols()" nz-button nzType="default">
      <span *ngIf="!loadingBol" nz-icon nzType="download" nzTheme="outline"></span>
      Download Bols
      <span *ngIf="loadingBol" nz-icon nzType="loading"></span>
    </button>
    <button nz-button nzTrigger="hover" nz-dropdown [nzDropdownMenu]="menuRoute">
      More actions <span nz-icon nzType="more" nzTheme="outline"></span>
      <nz-dropdown-menu #menuRoute="nzDropdownMenu">
        <ul nz-menu nzSelectable>
          <li nz-menu-item (click)="onBtnManualRouteInbound()">
            <span>Create Inbound Route </span>
          </li>
          <li nz-menu-item (click)="onBtnManualRouteOutbound()">
            <span> Create Outbound Route</span>
          </li>
          <li nz-menu-divider></li>
          <li nz-menu-item (click)="onBtnCopyInboundIds()">
            <span style="margin-right: 3px" nz-icon nzType="copy" nzTheme="outline"></span> Copy Inbound Leg Ids
          </li>
          <li nz-menu-item (click)="onBtnCopyOutboundIds()">
            <span style="margin-right: 3px" nz-icon nzType="copy" nzTheme="outline"></span> Copy Outbound Leg Ids
          </li>
          <li nz-menu-item (click)="onBtnCopyMainIds()">
            <span style="margin-right: 3px" nz-icon nzType="copy" nzTheme="outline"></span> Copy Main Shipment Ids
          </li>
          <li nz-menu-item (click)="onBtnCopyOrderIds()">
            <span style="margin-right: 3px" nz-icon nzType="copy" nzTheme="outline"></span> Copy Order Ids
          </li>
          <li nz-menu-divider></li>
          <ng-container *ngFor="let item of actionsUpdateScheduleTime">
            <li nz-menu-item (click)="item?.action()">
              <span>
                <span style="margin-right: 3px" nz-icon nzType="edit" nzTheme="outline"></span>
                {{ item?.label }}
              </span>
            </li>
          </ng-container>
        </ul>
      </nz-dropdown-menu>
    </button>
    <a (click)="onBtnClearAllSelected()">Uncheck all checkboxes</a>
  </div>
  <div>
    <span *ngIf="getSelectedItems().length">
      <i>
        Selected: {{displaySummaryRecords(getSelectedItems().length)}} and
        {{displaySummaryPallets(countSelectedPallets)}}
      </i>
      |
    </span>
    <ng-container *ngTemplateOutlet="tplSummary"></ng-container>
  </div>
</div>

<div *ngIf="!getSelectedItems().length" class="summary-container">
  <ng-container *ngTemplateOutlet="tplSummary"></ng-container>
</div>

<ng-template #tplSummary>
  <span style="color: red">
    <i>Total: {{displaySummaryRecords(summary.totalRecords)}}</i> |
    <i>Show: {{displaySummaryRecords(summary.records)}} and {{displaySummaryPallets(summary.pallets)}}</i>
  </span>
</ng-template>

<nz-table
  nzBordered="true"
  #rowSelectionTable
  [nzScroll]="{ x: 'scroll' }"
  [nzTotal]="totalCount"
  [nzPageSize]="limit"
  [nzData]="listData"
  [nzHideOnSinglePage]="true"
  [nzFrontPagination]="false"
  [nzLoading]="isLoading"
  [nzLoadingIndicator]="tplLoading"
  [nzShowPagination]="totalCount > listData.length"
  (nzPageIndexChange)="onDataListPageChanged($event)"
  id="warehouse-shipments"
>
  <ng-template #tplLoading>
    <div class="nodata">
      <i nz-icon nzType="loading" nzTheme="outline"></i>
    </div>
  </ng-template>
  <thead>
    <tr>
      <th
        nzWidth="45px"
        [nzChecked]="checkedAll"
        [nzIndeterminate]="indeterminate"
        (nzCheckedChange)="onAllChecked($event)"
      ></th>
      <th nzWidth="100px">Status</th>
      <th nzWidth="75px">Tasks</th>
      <th nzWidth="100px">Shipment</th>
      <th nzWidth="200px">Client Name</th>
      <th nzWidth="200px">PO#</th>
      <th nzWidth="130px">Inventories</th>
      <th nzWidth="130px">Weight</th>
      <th nzWidth="150px">Inbound Leg</th>
      <th nzWidth="150px">Outbound Leg</th>
      <th nzWidth="170px">Schedule Pickup</th>
      <th nzWidth="170px">Schedule Inbound</th>
      <th nzWidth="170px">Schedule Outbound</th>
      <th nzWidth="170px">Schedule Delivery</th>
      <th nzWidth="300px">Pickup Location</th>
      <th nzWidth="300px">Delivery Location</th>
      <th nzWidth="300px">Original Pickup Location</th>
      <th nzWidth="300px">Final Delivery Location</th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let row of listData">
      <tr
        style="cursor: pointer"
        warehouse-item-list-item
        [item]="row"
        (onItemSelected)="onItemChecked(row)"
        [selected]="!!setOfCheckedId[row.id]"
        (click)="onExpandChange(row)"
      ></tr>
      <tr [nzExpand]="expandSet.has(row.id)">
        <div *ngIf="expandSet.has(row.id)" warehouse-item-content [item]="row" (onRefresh)="onRefresh()"></div>
      </tr>
    </ng-container>
  </tbody>
</nz-table>

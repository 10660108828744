<div style="display: flex; align-items: center;">
  <div>
    <nz-select
      nzAllowClear
      nzShowSearch
      [(ngModel)]="warehouseId"
      [nzLoading]="isLoadingWarehouses"
      [nzPlaceHolder]="'Select warehouse'"
      (ngModelChange)="onChangeWarehouse($event)"
      style="width: 300px; height: 38px; margin-right: 20px;"
    >
      <nz-option
        *ngFor="let item of listOfCrossdockWarehouse"
        [nzValue]="item.id"
        [nzLabel]="item?.label || item?.name"
      ></nz-option>
    </nz-select>
  </div>
  <search-box #searchBox style="width: 300px; height: 38px;"
    [placeHolder]="'Search by MBL number'"
    [searchKeyword]="search"
    (doSearch)="doSearch($event)">
  </search-box>
</div>
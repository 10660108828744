import { BaseList } from "../base/list";
import { Component } from "@angular/core";
import { Const } from "@app/const/Const";
import { ActivatedRoute } from "@angular/router";
import { DateUtil } from "@services/date-utils";
import { InputHelper } from "@services/input-helper";
import { CarrierBidFilterComponent } from './components/carrier-bid-filter/carrier-bid-filter.component';
import { WarpId } from "@wearewarp/universal-libs";

@Component({
  selector: "carrier-bid-list",
  templateUrl: "./list.html",
  styleUrls: ["../list.scss"],
})
export class CarrierBidList extends BaseList {
  constructor(protected activatedRoute: ActivatedRoute) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  protected getApiUrl(): string {
    return Const.APIURI_CARRIER_BIDS;
  }

  protected getDialogFilterClass(): {class: any, params?: any} {
    return {class: CarrierBidFilterComponent, params: {metadata: this.filterMetadata}}
  }

  onBtnCreate() {
    this.router.navigate([this.routeAdminCarrierBidList, 'create'])
  }

  onBtnDetail(item = null) {
    this.router.navigate([this.routeAdminCarrierBidList, item.id])

  }

  public getAcceptanceCount(item) {
    return item.carriers.filter((carrier) => carrier.state == 2).length;
  }

  public getRefuseCount(item) {
    return item.carriers.filter((carrier) => carrier.state == 1).length;
  }

  public getWaitingCount(item) {
    return item.carriers.filter((carrier) => !carrier.state).length;
  }

  public formatDate(date, timezone) {
    return DateUtil.displayLocalTime(date, {
      format: Const.FORMAT_GUI_DATETIME,
      timezone: timezone
    });
  }

  public getBestOffer(item) {
    let bestOffer = 0;
    let hasOffer = 0;
    for (let carrier of item.carriers) {
      if (carrier.state != 2) continue;
      bestOffer = hasOffer ? Math.min(bestOffer, carrier.price) : carrier.price;
      hasOffer = 1;
    }
    return hasOffer ? InputHelper.formatMoney1(bestOffer + "") : "-";
  }

  public getShipmentIdText(item) {
    const shipments = item?.metadata?.shipments ? [...item?.metadata?.shipments] : [];
    let shipmentIds = shipments.map(item => item?.warpId)
    if(!shipmentIds.length) return 'N/A'
    return WarpId.showMultipleShipments(shipmentIds)
  }

  getDefaultFilter = () => {
    return {assignedCarrierId: 'not_assigned'}
  }

  protected handleNavigationEnd(url: string, prevQueryParam: any): void {
    if (!this.queryParams.filter && !this.queryParams.search) {
      let filter = JSON.stringify(this.getDefaultFilter());
      return this.routeWithQueryUrl({filter});
    }
    super.handleNavigationEnd(url, prevQueryParam);
  }
}

<div *ngIf="!model" class="nodata">
  <i nz-icon nzType="loading" nzTheme="outline"></i>
</div>
<div *ngIf="model">
  <div warehouse-manifest-navigation-bar [manifestModel]="{ id: model.id, mblNumber: model.mblNumber }"></div>
  <div class="manifest-body top20">
    <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }">
      <div nz-col nzSm="24" nzLg="14" nzXl="16">
        <div manifest-detail-basic-info [model]="model" (onRefresh)="onBtnRefresh()"></div>
      </div>
      <div nz-col nzSm="24" nzLg="10" nzXl="8">
        <div manifest-detail-documents [manifestId]="model.id"></div>
      </div>
    </div>
    <div>
      <div manifest-detail-items [manifestId]="model.id"></div>
    </div>
  </div>
</div>
<div class="dashboard-child-container no-padding list">
  <div class="list-body">
    <nz-table
      #nzTable
      [nzData]="listData"
      [nzFrontPagination]="true"
      [nzLoadingIndicator]="tplLoading"
      [nzLoading]="isLoadingList"
      [nzNoResult]="tplNoData"
      [nzPageSize]="listData.length"
      [nzShowPagination]="false"
      [nzSize]="'default'"
      [nzTotal]="listData.length"
      nzBordered="true"
      nzSize="small"
    >
      <ng-template #tplNoData>
        <div *ngIf="!isLoadingList" class="nodata"><i nz-icon nzTheme="outline" nzType="search"></i>
          This customer does not have a parent company.
        </div>
      </ng-template>
      <ng-template #tplLoading>
        <div class="nodata"><i nz-icon nzTheme="outline" nzType="loading"></i></div>
      </ng-template>
      <thead>
      <tr>
        <th>WARP ID</th>
        <th>Name</th>
        <th>Address</th>
        <th>Number of locations</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let item of nzTable.data; let i = index">
        <td>
          <a [routerLink]="[routeAdminClientList, item.id]" class="inherit">{{item.warpId}}</a>
        </td>
        <td>
          <a [routerLink]="[routeAdminClientList, item.id]">{{item.name}}</a>
        </td>
        <td>{{getAddressText(item.address)}}</td>
        <td>
          <a [routerLink]="[routeAdminClientList, item.id, 'locations']">{{getLocationCount(item)}}</a>
        </td>
      </tr>
      </tbody>
    </nz-table>
  </div>
</div>

<div class="parent">
    <nz-tree [nzData]="data" nzDraggable nzBlockNode (nzOnDrop)="nzEvent($event)" [nzTreeTemplate]="nzTreeTemplate" ></nz-tree>
    <ng-template #nzTreeTemplate let-node let-origin="origin">
        <span class="custom-node">
          <span *ngIf="origin.isOld">
            <span class="old-title">{{ node.title }}</span>
          </span>
          <span *ngIf="origin.isNew">
            <span class="new-title">{{ node.title }}</span>
          </span>
          <span *ngIf="!origin.isNew && !origin.isOld">
            <span> {{ node.title }}</span>
          </span>
        </span>
      </ng-template>
</div>
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'mileage-str',
  template: '<span *ngIf="display">{{display}} mi</span>',
  styleUrls: []
})
export class MileageStr extends Component  {
    _data: { m?: number | null, unit?: string, decimals?: number} = null

    @Input() set data(v) {
        this._data = v
        if (v.m !== undefined && v.m !== null) {
            const miles = v.m / 1609.34
            this.display = Number(miles).toFixed(v.decimals ?? 2)
        }
    }

    display: string | null = null    
}

import { Component, Input } from '@angular/core';
import { BaseDetail } from '@app/admin/base/detail';
import { ActivatedRoute } from '@angular/router';
import { Const } from '@const/Const';
import { DateUtil } from '@services/date-utils';
import { DialogService } from '@dialogs/dialog.service';
import { EditWeight } from './components/edit-weight';
import { environment } from '@env/environment';
@Component({
  selector: 'crossdock-warehouse-list',
  templateUrl: './view.html',
  styleUrls: ['./style.scss']
})
export class CrossdockWarehouseList extends BaseDetail {
  protected get crudEntity(): string { return 'crossdock-warehouse-list' }

  public listClientWithInventory = []; //list client hiển thị trên table
  public isLoadingListClientWithInventory //loading khi get list client with inventory
  private _warehouseId: string;

  @Input() set warehouseId(value: string) {
     this._warehouseId = value;
     this.getListOrderWithInventory(this._warehouseId);
  }
  
  get warehouseId(): string {
    return this._warehouseId;
  }
  constructor(protected activatedRoute: ActivatedRoute) {
    super(activatedRoute);
  }

  handleNavigationEnd() {
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  processData(){
    this.listClientWithInventory = this.listClientWithInventory.map(client => {
      return {
        ...client, 
        key: client.clientId,
        expand: true
      }
    })
  }

  async getListOrderWithInventory(warehouseId, keyword = undefined) {
    if (!warehouseId) return
    this.isLoadingListClientWithInventory = true;
    const url = `${Const.APIURI_INVENTORIES}/get_list_order_with_inventory`;
    const params = {warehouseId: warehouseId, keyword: keyword}
    const resp = await this.api.POST(url, params).toPromise().catch(e => {
      this.showErr(`${e.message}`)
      this.listClientWithInventory = []
      this.isLoadingListClientWithInventory = false;
    });
    this.listClientWithInventory = resp?.data?.list_data
    this.isLoadingListClientWithInventory = false;
    this.processData()
  }

  getLinkToShipmentDetail(item){
    return `${this.routeAdminShipmentList}/${item?.orderId}`
  }

  async doSearch(keyword) {
    this.getListOrderWithInventory(this.warehouseId, keyword)
  }

  get naText(){
    return '--'
  }

  getLinkToTrackingItem(data){
    return `${environment.trackingWebUrl}/${data?.code ?? data?.shipmentTrackingCode}`;
  }
  getItemStatus(item) {
    const status = item.status
    switch (status) {
      case Const.CrossdockStatus.pending: return 'Pending';
      case Const.CrossdockStatus.unloaded: return 'Unloaded';
      case Const.CrossdockStatus.checkedIn: return 'Checked In';
      case Const.CrossdockStatus.staged: return 'Staged';
      case Const.CrossdockStatus.completed: return 'Completed';
      default: return this.naText;
    }
  }

  onDoEditWeight(item){

  }

  openEditWeight(item) {
    DialogService.openDialog(EditWeight, {
      nzComponentParams: {
        weight: item?.weight,
        onOk: () => this.onDoEditWeight(item)
      },
      nzClassName: 'modal-no-padding',
    });
  }
}

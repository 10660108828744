<div class="dashboard-child-container no-padding list">
  <div [ngClass]="{'list-header': 1, 'no-padding':!isReportRevenue}">
    <ng-container *ngIf="isReportRevenue">
      <div *ngIf="isReportRevenue" filter-revenue (getdata)="loadData($event)"></div>
    </ng-container>
    <ng-container *ngIf="!isReportRevenue">
      <div class="background-height"></div>
      <div class="box-container">
        <div *ngIf="!isReportRevenue" carrier-sales-list-header-filter-v3 [getDefaultFilter]="getDefaultFilter"></div>
      </div>
    </ng-container>
  </div>

  <div class="list-body">

    <ng-container *ngTemplateOutlet="tplRevenueSum; context: {revenueSum}"></ng-container>

    <nz-table [ngClass]="{'hidden': !shouldShowTable}" #nzTable nzBordered="true" [nzData]="listData" nzSize="small"
      [nzTotal]="totalCount" [nzFrontPagination]="false" [nzShowPagination]="totalCount > listData.length"
      [nzPageIndex]="pageIndex" [nzPageSize]="limit" [nzLoading]="isLoading" [nzLoadingIndicator]="tplLoading"
      [nzNoResult]="tplNoData" [nzSize]="'default'" (nzPageIndexChange)="onDataListPageChanged($event)"
      style="font-size: 12px;"
      [nzScroll]="{x: '1800px'}"
      >
      <ng-template #tplNoData>
        <div *ngIf="!isLoading" class="nodata"><i nz-icon nzType="search" nzTheme="outline"></i>No data</div>
      </ng-template>
      <ng-template #tplLoading>
        <div class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
      </ng-template>
      <thead (nzSortOrderChange)="onSortOrderChange($event)">
        <tr>
          <th nzWidth="65px" nzColumnKey="label" style="max-width: 120px;">Load ID</th>
          <th nzWidth="70px" nzColumnKey="label" nzLeft>WARP ID</th>
          <th nzWidth="70px" style="min-width: 170px;">Customer Name</th>
          <th nzWidth="70px" nzColumnKey="label" style="min-width: 170px;">Equipment</th>
          <th nzWidth="100px" nzColumnKey="pickDt.time" style="min-width: 250px;">Pickup Time & Location</th>
          <th nzWidth="100px" nzColumnKey="dropDt.time" style="min-width: 250px;">Dropoff Time & Location</th>
          <th nzWidth="100px" style="min-width: 170px;">Revenue</th>
          <th nzWidth="70px" style="min-width: 120px;">Service Opt.</th>
          <th nzWidth="70px" *ngIf="canPerformAction" style="min-width: 120px;">Target Rate</th>
          <th nzWidth="100px" style="min-width: 200px;">Best Offers</th>
          <th nzWidth="100px" style="min-width: 50px;">Bid Status</th>
          <th nzWidth="50px" style="min-width: 50px;">Avg Past Rates</th>
          <th nzWidth="50px" style="min-width: 50px;">Show On Bid Board</th>
          <th nzWidth="50px" style="min-width: 135px;" nzRight>Carrier & Cost</th>
          <th nzWidth="50px" *ngIf="canPerformAction" nzRight>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of listData; let i = index">
          <td style="max-width: 120px;">
            <div>
              <a target="_blank" style="font-size: 13px;" [routerLink]="[routeAdminDispatchList, item.id]"
                [queryParams]="queryParams" nz-tooltip nzTooltipTitle="Go to dispatch">
                <code>{{item.code}}</code>
              </a>
            </div>
          </td>
          <td nzLeft>
            <div>
              <div *ngIf="item.onHold" class="hold-badge">HOLD</div>
              <a *ngIf="item.shipments?.length" class="link" target="_blank"
                  [routerLink]="getRouterLinkWarp(item.shipments?.[0])"
                  [fragment]="getRouterLinkFragmentWarp(item.shipments?.[0])">{{getFirstShipmentId(item.shipments)}}</a>
              <ng-container *ngIf="item.shipments?.length > 1">
                <a class="link" (click)="seeAllOrder(i)"
                  style="margin-left: 5px;">(+{{item.shipments.length-1}})</a>
              </ng-container>
            </div>
          </td>
          <td>
            <div>
              <span *ngIf="item.clients?.length">
                {{getFirstClientName(item)}}
              </span>
              <ng-container *ngIf="item.clients?.length > 1">
                <span style="margin-left: 5px;" nz-tooltip [nzTooltipTitle]="getAllClientNames(item)">+{{item.clients.length-1}}</span>
              </ng-container>
            </div>
          </td>
          <td>
            <div class="shipment-type">{{item.shipmentType}}</div>
            <div *ngIf="shouldShowEquipmentAndTemp(item)" class="bottom5">
              <div *ngIf="item.equipments" class="shipment-mode">{{item.equipments}}</div>
              <div *ngIf="item.tempRange" class="shipment-mode">{{item.tempRange}}</div>
            </div>
          </td>
          <td>
              <div style="white-space: pre-wrap;">{{item.pickWindow}}</div>
              <div *ngIf="item.pickInfo" class="arr-client-address">
                <span *ngIf="item.pickInfo.locationName">{{item.pickInfo.locationName + ', '}}</span>
                {{item.pickInfo.addr}}

                <div style="display: inline-block">
                  <span nz-icon nzType="info-circle" nzTheme="outline" nz-popover nzPopoverTitle="Location Info"
                    [nzPopoverContent]="pickupInfo" style="cursor: pointer;">
                  </span>
                  <ng-template #pickupInfo>
                    <nz-table class="table-info" nzBordered nzTemplateMode nzSize="small">
                      <tbody>
                        <tr>
                          <td><b>Note</b></td>
                          <td> {{getInfo(item, 'PICKUP')?.note || 'N/A'}}</td>
                        </tr>
                        <tr>
                          <td><b>Instructions</b></td>
                          <td> {{getInfo(item, 'PICKUP')?.instructions || 'N/A'}}</td>
                        </tr>
                      </tbody>
                    </nz-table>
                  </ng-template>
                </div>
              </div>
             
          </td>
          <td>
            <div style="white-space: pre-wrap;">{{item.dropWindow}}</div>
            <div *ngIf="item.dropInfo" class="arr-client-address">
              <span *ngIf="item.dropInfo.locationName">{{item.dropInfo.locationName + ', '}}</span>
              {{item.dropInfo.addr}}


            <div style="display:inline-block;">
              <span nz-icon nzType="info-circle" nzTheme="outline" nz-popover nzPopoverTitle="Location Info"
                [nzPopoverContent]="dropoffInfo" style="cursor: pointer;">
              </span>
              <ng-template #dropoffInfo>
                <nz-table class="table-info" nzBordered nzTemplateMode nzSize="small">
                  <tbody>
                    <tr>
                      <td><b>Note</b></td>
                      <td> {{getInfo(item, 'DROPOFF')?.note || 'N/A'}}</td>
                    </tr>
                    <tr>
                      <td><b>Instructions</b></td>
                      <td> {{getInfo(item, 'DROPOFF')?.instructions || 'N/A'}}</td>
                    </tr>
                  </tbody>
                </nz-table>
              </ng-template>
            </div>
            </div>
          </td>
          <td>
            <div>
              {{item.totalShipmentsCost}}
              <i nz-icon nzType="info-circle" nzTheme="outline" nzTooltipPlacement="bottom"
                nzTooltipOverlayClassName="tooltip-info" style="margin-left: 10px;" class="clickable" nz-tooltip
                [nzTooltipTitle]="tplCostItems"></i>
              <ng-template #tplCostItems>
                <ng-container [ngTemplateOutlet]="tplCostItemsInner"
                  [ngTemplateOutletContext]="{cost: item.totalShipmentsCost, units: item.totalShipmentsItems.units, weight: item.totalShipmentsItems.weight}">
                </ng-container>
                <ng-template #tplCostItemsInner let-cost="cost" let-units="units" let-weight="weight">
                  <div class="tplCostItems">
                    <div class="value">{{cost}}</div>
                    <div class="description">Add up grand total for all shipments on the route</div>
                    <div class="value">{{weight}}</div>
                    <div class="description">Add up weight for all shipments on the route</div>
                    <div class="value">{{units}}</div>
                    <div class="description">Add up units for all shipments on the route</div>
                  </div>
                </ng-template>
              </ng-template>
            </div>
            <div class="shipment-items">
              {{item.totalShipmentsItems?.weight}}
              <ng-container *ngIf="item.totalShipmentsItems?.units?.length">,
                {{item.totalShipmentsItems.units}}</ng-container>
              <div>
                <span>{{getFirstCommodity(item)}}</span>
                <ng-container *ngIf="item.totalShipmentsItems?.commodity.length > 2">
                  <span style="margin-left: 5px;" nz-tooltip [nzTooltipTitle]="getAllCommodities(item)">+{{item.totalShipmentsItems.commodity.length-2}}</span>
                </ng-container>
              </div>
            </div>
          </td>
          <td>
            <display-service-options [data]="item"></display-service-options>
          </td>
          <td *ngIf="canPerformAction">
            <ng-container *ngIf="item?.datRate?.total_charge?.mediumTotal">
              <div class="bottom10">
                <span class="source-label">DAT:</span>
                <span (click)="onReUpdateDatRate(item, $event)">{{getMoneyValue(item?.datRate?.cost_update?.cost || item?.datRate?.total_charge?.mediumTotal)}}</span>
                <span nz-icon nzType="edit" nzTheme="outline" style="color: #1802d0; cursor: pointer;"
                  (click)="onBtnOnUpdateTargetRate(item)"></span>
              </div>
            </ng-container>
            <ng-container *ngIf="item?.cost?.finance > 0">
              <div class="bottom10">
                <span class="source-label">WARP:</span>
                <span (click)="onReUpdateDatRate(item, $event)">{{getMoneyValue(item?.cost?.finance) }}</span>
              </div>
            </ng-container>
            <div class="shipment-items">
              <ng-container *ngIf="item?.datRate?.cost_update?.cost > 0">
                <b>DAT:</b> {{getMoneyValue(item?.datRate?.total_charge?.mediumTotal)}}
              </ng-container>
            </div>

          </td>
          <td>
            <div *ngFor="let offer of getBestOffers(item); let i=index">
              <a target="_blank" nz-tooltip nzTooltipTitle="Assign Carrier" (click)="onBtnBestOffer(item, offer)">{{i+1}}. ${{offer.price}} - {{offer.carrier.basicInfo.name}}</a>
            </div>
          </td>
          <td style="min-width: 170px;">
            <div style="display: flex;" nz-tooltip nzTooltipTitle="Accepted/Refused/Matches">
              <div style="color: var(--dispatch-green);">{{getAcceptanceCount(item)}}</div>/
              <div style="color: var(--dispatch-red);">{{getRefuseCount(item)}}</div>/
              <div>{{getMatchingCount(item)}}</div>
            </div>
            <div *ngIf="item?.carrierBidInfo?.lastSent" class="shipment-items">
              Last sent: <br />{{formatDate(item?.carrierBidInfo?.lastSent)}}
            </div>
          </td>
          <td nzAlign="right">
            <ng-container *ngIf="isLoadAvgPastRated">
              <nz-spin nzSimple></nz-spin> 
            </ng-container>
            <ng-container *ngIf="!isLoadAvgPastRated">
              {{item.avgPastRates ?? 'N/A'}}
            </ng-container>
          </td>
          <td>
            <div>{{item.isShowOnBidBoard || 'N/A'}}</div>
          </td>
          <td style="min-width: 135px;" nzRight>
            <div *ngIf="item.carrier">
              <a (click)="onBtnCarrier(i)" [nzTooltipTitle]="item.carrier.basicInfo.name" nzTooltipPlacement="top"
                nz-tooltip nz-typography nzEllipsis>
                {{ getCarrierShortName(item.carrier.basicInfo.name) }}
              </a>
            </div>
            <div>
              <a *ngIf="!item.carrier" (click)="onBtnCarrier(i)">
                Assign Carrier
              </a>
            </div>
            <div *ngIf="item.carrierCost" class="carrier-cost">
              {{item.carrierCost}}
            </div>
          </td>
          <td *ngIf="canPerformAction" style="min-width: 50px;" nzRight>
            <div class="flex">
              <div class="action-item" *ngIf="canSendDocs">
                <a *ngIf="item.carrier" class="action-item" (click)="onBtnSendDocs(i)">
                  <i nz-icon nzType="file-text" nzTheme="outline" nzTooltipPlacement="top" nzTooltipTitle="Send Docs"
                    nz-tooltip nz-typography></i>
                </a>
              </div>

              <div class="action-item">
                <div *ngIf="item.carrierBids.length" class="info">
                  <a (click)="onClickViewCarrierBid(item)">
                    <i nz-icon nzType="dollar-circle" nzTheme="outline" nzTooltipPlacement="top"
                      nzTooltipTitle="View Carrier Bid" nz-tooltip nz-typography></i>
                  </a>
                </div>
                <div *ngIf="!item.carrierBids.length && !isAdminReadOnlyRole" class="info">
                  <a (click)="onClickCreateCarrierBid(item)">
                    <i nz-icon nzType="dollar-circle" nzTheme="outline" nzTooltipPlacement="top"
                      nzTooltipTitle="Create Carrier Bid" nz-tooltip nz-typography></i>
                  </a>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</div>


<ng-template #tplRevenueSum let-revenueSum="revenueSum">
  <div *ngIf="isReportRevenue" style="display: block;">
    <ng-container *ngIf="!isLoading">
      <div class="flex f18b bottom15">
        <div class="w150">Revenue:</div>
        <div class="flex1">{{revenueSum.shipmentCost}}</div>
      </div>
      <div class="flex f18b bottom20">
        <div class="w150">Carrier Cost:</div>
        <div class="flex1">{{revenueSum.routeCost}}</div>
      </div>
      <div class="bottom20">
        <a (click)="toggleTable()">{{shouldShowTable ? 'Hide details' : 'Show details'}} ({{totalCount}} loads)</a>
      </div>
    </ng-container>
    <div *ngIf="isLoading" class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
  </div>
</ng-template>
import { Component } from '@angular/core';
import { Const } from '@const/Const';
import { BaseList } from '../base/list';
import { DialogService } from '@dialogs/dialog.service';
import { ChangeCarrierForDriver } from './change-carrier';

@Component({
  selector: 'driver-list',
  templateUrl: './list.html',
  styleUrls: ['../list.scss']
})
export class DriverList extends BaseList {

  constructor() {
    super();
  }

  protected getApiUrl(): string {
    return Const.APIURI_DRIVERS;
  }

  getRouterLink(item) {
    return [this.routeAdminDriverList, item.id];
  }

  onBtnUpdateChangeCarrier(item) {
    if (!item.id || !item.carrierId) return;
    DialogService.openFormDialog1(ChangeCarrierForDriver, {
      nzComponentParams: {
        model: { carrierId: item.carrierId },
        closeOnSuccess: true,
        onSave: data => {
          const url = `${Const.APIURI_DRIVERS}/${item.id}/change-carrier`;
          return this.api.PUT(url, data)
        },
        onRefresh: () => this.onBtnRefresh()
      },
      nzClassName: "modal",
    });
  }
}
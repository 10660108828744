import { AfterViewInit, Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild, Input } from "@angular/core";
import { FilterLayout } from "@app/admin/components/filter-layout";
import { FilterFieldDeclaration } from "@app/admin/components/filter-layout";
import { BaseFormItem } from "@app/admin/base/form-item";
import { WarpIdMode } from "@wearewarp/ng-antd";
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from "rxjs";
@Component({
  selector: '[warehouse-shipment-filter]',
  templateUrl: './view.html',
  styleUrls: ['./style.scss']
})
export class WarehouseShipmentFilter extends BaseFormItem implements OnInit, AfterViewInit {
  @Output() onReload: EventEmitter<any> = new EventEmitter<any>();

  public allStatus: any = [];
  statusSelected: any = null;

  @Input() clients: any = []
  @Input() pickupLocations: any = []
  @Input() deliveryLocations: any = []
  @Input() statusOptions: any = []
  @Input() deliveryStates: any = []
  @Input() pickupStates: any = []
  @Input() originalPickupLocations: any = []
  @Input() finalDeliveryLocations: any = []

  isLoading = false
  private searchText$ = new Subject<string>();
  onChangeSearchKeyWord(value: any) {
    this.searchText$.next(value);
  }

  ngOnInit(): void {
    this.searchText$.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(value => {
      this.onFilterChange("search", value);
    });
  }

  ngAfterViewInit(): void {
    this.filter.ngHostAfterViewInit(this.getFilterFields())
  }

  // for filter layout
  @ViewChild('filter') filter: FilterLayout;
  @ViewChild('tplFilter_search') tplFilter_search: TemplateRef<any>;
  @ViewChild('tplFilter_shipmentIds') tplFilter_shipmentIds: TemplateRef<any>;
  @ViewChild('tplFilter_client') tplFilter_client: TemplateRef<any>;
  @ViewChild('tplFilter_pickupLocation') tplFilter_pickupLocation: TemplateRef<any>;
  @ViewChild('tplFilter_deliveryLocation') tplFilter_deliveryLocation: TemplateRef<any>;
  @ViewChild('tplFilter_pickupState') tplFilter_pickupState: TemplateRef<any>;
  @ViewChild('tplFilter_deliveryState') tplFilter_deliveryState: TemplateRef<any>;
  @ViewChild('tplFilter_originalPickupLocation') tplFilter_originalPickupLocation: TemplateRef<any>;
  @ViewChild('tplFilter_finalDeliveryLocation') tplFilter_finalDeliveryLocation: TemplateRef<any>;
  @ViewChild('tplFilter_status') tplFilter_status: TemplateRef<any>;

  onFilterChange(key: string, value: any) {
    this.filter.onChange({ key, value });
  }

  getFilterPlaceHolder(key: string): string {
    return <string>this.filter.getPlaceHolder(key);
  }

  private filterFieldDeclaration: FilterFieldDeclaration[];
  private readonly labelWarpIds = 'Shipment IDs';

  getFilterFields(): FilterFieldDeclaration[] {
    if (!this.filterFieldDeclaration) {
      this.filterFieldDeclaration = [
        {
          key: 'search',
          declaration: { label: 'Search Keyword', notAcceptEmpty: true, placeHolder: 'Search By Ref#: 45124711-1...' },
          templateInput: this.tplFilter_search
        },
        {
          key: 'shipmentIds',
          declaration: { label: this.labelWarpIds, notAcceptEmpty: true },
          templateInput: this.tplFilter_shipmentIds
        },
        {
          key: 'client',
          declaration: { label: 'Client', placeHolder: '', notAcceptEmpty: true },
          templateInput: this.tplFilter_client
        },
        {
          key: 'pickupLocation',
          declaration: { label: 'Pickup Location', placeHolder: '', notAcceptEmpty: true },
          templateInput: this.tplFilter_pickupLocation
        },
        {
          key: 'deliveryLocation',
          declaration: { label: 'Delivery Location', placeHolder: '', notAcceptEmpty: true },
          templateInput: this.tplFilter_deliveryLocation
        },
        {
          key: 'pickupState',
          declaration: { label: 'Original Pickup State', placeHolder: '', notAcceptEmpty: true },
          templateInput: this.tplFilter_pickupState
        },
        {
          key: 'originalPickupLocation',
          declaration: { label: 'Original Pickup Location', placeHolder: '', notAcceptEmpty: true },
          templateInput: this.tplFilter_originalPickupLocation
        },
        {
          key: 'deliveryState',
          declaration: { label: 'Final Delivery State', placeHolder: '', notAcceptEmpty: true },
          templateInput: this.tplFilter_deliveryState
        },
        {
          key: 'finalDeliveryLocation',
          declaration: { label: 'Final Delivery Location', placeHolder: '', notAcceptEmpty: true },
          templateInput: this.tplFilter_finalDeliveryLocation
        },
        {
          key: 'status',
          declaration: { label: 'Status', placeHolder: '', notAcceptEmpty: true },
          templateInput: this.tplFilter_status
        }
      ];
    }
    return this.filterFieldDeclaration;
  }

  showSelectionLabel(item: any) {
    return item ? `${item.name} (${item.count})` : ""
  }

  showSelectionLocation(item: any) {
    return item ? `${item?.locationName} (${item.count})` : ""
  }

  showSelectionLocationLabel(item: any) {
    return this.showSelectionLocation(item) + " - " + item.name
  }

  warpIdMode: WarpIdMode = 'multi';
  warpIdModes: WarpIdMode[] = ['multi', 'range'];

  getWarpIdModeDesc(mode: WarpIdMode): string {
    switch (mode) {
      case 'range': return 'Range Mode';
      default: return 'Normal Mode';
    }
  }

  onWarpIdModeChanged(mode: WarpIdMode) {
    let declaration = this.filterFieldDeclaration.filter(it => it.key == 'shipmentIds')[0]?.declaration;
    if (declaration) {
      declaration.label = mode == 'range' ? `${this.labelWarpIds} (Range Mode)` : this.labelWarpIds;
    }
  }

}

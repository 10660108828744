import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NzTableModule } from "ng-zorro-antd/table";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzSelectModule } from "ng-zorro-antd/select";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzListModule } from 'ng-zorro-antd/list';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzInputModule } from "ng-zorro-antd/input";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzTabsModule } from "ng-zorro-antd/tabs";
import { NzDividerModule } from "ng-zorro-antd/divider";
import { NzEmptyModule } from "ng-zorro-antd/empty";
import { NzDropDownModule } from "ng-zorro-antd/dropdown";
import { NzResultModule } from "ng-zorro-antd/result";
import { NzCollapseModule } from "ng-zorro-antd/collapse";
import { NzDatePickerModule } from "ng-zorro-antd/date-picker";
import { SearchBoxModule } from "@libs/search-box/search-box.module";
import { DetailModule } from "../../base/detail.module";
import { SelectBySearchingModule } from "@libs/select-client/module";
import { FormAddressUSModule } from "../../base/form-address-us/module";
import { NzModalModule } from "ng-zorro-antd/modal";
import { NzSpinModule } from "ng-zorro-antd/spin";
import { NzDrawerModule } from "ng-zorro-antd/drawer";
import { NzTagModule } from "ng-zorro-antd/tag";
import { PdfViewerModule } from "@libs/pdf-viewer/pdf-viewer.module";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { NzImageModule } from "ng-zorro-antd/image";
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzRadioModule } from "ng-zorro-antd/radio";
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { ModuleInputWarpIds } from '@wearewarp/ng-antd';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { WarehouseManifest } from ".";
import { ManifestListHeaderFilter } from "./list-header-filter";
import { ImportManifest } from "./import";
import { WarehouseManifestDetail } from "./detail";
import { ManifestDetailBasicInfo } from "./detail/components/basic-info";
import { ManifestDetailDocuments } from "./detail/components/documents";
import { ManifestDetailItems } from "./detail/components/items";
import { ManifestUploadBOLFiles } from "./detail/components/upload-bol-files";
import { ManifestUploadContainerImages } from "./detail/components/upload-container-images";
import { WarehouseManifestCartonDetail } from "./detail/components/carton-detail";
import { ManifestItemsListCarton } from "./detail/components/list-carton";
import { ManifestItemsListPallet } from "./detail/components/list-pallet";
import { WarehouseManifestPalletDetail } from "./detail/components/pallet-detail";
import { WarehouseManifestEditPallet } from "./detail/components/edit-pallet";
import { ManifestUploadLabelSKU } from "./detail/components/upload-label-sku";
import { LoadingComponent } from "./detail/components/loading/loading.component";
import { WarehouseManifestNavigationBar } from "./detail/components/navigation-bar";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NzTableModule,
    NzTabsModule,
    NzDividerModule,
    NzEmptyModule,
    NzDropDownModule,
    NzResultModule,
    NzCollapseModule,
    NzDatePickerModule,
    NzButtonModule,
    NzSelectModule,
    NzIconModule,
    NzGridModule,
    NzListModule,
    NzCardModule,
    NzInputModule,
    NzFormModule,
    SearchBoxModule,
    DetailModule,
    SelectBySearchingModule,
    FormAddressUSModule,
    NzModalModule,
    NzSpinModule,
    NzModalModule,
    NzDrawerModule,
    NzTagModule,
    PdfViewerModule,
    NzImageModule,
    NzAutocompleteModule,
    NzRadioModule,
    NzSpaceModule,
    NzBreadCrumbModule,
    NzToolTipModule,
    ModuleInputWarpIds,
  ],
  declarations: [
    WarehouseManifest,
    ManifestListHeaderFilter,
    ImportManifest,
    WarehouseManifestDetail,
    ManifestDetailBasicInfo,
    ManifestDetailDocuments,
    ManifestDetailItems,
    ManifestUploadBOLFiles,
    ManifestUploadContainerImages,
    ManifestUploadLabelSKU,
    ManifestItemsListCarton,
    ManifestItemsListPallet,
    WarehouseManifestCartonDetail,
    WarehouseManifestPalletDetail,
    WarehouseManifestEditPallet,
    LoadingComponent,
    WarehouseManifestNavigationBar,
  ],
  exports: [
  ],
  providers: [],
})
export class ModuleCrossDockManifest {}

<div class="rate-content">
  <div>
    <span class="rate-number">{{ numOfLike }}</span>
    <img class="rate-icon" src="assets/img/thumb_up_black.svg" />
  </div>
  <div>
    <span class="rate-number">{{ numOfDislike }}</span>
    <img class="rate-icon" src="assets/img/thumb_down_black.svg" />
  </div>
</div>

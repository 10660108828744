<div class="form-header no-border no-padding-bottom">
  <div class="form-title f18b">Edit weight</div>
</div>

<div style="padding: 20px;">
  <form nz-form class="form-detail">
    <nz-form-item>
      <nz-form-label>Weight (lbs)</nz-form-label>
      <nz-form-control>
        <input nz-input
          [(ngModel)]="weight"
          formControlName="weight"
        />
      </nz-form-control>
    </nz-form-item>
  </form>

  <!-- <input nz-input [(ngModel)]="demoValue" [nzSize]="'large'" /> -->
</div>


<div form-footer class="no-border"
  [canClickOK]="!isLoading" 
  [canClickCancel]="true"
  nzIconOK="check" nzIconCancel="close" labelOK="Save" nzOkDanger="false" (onOK)="onBtnSave()"
  (onCancel)="closeDialog()">
</div>


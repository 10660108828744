import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from "@angular/common";
import { FormsModule } from '@angular/forms';
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzCheckboxModule } from "ng-zorro-antd/checkbox";
import { DisplayCarrierCost } from './index';
import { CreateByUserModule } from '@app/admin/components/create-by-user/module';
import { ModuleCarrierPaymentPlanning } from '../carrier-payment-planning/module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    NzGridModule,
    NzButtonModule,
    NzIconModule,
    NzInputModule,
    NzCheckboxModule,
    CreateByUserModule,
    ModuleCarrierPaymentPlanning,
  ],
  declarations: [
    DisplayCarrierCost
  ],
  exports: [
    DisplayCarrierCost
  ]
})
export class ModuleDisplayCarrierCost {}
<div class="manifest-container">
  <div class="container-header flex-space-between">
    <div class="f16 medium">Manifest Details</div>
    <div>
      <button nz-button (click)="onBtnEdit()" class="btn">
        <i nz-icon nzType="edit" nzTheme="outline"></i>Edit
      </button>
    </div>
  </div>
  <div class="container-body">
    <div nz-row class="bottom10">
      <div nz-col nzSm="8" class="label">Customer</div>
      <div nz-col nzSm="16">
        <div>{{ displayInfo.clientName }}</div>
        <div class="top5">{{ displayInfo.clientAddr }}</div>
      </div>
    </div>
    <div nz-row class="bottom10">
      <div nz-col nzSm="8" class="label">Intended injection date</div>
      <div nz-col nzSm="16">{{ displayInfo.intendedInjectionDate || 'N/A' }}</div>
    </div>
    <div nz-row class="bottom10">
      <div nz-col nzSm="8" class="label">Manifest</div>
      <div nz-col nzSm="16">
        <a [href]="displayInfo.manifestFileUrl" target="_blank">
          <i nz-icon nzType="paper-clip" nzTheme="outline"></i>{{ displayInfo.manifestFileName }}
        </a>
      </div>
    </div>
  </div>
</div>
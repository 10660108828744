<div *ngIf="eta" [class]="'box-container ' + (compact ? 'compact' : '')" (click)="onToggleExpanded()" style="cursor: pointer;">
    <div *ngIf="loading" class="loading"><span nz-icon [nzType]="'loading'"></span></div>
    <div *ngIf="!compact">
        <div *ngIf="showEta" class="bottom10">
            ETA: {{ showEta }}
        </div>
        <div class="bottom10">
            <div>Last Reported: <strong>{{reported}}</strong></div>
            <div><span class="blur">  @ {{reportedTs}}</span></div>
        </div>
        <div class="bottom10" *ngIf="mileOut">Miles out: <strong>{{mileOut}}</strong></div>
        <div class="bottom10" *ngIf="timeToDestination">
            <div>Driving Time left: <strong>{{timeToDestination}}</strong></div>
            <div class="blur">Since last update time</div>
        </div>
        <div class="bottom10" *ngIf="break">Possible driver break: <strong>{{break}}</strong></div>
        <div class="bottom10" *ngIf="window">ETA: <strong>{{window}}</strong> <span *ngIf="displayTz"> ({{displayTz}})</span></div>
    </div>

    <div *ngIf="compact">
        <div *ngIf="window" nz-popover nzPopoverTitle="System ETA [Beta version]" [nzPopoverContent]="etaDetailTemplate">
            <span nz-icon nzType="field-time" nzTheme="outline" class="right5"></span> <strong>{{window}}</strong> <span *ngIf="displayTz"> ({{displayTz}})</span>
        </div>
        <ng-template #etaDetailTemplate>
            <div *ngIf="showEta" class="bottom10">
                ETA: <strong>{{ showEta }}</strong> <span *ngIf="displayTz"> ({{displayTz}})</span>
            </div>
    
            <div class="bottom10">
                <div>Last Reported: <strong>{{reported}}</strong></div>
                <div><span class="blur">  @ {{reportedTs}}</span></div>
            </div>
            <div class="bottom10" *ngIf="mileOut">Miles out: <strong>{{mileOut}}</strong></div>
            <div class="bottom10" *ngIf="timeToDestination">
                <div>Driving Time left: <strong>{{timeToDestination}}</strong></div>
                <div class="blur">Since last reported time</div>
            </div>
            <div class="bottom10" *ngIf="break">Possible driver break time: <strong>{{break}}</strong></div>    
        </ng-template>
        <div *ngIf="false && expanded" [class]="'expanded ' + (overflow ? 'overflow' : '')" (click)="$event.stopPropagation()">
            <div class="bottom10">
                <div>Last Reported: <strong>{{reported}}</strong></div>
                <div><span class="blur">  @ {{reportedTs}}</span></div>
            </div>
            <div class="bottom10" *ngIf="mileOut">Miles out: <strong>{{mileOut}}</strong></div>
            <div class="bottom10" *ngIf="timeToDestination">
                <div>Driving Time left: <strong>{{timeToDestination}}</strong></div>
                <div class="blur">Since last reported time</div>
            </div>
            <div class="bottom10" *ngIf="break">Possible driver break time: <strong>{{break}}</strong></div>    
        </div>
    </div>

</div>
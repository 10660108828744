
import { Component } from '@angular/core';
import { BaseDetail } from '../base/detail';
import { ActivatedRoute } from '@angular/router';
import { ApiMethod } from '@app/enum';
import { Const } from '@const/Const';
import { InputHelper } from '@services/input-helper';
import { Utils } from '@services/utils';

@Component({
  selector: '[driver-detail]',
  templateUrl: './detail.html',
  styleUrls: ['../detail.scss', './detail.scss']
})
export class DriverDetail extends BaseDetail {
  protected formGroupDeclaration: FormGroupDeclaration = {
    firstName: {label: 'First name', required: true},
    lastName: {label: 'Last name', required: true},
    carrierId: {label: 'Carrier', required: true},
    phone: {label: 'Phone', inputType: 'tel', required: true, getValue: InputHelper.getValuePhone, formatValue: InputHelper.formatPhone},
    tractorNumber: {label: 'Tractor Number'},
    trailerNumber: {label: 'Trailer Number'},
    isNotifyViaSMS: {label: 'Driver has opted in to receive text messages', type: 'boolean', initialValue: true },

  };

  linkedUserDesc: string;

  public get keys() {
    return ['firstName', 'lastName', 'carrierId', 'phone', 'tractorNumber', 'trailerNumber'];
  }
  protected get crudEntity(): string { return 'drivers' }
  public listCarriers = [];
  public carrierStatusActive = Const.CarrierStatus.active
  constructor(protected activatedRoute: ActivatedRoute) {
    super(activatedRoute);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }
  protected getApiUrl(method: ApiMethod = ApiMethod.get): string {
    return Const.APIURI_DRIVERS;
  }

  get isMobile(): boolean {
    let mediaQuery = window.matchMedia('(max-width: 900px)'); 
    return mediaQuery.matches;
  }

  private handleLinkedUser(model) {
    if (model.linkedUser) {
      this.linkedUserDesc = this.getFullName(model.linkedUser);
      if (model.linkedUser.phone) {
        this.linkedUserDesc += ` ${InputHelper.formatPhone(model.linkedUser.phone)}`;
      }
    } else {
      this.linkedUserDesc = undefined;
    }
  }

  protected onGetDetailSuccess(data) {
    if (!data) return data;
    if (!data.isNotifyViaSMS) {
      data.isNotifyViaSMS = false;
    }
    this.handleLinkedUser(data);
    return data;
  }

  protected beforeBindModel(model: any) {
    if (model.carrier) {
      if (!Utils.isArrayNotEmpty(this.listCarriers)) {
        this.listCarriers = [model.carrier];
      }
    }
    return model;
  }

  protected bindDataModel(model: any): void {
    super.bindDataModel(model);
    this.handleLinkedUser(model);
  }

  private _updateData() {
    super.updateData();
  }

  protected updateData() {
    const userPhone = this.model.linkedUser?.phone;
    if (userPhone) {
      const phone = this.getItemValue('phone');
      if (phone && phone != userPhone) {
        this.confirmYesNo(`Changing driver's phone number will unlink user <b>${this.linkedUserDesc}</b>.<br/> Are you sure you want to change?`, () => this._updateData());
        return;
      }
    }
    this._updateData();
  }

}
<form class="form-detail" [formGroup]="formInput" nz-form>
  <div class="bottom30 flex">
    <div class="f20" style="flex: 1; height: 50px;">Please select filter conditions</div>
    <button *ngIf="canClear" nz-button class="btn1" (click)="onBtnClear()"><i nz-icon nzType="clear" nzTheme="outline"></i>Clear filter</button>
  </div>

  <div class="bottom20">
    <div class="form-label-v2" *ngIf="getLabel('assignedCarrierId')">
      {{getLabel('assignedCarrierId')}}<span *ngIf="isRequired('assignedCarrierId')" class="label-mark-required"></span>
    </div>
    <div>
      <nz-select nzBackdrop="true" style="width: 100%"
        nzAllowClear [formControlName]="'assignedCarrierId'">
        <nz-option [nzLabel]="'Not assigned yet'" [nzValue]="'not_assigned'"></nz-option>
        <nz-option [nzLabel]="'Has been assigned'" [nzValue]="'assigned'"></nz-option>
      </nz-select>
    </div>
  </div>

  <div class="form-label-v2">Pickup date range</div>
  <div class="date-picker-row bottom20 flex">
    <nz-date-picker *ngFor="let key of ['fromDate', 'toDate']; let i = index" class="flex1"
      [ngClass]="{'first':i==0, 'last':i==1}"
      [formControlName]="key" nzFormat="yyyy-MM-dd"
      [nzPlaceHolder]="getPlaceHolder(key)">
    </nz-date-picker>
  </div>
</form>

<div form-footer [onProgress]="onProgress"
  [canClickOK]="needUpdate && !onProgress" [canClickCancel]="!onProgress"
  [nzIconOK]="'filter'" [labelOK]="'OK'" (onOK)="onBtnSave()"
  (onCancel)="onClose()"></div>

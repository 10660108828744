import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ApiService } from "@services/api.service";
import { Const } from "@const/Const";
import { Log } from "@services/log";
import { Subscription } from "rxjs";
import { BaseFormItem } from "@app/admin/base/form-item";
import { DateUtil } from "@services/date-utils";
import { ExtendValidators } from "@app/admin/base/validator";

@Component({
  selector: "dat-posting",
  templateUrl: "./dat-posting.component.html",
  styleUrls: ["./dat-posting.component.scss"],
})
export class DatPostingComponent extends BaseFormItem {
  @Input() data: any;

  @Output() onDelete: EventEmitter<any> = new EventEmitter<any>();

  public isError = false;
  public isLoading = false;
  public listAddressAutoComplete: any = {};
  private subAddressSuggestion: Subscription;
  private timerAddressSuggestion;
  equipmentType = [];
  contactInfoOption = [];

  carrierBidId: string;
  editId: string;
  isClosed = false;

  protected formGroupDeclaration: FormGroupDeclaration = {
    origin: {
      label: "Origin (City, ST, ZIP)",
      placeHolder: "Origin (City, ST, ZIP)",
      required: true,
      type: "string",
    },
    destination: {
      label: "Destination (City, ST, ZIP)",
      placeHolder: "Destination (City, ST, ZIP)",
      required: true,
      type: "string",
    },
    pickupEarliest: {
      label: "Pickup Earliest",
      placeHolder: "Pickup Earliest",
      required: true,
      type: "date",
      getValue: (val) => this.formatDate(val),
    },
    pickupLatest: {
      label: "Pickup Latest",
      placeHolder: "Pickup Latest",
      required: false,
      type: "date",
      getValue: (val) => this.formatDate(val),
    },
    pickupHours: {
      label: "Pickup Hours",
      placeHolder: "Pickup Hours",
      required: false,
      type: "time",
      getValue: (val) => this.formatDate(val),
    },
    dropOffHours: {
      label: "Drop Off Hours",
      placeHolder: "Drop Off Hours",
      required: false,
      type: "time",
      getValue: (val) => this.formatDate(val),
    },
    fullPartial: {
      label: "fullPartial",
      required: true,
      type: "string",
    },
    equipmentType: {
      label: "Equipment Type",
      required: true,
      type: "array",
    },
    lengthFeet: {
      label: "Length (ft)",
      required: true,
      type: "number",
    },
    weightPounds: {
      label: "Weight (Ibs)",
      required: true,
      type: "number",
    },
    comments: {
      label: "Comments",
      required: false,
      type: "string",
      validators: ExtendValidators.maxLength(70, "Comments must be less than or equal 70 characters"),
    },
    commodity: {
      label: "Commodity",
      required: false,
      type: "string",
      validators: ExtendValidators.maxLength(70, "Commodity must be less than or equal 70 characters"),
    },
    referenceId: {
      label: "Reference Id",
      required: false,
      type: "string",
      validators: ExtendValidators.maxLength(8, "Reference Id must be less than or equal 8 characters"),
    },
    contactMethods: {
      label: "",
      required: false,
      type: "array",
    },
    isTrackingRequired: {
      label: "Required Tracking",
      required: false,
      type: "boolean",
    },
    jobId: {
      label: "Job Id",
      required: false,
      type: "string",
    },
  };

  constructor(api: ApiService) {
    super();
    this.api = api;
  }

  get shouldCreateFormImmediately() {
    return false;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.getEquipment();
  }

  ngOnChanges(): void {
    if (!this.data) return;

    this.createFormInput(this.data);
    this.getContactInfo(this.data?.posterInfo?.contactMethods || []);
    this.editId = this.data.id || "";
    if (this.editId) {
      this.model = this.data;
      this.isClosed = this.data?.close?.when || false;
      if (this.isClosed) this.setEnableFormGroup(false);
    }
  }

  get isCreateNew(): boolean {
    return !this.editId;
  }

  getApiUrl() {
    return Const.APIURI_DAT_V2;
  }

  buildUrl(): string {
    let url = this.getApiUrl();
    if (!url) return url;
    let id = (<any>this.model)?.id;
    if (id) {
      url = `${url}/${id}`;
    }
    return url;
  }

  onCreateSuccess(resp) {
    // this.fileToUpload = {};
    if (resp && resp.ui_message) {
      this.showInfo(resp.ui_message);
    } else {
      this.showInfo("New item has been created successfully.");
    }
    this.editId = resp.data?.id;
  }

  onUpdateSuccess(resp) {
    // this.fileToUpload = {};
    if (resp && resp.ui_message) {
      this.showInfo(resp.ui_message);
    } else {
      this.showInfo("The item has been updated successfully.");
    }
    this.isEditing = false;
  }

  onBtnCancel() {
    this.bindDataModel(this.model);
    this.isEditing = false;
  }

  onBtnDelete() {
    const url = this.buildUrl();
    this.onProgress = true;
    this.api.DELETE(url).subscribe(
      (resp) => {
        this.showInfo("The item has been deleted successfully.");
        this.onDelete.emit();
      },
      (error) => {
        this.showErr(error);
      }
    );
  }

  onBtnRefresh() {
    const url = this.buildUrl();
    this.onProgress = true;
    this.api.POST(`${url}/refresh`).subscribe(
      (resp) => {
        const data = resp.data;
        if (this.model && data.refresh) {
          this.model.refresh = data.refresh;
        }
        this.showInfo("The item has been refreshed successfully.");
        this.onProgress = false
      },
      (error) => {
        this.showErr(error);
        this.onProgress = false
      }, 
    );
    
  }

  onBtnEdit() {
    super.onBtnEdit();
    this.getItemByKey("origin").disable();
    this.getItemByKey("destination").disable();
    this.getItemByKey("referenceId").disable();
  }

  getEquipment() {
    this.api.GET(`${Const.APIURI_DAT_V2}/list-equipments`).subscribe(
      (resp) => (this.equipmentType = resp.data?.list_data || []),
      (err) => this.showErr(err)
    );
  }

  getEquipmentType(type) {
    return `${type?.label} - ${type?.value}`
  }

  getContactInfo(data) {
    this.contactInfoOption = data.map((item) => {
      return {
        label: `${item?.value} (${item.method})`,
        value: item?.value,
      };
    });
  }

  formatDate(date) {
    return DateUtil.convertLocalTime(date, DateUtil.getLocalTimeZone())
  }

  private tryAddressSuggestion(elm: HTMLInputElement) {
    clearTimeout(this.timerAddressSuggestion);
    let userInput = elm.value;
    if (!userInput) {
      this.subAddressSuggestion?.unsubscribe();
      this.listAddressAutoComplete[elm.id] = [];
      return;
    }
    this.timerAddressSuggestion = setTimeout(() => this.doAddressSuggestion(elm, userInput), 100);
  }

  onInputChanged(event, key) {
    switch (key) {
      case "origin":
      case "destination":
        return this.tryAddressSuggestion(event.target);
      default:
        return super.onInputChanged(event, key);
    }
  }

  private doAddressSuggestion(elm: HTMLInputElement, userInput: string) {
    this.subAddressSuggestion?.unsubscribe();
    if (this.isProduction) {
      const query = {
        query: userInput,
        noZone: true,
        noState: true,
      }
      this.subAddressSuggestion = this.api.POST(`${Const.APIURI_DAT_V2}/search/location`, query).subscribe(
        resp => {
          this.listAddressAutoComplete[elm.id] = resp.data.list_data.map(it => `${it.city}, ${it.state}`);
        }, err => {
          Log.e('doAddressSuggestion failed. ', err);
        }
      );
      return;
    }
    this.subAddressSuggestion = this.api.searchUsCities(userInput).subscribe(
      (resp) => {
        this.listAddressAutoComplete[elm.id] = resp.data.list_data.map((it) => `${it.city}, ${it.stateCode}`);
      },
      (err) => {
        Log.e("doAddressSuggestion failed. ", err);
      }
    );
  }
}


{{data.serviceOptions_Short}}
<ng-container *ngIf="shouldShowServiceOptionToolTip()">
    <i nz-icon nzType="info-circle" nzTheme="outline" nzTooltipPlacement="bottom" style="margin-left: 10px;"
        class="clickable" nz-tooltip [nzTooltipTitle]="tplServiceOptions"></i>
    <ng-template #tplServiceOptions>
        <ng-container [ngTemplateOutlet]="tplServiceOptionsInner"
            [ngTemplateOutletContext]="{serviceOptions: data.serviceOptions_Full}">
        </ng-container>
        <ng-template #tplServiceOptionsInner let-serviceOptions="serviceOptions">
            <div class="tplServiceOptions">
                <div class="lable">Service Options</div>
                <ng-container *ngFor="let obj of serviceOptions">
                    <div class="value">{{obj}}</div>
                </ng-container>
            </div>
        </ng-template>
    </ng-template>
</ng-container>
<div>
  <div>
    <h4 nz-typography>{{getAddress(item.matchingAssetInfo?.origin)}} <i nz-icon nzType="arrow-right" nzTheme="outline" class="arrow-right"></i>
      {{getAddress(item.matchingAssetInfo?.destination)}}</h4>
  </div>
  <nz-row>
    <nz-col nzSpan="8">
      <div><strong>TRIP</strong></div>

      <!-- Location -->
      <nz-steps [nzCurrent]="1" nzProgressDot nzDirection="vertical">
        <nz-step [nzTitle]="origin" [nzDescription]="availability"></nz-step>
        <nz-step [nzTitle]="destination"></nz-step>
      </nz-steps>

      <!-- Equipment -->
      <div>
        <div>EQUIPMENT</div>
        <div class="data-container">
          <div class="equipment-label">
            <div class="data-label">Load</div>
            <div class="data-label">Truck</div>
            <div class="data-label">Length</div>
            <div class="data-label">Weight</div>
            <div class="data-label">Reference ID</div>
          </div>
          <div class="equipment-data">
            <div>{{getEquipment(item.matchingAssetInfo?.equipmentType)}}</div>
            <div>{{getLoadType(item.matchingAssetInfo?.capacity?.truck?.fullPartial)}}</div>
            <div>{{item.matchingAssetInfo?.capacity?.truck?.availableLengthFeet}} ft</div>
            <div>{{item.matchingAssetInfo?.capacity?.truck?.availableWeightPounds}} lbs</div>
            <div>-</div>
          </div>
        </div>

      </div>
      <br />
      <!-- Comments -->
      <div>
        <div>COMMENTS</div>
        <div>
          {{item.comments}}
        </div>
      </div>
    </nz-col>

    <!-- Rate -->
    <nz-col nzSpan="8">
      <div><strong>MINIMUM RATE</strong></div>
      <div class="data-container">
        <div class="rate-detail-label">
          <div class="data-label">Trip</div>
          <div class="data-label">Rate / mile</div>
        </div>
        <div class="rate-detail-data">
          <div>-</div>
          <div>-</div>
        </div>
      </div>
      <!-- Market -->
      <div>

      </div>
    </nz-col>

    <!-- Company -->
    <nz-col nzSpan="8">
      <div><strong>COMPANY</strong></div>
      <div>
        {{item.posterInfo?.companyName}}
      </div>
      <div *ngIf="item.posterInfo?.contact?.phone">
        <p nz-typography nzCopyable [nzCopyText]="item.posterInfo?.contact?.phone">{{displayPhone(item.posterInfo?.contact?.phone)}}</p>
      </div>
      <div *ngIf="item.posterInfo?.contact?.email">
        <p nz-typography nzCopyable [nzCopyText]="item.posterInfo?.contact?.email">{{item.posterInfo?.contact?.email}}</p>
      </div>
      <div *ngIf="item.posterDotIds?.carrierMcNumber">
        MC#{{item.posterDotIds?.carrierMcNumber}}
      </div>
      <div *ngIf="item.posterDotIds?.dotNumber">
        DOT#{{item.posterDotIds?.dotNumber}}
      </div>
      <div>
        {{item.posterInfo?.city}}, {{item.posterInfo?.state}}
      </div>
    </nz-col>
  </nz-row>
</div>
<ng-template #origin>
  <div>{{getAddress(item.matchingAssetInfo?.origin)}} ({{item.originDeadheadMiles?.miles}})</div>
</ng-template>
<ng-template #destination>
  <div>{{getAddress(item.matchingAssetInfo?.destination)}} </div>
</ng-template>
<ng-template #availability>
  <div>{{getDate(item.availability?.earliestWhen)}} - {{getDate(item.availability?.latestWhen)}}</div>
</ng-template>
<div class="manifest-container">
  <div class="flex bottom10" style="align-items: center;">
    <div class="right10">Item list view by </div>
    <div>
      <nz-select nzBackdrop="true" (ngModelChange)="onViewByQtyUnitChange($event)" [(ngModel)]="selectedQtyUnit" style="width: 100px;">
        <nz-option *ngFor="let item of itemQtyUnitSelect" [nzValue]="item" [nzLabel]="item" ></nz-option>
      </nz-select>
    </div>
    <div class="left15">
      <button nz-button (click)="onBtnRefreshDataItems()">
        <i *ngIf="isLoading" nz-icon nzType="loading" nzTheme="outline"></i>
        <span *ngIf="!isLoading" nz-icon nzType="reload" nzTheme="outline"></span> Refresh
      </button>
    </div>
  </div>
  <div *ngIf="selectedQtyUnit == 'Pallet'">
    <div #manifestItemsListPalletComponent manifest-items-list-pallet [manifestId]="manifestId" [listItems]="listOfPallets" (onRefresh)="onBtnRefresh()"></div>
  </div>
  <div *ngIf="selectedQtyUnit == 'Carton'">
    <div #manifestItemsListCartonComponent manifest-items-list-carton [manifestId]="manifestId" [listItems]="listOfCartons" (onRefresh)="onBtnRefresh()"></div>
  </div>
</div>
<form nz-form [formGroup]="formInput!">
  <div *ngIf="'note' as key" class="form-item {{key}}">
    <div class="form-label-v2">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
    <nz-form-item>
      <nz-form-control>
        <textarea nz-input [formControlName]="key" [placeholder]="getPlaceHolder(key)" [nzAutosize]="{minRows: 3, maxRows: 6}"></textarea>
      </nz-form-control>
    </nz-form-item>
  </div>

  <div *ngIf="'transferDate' as key" class="form-item {{key}}">
    <div class="form-label-v2">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
    <nz-form-item>
      <nz-form-control>
        <nz-date-picker [formControlName]="key"></nz-date-picker>
      </nz-form-control>
    </nz-form-item>
  </div>

</form>
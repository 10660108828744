<div [class]="['pod-wrapper', compact ? 'compact': 'full']">
  <img *ngIf="!pdf" class="pod clickable"[src]="pod.fullPath" (click)="toggleCompact()" />
  <div *ngIf="pdf" class="pdf-viewer clickable" (click)="toggleCompact()">
    <pdf-viewer [fit-to-page]="1" [zoom-scale]="'page-height'" [src]="pod.fullPath" style="height: 100%"></pdf-viewer>
  </div>
  <div class="info">
    <nz-tag *ngIf="pod.metadata?.taskType || pod.type" [class]="['right10', 'task-type', pod.metadata?.taskType || pod.type]" nzColor="default">
      {{ pod.metadata?.taskType || pod.type }}
    </nz-tag>
    <nz-tag (click)="download()" class="download-button" [nzColor]="'#108ee9'" nz-tooltip nzTooltipTitle="Download">
      <span nz-icon nzType="download" nzTheme="outline"></span>
    </nz-tag>
    <nz-tag (click)="remove()" class="remove-button" [nzColor]="'#f50'" nz-tooltip nzTooltipTitle="Remove">
      <span nz-icon nzType="close" nzTheme="outline"></span>
    </nz-tag>
  </div>
</div>

<nz-drawer
  [nzClosable]="true"
  [nzVisible]="viewDrawer"
  nzWidth="calc(100vw - 280px)"
  nzSize="large"
  nzPlacement="right"
  nzTitle=""
  (nzOnClose)="closeDrawer()"
>
  <ng-container *nzDrawerContent>
    <pdf-viewer [fit-to-page]="1" [zoom-scale]="'page-height'" [src]="pod.fullPath" style="height: 100%"></pdf-viewer>
  </ng-container>
</nz-drawer>

<div nz-row>
  <div nz-col nzSpan="7" style="height: fit-content; padding-bottom: 15px;">
    <search-box #searchBox (doSearch)="doSearch($event)" [placeHolder]="'Search by Order ID, Shipment ID.Ex: O-49451, S-30174...'" style="width: 100%;"></search-box>
  </div>
</div>

<nz-table #nestedTable [nzData]="listClientWithInventory" [nzPageSize]="10">
  <thead>
    <tr>
      <th></th>
      <th>Order ID</th>
      <th>Shipment ID</th>
      <th>Customer</th>
      <th>Arrived on</th>
      <th>Scheduled Outbound Date</th>
      <th>Pallets</th>
      <th>Inventory Time</th>
      <th>Inbound Route ID</th>
      <th>Outbound Route ID</th>
      <th>Action</th>
    </tr>
  </thead>
  <tbody>
    <ng-template ngFor let-data [ngForOf]="nestedTable.data">
      <tr>
        <td [(nzExpand)]="data.expand"></td>
        <td>
          <div *ngIf="data?.orderWarpId" class="detail"> 
            <a [href]="getLinkToShipmentDetail(data)" target="_blank">{{data?.orderWarpId}}</a>
          </div>
          <div *ngIf="!data?.orderWarpId">{{naText}}</div>
        </td>
        <td>
          <div *ngIf="data?.shipmentWarpId" class="detail"> 
            <a [href]="getLinkToShipmentDetail(data)" target="_blank">{{data?.shipmentWarpId}}</a>
          </div>
          <div *ngIf="!data?.shipmentWarpId">{{naText}}</div>
        </td>
        <td>{{ data?.clientName }}</td>
        <td>{{ data?.arrivedOn }}</td>
        <td>{{ data?.scheduledOutboundDate || '--' }}</td>
        <td>{{ data?.pallets }}</td>
        <td>{{ data?.inventoryTime }}</td>
        <td>
          <a 
            *ngIf="data?.inboundJobId" 
            [routerLink]="[routeAdminDispatchList, data?.inboundJobId]" 
            target="_blank">
            {{ data?.inboundRouteId }}
          </a>
          <span *ngIf="!data?.inboundJobId">{{this.naText}}</span>
        </td>
        <td>
          <a 
            *ngIf="data?.outboundJobId" 
            [routerLink]="[routeAdminDispatchList, data?.outboundJobId]">
            {{ data?.outboundRouteId }}
          </a>
          <span *ngIf="!data?.outboundJobId">{{this.naText}}</span>
        </td>
        <td>
          <a *ngIf="data?.shipmentTrackingCode"[href]="getLinkToTrackingItem(data)" target="_blank">Track</a>
          <span *ngIf="!data?.shipmentTrackingCode">{{naText}}</span>
        </td>
      </tr>
      <tr [nzExpand]="data.expand">
        <nz-table #innerTable [nzData]="data?.listItems" nzSize="middle" [nzShowPagination]="true">
          <thead>
            <tr>
            <th>Location</th>
            <th>Item ID</th>
            <th>Item Name</th>
            <th>Barcode</th>
            <th>Weight</th>
            <th>Quantity</th>
            <!-- <th>Order ID</th>
            <th>Shipment ID</th> -->
            <th>Leg ID</th>
            <!-- <th>To City</th> -->
            <th>Status</th>
            <th>Date Received</th>
            <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of innerTable.data">
              <td>{{item?.inventoryName}}</td>
              <td>{{item?.warpId}}</td>
              <td>{{item?.name}}</td>
              <td>{{item?.barcode}}</td>
              <td>{{item?.weight}}</td>
              <td>{{item?.quantityWithUnit}}</td>
              <!-- <td>
                <div *ngIf="item?.orderWarpId" class="detail"> 
                  <a [href]="getLinkToShipmentDetail(item)" target="_blank">{{item?.orderWarpId}}</a>
                </div>
                <div *ngIf="!item?.orderWarpId">{{naText}}</div>
              </td>
              <td>
                <div *ngIf="item?.shipmentWarpId" class="detail"> 
                  <a [href]="getLinkToShipmentDetail(item)" target="_blank">{{item?.shipmentWarpId}}</a>
                </div>
                <div *ngIf="!item?.shipmentWarpId">{{naText}}</div>
              </td> -->
              <td>{{item?.legWarpId}}</td>
              <!-- <td>{{item?.toCity}}</td> -->
              <td>
                <div [ngClass]="item?.status">{{getItemStatus(item)}}</div>
              </td>
              <td>{{item?.dateReceived}}</td>
              <td>
                <div class="itemAction">
                  <a><img src="assets/img/warehouse/eye-icon.svg"></a>
                  <a><img src="assets/img/warehouse/exit-icon.svg"></a>
                  <a><img src="assets/img/warehouse/edit-weight-button.svg"></a>
                  <a><img src="assets/img/warehouse/audit-button.svg"></a>
                </div>
              </td>
            </tr>
          </tbody>
        </nz-table>
      </tr>
    </ng-template>
  </tbody>
</nz-table>
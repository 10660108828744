import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CountdownTimer } from "./";
import { UiCommonModule } from "@app/admin/components/common/module";

@NgModule({
  imports: [
    CommonModule,
    UiCommonModule,
  ],
  declarations: [
    CountdownTimer,
  ],
  exports: [
    CountdownTimer
  ],
  providers: [
  ]
})
export class CountdownTimerModule {
}

<div *ngIf="displayInfo.numOfStops" class="flex" style="align-items: center;">
  <div>
    <div class="address" nz-popover [nzPopoverContent]="tooltipPickTemplate" 
      nzPopoverOverlayClassName="tooltip-addr" nzPopoverPlacement="bottom">
      {{ displayInfo.firstStopAddr }}
    </div>
    <div class="datetime">{{ displayInfo.firstStopScheduled || 'Appointment required' }}</div>
  </div>
  <div class="flex left10 right10" style="height: 41px; position: relative;">
    <div class="dashed-line-left" [ngStyle]="{ 'width': displayInfo.numOfStops > 2 ? '50px': '75px'}"></div>
    <div *ngIf="displayInfo.numOfStops > 2" class="text">+{{ displayInfo.numOfStops - 2 }} {{ displayInfo.numOfStops - 2 > 1 ? 'stops' : 'stop' }}</div>
    <div class="dashed-line-right" [ngStyle]="{ 'width': displayInfo.numOfStops > 2 ? '50px': '75px'}"></div>
    <div *ngIf="mileage" class="datetime" style="white-space: nowrap; position: absolute; left: 0; text-align: center;" 
      [ngStyle]="{ 'top': positionMileage.top, width: positionMileage.width }">
      Total {{ mileage }}mi
    </div>
  </div>
  <div>
    <div class="address" nz-popover [nzPopoverContent]="tooltipDropTemplate" 
      nzPopoverOverlayClassName="tooltip-addr" nzPopoverPlacement="bottom">
      {{ displayInfo.lastStopAddr }}
    </div>
    <div class="datetime">{{ displayInfo.lastStopScheduled || 'Appointment required' }}</div>
  </div>
</div>

<ng-template #tooltipPickTemplate>
  <nz-descriptions *ngIf="displayInfo.firstStopInfo.metadata" nzBordered [nzColumn]="1" nzSize="small">
      <nz-descriptions-item nzTitle="Address">
          {{ displayInfo.firstStopAddrFull }}
          <span nz-icon nzType="copy" nzTheme="outline" (click)="copyAddress(displayInfo.firstStopAddrFull)" nz-tooltip
              nzTooltipTitle="Copy address" style="cursor: pointer;"></span>
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="Country">
          {{displayInfo.firstStopInfo?.countryAlpha2Code || 'US'}}
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="TimeZone">
          {{displayInfo.firstStopInfo?.metadata?.timeZone}} ({{displayInfo.firstStopInfo?.metadata?.timeZoneStandard}})
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="GPS Location">
          {{displayInfo.firstStopInfo?.metadata?.latitude}},{{displayInfo.firstStopInfo?.metadata?.longitude}}
      </nz-descriptions-item>
  </nz-descriptions>
</ng-template>

<ng-template #tooltipDropTemplate>
  <nz-descriptions *ngIf="displayInfo.lastStopInfo.metadata" nzBordered [nzColumn]="1" nzSize="small">
      <nz-descriptions-item nzTitle="Address">
          {{ displayInfo.lastStopAddrFull }}
          <span nz-icon nzType="copy" nzTheme="outline" (click)="copyAddress(displayInfo.lastStopAddrFull)" nz-tooltip
              nzTooltipTitle="Copy address" style="cursor: pointer;"></span>
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="Country">
          {{displayInfo.lastStopInfo?.countryAlpha2Code || 'US'}}
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="TimeZone">
          {{displayInfo.lastStopInfo?.metadata?.timeZone}} ({{displayInfo.lastStopInfo?.metadata?.timeZoneStandard}})
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="GPS Location">
          {{displayInfo.lastStopInfo?.metadata?.latitude}},{{displayInfo.lastStopInfo?.metadata?.longitude}}
      </nz-descriptions-item>
  </nz-descriptions>
</ng-template>
import { Component, Input, OnInit } from "@angular/core";
import { NzCheckBoxOptionInterface } from "ng-zorro-antd/checkbox";
import isEmail from 'validator/lib/isEmail';
import { CarrierCostIssueNotificationRecipient } from "../cost-issue/interface";

@Component({
  selector: '[carrier-cost-issue-confirm]',
  templateUrl: './view.html',
  styleUrls: ['style.scss']
})
export class CarrierCostIssueConfirm implements OnInit {
  @Input() listEmails: CarrierCostIssueNotificationRecipient[];

  listData: NzCheckBoxOptionInterface[] = [];
  moreEmail: string = '';
  moreEmailError: string = '';

  ngOnInit(): void {
    this.initData();
  }

  private initData() {
    for (let item of this.listEmails) {
      this.listData.push({
        label: item.roles.length > 0 ? `${item.email} (${item.roles.join(', ')})` : item.email,
        value: item.email,
        checked: true,
      });
    }
  }

  getListEmails(): string[] {
    return this.listData.filter(it => it.checked).map(it => it.value);
  }

  addMoreEmail() {
    const email = this.moreEmail.trim();
    if (!isEmail(email)) {
      this.moreEmailError = 'Invalid email address';
      return;
    }
    this.listData.push({
      label: email,
      value: email,
      checked: true,
    });
    this.moreEmail = '';
  }

  onMoreEmailChange() {
    this.moreEmailError = '';
  }
}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CrossdockWarehouse } from '.';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzFormModule } from 'ng-zorro-antd/form';
import { SearchBoxModule } from '@libs/search-box/search-box.module';
import { DetailModule } from '../base/detail.module';
import { SelectBySearchingModule } from '@libs/select-client/module';
import { SharedModule } from '../shared/shared.module';
import { CrossdockWarehouseMap } from './components/map';
import { CrossdockWarehouseTable } from './components/table';
import { CrossdockWarehouseList } from './components/list';
import { EditWeight } from './components/list/components/edit-weight';
@NgModule({
    imports: [
      CommonModule,
      RouterModule,
      FormsModule,
      ReactiveFormsModule,
      NzTableModule,
      SearchBoxModule,
      NzButtonModule,
      NzSelectModule,
      NzIconModule,
      NzGridModule,
      NzInputModule,
      NzFormModule,
      DetailModule,
      SelectBySearchingModule,
      SharedModule,

    ],
    declarations: [
      CrossdockWarehouse,
      CrossdockWarehouseMap,
      CrossdockWarehouseTable,
      CrossdockWarehouseList,
      EditWeight
    ],
    exports: [
  
    ],
    providers: [
    ]
  })
  export class CrossdockWarehouseModule {}
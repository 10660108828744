import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Const } from '@const/Const';
import { DateUtil } from '@services/date-utils';

@Component({
  selector: 'time-window-str',
  template: '<span *ngIf="display">{{display}}</span>',
  styleUrls: []
})
export class TimeWindowStr extends Component  {
    _data: { from: any, to: any, format?: string, formatDate?: string, tz?: string | null} = null

    @Input() set data(v) {
        this._data = v

        this.display = v.from ? DateUtil.displayTimeWindow(v, {
            format: v.format ?? Const.FORMAT_GUI_DATETIME_V5,
            formatDateOnly: v.formatDate ?? Const.FORMAT_GUI_DATE_SHORT,
            timezone: v.tz
        }) : null
    }

    display: string | null = null    
}
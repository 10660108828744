<td (nzCheckedChange)="onSelect()" [nzChecked]="selected" (click)="stopPropergate($event)"></td>
<td [class]="[_item?.status]" style="text-align: center">
  <small>{{ getWarehouseJobStatus(_item?.status) }}</small>
</td>
<td style="text-align: center">{{_item?.tasksInfo?.completed}} / {{_item?.tasksInfo?.total}}</td>
<td style="text-align: center">
  <a *ngIf="_item?.parent?.warpId" target="_blank" [routerLink]="[routeAdminShipmentList, _item?.parent?.order?.id]">
    {{showShipmentWarpId(_item?.parent?.warpId)}}
  </a>
</td>

<td>{{ _item?.client?.name }}</td>
<td>
  <div *ngIf="getItemRefNums(_item.refNums)">
    <code
      *ngFor="let po of _item.refNums; index as i"
      class="po-number clickable"
      (click)="copyToClipboard($event, po)"
    >
      {{ displayRefNum(po, i, _item.refNums.length) }}
    </code>
    <span class="copy-button clickable" (click)="copyToClipboard($event, getItemRefNums(_item.refNums))">
      <span nz-icon nzType="copy" nzTheme="outline"></span>
    </span>
  </div>
</td>
<td [style]="getStyleInventories(_item?.summaryItems)">{{ displayInventories(_item?.summaryItems) }}</td>
<td>{{_item?.summaryItems?.weight}}</td>
<td style="text-align: center">
  <div *ngIf="_item?.inboundLeg">
    <div>
      {{showShipmentWarpId(_item?.inboundLeg?.warpId)}}
      <span class="copy-button clickable" (click)="copyToClipboard($event, _item?.inboundLeg?.warpId)">
        <span nz-icon nzType="copy" nzTheme="outline"></span>
      </span>
      <a
        *ngIf="_item?.inboundLeg?.lastJobId"
        target="_blank"
        [routerLink]="[routeAdminDispatchList, _item.inboundLeg.lastJobId]"
      >
        <i nz-icon nzType="rocket" nzTheme="outline"></i>
      </a>
    </div>
    <nz-tag style="max-width: 100%" [nzColor]="getShipmentStatusColor(_item?.inboundLeg?.status)">
      {{ getStatusShipment(_item?.inboundLeg?.status) }}
    </nz-tag>
  </div>
</td>
<td style="text-align: center">
  <div *ngIf="_item?.outboundLeg">
    <div>
      {{showShipmentWarpId(_item?.outboundLeg?.warpId)}}
      <span class="copy-button clickable" (click)="copyToClipboard($event, _item?.outboundLeg?.warpId)">
        <span nz-icon nzType="copy" nzTheme="outline"></span>
      </span>
      <a
        *ngIf="_item?.outboundLeg?.lastJobId"
        target="_blank"
        [routerLink]="[routeAdminDispatchList, _item.outboundLeg.lastJobId]"
      >
        <i nz-icon nzType="rocket" nzTheme="outline"></i>
      </a>
    </div>
    <nz-tag style="max-width: 100%" [nzColor]="getShipmentStatusColor(_item?.outboundLeg?.status)">
      {{ getStatusShipment(_item?.outboundLeg?.status) }}
    </nz-tag>
  </div>
</td>
<td style="text-align: center">
  <div *ngIf="_item?.pickSchedule?.windowTime">
    <small>{{ _item?.pickSchedule.txtWindowTime }}</small>
    <small> {{ _item?.pickSchedule?.txtTimeZone }}</small>
  </div>
</td>
<td style="text-align: center">
  <div *ngIf="_item?.inboundSchedule?.windowTime">
    <small>{{ _item?.inboundSchedule.txtWindowTime }}</small>
    <small> {{ _item?.inboundSchedule?.txtTimeZone }}</small>
  </div>
</td>
<td style="text-align: center">
  <div *ngIf="_item?.outboundSchedule?.windowTime">
    <small>{{ _item?.outboundSchedule.txtWindowTime }}</small>
    <small> {{ _item?.outboundSchedule?.txtTimeZone }}</small>
  </div>
</td>
<td style="text-align: center">
  <div *ngIf="_item?.dropSchedule?.windowTime">
    <small>{{ _item?.dropSchedule.txtWindowTime }}</small>
    <small> {{ _item?.dropSchedule?.txtTimeZone }}</small>
  </div>
</td>
<td>
  <div *ngIf="_item?.pickLocation?.locationName" class="font-medium">{{ _item.pickLocation.locationName }}</div>
  <div *ngIf="_item?.pickFullAddress" class="small-text">{{ _item.pickFullAddress }}</div>
</td>
<td>
  <div *ngIf="_item?.dropLocation?.locationName" class="font-medium">{{ _item.dropLocation.locationName }}</div>
  <div *ngIf="_item?.dropFullAddress" class="small-text">{{ _item.dropFullAddress }}</div>
</td>

<td>
  <div *ngIf="_item?.parent?.pickInfo?.locationName" class="font-medium">{{ _item.parent.pickInfo.locationName }}</div>
  <div *ngIf="_item?.originalPickAddress" class="small-text">{{ _item.originalPickAddress }}</div>
</td>
<td>
  <div *ngIf="_item?.parent?.dropInfo?.locationName" class="font-medium">{{ _item.parent.dropInfo.locationName }}</div>
  <div *ngIf="_item?.finalDeliveryAddress" class="small-text">{{ _item.finalDeliveryAddress }}</div>
</td>

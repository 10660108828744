<ng-container *ngFor="let groupKey of documentTypes">
  <div class="group-document flex-column" style="border-radius: 7px;border: 1px solid #EEEEEE;padding: 20px">
    <div class="flex group-item header center-vertical">
      <div class="title flex">{{ getLabel(groupKey.key) }}
        <div *ngIf="isRequiredDocument(groupKey.key)" class="danger left5">*</div>
      </div>
      <div *ngIf="isEditing">
        <ng-container *ngIf="loadingKey == groupKey.key">
          <button nz-button nzShape="round">
            <i nz-icon nzTheme="outline" nzType="loading"></i> Upload
          </button>
        </ng-container>
        <ng-container *ngIf="loadingKey != groupKey.key">
          <button (click)="onClickUpload(groupKey.key)" nz-button nzShape="round">
            <i nz-icon nzTheme="outline" nzType="upload"></i> Upload
          </button>
        </ng-container>
      </div>
    </div>
    <div class="group-body top10">
      <ng-container *ngIf="selected">
        <ng-container *ngFor="let item of selected[groupKey.key]?.docs ?? []; let i = index">
          <div class="group-item center-vertical">
            <div class="file-name">
              <ng-container *ngIf="!isFile(item)">
                <a [href]="attachedFileUrl(item)" [ngClass]="{'strikethrough': item.deleted}" nz-tooltip
                   nzTooltipTitle="View file"
                   target="_blank">
                  <i nz-icon nzTheme="outline" nzType="paper-clip"></i>
                  <span class="description">{{ getAttachedFileDesc(item) }}</span>
                </a>
              </ng-container>
              <ng-container *ngIf="isFile(item)">
                <i nz-icon nzTheme="outline" nzType="paper-clip"></i>
                <span class="description">{{ getAttachedFileDesc(item) }}</span>
              </ng-container>
            </div>
            <div *ngIf="isEditing">
              <button (click)="delFile(i, groupKey.key)" *ngIf="!isFileDeleted(i, groupKey.key)"
                      nz-button nz-tooltip
                      nzTooltipPlacement="right" nzTooltipTitle="Delete file" nzType="text">
                <i nz-icon nzType="delete"></i>
              </button>
              <button (click)="undoDelFile(i, groupKey.key)" *ngIf="isFileDeleted(i, groupKey.key)"
                      nz-button
                      nz-tooltip nzTooltipPlacement="right" nzTooltipTitle="Undo delete file" nzType="text">
                <i nz-icon nzType="undo"></i>
              </button>
            </div>
          </div>
        </ng-container>
        <div (click)="onClickUpload(groupKey.key)" *ngIf="checkShowDate(groupKey.key) && !isEmpty(selected[groupKey.key])" class="flex"
             style="cursor: pointer">
          <div *ngIf="!hasExpirationDate(selected[groupKey.key]) && !isEmpty(selected[groupKey.key])"
               class="danger flex center-vertical">
            <span nz-icon nzTheme="outline" nzType="edit"></span>
            <div class="left10">Add expiration date</div>
          </div>
          <div *ngIf="hasExpirationDate(selected[groupKey.key])" class="flex center-vertical" nz-tooltip
               nzTooltipTitle="Edit expiration date">
            <span nz-icon nzTheme="outline" nzType="edit"></span>
            <span class="left10">{{ getDateRangeOfInsurance(selected[groupKey.key], groupKey.key) }}</span>
            <span *ngIf="checkExpired(selected[groupKey.key])" class="danger left5">(Expired)</span>
          </div>
        </div>
      <ng-container *ngIf="isEmpty(selected[groupKey.key])">
        <div style="opacity: 0.5"><span nz-icon nzType="info-circle" nzTheme="outline"></span> No file uploaded</div>
      </ng-container>
      </ng-container>
    </div>
  </div>
</ng-container>

<div class="bottom20">Fill-out the email will be received Quick Order Form Link</div>
<form nz-form [formGroup]="formInput!">
  <div *ngFor="let key of ['email']" class="flex">
    <div style="width: 70px;">{{getLabel(key)}}:</div>
    <div class="left10 flex1">
      <nz-form-item>
        <nz-form-control>
          <input nz-input [formControlName]="key"
            [type]="getInputType(key)"
            [placeholder]="getPlaceHolder(key)"
            (input)="onInputChanged($event, key)"
            (keypress)="onInputKeyPress($event, key)" >
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
</form>
<div *ngIf="isLoading" class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
<div *ngIf="isError" class="nodata">Cannot fetch data, please try again later</div>

<form nz-form *ngIf="!isLoading && formInput" [formGroup]="formInput" class="form-detail" nzLayout="vertical">

  <div class="carrier-selection">
    <div *ngIf="jobHyperLink" class="bottom40 f18">
      Related route: <a [routerLink]="[jobHyperLink.hyperLinkUrl]" target="_blank">{{jobHyperLink.hyperLinkText}}</a>
    </div>
    <div class="box-with-title">
      <div class="box-title">Information</div>
      <div class="box-content">
        <div nz-row [nzGutter]="12">
          <div nz-col nzSpan="12">
            <nz-form-item>
              <nz-form-label>
                {{getLabel("pickupAddress")}}
                <span *ngIf="isRequired('pickupAddress')" class="label-mark-required"></span>
              </nz-form-label>
              <nz-form-control>
                <form-address [formControlName]="'pickupAddress'"></form-address>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col nzSpan="12">
            <nz-form-item>
              <nz-form-label>
                {{getLabel("dropoffAddress")}}
                <span *ngIf="isRequired('dropoffAddress')" class="label-mark-required"></span>
              </nz-form-label>
              <nz-form-control>
                <form-address [formControlName]="'dropoffAddress'"></form-address>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div nz-col nzSpan="12">
            <nz-form-item>
              <nz-form-label>
                {{getLabel("pickupDate")}}
                <span *ngIf="isRequired('pickupDate')" class="label-mark-required"></span>
              </nz-form-label>
              <nz-form-control>
                <nz-date-picker formControlName="pickupDate" class="full-w" nzFormat="yyyy-MM-dd HH:mm"
                  [nzDisabled]="hasJobId" [nzShowTime]="{ nzFormat: 'HH:mm' }"></nz-date-picker>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div nz-col nzSpan="12">
            <nz-form-item>
              <nz-form-label>
                {{getLabel("dropoffDate")}}
                <span *ngIf="isRequired('dropoffDate')" class="label-mark-required"></span>
              </nz-form-label>
              <nz-form-control>
                <nz-date-picker formControlName="dropoffDate" class="full-w" nzFormat="yyyy-MM-dd HH:mm"
                  [nzDisabled]="hasJobId" [nzShowTime]="{ nzFormat: 'HH:mm' }"></nz-date-picker>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div nz-col nzSpan="12">
            <nz-form-item>
              <nz-form-label>
                {{getLabel("serviceOptions")}}
                <span *ngIf="isRequired('serviceOptions')" class="label-mark-required"></span>
              </nz-form-label>
              <nz-form-control>
                <app-form-service-options formControlName="serviceOptions"></app-form-service-options>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div nz-col nzSpan="12">
            <nz-form-item>
              <div class="box-with-title">
                <div class="box-title">{{getLabel("equipments")}}</div>
                <div class="box-content">
                  <div nz-row [nzGutter]="12">
                    <div nz-col nzSpan="12">
                      <nz-form-item>
                        <nz-form-label>
                          Preferred
                          <span *ngIf="isRequired('equipments')" class="label-mark-required"></span>
                        </nz-form-label>
                        <nz-form-control>
                          <ng-container *ngIf="getPreferredEquipment()">
                            <div vehicle-selector [isDisabled]="true" [value]="getPreferredEquipment()" [quoting]="false" [withOptions]="true"></div>
                          </ng-container>
                          <ng-container *ngIf="!getPreferredEquipment()">
                            <app-form-equipments formControlName="equipments"></app-form-equipments>
                          </ng-container>
                          
                        </nz-form-control>
                      </nz-form-item>
                    </div>
                    <div nz-col nzSpan="12">
                      <nz-form-item>
                        <nz-form-label>
                          Optional
                          <i nz-icon nzType="info-circle" nzTheme="outline"
                          nzTooltipPlacement="bottom"
                          nzTooltipOverlayClassName="tooltip-info"
                          style="margin-left: 10px;" class="clickable" nz-tooltip [nzTooltipTitle]="'The other carriers who have this equipment can also bid'"></i>
                        </nz-form-label>
                        <nz-form-control>
                          <div [mode]="'multiple'" vehicle-selector [value]="optionalEquipments" (valueChange)="onDropdownSelectChange('optionalEquipments', $event)" [quoting]="false"></div>
                        </nz-form-control>
                      </nz-form-item>
                    </div>
                  </div>
                </div>
              </div>
            </nz-form-item>
          </div>
          <div nz-col nzSpan="8">
            <nz-form-item>
              <nz-form-label>
                {{getLabel("type")}}
                <span *ngIf="isRequired('type')" class="label-mark-required"></span>
              </nz-form-label>
              <nz-form-control>
                <nz-select formControlName="type" (ngModelChange)="onInputChanged($event, 'type')">
                  <nz-option *ngFor="let option of carrierBIDType" [nzLabel]="getLabelType(option)" [nzValue]="option">
                  </nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col nzSpan="16"></div>
          <div nz-col nzSpan="24">
            <b>Settings: </b>
            <div class="error" *ngIf="isSourceMarketPlace">Note: CAN NOT change WARP Offer for carrier bid relate to Marketplace Order</div>
            <div class="error" *ngIf="!isAllowPlaceBid && !isShowWarpOffer">You need to select at least one option(<b>Allow the carrier place bid</b> or <b>Show WARP Offer</b>) below.</div>
          </div>
          <div nz-col nzSpan="24">
            <nz-form-control>
              <nz-form-control>
                <div nz-checkbox formControlName="isAllowPlaceBid">
                  Allow the carrier place bid
                </div>
              </nz-form-control>
            </nz-form-control>
          </div>
          <div nz-col nzSpan="24">
            <nz-form-control>
              <nz-form-control>
                <div nz-checkbox formControlName="isShowBasePrice">
                  Show WARP Offer
                </div>
              </nz-form-control>
            </nz-form-control>
          </div>
          <div nz-col nzSpan="8" *ngIf="isShowWarpOffer">
            <nz-form-label>
              WARP Offer (Base Price)
              <span *ngIf="isRequired('basePrice')" class="label-mark-required"></span>
            </nz-form-label>
            <nz-form-control>
              <input nz-input [placeholder]="getPlaceHolder('basePrice')" (input)="onInputChanged($event, 'basePrice')"
                formControlName="basePrice" />
            </nz-form-control>
          </div>
          <div nz-col nzSpan="24">
            <nz-form-control>
              <nz-form-control>
                <div nz-checkbox formControlName="isShowOnBidBoard">
                  {{getLabel("isShowOnBidBoard")}}
                  <span style="margin-left: 10px;"
                    nz-icon nzType="info-circle" nzTheme="outline"
                    nz-tooltip nzTooltipTitle="Allow all the carries, including unmatched, to see the load on their Load Board">
                  </span>
                </div>
              </nz-form-control>
            </nz-form-control>
          </div>
          <div nz-col nzSpan="24">
            <nz-form-control>
              <nz-form-control>
                <div nz-checkbox formControlName="isRequireCarrierAcceptLoadTender">
                  {{getLabel("isRequireCarrierAcceptLoadTender")}}
                </div>
              </nz-form-control>
            </nz-form-control>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>

<div style="height: 50px;">
  <div *ngIf="!isAdminReadOnlyRole" detail-footer-buttons labelButtonCreate="Create New Carrier Bid"
    [isCreateNew]="isCreateNew" [isEditing]="isEditing" [onProgress]="onProgress" [needUpdate]="needUpdate"
    (onEdit)="onBtnEdit()" (onSave)="onBtnSave()" (onCancel)="onBtnCancel()">
  </div>
</div>
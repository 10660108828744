import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NzTableModule } from "ng-zorro-antd/table";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzSelectModule } from "ng-zorro-antd/select";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzTabsModule } from "ng-zorro-antd/tabs";
import { NzDividerModule } from "ng-zorro-antd/divider";
import { NzEmptyModule } from "ng-zorro-antd/empty";
import { NzDropDownModule } from "ng-zorro-antd/dropdown";
import { NzResultModule } from "ng-zorro-antd/result";
import { NzCollapseModule } from "ng-zorro-antd/collapse";
import { NzDatePickerModule } from "ng-zorro-antd/date-picker";
import { SearchBoxModule } from "@libs/search-box/search-box.module";
import { DetailModule } from "../base/detail.module";
import { SelectBySearchingModule } from "@libs/select-client/module";
import { FormAddressUSModule } from "../base/form-address-us/module";
import { CrossdockManagement } from "./index";
import { NzImageModule } from "ng-zorro-antd/image";
import { WarehouseItemListItem } from "./shipments/item";
import { WarehouseItemContent } from "./shipments/item-content";
import { ViewShipmentBol } from "./shipments/bols";
import { NzModalModule } from "ng-zorro-antd/modal";
import { ShipRecModule } from "../base/shiprec/module";
import { NzSpinModule } from "ng-zorro-antd/spin";
import { NzDrawerModule } from "ng-zorro-antd/drawer";
import { WarehouseShipment } from "./shipments";
import { WarehouseShipmentFilter } from "./shipments/filter";
import { WarehouseShipmentItem } from "./shipments/shipment-item";
import { TransitNetworkModule } from "../transit-network/module";
import { NzTagModule } from "ng-zorro-antd/tag";
import { PdfViewerModule } from '@libs/pdf-viewer/pdf-viewer.module';
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { DlgCreateRoute } from "./shipments/dlg-create-route";
import { DlgChecklistItems } from "./shipments/dlg-checklist-items";
import { ModuleFilterLayout } from '@app/admin/components/filter-layout/module';
import { ModuleInputWarpIds } from '@wearewarp/ng-antd';
import { NzTypographyModule } from "ng-zorro-antd/typography";
import {WarehouseRatePlanList} from '@app/admin/crossdock/rate-configuration/plan-list';
import {AddPlan} from '@app/admin/crossdock/rate-configuration/add-plan';
import {SharedModule} from '@app/admin/shared/shared.module';
import {EditPlanDuration} from '@app/admin/crossdock/rate-configuration/edit-plan-duration';
import {ClonePlan} from '@app/admin/crossdock/rate-configuration/clone-plan';
import {WarehouseRatePlanDetail} from '@app/admin/crossdock/rate-configuration/plan-detail';
import {AddRateAdjustment} from '@app/admin/crossdock/rate-configuration/add-rate';
import {EditRateAdjustment} from '@app/admin/crossdock/rate-configuration/edit-rate';
import {WarehouseRateAdjustmentStorageFee} from '@app/admin/crossdock/rate-configuration/storage-fee';
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NzTableModule,
    NzTabsModule,
    NzDividerModule,
    NzEmptyModule,
    NzDropDownModule,
    NzResultModule,
    NzCollapseModule,
    NzDatePickerModule,
    NzButtonModule,
    NzSelectModule,
    NzIconModule,
    NzGridModule,
    NzInputModule,
    NzFormModule,
    SearchBoxModule,
    DetailModule,
    SelectBySearchingModule,
    FormAddressUSModule,
    NzModalModule,
    ShipRecModule,
    NzSpinModule,
    NzModalModule,
    NzDrawerModule,
    TransitNetworkModule,
    NzTagModule,
    PdfViewerModule,
    NzImageModule,
    NzToolTipModule,
    ModuleFilterLayout,
    ModuleInputWarpIds,
    NzTypographyModule,
    SharedModule
  ],
  declarations: [
    CrossdockManagement,
    WarehouseShipment,
    WarehouseShipmentFilter,
    WarehouseItemListItem,
    WarehouseItemContent,
    WarehouseShipmentItem,
    ViewShipmentBol,
    DlgCreateRoute,
    DlgChecklistItems,
    WarehouseRatePlanList,
    AddPlan,
    EditPlanDuration,
    ClonePlan,
    WarehouseRatePlanDetail,
    AddRateAdjustment,
    EditRateAdjustment,
    WarehouseRateAdjustmentStorageFee,
  ],
  exports: [],
  providers: [],
})
export class ModuleCrossDockManagement {}

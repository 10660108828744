<div>
  <div class="f20 medium bottom20">
    Enter the address manually
  </div>
  <div nz-row nzGutter="32">
    <div nz-col nzMd="12">
      <div class="form-label-v2">Address<span class="label-mark-required"></span></div>
      <form nz-form [formGroup]="formInput" class="form-address-us">
        <nz-form-item class="bottom0">
          <nz-form-control>
            <input nz-input formControlName="street"
              autocomplete="new-street"
              [placeholder]="getPlaceHolder('street')" class="top" 
              (input)="onInputChanged($event, 'street')">
          </nz-form-control>
        </nz-form-item>
        <nz-form-item class="bottom0">
          <nz-form-control>
            <input nz-input formControlName="street2"
              autocomplete="new-street2"
              [placeholder]="getPlaceHolder('street2')" class="top" 
              (input)="onInputChanged($event, 'street2')">
          </nz-form-control>
        </nz-form-item>
        <nz-form-item class="bottom0">
          <nz-form-control>
            <input nz-input formControlName="city" autocomplete="new-city" [placeholder]="getPlaceHolder('city')" class="middle">
          </nz-form-control>
        </nz-form-item>
        <nz-form-item class="bottom0">
          <nz-form-control>
            <nz-select nzBackdrop="true" class="middle" style="width: 100%;" 
              nzShowSearch nzAllowClear 
              [nzPlaceHolder]="getPlaceHolder('countryAlpha2Code')" 
              formControlName="countryAlpha2Code"
              (ngModelChange)="onInputChanged($event, 'countryAlpha2Code')">
              <nz-option *ngFor="let item of country" [nzLabel]="item.name" [nzValue]="item.code"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
        <div class="flex">
          <nz-form-item class="flex1">
            <nz-form-control>
              <nz-select nzBackdrop="true" class="bottom-left" style="width: 100%;" 
                [nzDropdownRender]="isCountryUS ? null : tplAddState"
                [nzDropdownClassName]="'form-address-us-dropdown-state'"
                nzShowSearch nzAllowClear 
                [nzPlaceHolder]="getPlaceHolder('state')" 
                formControlName="state">
                <nz-option *ngFor="let state of allStates" [nzLabel]="getStateDesc(state)" [nzValue]="state.code"></nz-option>
              </nz-select>
              <ng-template #tplAddState>
                <nz-divider></nz-divider>
                <div class="add-state-container">
                  <input type="text" nz-input #inputElement />
                  <a class="add-state" (click)="addState(inputElement.value)">
                    <span nz-icon nzType="plus"></span>
                    Add state
                  </a>
                </div>
              </ng-template>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item class="flex1">
            <nz-form-control>
              <input nz-input formControlName="zipcode" autocomplete="new-zipcode" [placeholder]="getPlaceHolder('zipcode')" (keypress)="onlyNumberKey($event)" class="bottom-right" (input)="onInputChanged($event, 'zipcode')">
            </nz-form-control>
          </nz-form-item>
        </div>
    
        <div>Metadata<span class="label-mark-required"></span></div>
        <form nz-form formGroupName="metadata">
          <div class="flex">
            <nz-form-item class="flex1 bottom0">
              <nz-form-control>
                <nz-select nzBackdrop="true" class="top-left" style="width: 100%;" 
                  [nzDropdownRender]="isCountryUS ? null : tplAddShortTimezone"
                  [nzDropdownClassName]="'form-address-us-dropdown-state'"
                  [nzPlaceHolder]="getPlaceHolder('timeZone')" 
                  nzShowSearch nzAllowClear 
                  formControlName="timeZone">
                  <nz-option *ngFor="let item of allShortTimezones" [nzLabel]="item" [nzValue]="item"></nz-option>
                </nz-select>
                <ng-template #tplAddShortTimezone>
                  <nz-divider></nz-divider>
                  <div class="add-state-container">
                    <input type="text" nz-input #inputElement />
                    <a class="add-state" (click)="addShortTimezone(inputElement.value)">
                      <span nz-icon nzType="plus"></span>
                      Add time short zone
                    </a>
                  </div>
                </ng-template>
              </nz-form-control>
            </nz-form-item>
            <nz-form-item class="flex1 bottom0">
              <nz-form-control>
                <nz-select nzBackdrop="true" style="width: 100%;" nzShowSearch nzAllowClear [nzPlaceHolder]="getPlaceHolder('timeZoneStandard')" formControlName="timeZoneStandard" class="top-right">
                  <nz-option *ngFor="let item of allTimezones" [nzLabel]="item.tzCode" [nzValue]="item.tzCode"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div class="flex">
            <nz-form-item class="flex1">
              <nz-form-control>
                <input nz-input formControlName="latitude" [placeholder]="getPlaceHolder('latitude')">
              </nz-form-control>
            </nz-form-item>
            <nz-form-item class="flex1">
              <nz-form-control>
                <input nz-input formControlName="longitude" [placeholder]="getPlaceHolder('longitude')">
              </nz-form-control>
            </nz-form-item>
          </div>
        </form>
      </form>
      <div class="guide-action">
        Move location icon in the map to update GPS coordinate.
      </div>
    </div>
    <div nz-col nzMd="12">
      <div #mapElm id="dispatch-map-address" style="width: 100%; height: 600px; margin-bottom: 20px;"></div>
    </div>
  </div>
  <div form-footer class="no-border"
    [canClickOK]="needUpdate" 
    [canClickCancel]="true"
    nzIconOK="check" nzIconCancel="close" labelOK="OK" nzOkDanger="false" (onOK)="onBtnSave()"
    (onCancel)="closeDialog()">
  </div>
</div>
import { Injector, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DispatchRouteMap } from '@app/admin/dispatch/components/dispatch-route-map';
import { MapModule } from "@app/admin/components/map/module";
import { NzSliderModule } from 'ng-zorro-antd/slider';
import { FormsModule } from "@angular/forms";
import { PopupLocation } from "@app/admin/dispatch/components/dispatch-route-map/popup";

@NgModule({
    imports: [
        MapModule,
        CommonModule,
        NzSliderModule,
        FormsModule,
    ],
    declarations: [
        DispatchRouteMap,
        PopupLocation
    ],
    exports: [
        DispatchRouteMap
    ]
})

export class DispatchRouteMapModule {}
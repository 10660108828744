import { BaseList } from '../base/list';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UnsubscribeForm } from './form';
import { ApiService } from '@services/api.service';
import { DrawerService } from '@app/drawers/drawer.service';
import { Const } from '@const/Const';
import { Const as WarpConst } from '@wearewarp/universal-libs'

@Component({
  selector: 'comm-unsubscribe-index',
  templateUrl: './list.html',
  styleUrls: ['../list.scss', './list.scss']
})
export class CommUnsubscribeList extends BaseList {
  constructor(protected activatedRoute: ActivatedRoute) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  protected getItemId(item) {
    return item.id;
  }
  protected getItemName(item): string {
    return item?.owner;
  }

  protected getApiUrl(): string {
    return Const.API_SERVICE_COMM_UNSUBSCRIBE;
  }

  private updated(resp) {
    let newData = resp.data;
    for (let i = 0; i < this.listData.length; i++) {
      if (this.listData[i].id == newData.id) {
        this.listData[i] = newData;
        break;
      }
    }
  }

  private created(resp) {
    if (this.currentPage == 1) {
      this.onBtnRefresh();
    } else {
      this.routeWithQueryUrl({ page: 1 });
    }
  }

  // item null means create new
  onBtnDetail(item = null) {
    DrawerService.openFormDrawer1(UnsubscribeForm, {
      nzContentParams: {
        createSuccess: this.created.bind(this),
        updateSuccess: this.updated.bind(this),
        model: item,
        closeOnSuccess: true
      },
      // nzWidth: 300,
    });
  }

  displayScope(scope) {
    switch (scope) {
      case WarpConst.CommunicationScope.carrier_bid:
        return 'Carrier Bid';
      case WarpConst.CommunicationScope.notification:
        return 'Notification';
      case WarpConst.CommunicationScope.auth:
        return 'Authentification';
      default:
        return scope;
    }
  }

  displaySource(source) {
    switch (source) {
      case 'email_unsubscribe':
        return 'From Link in the email';
      case 'sms_reply':
        return 'From SMS reply';
      case 'email_failed':
        return 'From Email bounced';
      case 'sms_failed':
        return 'From SMS failed';
      default:
        return source
    }
  }

}
import { BaseComponent } from "@abstract/BaseComponent";
import { Component, HostBinding, Input, TemplateRef, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { DispatchService } from "@app/admin/dispatch/dispatchService";
import { InputHelper } from "@services/input-helper";
import { MasterData } from "@services/master.data";
import { Utils } from "@services/utils";
import { CarrierCostHelper } from "../../carrier-cost/helper";
import { CarrierCost } from "@wearewarp/types/data-model/types/AssignedCarrier";
import { Observable, from, of } from "rxjs";
import { map } from "rxjs/operators";
import RouteEntity from "@app/admin/dispatch/entity/RouteEntity";
import { UIHelper } from "@services/UIHelper";
import { StringDateTimeIso, StringULID } from "@wearewarp/types";
import { CarrierMeta } from "@wearewarp/types/data-model/types/Metadata";
import { Const } from "@const/Const";
import { getInjector } from "@services/injector";
import { NzModalService } from "ng-zorro-antd/modal";
import { ModalHelper, UpdateText } from "@wearewarp/ng-antd";
import { CarrierPaymentPlanning, FormDataCarrierPaymentPlanning } from "../carrier-payment-planning";

export interface DataFetchDisplayCarrierCost {
  jobId: string, carrierCost: CarrierCost, carrierId?: StringULID
}

@Component({
  selector: '[display-carrier-cost]',
  templateUrl: './index.html',
  styleUrls: ['index.scss']
})
export class DisplayCarrierCost extends BaseComponent {
 
  constructor(
    protected activatedRoute: ActivatedRoute,
    protected dispatchService: DispatchService,
    private modalHelper: ModalHelper,
  ) {
    super(activatedRoute)
  }

  public displayInfo: any = {}
  get arrServiceOptions(): any[] { return this.displayInfo?.arrServiceOptions ?? [] }

  private _carrierCost: CarrierCost;
  @Input() get carrierCost(): CarrierCost { return this._carrierCost }
  set carrierCost(value: CarrierCost) {
    this._carrierCost = value;
    this.updateValueCheckbox_alreadyPaid(value);
  }
  @Input() jobId: string | 'no_job' | 'no_job_additional_cost' | undefined;
  @Input() saveCarrierCost: (jobId: string, data: CarrierCost) => Observable<any>;
  @Input() saveCarrierCostSuccess: () => void;
  @Input() saveMarkAlreadyPaid: (data: FormDataCarrierPaymentPlanning & {value: boolean}) => Observable<any>;
  @Input() updateNoteCarrierPaid: (data: FormDataCarrierPaymentPlanning) => Observable<any>;
  @Input() shouldDisableMarkAlreadyPaid: boolean = false;
  @HostBinding('class.readonly') @Input() isReadOnly: boolean = false;

  private carrierAssigned: CarrierMeta;
  private isInDispatchScreen: boolean = true;
  public isAdditionalCarrierCost: boolean = false;

  ngOnInit(): void {
    super.ngOnInit();
    this.isInDispatchScreen = this.jobId === undefined;
    this.subscription.add(this.waitingForData().subscribe(async (data) => {
      this.jobId = data.jobId;
      this.displayInfo = {};
      await this.fetchCarrier(data?.carrierId);
      this.buildDisplayInfo(data.carrierCost)
    }));
  }

  private async fetchCarrier(carrierId: StringULID) {
    let data = await RouteEntity.fetchCarrier(carrierId);
    if (data) this.carrierAssigned = data;
  }

  private waitingForData(): Observable<DataFetchDisplayCarrierCost> {
    if (this.isInDispatchScreen) {
      // Mặc định là dùng ở màn dispatch
      return this.dispatchService.routeData$.pipe(map(() => {
        let route = this.dispatchService.getRoute();
        this.carrierAssigned = route?.getCarrier();
        return {
          jobId: route?.getId(),
          carrierCost: route?.getCarrierCost(),
          carrierId: route?.getAssignedCarrier()?.carrierId,
        };
      }))
    } else if (this.jobId === 'no_job' || this.jobId === 'no_job_additional_cost') {
      // Dùng ở màn carrier pool thì không có job
      this.isAdditionalCarrierCost = this.jobId === 'no_job_additional_cost';
      return of({carrierCost: this.carrierCost ?? {}, jobId: ''});
    } else {
      // Nếu dùng ở màn khác không phải dispatch thì phải có jobId
      return from(RouteEntity.withJobId(this.jobId)).pipe(map((route: RouteEntity) => {
        this.carrierAssigned = route?.getCarrier();
        return {
          jobId: route?.getId(),
          carrierCost: route?.getCarrierCost(),
          carrierId: route?.getAssignedCarrier()?.carrierId,
        }
      }));
    }
  }

  private buildDisplayInfo(carrierCost: CarrierCost) {
    if (!carrierCost) return;
    this.carrierCost = carrierCost;
    const isRequiredBill = this.carrierAssigned?.isRequiredBill ?? false;
    this.displayInfo = {
      transitCost: InputHelper.formatMoney2(`${carrierCost?.transitCost?.total || 0}`),
      fuelCost: InputHelper.formatMoney2(`${carrierCost?.fuelCost?.total || 0}`),
      serviceCost: this.getServiceCost(carrierCost),
      grandTotal: InputHelper.formatMoney2(`${carrierCost?.grandTotal || 0}`),
      arrServiceOptions: this.getArrServiveOptions(carrierCost),
      isCarrierBillMatches: carrierCost?.isCarrierBillMatches || false,
      isCarrierRequiredBill: isRequiredBill,
    }
  }

  private getServiceCost(cost) {
    let total = 0;
    for (let item of cost?.serviceOptions || []) {
      if (Utils.isNumber(item.total)) {
        if (MasterData.isServiceOptionTypeNegative(item._id)) {
          total = total - item.total;
        } else {
          total = total + item.total;
        }
      }
    }
    return InputHelper.formatMoney2(`${total}`);
  }

  private getArrServiveOptions(cost) {
    let arr: any = [];
    for (let item of cost?.serviceOptions || []) {
      let obj: any ={
        name: this.getServiceOptionName(item._id),
        rate: InputHelper.formatMoney2(`${item?.rate || 0}`),
        qty: item.qty,
      };
      if (Utils.isNumber(item.total)) {
        if (MasterData.isServiceOptionTypeNegative(item._id)) {
          obj.total = `-${InputHelper.formatMoney2(`${item?.total || 0}`)}`;
        } else {
          obj.total = InputHelper.formatMoney2(`${item?.total || 0}`);
        }
      }
      if (obj.name) arr.push(obj);
    }
    return arr
  }

  onBtnEditCarrierCost() {
    if (this.isReadOnly) {
      return;
    }
    if (this.carrierCost?.paid?.when) {
      return this.showErr("The cost already paid for carrier. Please create additional cost."); 
    }
    CarrierCostHelper.openModalCarrierCost({
      jobId: this.jobId,
      title: 'Edit Carrier Cost',
      currentData: this.carrierCost,
      submit: (jobId, data) => {
        this.buildDisplayInfo(data);
        return this.saveCarrierCost(jobId, data);
      },
      onError: err => UIHelper.showErr(err),
      onSuccess: resp => {
        if (typeof this.saveCarrierCostSuccess == 'function') {
          this.saveCarrierCostSuccess();
        } else {
          let msg = 'Carrier cost has been updated successfully.';
          this.showDialog(msg);
          this.dispatchService.refresh();
        }
      },
    });
  }

  get shouldShowCheckbox_alreadyPaid(): boolean {
    return (this.isInDispatchScreen || this.isAdditionalCarrierCost) && this.isAccounting;
  }

  private updateValueCheckbox_alreadyPaid(carrierCost: CarrierCost) {
    this.checkBoxValue_alreadyPaid = carrierCost?.paid?.when != null;
  }

  checkBoxValue_alreadyPaid: boolean;
  isMarkingCarrierPaid = false;

  onCheckboxChange_AlreadyPaid(checkValue: boolean) {
    if (!this.shouldShowCheckbox_alreadyPaid) {
      return;
    }
    const modalService = getInjector().get(NzModalService);
    if (checkValue) {
      this.modalHelper.open(CarrierPaymentPlanning, {
        nzTitle: 'Are you sure you want to mark it as already paid?',
        nzClosable: false,
        labelBtnOK: 'Yes',
        labelBtnCancel: 'No',
        onOK: (component: CarrierPaymentPlanning) => {
          const formData = component.getFormData();
          this.markAlreadyPaid(true, formData);
        },
        onCancel: (component: CarrierPaymentPlanning) => {
          this.checkBoxValue_alreadyPaid = false;
        },
      });
    } else {
      modalService.confirm({
        nzTitle: 'Are you sure you want to mark it as not paid yet?',
        nzClosable: false,
        nzMaskClosable: false,
        nzCentered: true,
        nzOkText: 'Yes',
        nzOnOk: () => {
          this.markAlreadyPaid(false);
        },
        nzCancelText: 'No',
        nzOnCancel: () => {
          this.checkBoxValue_alreadyPaid = true;
        }
      });
    }
  }

  private markAlreadyPaid(value: boolean, data?: FormDataCarrierPaymentPlanning) {
    this.isMarkingCarrierPaid = true;
    this.saveMarkAlreadyPaid({value, ...data}).subscribe(
      resp => {
        this.carrierCost.paid = resp.data;
        this.isMarkingCarrierPaid = false;
      }, err => {
        this.showErr(err);
        this.isMarkingCarrierPaid = false;
      }
    );
  }

  onBtnNoteCarrierPaid() {
    this.modalHelper.openForm(CarrierPaymentPlanning, {
      nzTitle: 'Update note for carrier paid',
      nzClosable: false,
      onSubmitError: err => UIHelper.showErr(err),
      onSubmitSucceeded: resp => {
        this.carrierCost.paid = resp.data;
      },
      nzComponentParams: {
        model: {
          note: this.carrierCost?.paid?.note,
          transferDate: this.carrierCost?.paid?.transferDate
        },
        submit: (data) => this.updateNoteCarrierPaid(data)
      }
    });
  }

}
  
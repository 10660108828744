import { environment } from "@env/environment";

export function customMapboxConfig(mapboxgl: any) {
  if (!mapboxgl || !environment?.mapboxgl?.useProxy) return;
  const config = mapboxgl.config;
  delete config.API_URL;
  delete config.API_URL_REGEX;
  delete config.API_TILEJSON_REGEX;
  delete config.API_SPRITE_REGEX;
  delete config.API_FONTS_REGEX;
  delete config.API_STYLE_REGEX;
  delete config.API_CDN_URL_REGEX;
  delete config.EVENTS_URL;

  config.API_URL = environment?.mapboxgl?.apiUrl || "https://mapbox.dev.wearewarp.com";
  config.API_URL_REGEX = /(.*)/i;
  config.API_TILEJSON_REGEX = /(.*)/i;
  config.API_SPRITE_REGEX = /(.*)/i;
  config.API_FONTS_REGEX = /(.*)/i;
  config.API_STYLE_REGEX = /(.*)/i;
  config.API_CDN_URL_REGEX = /(.*)/i;
  config.EVENTS_URL = environment?.mapboxgl?.eventUrl || "https://mapbox.dev.wearewarp.com/events/v2";
  console.log('customMapboxConfig', config)
}
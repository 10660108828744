import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzInputModule } from "ng-zorro-antd/input";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzSpaceModule } from "ng-zorro-antd/space";
import { NzAvatarModule } from "ng-zorro-antd/avatar";
import { NzPopoverModule } from "ng-zorro-antd/popover";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NotesComponent } from './index';
import { FormInputUploadFileModule } from "../../base/form-input-upload-file/module";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NzInputModule,
    NzIconModule,
    NzSpaceModule,
    NzAvatarModule,
    NzPopoverModule,
    NzButtonModule,
    FormInputUploadFileModule
  ],
  declarations: [
    NotesComponent
  ],
  exports: [
    NotesComponent
  ]
})
export class ModuleNotes {}
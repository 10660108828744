import {Component, Input} from '@angular/core';
import {Const} from '@const/Const';
import {Utils} from '@services/utils';
import {BaseComponent} from '@abstract/BaseComponent';

@Component({
  selector: '[client-parent-account-management]',
  templateUrl: './index.html',
  styleUrls: [
    './index.scss',
    '../../../../list.scss',
    '../../../../../app.scss'
  ],
})
// Customer Parent Company Management
export class ClientParentAccountManagement extends BaseComponent {
  isLoadingList = false;
  listData = [];

  constructor() {
    super();
  }

  private _childId: string;

  get childId() {
    return this._childId;
  }

  @Input() set childId(value) {
    this._childId = value;
    this.getListParentAccounts();
  }

  getLocationCount(item) {
    if (Utils.isArrayNotEmpty(item.warehouses)) {
      return item.warehouses.length;
    }
    return 0;
  }

  private getListParentAccounts() {
    let url = Const.APIV2(`${Const.APIURI_CLIENTS}/${this.childId}/parent-client`);
    this.isLoadingList = true;
    this.api.GET(url).subscribe(
      resp => {
        this.isLoadingList = false;
        this.listData = resp?.data?.list_data?.sort((a, b) => a.name.localeCompare(b.name));;
      }, err => {
        this.isLoadingList = false;
        this.showErr(err);
      }
    );
  }
}

<div class="bottom5">
    <div class="time">{{ displayInfo.ts }}</div>
    <div class="clickable" (click)="showEvent(_event.event)">{{ displayInfo.action }}</div>

    <ng-container *ngIf="_event.event?.states?.status"><div>
        <code class="status right5" [class]="'status-' + _event.event?.fact?.status">{{ _event.event?.fact?.status }}</code>
        <span class="right5 status">to</span>
        <code class="status" [class]="'status-' + _event.event?.states?.status">{{ _event.event?.states?.status }}</code>
    </div></ng-container>

    <div *ngIf="_event.subs?.length" class="subs top5">
        <div *ngFor="let event of _event.subs">
            <event-detail-container [event]="event"></event-detail-container>
        </div> 
    </div>
</div>
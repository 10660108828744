import { BaseComponent } from "@abstract/BaseComponent";
import { Component } from "@angular/core";
import { Input } from "@angular/core";
@Component({
  selector: 'dlg-checklist-items',
  templateUrl: './view.html',
  styleUrls: ['../../../../dialogs/dialogs.scss', '../../../../app.scss']
})
export class DlgChecklistItems extends BaseComponent {

  public _items: any = []
  public summaryText: string = ""
  public showUpdateScheduleTime: boolean = false;
  public btnTitle: string = "Update Schedule Time";

  @Input() legType: string = "";
  @Input() actionName: string = "";
  @Input() set items(value) {
    this._items = value;
    this.processText();
  }
  @Input() onUpdate: (data) => void = () => { };

  private processText() {
    const countSelected = this._items?.length;
    const countInvalid = (this._items.filter(item => !item.isValid))?.length;
    const countMissingTime = (this._items.filter(item => !item.isValid && item.type == "missing_schedule_time"))?.length;
    const countValid = countSelected - countInvalid;
    this.showUpdateScheduleTime = countInvalid > 0 && countMissingTime == countInvalid;
    let btnTitle = "Update Scheduled Release Time";
    if (this.legType == "inboundLeg") {
      btnTitle = "Update Scheduled Pickup Time"
    }
    let strRecord = countSelected > 1 ? "shipments" : "shipment";
    this.btnTitle = `${btnTitle} for the above ${strRecord}`;
    let strSelected = `${countSelected} ${strRecord} selected`;
    this.summaryText = `${strSelected} (Valid: ${countValid}, Invalid: ${countInvalid})`;
  }

  public updateScheduleTime() {
    this.onUpdate(this.legType)
  }

} 
import { Component, Input } from "@angular/core";
import { Const } from "@const/Const";
import { WarehouseForm } from "@app/admin/warehouses/warehouse-form";
import { DialogService } from "@dialogs/dialog.service";
import { BaseFormItem } from "@app/admin/base/form-item";
import { Utils } from "@services/utils";

@Component({
  selector: "[client-detail-basic-info]",
  templateUrl: "./index.html",
  styleUrls: [
    "./index.scss",
    "../../../../detail.scss",
    "../../../../../../styles/row-col.scss",
    "../../../../../../styles/form-v2.scss",
  ],
})
// Customer Information
export class ClientDetailBasicInfo extends BaseFormItem {
  protected formGroupDeclaration: FormGroupDeclaration = {
    name: { label: "Company name", required: true },
    website: { label: "Company website", inputType: "url", placeHolder: "http://" },
    address: { label: "Address", required: true },
  };

  private originModel;
  @Input() set myModel(value) {
    let currentModel = this.model;
    this.model = value;
    this.originModel = {...value};
    if(currentModel) this.bindDataModel(value);
  }

  disableEditing() {
    this.setEnableFormGroup(false);
    this.isEditing = false;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.disableEditing();
  }

  protected afterBindModel(model?: any) {
    this.checkLogHistory(model?.id);
  }

  protected getApiUrl(): string {
    return Const.APIURI_CLIENTS;
  }

  protected onUpdateSuccess(resp) {
    super.onUpdateSuccess(resp);
    this.disableEditing();
    this.originModel = {...this.model};
  }

  onBtnAddLocation() {
    DialogService.openFormDialog1(WarehouseForm, {
      nzComponentParams: {
        model: location,
        client: this.model,
        closeOnSuccess: true,
      },
      nzClassName: "modal-no-padding warehouse-form",
    });
  }

  /*History feature*/

  private isHistoryExisting = false;

  public get shouldShowHistory(): boolean {
    return this.isHistoryExisting && this.model;
  }

  private subApiHistory;

  protected get crudEntity(): string {
    return "clients";
  }

  private checkLogHistory(detailId) {
    this.isHistoryExisting = false;
    this.subApiHistory?.unsubscribe();
    if (this.isCreateNew) {
      return;
    }
    let entity = this.crudEntity;
    let objectId = this.model.id;
    if (!entity || !objectId) {
      return;
    }
    let url = `${Const.APIURI_AUDIT_LOGS}/one?entity=${entity}&objectId=${objectId}`;
    this.subApiHistory = this.api.GET(url).subscribe(
      (resp) => {
        this.isHistoryExisting = Utils.isObjectNotEmpty(resp?.data);
      },
      (err) => {
        // skip
      }
    );
  }

  public goToHistoryScreen(item, routePath) {
    this.router.navigate([routePath, "history", item._id], { queryParams: { entity: this.crudEntity } });
  }

  onBtnCancel() {
    this.myModel = {...this.originModel};
    this.createFormInput(this.model);
    this.disableEditing();
  }

  isInputsChange() {
    return this.isFormDataChanged();
  }

}

import { Component } from "@angular/core";
import { BaseComponent } from "@abstract/BaseComponent";

@Component({
  selector: '[crossdock-management]',
  templateUrl: './index.html',
  styleUrls: ['./style.scss']
})
export class CrossdockManagement extends BaseComponent{

  constructor() {
    super();
  }

}
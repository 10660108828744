<div class="dashboard-child-container no-padding list">
  <div class="list-header">
    <div class="right5">
      <search-box
        #searchBox
        (doSearch)="doSearch($event)"
        placeHolder="Search by name, ID or address"
        [searchKeyword]="searchKeyword"
      ></search-box>
    </div>
    <div class="right10"></div>

    <div class="flex1"></div>
    <button *ngIf="requirePermissions([PermissionCode.comm_unsubscribes.create])" nz-button (click)="onBtnDetail()">
      <i nz-icon nzType="plus" nzTheme="outline"></i>Add new
    </button>
    <!-- <button nz-button (click)="onBtnExport()" [nzLoading]="isExporting">
      <i nz-icon nzType="file-excel" nzTheme="outline"></i>
      Export
    </button> -->
  </div>
  <div class="list-body">
    <nz-table
      #nzTable
      nzBordered="true"
      [nzData]="listData"
      nzSize="small"
      [nzTotal]="totalCount"
      [nzFrontPagination]="false"
      [nzShowPagination]="totalCount > listData.length"
      [nzPageSize]="limit"
      [nzPageIndex]="pageIndex"
      [nzLoading]="isLoading"
      [nzLoadingIndicator]="tplLoading"
      [nzNoResult]="tplNoData"
      [nzSize]="'default'"
      (nzPageIndexChange)="onDataListPageChanged($event)"
    >
      <ng-template #tplNoData>
        <div *ngIf="!isLoading" class="nodata"><i nz-icon nzType="search" nzTheme="outline"></i>No data</div>
      </ng-template>
      <ng-template #tplLoading>
        <div class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
      </ng-template>
      <thead (nzSortOrderChange)="onSortOrderChange($event)">
        <tr>
          <th nzSortOrder="null" [nzSortFn]="true" nzColumnKey="owner" nzWidth="200px">Owner</th>
          <th nzSortOrder="null" [nzSortFn]="true" nzColumnKey="type" nzWidth="200px">Type</th>
          <th nzSortOrder="null" [nzSortFn]="true" nzColumnKey="type" nzWidth="200px">Scope</th>
          <th nzSortOrder="null" [nzSortFn]="true" nzColumnKey="source" nzWidth="200px">Source</th>
          <th nzSortOrder="null" [nzSortFn]="true" nzColumnKey="insert.when" nzWidth="200px">Unsubscribe Date</th>
          <th colspan="2" *ngIf="requirePermissions([PermissionCode.comm_unsubscribes.delete])">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of listData; let i = index">
          <td>
            <a (click)="onBtnDetail(item)">{{item.owner}}</a>
          </td>
          <td>{{item.type}}</td>
          <td>{{displayScope(item.scope)}}</td>
          <td>{{displaySource(item.source)}}</td>
          <td>{{displayCreatedTime(item)}}</td>
          <td class="actions w50" *ngIf="requirePermissions([PermissionCode.comm_unsubscribes.delete])">
            <nz-space nzSize="middle">
              <button *nzSpaceItem (click)="onBtnDelItem(item)" nz-button nzType="text" nzShape="circle" nzDanger>
                <i nz-icon nzType="delete" nzTheme="outline"></i>
              </button>
            </nz-space>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</div>

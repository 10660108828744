<select-list-carrier-pools #tablePools [model]="model"></select-list-carrier-pools>

<div
  form-footer
  [onProgress]="onProgress"
  [nzIconCancel]="null"
  [nzIconOK]="null"
  [canClickOK]="canClickOK() && !onProgress"
  [canClickCancel]="!onProgress"
  labelOK="Add"
  (onOK)="updateCarrier()"
  (onCancel)="closeDialog()"
></div>
import { Component, Input, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { InputHelper } from "@services/input-helper";

@Component({
  selector: "[add-target-rate]",
  templateUrl: "./index.html",
  styleUrls: ['../../../dialogs/dialogs.scss', '../../../../styles/form-v2.scss', '../../../app.scss'],
})
export class AddTargetRate extends BaseFormDialog1 implements OnInit {
  @Input() data;
  public onSave: (data) => void = () => {}
  
  protected formGroupDeclaration: FormGroupDeclaration = {
    costRate: { label: "Target Rate" },
  };
  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.formInput.get("costRate").setValue(this.data)
  }

  public isError = false;
  public confirmData: any = {};
  public inConfirm: boolean = false;

  get canClickOK() {
    let data: any = this.getFormData_JSON(true);
    if (data.costRate && data.costRate !== this.data?.costRate) return true;
    return false;
  }

  onBtnSave() {
    let data: any = this.getFormData_JSON(true);
    this.onSave(data);
    this.closeDialog();
  }
  onBtnCancel() {
    this.closeDialog();
  }

  onInputKeyPress(event, key) {
    switch (key) {
      case 'costRate':
        return InputHelper.handleInputKeyPressNumberOnly(event);
      default:
        return super.onInputKeyPress(event, key);
    }
  }

  onInputChanged(event, key) {
    switch (key) {
      case 'costRate':
        return InputHelper.handleInputChangeNumberOnly(event, <FormControl>this.formInput.get(key));
      default:
        return super.onInputChanged(event, key);
    }
  }
}

import _ from 'underscore'
import Utils from '../utils';
import { BizUtil } from '@services/biz';
import ArcBrushingLayer from './brushing_arc_layer'

export default class ShipmentArcLayer {
    id: string
    static layerName = "ShipmentArcLayer"
    shipments: any[]
    currentTime: number = 0
    coef: number = 0
    selectShipment: (s: string) => void
    _deckglLayer: any = null
    options: any = null
    get deckglLayer() {
        return this._deckglLayer
    }

    constructor(id, data: {shipments, currentTime?: number}, options: {selectShipment?: any, animation?: number, baseOpacity?: number}) {
        this.id = id
        this.shipments = data.shipments
        this.currentTime = data.currentTime || 0
        this.options = options
        this.selectShipment = options.selectShipment
        this._deckglLayer = this.createShipmentArcLayer()
    }

    static getSourceColor(item, mile) {
        if (item.selected) return [0, 255, 255]
        return mile === 'last' ? [14, 160, 98] : [44, 68, 230]
    }

    static getTargetColor(item, mile) {
        if (item.selected) return [0, 200, 200]
        return mile === 'first' ? [14, 160, 98]:[200, 90, 46]
    }

    static getInfo(item) {
        const { shipment } = item
        const pickup = BizUtil.getPickInfo(shipment)
        const dropoff = BizUtil.getDropInfo(shipment)
        const pickupAddr = pickup?.addr
        const dropoffAddr = dropoff?.addr
        if (!pickupAddr || !dropoffAddr) return null

        const metadata: any = shipment.metadata
        const client = shipment.client || shipment.metadata?.client
        const { traffic, workload } = metadata || {}
        const { distance } = traffic || {}
        const mileage = distance ? (distance / 1609.34).toFixed(1) : null

        const pickupTime = Utils.getDisplayTime(pickup, null)
        const dropoffTime = Utils.getDisplayTime(dropoff, null)

        const status = (shipment.lastJobId ? 'routed + ' : '') + `${shipment.status || ''}`

        return (shipment.warpId ? `Shipment ${shipment.warpId}\n` : '') +
            (client?.name ? `Customer: ${client?.name}\n` : '') +
            (pickupAddr ? `Origin: ${pickupAddr.street}, ${pickupAddr.state} ${pickupAddr.zipcode}\n` : '') +
            (dropoffAddr ? `Dest: ${dropoffAddr.street}, ${dropoffAddr.state} ${dropoffAddr.zipcode}\n` : '') +
            `${status || ''}` +
            (mileage ? `\nMileage: ${mileage}mi` : '') +
            (workload ? `\n${workload} Pallets` : '') +
            (pickupTime ? `\nPickup  ${pickupTime}` : '') +
            (dropoffTime ? `\nDropoff ${dropoffTime}` : '')
    }

    static prepareArcData(shipments) {
        const byLane = {}
        for (let shipment of shipments) {
            const pickup = shipment.deliveryInfos.filter(x => x.type === 'PICKUP')[0]?.addr
            const dropoff = shipment.deliveryInfos.filter(x => x.type === 'DROPOFF')[0]?.addr
            if (!pickup || !dropoff) continue
            const fromLocId = Utils.calculateAddressLocationId(pickup)
            const toLocId = Utils.calculateAddressLocationId(dropoff)
            const lane = [fromLocId, toLocId].sort().join('<>')
            const existing = byLane[lane] || []
            existing.push(shipment.id)
            byLane[lane] = existing
        }

        const tilts = {}
        const heights = {}
        for (const k in byLane) {
            const l = byLane[k]
            let inc = 0
            if (l.length == 1) {
                inc = 0
            } else if (l.length == 2) {
                inc = 30
            } else if (l.length == 3 || l.length == 4) {
                inc = 15
            } else {
                inc = 90 / l.length
            }
            for (let i = 0; i < l.length; i++) {
                tilts[l[i]] = inc * (i - (l.length - 1) / 2)
                heights[l[i]] = 0.5 + inc * (i - (l.length - 1) / 2) / 180
            }
        }

        return shipments.map(it => {
            const metadata: any = it.metadata
            const { mile } = metadata || {}
            const pickup = BizUtil.getPickInfo(it)
            const dropoff = BizUtil.getDropInfo(it)
            const pickupAddr = pickup?.addr
            const dropoffAddr = dropoff?.addr
            if (!pickupAddr || !dropoffAddr) return null

            if (!pickupAddr?.metadata?.longitude) return null
            if (!dropoffAddr?.metadata?.longitude) return null
            return {
                warpId: it.warpId,
                id: it.id,
                type: 'SHIPMENT',
                from: [pickupAddr.metadata.longitude, pickupAddr.metadata.latitude],
                to: [dropoffAddr.metadata.longitude, dropoffAddr.metadata.latitude],
                sourceColor: ShipmentArcLayer.getSourceColor(it, mile),
                targetColor: ShipmentArcLayer.getTargetColor(it, mile),
                width: 2,
                getInfo: this.getInfo,
                tilt: tilts[it.id],
                height: heights[it.id],
                shipment: it,
                // width: it.selected ? 5 : 2
            }
        }).filter(it => it)
    }

    animate(animation, callback?: any) {
        this.options = Object.assign(this.options, {animation})
        this.options.callback = callback
    }

    tic() {
        if (this.options?.animation === 0) return false
        if (this.options?.animation === undefined) return false

        this.currentTime += 5
        if (this.options.animation > 0) {
            if (this.currentTime > this.options.animation * 100) {
                // stop
                this.options.animation = 0
                this._deckglLayer = this.createShipmentArcLayer()
                if (this.options.callback) {
                    this.options.callback()
                }
                return true
            }
        }

        const animation = Math.abs(this.options.animation)
        let t = this.currentTime % (animation * 100)
        const coef = animation === 1 ? t / 100 : (Math.min(t, 200) - 100) / 100
        if (this.coef === coef) return false
        this.coef = coef
        this._deckglLayer = this.createShipmentArcLayer()
        return true
    }

    createShipmentArcLayer() {
        return new ArcBrushingLayer({
            id: `${this.id}-arc`,
            data: this.shipments,
            pickable: true,
            getWidth: d => d.width,
            getSourcePosition: d => d.from,
            getTargetPosition: d => d.to,
            getHeight: d => d.height,
            getTilt: d => d.tilt,
            coef: this.options.animation ? this.coef : 1.0,
            baseOpacity: this.options?.baseOpacity ?? 0.0,
            numSegments: 180,
            getSourceColor: d => d.sourceColor,
            getTargetColor: d => d.targetColor,
            greatCircle: true,
        });
    }
}
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { BaseComponent } from '@abstract/BaseComponent';
import { VehicleTypeService } from '@services/vehicle.service';
import { VehicleType } from '@wearewarp/types/data-model';
import DeliveryMap from '../map';

@Component({
  selector: '[map-config]',
  templateUrl: './index.html',
  styleUrls: ['./index.scss',]
})
export class MapConfig extends BaseComponent {
    @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

    _map: DeliveryMap = null
    get map() {
        return this._map
    }
    @Input() set map(v: DeliveryMap) {
        this._map = v
        this.value = v?.config
    }

    style: string = "compact"

    value?: any = {}

    toggleStyle() {
        if (this.style === 'compact') {
            this.value = this._map.config
            this.style = 'expanded'
        } else {
            this.style = 'compact'
        }
    }

    onConfigUpdate(key) {
        this.map?.updateConfig(key, this.value[key])
    }

    setPitch(pitch) {
        this.map?.setPitch(pitch)
    }
}
import { Component, EventEmitter, Output } from "@angular/core";
import { BaseFormItem } from "@app/admin/base/form-item";
import { ActivatedRoute } from "@angular/router";
import { Const } from "@const/Const";

@Component({
  selector: '[carrier-pool-filter]',
  templateUrl: './index.html',
  styleUrls: ['./index.scss', '../../../../../../app.scss', '../../../../../../dialogs/dialogs.scss']
})
export class CarrierPoolFilter extends BaseFormItem {
  @Output() onFilter: EventEmitter<any> = new EventEmitter<any>();
  public static get declaration(): FormGroupDeclaration {
    return {
      searchKeyword: { label: 'Keyword', placeHolder: 'Search by name', notAcceptEmpty: true },
      isDedicated: { label: 'Dedicated', notAcceptEmpty: true, placeHolder: 'Select from list' },
      vehicleTypes: { label: 'Vehicle', notAcceptEmpty: true, placeHolder: 'Select from list' },
      areaIds: { label: 'Area', notAcceptEmpty: true, placeHolder: 'Select from list' },
    }
  };
  protected formGroupDeclaration: FormGroupDeclaration = CarrierPoolFilter.declaration;

  public condition: any = {}
  public searchKeyword: string = null
  public loaded: number = 0
  public page: number = 1
  listDedicated = [
    { id: "yes", name: "TRUE" },
    { id: "no", name: "FALSE" },
  ];
  listVehicles = [];
  listAreas = [];

  constructor(protected activatedRoute: ActivatedRoute) {
    super();
  }

  doSearch(s) {
    this.searchKeyword = s
    this.loadData()
  }

  onRefresh() {
    this.loadData()
  }

  private loadData() {
    const data = { ...this.condition, searchKeyword: this.searchKeyword }
    this.onFilter.emit(data)
  }

  onChange(e, name) {
    if (e)
      this.condition[name] = e
    else
      delete this.condition[name]
    this.loadData()
  }

  ngOnInit(): void {
    this.model = Object.assign({}, this.condition, { search: this.searchKeyword })
    super.ngOnInit();
    this.fetchSelections();
  }

  public filterLoading = false;
  private fetchSelections() {
    let url = Const.APIV2(`${Const.APIURI_POOLS}/summary/carrier-pools`);
    this.filterLoading = true;
    this.api.GET(url).subscribe(
      resp => {
        this.listVehicles = resp.data?.vehicles || [];
        this.listAreas = resp.data?.areas || [];
        this.filterLoading = false;
      }, err => {
        this.showErr(err);
        this.filterLoading = false;
      }
    );
  }

  getVehicleName(item: any) {
    let str = item?.name || item.id;
    let parentName = item?.parentName || item?.parent || "";
    if (parentName) str = `${str} (${parentName})`;
    return str
  }

  getAreaName(item: any) {
    return item?.name || ""
  }
}

import { BaseComponent } from "@abstract/BaseComponent";
import { Component, Input } from "@angular/core";
import { environment } from "@env/environment";

@Component({
    selector: "raw-event-json-container",
    templateUrl: "./raw.html",
    styleUrls: ["./raw.scss"],
})
export class RawEventJsonContainer extends BaseComponent {
    _event: any
    processingResults: any[] = null
    @Input()
    public set event(v) {
        this._event = v;
        this.loadProcessingResult();
    }

    loadProcessingResult() {
        let url = `${environment.eventUrl}/events/${this._event.id}/processing-results`;
        this.api.GET(url).subscribe((res) => {
            for (let r of res) {
                const comps = r.handlerId.split(".")
                const name = comps[comps.length - 1]
                let packageName = ""
                for (let i = 0; i < comps.length - 1; i++) {
                    packageName += comps[i].substring(0, 1) + "."
                }
                r.handlerId = packageName + name
            }
            this.processingResults = res
        })
    }
}  
import {BaseList} from '@base/list';
import {Component, ViewChild} from '@angular/core';
import {Const} from '@app/const/Const';
import {Log} from "@services/log";
import {Utils} from "@services/utils";
import { DateUtil } from '@services/date-utils';
@Component({
  selector: 'truck-list',
  templateUrl: './list.html',
  styleUrls: ['./truck-list.scss', '../../list.scss', '../../../app.scss']
})
export class DatTruckList extends BaseList {
  sortOrderAge: string;
  sortOrderRate: string;
  sortOrderAvailable: string;
  sortOrderTrip: string;
  sortOrderOrigin: string;
  sortOrderDho: string;
  sortOrderDestination: string;
  sortOrderDhd: string;

  equipmentTypes: any = [];

  loadTypes = [
    { value: "BOTH", label: "Full & Partial"},
    { value: "FULL", label: "Full"},
    { value: "PARTIAL", label: "Partial"}
  ]

  expandSet = new Set<number>();

  constructor() {
    super();
  }

  protected getApiUrl(): string {
    return Const.APIURI_DAT_V2 + '/search/truck';
  }

  protected getData() {  }

  onSearch(params) {
    if (!params || !params.filter) return;
    this.sendRequest(params);
  }

  private sendRequest(params) {
    let apiUrl = this.getApiUrl();
    if (!apiUrl) {
      Log.e(`[${this.TAG}] getData but apiUrl is empty`);
      return;
    }
    this.isLoading = true;
    this.api.POST(this.api.buildUrl(apiUrl, this.apiVersion), params.filter).subscribe(
      resp => {
        Log.d('get list data done: ', resp);
        this.getDataDone(resp);
      },
      err => {
        this.showErr(err);
        this.isLoading = false;
      },
      () => { // Complete callback
        this.isLoading = false;
      }
    );
  }

  protected handleNavigationEnd(url: string, prevQueryParam: any) {
    this.pageIndex = Utils.toNumber(this.queryParams.page, 1);
    // this.getData();
  }

  ngOnInit(): void {
      super.ngOnInit();
      this.getEquipmentTypes();
  }

  onExpandChange(id: number, checked: boolean): void {
    if (checked) {
      this.expandSet.add(id);
    } else {
      this.expandSet.delete(id);
    }
  }

  async getEquipmentTypes() {
    this.api.GET(`${Const.APIURI_DAT_V2}/list-equipments`).subscribe(
      (resp) => {
        console.log('resp')
        console.log(resp)
        this.equipmentTypes = resp.data?.list_data || [];
      },
      (err) => {
        this.showErr(err);
      }
    );
  }

  getAddress(data) {
    if (data.open) {
      return 'Anywhere';
    }
    if (data.city && data.stateProv) {
      return `${data.city}, ${data.stateProv}`;
    }
    if (data.area) {
      if (data.area.zones) {
        return data.area.zones.join(', ');
      }
      if (data.area.states) {
        return data.area.states.join(', ');
      }
    }

    return "N/A";
  }

  getPosterState(data) {
    return data.state || data.carrierHomeState || "N/A";
  }

  getEventDuration(evenTime) {
    const now = Date.now();
    const duration = now - new Date(evenTime).getTime();
    return DateUtil.displayDuration(duration, {skipSecond: true});
  }

  getDate(data) {
    return DateUtil.formatDate(data, 'MM/DD/YYYY');
  }

  getEquipment(type: string) {
    const equipment = this.equipmentTypes.find(t => t.value === type);
    return equipment ? `${type} | ${equipment.label}` : type;
  }

  getCapacity(capacity: any) {
    return `${capacity.availableLengthFeet || '-'} ft | ${capacity.availableWeightPounds || '-'} lbs | ${this.getLoadType(capacity.fullPartial)}`;
  }

  getLoadType(type: string) {
    return this.loadTypes.find(t => t.value === type)?.label || type;
  }
}

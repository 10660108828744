<form [formGroup]="formInput" nz-form class="filterform" *ngIf="formInput">
  <div class="form-inner-box">
    <div *ngFor="let key of formInputKeys" class="form-item {{key}}">
      <div class="form-label-v2 single-line fix-height">{{getLabel(key)}}</div>
      <ng-container *ngTemplateOutlet="getTemplateFilterField(key);context:{key, form: formInput}"></ng-container>
    </div>

    <div class="form-item" *ngIf="shouldClearFilter">
      <div class="form-label-v2 single-line fix-height"></div>
      <a nz-button nzType="link" (click)="onBtnClearFilter()">Clear Filter</a>
    </div>
  </div>
</form>
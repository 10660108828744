<div nz-row>
  <div nz-col nzSpan="7" style="height: fit-content; padding-bottom: 15px;">
    <search-box #searchBox (doSearch)="doSearch($event)" [placeHolder]="'Search by Location, Item ID, Shipment ID, Customer Name'" style="width: 100%;"></search-box>
  </div>
</div>
<div nz-row>
  <div nz-col nzSpan="24" class="map-background" style="height: fit-content;">
    <div >
      <nz-table #nzTable [nzData]="listInventoryOnTable" [nzPageSize]="10" >
        <thead>
          <tr>
            <th>Location</th>
            <th>Item ID</th>
            <th>Barcode</th>
            <th>Quantity</th>
            <th>Weight</th>
            <th>Customer</th>
            <th>Order ID</th>
            <th>Shipment ID</th>
            <th>Leg ID</th>
            <th>To City</th>
            <th>Date Received</th>
            <!-- <th nzWidth="120px">Action</th> -->
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let item of nzTable.data; let i = index">
            <tr>
              <td>{{item?.inventoryName}}</td>
              <td>{{item?.warpId}}</td>
              <td>{{item?.barcode}}</td>
              <td>{{item?.quantityWithUnit}}</td>
              <td>{{item?.weight}}</td>
              <td>{{item?.clientName}}</td>
              <td>
                <div *ngIf="item?.orderWarpId" class="detail"> 
                  <a [href]="getLinkToShipmentDetail(item)" target="_blank">{{item?.orderWarpId}}</a>
                </div>
                <div *ngIf="!item?.orderWarpId">{{naText}}</div>
              </td>
              <td>
                <div *ngIf="item?.shipmentWarpId" class="detail"> 
                  <a [href]="getLinkToShipmentDetail(item)" target="_blank">{{item?.shipmentWarpId}}</a>
                </div>
                <div *ngIf="!item?.shipmentWarpId">{{naText}}</div>
              </td>
              <td>{{item?.legWarpId}}</td>
              <td>{{item?.toCity}}</td>
              <td>{{item?.dateReceived}}</td>
              <td>
                <!-- <div class="remove-item">
                  <a (click)="removeItem(selectedInventoryInfo, item)">
                    <span nz-icon nzType="logout" nzTheme="outline"></span>
                     Remove
                  </a>
                </div> -->
              </td>
            </tr>
          </ng-container>
        </tbody>
      </nz-table>
    </div>
  </div>
</div>
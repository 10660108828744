<nz-form-item>
  <nz-form-control>
    <nz-select [nzDisabled]="isDisabled" [(ngModel)]="value" nzBackdrop="true" nzOptionHeightPx="55" style="width: 100%;" [nzLoading]="isLoading"
      [nzShowSearch]="true" nzAllowClear nzPlaceHolder="Please select carriers" nzDropdownClassName="choose-carrier">
      <nz-option nzCustomContent *ngFor="let carrier of allCarriers" [nzLabel]="carrier.label" [nzValue]="carrier.id">
        <div>{{carrier.name}}</div>
        <div *ngIf="carrier.basicInfoMC" class="sub-info">MC: {{carrier.basicInfoMC}}</div>
        <div *ngIf="carrier.basicInfoDOT" class="sub-info">DOT: {{carrier.basicInfoDOT}}</div>
      </nz-option>
    </nz-select>
  </nz-form-control>
</nz-form-item>

import moment from 'moment-timezone';
import {Const} from '@const/Const';
import {DateUtil} from '@services/date-utils';

export class XDockDateHelper {
  public static getLocalMoment(warehouseDate, warehouseTimezone) {
    const localTimezone = moment.tz.guess();
    const localOffset = moment.tz(localTimezone).utcOffset();
    const warehouseOffset = moment.tz(warehouseTimezone).utcOffset();
    const offsetDifferenceMinute = warehouseOffset - localOffset;
    const localMoment = moment.tz(warehouseDate, warehouseTimezone)
      .add(offsetDifferenceMinute, 'minute').clone().tz(localTimezone);
    return localMoment;
  }

  public static getWarehouseMoment(localDate, warehouseTimezone) {
    const localTimezone = moment.tz.guess();
    const localOffset = moment.tz(localTimezone).utcOffset();
    const warehouseOffset = moment.tz(warehouseTimezone).utcOffset();
    const offsetDifferenceMinute = localOffset - warehouseOffset;
    const warehouseMoment = moment.tz(localDate, localTimezone)
      .add(offsetDifferenceMinute, 'minute').clone().tz(warehouseTimezone);
    return warehouseMoment;
  }

  public static getDurationText(warehouseStartDate: any, warehouseEndDate: any, warehouseTimezone: string): string {
    const startMoment = XDockDateHelper.getLocalMoment(warehouseStartDate, warehouseTimezone);
    const endMoment = XDockDateHelper.getLocalMoment(warehouseEndDate, warehouseTimezone);
    const startDate = startMoment.format(Const.FORMAT_GUI_DATE_V2);
    const endDate = endMoment.format(Const.FORMAT_GUI_DATE_V2);
    return `${startDate} → ${endDate}`;
  }

  public static timezoneText(timezoneStandard: string): string {
    if (!timezoneStandard) {
      return '';
    }
    const offset = moment.tz(timezoneStandard).utcOffset()/60;
    return `${timezoneStandard} (${DateUtil.timezoneStandardToUsShort(timezoneStandard)} UTC/GMT${offset})`;
  }

}

<div class="dashboard-child-container no-padding list">
  <div style="margin-bottom: 20px;display: flex;width: 100%;align-items: flex-end;justify-content: space-between;">
    <div style="margin-left: 4px">Total: {{listData.length}}</div>
    <div>
      <button (click)="onBtnAddSubAccount()" *ngIf="requirePermissions([PermissionCode.client.addSubAccount])" nz-button style="height: 32px">
        <i nz-icon nzTheme="outline" nzType="plus"></i>Add Sub Account
      </button>
    </div>
  </div>
  <div class="list-body">
    <nz-table
      #nzTable
      [nzData]="listData"
      [nzFrontPagination]="true"
      [nzLoadingIndicator]="tplLoading"
      [nzLoading]="isLoadingList"
      [nzNoResult]="tplNoData"
      [nzPageSize]="listData.length"
      [nzShowPagination]="false"
      [nzSize]="'default'"
      [nzTotal]="listData.length"
      nzBordered="true"
      nzSize="small"
    >
      <ng-template #tplNoData>
        <div *ngIf="!isLoadingList" class="nodata"><i nz-icon nzTheme="outline" nzType="search"></i>
          No result. Click 'Add Sub-Account' to add sub-account.
        </div>
      </ng-template>
      <ng-template #tplLoading>
        <div class="nodata"><i nz-icon nzTheme="outline" nzType="loading"></i></div>
      </ng-template>
      <thead>
      <tr>
        <th>WARP ID</th>
        <th>Name</th>
        <th>Address</th>
        <th>Number of locations</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let item of nzTable.data; let i = index">
        <td>
          <a [routerLink]="[routeAdminClientList, item.id]" class="inherit">{{item.warpId}}</a>
        </td>
        <td>
          <a [routerLink]="[routeAdminClientList, item.id]">{{item.name}}</a>
        </td>
        <td>{{getAddressText(item.address)}}</td>
        <td>
          <a [routerLink]="[routeAdminClientList, item.id, 'locations']">{{getLocationCount(item)}}</a>
        </td>
      </tr>
      </tbody>
    </nz-table>
  </div>
</div>

import { Component, Input } from "@angular/core";
import { Const } from "@const/Const";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { Log } from "@services/log";

@Component({
  selector: "re-assign-dispatcher",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})
export class ReAssignDispatcherComponent extends BaseFormDialog1 {

  public users;
  public isError = false;
  public isLoading = false;
  public currentPicId;
  public newPicId;

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.getData();
  }

  get canClickOK(): boolean {
    if (this.onProgress) return false;
    if (!this.currentPicId || !this.newPicId) return false;
    return this.currentPicId != this.newPicId;
  }

  get labelOK(): string {
    return 'Confirm';
  }

  private getData() {
    this.isError = false;
    this.isLoading = true;
    this.api.getListAdminUsers().subscribe(
      (resp) => {
        this.users = resp?.data?.list_data ?? [];
        this.isLoading = false;
      },
      (err) => {
        this.showErr(err);
        this.isLoading = false;
        this.isError = true;
      }
    );
  }

  public onBtnSave() {
    if (!this.canClickOK) return;
    let data = {
      currentPicId: this.currentPicId,
      newPicId: this.newPicId
    }
    this.startProgress();
    this.api.POST(`${Const.APIURI_JOBS}/re-assign-dispatcher`, data).subscribe(
      resp => {
        this.stopProgress();
        this.updateSuccess(resp);
        this.showDialog("You have successfully reassigned person in charge.");
        this.closeDialog();
      }, err => {
        Log.e(err);
        this.stopProgress();
        this.closeDialog()
      }
    );
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzTableModule } from 'ng-zorro-antd/table';
import { AppRating } from '.';
import { FromRatingDialog } from './rate-dlg';
import { CreateByUserModule } from '../create-by-user/module';
import { RouterModule } from '@angular/router';
import { DisplayRateInfo } from './display-rate/comp';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NzFormModule,
    NzInputModule,
    NzPopoverModule,
    NzTableModule,
    RouterModule,
    CreateByUserModule,
  ],
  declarations: [
    AppRating,
    FromRatingDialog,
    DisplayRateInfo,
  ],
  exports: [
    AppRating,
    DisplayRateInfo,
  ]
})
export class ModuleRating {}
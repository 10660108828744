import { BaseComponent } from "@abstract/BaseComponent";
import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Const } from "@const/Const";
import { DialogService } from "@dialogs/dialog.service";
import { DrawerService } from "@app/drawers/drawer.service";
import { WarehouseFormV2 } from "../warehouses/warehouse-form-v2";

@Component({
  selector: 'list-location',
  templateUrl: './list-location.html',
  styleUrls: ['../list.scss']
})
export class ListLocation extends BaseComponent {
  public isLoading = false;
  public listData = [];
  public get totalCount() {return this.listData.length}
  public customer;

  constructor(protected activatedRoute: ActivatedRoute) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    let customerId = this.activatedRoute.snapshot.params['id'];
    this.getData(customerId);
  }

  private getData(customerId) {
    this.isLoading = true;
    let urls = [
      `${Const.APIURI_CLIENTS}/${customerId}`,
      `${Const.APIURI_WAREHOUSES}?clientIds=${customerId}&limit=-1`
    ];
    this.api.concurrentGET(urls).subscribe(
      resp => {
        this.customer = resp[0].data;
        this.listData = resp[1].data.list_data;
        this.isLoading = false;
      }, err => {
        this.showErr(err);
        this.isLoading = false;
      }
    );
  }
  
  private updateWarehousesSucceeded(resp) {
    let newData = resp.data;
    for (let i = 0; i < this.listData.length; i++) {
      if (this.listData[i].id == newData.id) {
        this.listData[i] = newData;
        break;
      }
    }
  }

  private createWarehousesSucceeded(resp) {
    this.listData.push(resp.data);
  }

  // item null means creating new
  onBtnDetail(item = null) {
    DrawerService.openFormDrawer1(WarehouseFormV2, {
      nzContentParams: {
        createSuccess: this.createWarehousesSucceeded.bind(this),
        updateSuccess: this.updateWarehousesSucceeded.bind(this),
        client: this.customer,
        model: item,
        closeOnSuccess: true
      },
      nzWidth: 1000,
    });
  }

  onBtnDel(item) {
    this.confirmDeletion({
      message: `Delete location <b>${item.name}</b>?`,
      fnOk: () => {
        // Perform delete
      }
    })
  }
}
import {Component, Input} from '@angular/core';
import { Const } from "@const/Const";
import { Validators } from "@angular/forms";
import { Utils } from "@services/utils";
import { InputHelper } from "@services/input-helper";
import { BaseFormItem } from "@app/admin/base/form-item";

@Component({
  selector: "[client-detail-contact]",
  templateUrl: "./index.html",
  styleUrls: [
    "./index.scss",
    "../../../../detail.scss",
    "../../../../../../styles/row-col.scss",
    "../../../../../../styles/form-v2.scss",
  ],
})
// Contact Information
export class ClientDetailContact extends BaseFormItem {
  protected formGroupDeclaration: FormGroupDeclaration = {
    primaryContact: {
      label: "",
      type: "formGroup",
      childItem: {
        firstName: { label: "First Name", required: true },
        lastName: { label: "Last Name", required: true },
        phone: {
          label: "Phone",
          required: true,
          inputType: "tel",
          getValue: InputHelper.getValuePhone,
          formatValue: InputHelper.formatPhone,
        },
        phoneExtension: {label: '', placeHolder: 'Ext'},
        email: { label: "Email", required: true, validators: Validators.email },
        slackId: { label: "Slack Channel ID" },
        isPayableContact: { label: "Send invoice to above contact.", type: "boolean", initialValue: true },
      },
    },
    payableContact: {
      label: "",
      type: "formGroup",
      hidden: true,
      childItem: {
        firstName: { label: "First Name", required: true },
        lastName: { label: "Last Name", required: true },
        phone: {
          label: "Phone",
          required: true,
          inputType: "tel",
          getValue: InputHelper.getValuePhone,
          formatValue: InputHelper.formatPhone,
        },
        phoneExtension: {label: '', placeHolder: 'Ext'},
        email: { label: "Email", required: true, validators: Validators.email },
        slackId: { label: "Slack Channel ID" },
      },
    },
  };

  private originModel;
  @Input() set myModel(value) {
    let currentModel = this.model;
    this.model = value;
    this.originModel = {...value};
    if(currentModel) this.bindDataModel(value);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.disableEditing();
  }

  disableEditing() {
    this.setEnableFormGroup(false);
    this.isEditing = false;
  }

  protected getApiUrl(): string {
    return Const.APIURI_CLIENTS;
  }

  protected beforeBindModel(model: any) {
    let isPayableContact = true; // default value is true
    if (model?.primaryContact) {
      isPayableContact = !!model.primaryContact.isPayableContact;
    }
    (<FormGroupDeclaration>this.formGroupDeclaration.primaryContact.childItem).isPayableContact.initialValue =
      isPayableContact;
    this.formGroupDeclaration.payableContact.hidden = isPayableContact;
    return model;
  }

  protected onUpdateSuccess(resp) {
    super.onUpdateSuccess(resp);
    this.disableEditing();
    this.originModel = {...this.model};
  }

  onCheckBoxPayableContactChange(value) {
    if (this.isEditOrCreate) {
      let changedData = this.getFormData_JSON(this.isCreateNew);
      let currentData;
      if (this.isCreateNew) {
        currentData = changedData;
      } else {
        currentData = Object.assign(Utils.cloneObject(this.model), changedData);
      }
      if (!currentData.primaryContact) {
        currentData.primaryContact = {};
      }
      currentData.primaryContact.isPayableContact = value;
      this.createFormInput(currentData);
      this.setEnableFormGroup(true);
    }
  }

  onBtnCancel() {
    this.myModel = {...this.originModel};
    this.createFormInput(this.model);
    this.disableEditing();
  }

  isInputsChange() {
    return this.isFormDataChanged();
  }
}

import {Component, Input} from '@angular/core';
import {Const} from '@const/Const';
import {Utils} from '@services/utils';
import {BaseComponent} from '@abstract/BaseComponent';
@Component({
  selector: '[client-sub-account-management]',
  templateUrl: './index.html',
  styleUrls: [
    './index.scss',
    '../../../../list.scss',
    '../../../../../app.scss'
  ],
})
// Customer Sub Accounts Management
export class ClientSubAccountManagement extends BaseComponent {
  isLoadingList = false;
  listData = [];

  constructor() {
    super();
  }

  private _parentId: string;

  get parentId() {
    return this._parentId;
  }

  @Input() set parentId(value) {
    this._parentId = value;
    this.getListSubAccounts();
  }

  getLocationCount(item) {
    if (Utils.isArrayNotEmpty(item.warehouses)) {
      return item.warehouses.length;
    }
    return 0;
  }

  onBtnAddSubAccount() {
    if (this.isAdminReadOnlyRole) {
      return;
    }
    this.router.navigate([this.routeAdminClientList, 'add'],{ queryParams: {parentClient:this._parentId}});
  }

  private getListSubAccounts() {
    let url = Const.APIV2(`${Const.APIURI_CLIENTS}/${this.parentId}/sub-client`);
    this.isLoadingList = true;
    this.api.GET(url).subscribe(
      resp => {
        this.isLoadingList = false;
        this.listData = resp?.data?.list_data?.sort((a, b) => a.name.localeCompare(b.name));
      }, err => {
        this.isLoadingList = false;
        this.showErr(err);
      }
    );
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { FilterLayout } from '.';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NzFormModule,
    NzButtonModule,
  ],
  declarations: [
    FilterLayout,
  ],
  exports: [
    FilterLayout,
  ],
  providers: [
  ]
})
export class ModuleFilterLayout {}
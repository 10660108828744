<ng-template [nzModalTitle]>
  <div class="flex-space-between">
    <div>Edit Mode & Settings</div>
    <div>
      <span nz-icon nzType="close" nzTheme="outline" class="clickable" (click)="closeDialog()"></span>
    </div>
  </div>
</ng-template>

<form *ngIf="formInput" class="form-detail" nz-form [formGroup]="formInput">
  <div>
    <div class="form-label-v2 bottom5">
      {{getLabel('type')}}<span *ngIf="isRequired('type')" class="label-mark-required"></span>
    </div>
    <nz-form-item>
      <nz-form-control>
        <nz-select formControlName="type" (ngModelChange)="onInputChanged($event, 'type')">
          <nz-option *ngFor="let option of carrierBIDType" [nzLabel]="getLabelType(option)" [nzValue]="option">
          </nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
  </div>
  <div>
    <div class="bottom5"><b>Settings</b></div>
    <div class="error" *ngIf="isSourceMarketPlace">Note: CAN NOT change WARP Offer for carrier bid relate to Marketplace Order</div>
    <div class="error" *ngIf="!isAllowPlaceBid && !isShowWarpOffer">You need to select at least one option(Allow the carrier place bid or Show WARP Offer) below.</div>
  </div>
  <div>
    <nz-form-control>
      <nz-form-control>
        <div nz-checkbox formControlName="isAllowPlaceBid">
          Allow the carrier place bid
        </div>
      </nz-form-control>
    </nz-form-control>
  </div>
  <div>
    <nz-form-control>
      <nz-form-control>
        <div nz-checkbox formControlName="isShowBasePrice">
          Show WARP Offer
        </div>
      </nz-form-control>
    </nz-form-control>
  </div>
  <div *ngIf="isShowWarpOffer">
    <nz-form-label>
      WARP Offer (Base Price)
      <span *ngIf="isRequired('basePrice')" class="label-mark-required"></span>
    </nz-form-label>
    <nz-form-control>
      <input nz-input [placeholder]="getPlaceHolder('basePrice')" (input)="onInputChanged($event, 'basePrice')"
        formControlName="basePrice" />
    </nz-form-control>
  </div>
  <div>
    <nz-form-control>
      <nz-form-control>
        <div nz-checkbox formControlName="isShowOnBidBoard">
          {{getLabel("isShowOnBidBoard")}}
          <span style="margin-left: 10px;"
            nz-icon nzType="info-circle" nzTheme="outline"
            nz-tooltip nzTooltipTitle="Allow all the carries, including unmatched, to see the load on their Load Board">
          </span>
        </div>
      </nz-form-control>
    </nz-form-control>
  </div>
  <div>
    <nz-form-control>
      <nz-form-control>
        <div nz-checkbox formControlName="isRequireCarrierAcceptLoadTender">
          {{getLabel("isRequireCarrierAcceptLoadTender")}}
        </div>
      </nz-form-control>
    </nz-form-control>
  </div>
</form>

<div form-footer class="no-border"
  [canClickOK]="needUpdate" 
  [canClickCancel]="true"
  [onProgress]="onProgress"
  [nzIconOK]="false" [nzIconCancel]="false" labelOK="Update" nzOkDanger="false" (onOK)="onBtnSave()"
  (onCancel)="closeDialog()">
</div>

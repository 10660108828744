<span *ngIf="style == 'compact'" class="compact" (click)="toggleStyle()">
    <button nz-button nzShape="circle" nzSize="small">...</button>
</span>
<div *ngIf="style=='expanded'" class="expanded">
    <div>
        <label nz-checkbox [(ngModel)]="value.hideArc" (ngModelChange)="onConfigUpdate('hideArc')">Hide Shipment Arc</label>
    </div>
    <div>
        <label nz-checkbox [(ngModel)]="value.animation" (ngModelChange)="onConfigUpdate('animation')">Animation</label>
    </div>
    <div>
        <button nz-button class="right10" (click)="setPitch(0)">2D</button>
        <button nz-button (click)="setPitch(60)">3D</button>
    </div>
    <button style="width: 100%;" nz-button (click)="toggleStyle()">Close</button>
</div>
<div *ngIf="isLoading" class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
<div *ngIf="isError" class="nodata">Cannot fetch data, please try again later</div>

<div class="comm-log-selection" *ngIf="!isLoading">
  <div class="content">
    <div *ngIf="listChange.length > 0">
      <div class="timeline">
        <nz-timeline>
          <ng-container *ngFor="let item of listChange">
            <nz-timeline-item [nzColor]="item.color">
              <div class="date" [innerHTML]="item.date"></div>
              <div class="message" [innerHTML]="item.message"></div>
            </nz-timeline-item>
          </ng-container>
        </nz-timeline>
      </div>
    </div>
    <div *ngIf="listChange.length == 0 && isLoading == false">
      <nz-empty></nz-empty>
    </div>
  </div>
</div>
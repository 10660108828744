<style type="text/css">
  .model-btns {
    display: flex; flex-direction: row; align-items: center; justify-content: flex-end;
  }
  button:focus {
    outline: none;
    box-shadow: none;
    text-decoration: none;
  }
</style>
<form class="form-detail" [formGroup]="formInput" nz-form nzLayout="vertical">
  <div style="margin-bottom: 20px">
    <div class="title">Update Target Rate</div>
  </div>
  <nz-form-item>
    <nz-form-control> 
      <nz-input-group>
        <input 
          nz-input 
          formControlName="costRate"
          (input)="onInputChanged($event, 'costRate')" 
          (keypress)="onInputKeyPress($event, 'costRate')"
        >
      </nz-input-group>
    </nz-form-control>
  </nz-form-item>
  
</form>

<div class="model-btns">
  <button nz-button nzType="default"  style="width: 110px;" (click)="onBtnCancel()">{{ txtCancel }}</button>
  <button nz-button nzType="primary" style="margin-left: 10px; width: 110px;" [disabled]="!canClickOK" [nzLoading]="onProgress" (click)="onBtnSave()">OK
  </button>
</div>
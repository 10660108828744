import { Component, Input } from "@angular/core";
import { Const } from "@const/Const";
import { DateUtil } from "@services/date-utils";
import { BaseComponent } from "@abstract/BaseComponent";
import { DeliverStatus, CommLogStatus } from "@wearewarp/types/data-model/types/CommLog";
import { CommLogStatuses, commlogStatusMapper } from "@wearewarp/universal-libs";

@Component({
  selector: '[comm-log-detail]',
  templateUrl: './detail.html',
  styleUrls: ['../../../dialogs/dialogs.scss', './detail.scss']
})

export class CommLog extends BaseComponent {
  private _logId = '';

  @Input() get logId() {
    return this._logId;
  }
  set logId(value) {
    this._logId = value;
    this.getDataLog();
  }

  public listChange = [];
  public isLoading: boolean = false;
  public isError: boolean = false;
  private type: string = '';
  private provider: string = '';
  private timezone: string = DateUtil.getLocalTimeZone();
  private statuses = Const.CommLogStatuses;

  makeMessage(item: DeliverStatus) {
    let { raw } = item;
    const commLogStatus = commlogStatusMapper.from(item.status);

    const statuses: {[key in CommLogStatus]: string} = {
      pending: `Waiting to be processed`,
      sent: "Processed",
      failed: "Failed to send",
      success: "Successfully sent",
      delivered: `Received by ${raw?.email || raw?.delivery?.recipients?.[0]}`,
      // deferred: `The recipient temporarily rejects the message`,
      // dropped: `Dropped with reason: ${raw?.reason}`,
      // bounce: `The receiving server cannot or does not accept the message`,
      opened: `The recipient opened the email`,
      clicked: `The recipient clicked on the following <a target='_blank' rel='noopener' href='${raw?.url || raw?.click?.link}'>${raw?.url || raw?.click?.link}</a>`,
      // spam_report: `The recipient has marked the message as spam`,
      unsubscribed: `The recipient clicked the 'Opt out of all emails'`,
      undelivered: `The message has not arrived with ${raw?.email || raw?.delivery?.recipients?.[0]}`,
      invalid: `The recipient email is invalid`,
      unreachable: `The recipient's carrier is unreachable`,
      unknown: `Unknown status`
    }

    return statuses[commLogStatus] || 'N/A';
  }

  makeColor(status: string) {
    switch (commlogStatusMapper.from(status)) {
      case CommLogStatuses.pending:
        return 'gray';
      case CommLogStatuses.sent:
      case CommLogStatuses.success:
        return 'green';
      case CommLogStatuses.failed:
      case CommLogStatuses.invalid:
        return 'red';
      case CommLogStatuses.delivered:
      case CommLogStatuses.opened:
      case CommLogStatuses.clicked:
        return 'blue';
      case CommLogStatuses.undelivered:
      case CommLogStatuses.unreachable:
      case CommLogStatuses.unknown:
        return 'orange';
      case CommLogStatuses.unsubscribed:
        return 'black';
      default:
        return '';
    }
  }

  getDataLog() {
    if(!this._logId) return this.listChange = [];
    this.isLoading = true;
    const url = `${Const.API_SERVICE_COMM_LOG}/${this._logId}`;
    this.api.GET(url).subscribe(
      resp => {
        this.type = resp.data.type.toLowerCase();
        this.provider = resp.data.provider;
        let deliverStatus: DeliverStatus[] = resp.data?.deliverStatus || [];
        deliverStatus = deliverStatus.sort(function (a, b) {
          let bDate = new Date(b.time)
          let aDate = new Date(a.time)
          return Number(bDate) - Number(aDate)
        });

        this.listChange = deliverStatus.map(it => {
          const { time } = it;
          const timeZoneShort = this.getTimezoneShort();
          const date = `${this.formatDate(time)} ${timeZoneShort ? `(${timeZoneShort})` : ''}`;
          return { message: this.makeMessage(it), color: this.makeColor(it.status), date }
        });
        this.isLoading = false;
        this.isError = false;
      }, err => {
        this.isLoading = false;
        this.isError = true;
        this.showErr(err);
      }
    );
  }

  formatDate(time: string) {
    return DateUtil.displayTimeWindow({ from: time, to: time }, {
      timezone: this.timezone,
      formatDateOnly: 'MM/DD/YY',
      format: "MM/DD/YY h:mm A",
    });
  }

  getTimezoneShort() {
    const localTimeZoneShort = DateUtil.timezoneStandardToUsShort(this.timezone);
    return localTimeZoneShort || '';
  }
}

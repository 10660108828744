import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DisplayCarrierCostFin } from '.';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [DisplayCarrierCostFin],
  exports: [DisplayCarrierCostFin]
})
export class ModuleDisplayCarrierCostFin {}
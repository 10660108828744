import { Component, Input, NgZone } from "@angular/core";
import { AsyncValidatorFn, FormControl, ValidatorFn } from "@angular/forms";
import { ExtendValidators } from "@app/admin/base/validator";
import { Const } from "@const/Const";
import { ApiService } from "@services/api.service";
import _ from "underscore";
import { BaseDetail } from "@app/admin/base/detail";
import { ActivatedRoute } from "@angular/router";
import { InputHelper } from "@services/input-helper";
import { DateUtil } from "@services/date-utils";
import { BizUtil } from "@services/biz";
import { VehicleTypeService } from "@services/vehicle.service";

const API_URLS = {
  GET_CANDIDATES: `${Const.APIURI_CARRIERS}/get_candidates_for_bid`,
  GET_ZIPCODE_INFO: `${Const.APIURI_CARRIERS}/get/get_zipcode_info`,
  GET_JOB_DETAIL: `${Const.APIURI_JOBS}`,
  SUBMIT_BIDS: `${Const.APIURI_CARRIER_BIDS}`,
};

const basePriceValidator = ExtendValidators.required('Offer is required!');
@Component({
  selector: "app-create-carrier-bid",
  templateUrl: "./create-carrier-bid.component.html",
  styleUrls: ["./create-carrier-bid.component.scss"],
})
export class CreateCarrierBidComponent extends BaseDetail {
  @Input() jobId;

  private editId: number;
  public isError = false;
  public isLoading = false;
  public matchedCarriers: string[] = [];
  public isSourceMarketPlace: boolean = false;
  public optionalEquipments = [];
  vehicleTypeService: VehicleTypeService = null;
  vehicles = [];

  protected subscribeTopbarTitle(fn: Function) {
    return fn(this.editId ? "Edit Carrier Bid" : "Create Carrier Bid");
  }

  protected formGroupDeclaration: FormGroupDeclaration = {
    pickupAddress: {
      label: "Pickup Address",
      placeHolder: "Pickup Address",
      required: true
    },
    dropoffAddress: {
      label: "Dropoff Address",
      placeHolder: "Dropoff Address",
      required: true
    },
    pickupDate: {
      label: "Pickup Date Time",
      placeHolder: "Pickup Date Time",
      required: true,
      readOnly: this.hasJobId,
    },
    dropoffDate: {
      label: "Dropoff Date Time",
      placeHolder: "Dropoff Date Time",
      required: true,
      readOnly: this.hasJobId,
    },
    serviceOptions: {
      label: "Service Options",
      placeHolder: "Service Options",
      initialValue: [],
    },
    vehicles: {
      label: "Equipment",
      placeHolder: "Equipment",
      initialValue: [],
    },
    optionalEquipments: {
      label: "Optional",
      placeHolder: "Equipments",
      initialValue: [],
    },
    equipments: {
      label: "Equipments",
      placeHolder: "Equipments",
      initialValue: [],
    },
    basePrice: {
      label: "Base Price",
      placeHolder: "Enter base price",
      initialValue: 0,
      required: true,
      getValue: InputHelper.getValueMoney, formatValue: InputHelper.formatMoney2
    },
    isShowBasePrice: {
      label: "Show Base Price For Carrier",
      placeHolder: "",
      // required: true,
      initialValue: false
    },
    isAllowPlaceBid: {
      label: "Allow the carrier place bid",
      placeHolder: "",
      initialValue: false
    },
    isShowOnBidBoard : {
      label: "Show on Bid Board",
      placeHolder: "",
      initialValue: false
    },
     isRequireCarrierAcceptLoadTender : {
      label: "Carrier needs to accept load tender after route assignment",
      placeHolder: "",
      initialValue: false
    },
    type: {
      label: "Bid Mode",
      required: true,
      initialValue: Const.CarrierBidTypes.manual
    }
  };
  private validators = {
    pickupAddress: [
      ExtendValidators.required("Pickup Address is required!"),
    ],
    dropoffAddress: [
      ExtendValidators.required("Dropoff Address is required!"),
    ],
    pickupDate: [ExtendValidators.required("Pickup Date is required!")],
    dropoffDate: [ExtendValidators.required("Dropoff Date is required!")],
  };

  constructor(activatedRoute: ActivatedRoute, api: ApiService, private ngZone: NgZone) {
    super(activatedRoute);
    this.api = api;
    this.jobId = this.getRouteParam(this.activatedRoute, "jobId");
    this.editId = this.getRouteParam(this.activatedRoute, "id");
    this.vehicleTypeService = new VehicleTypeService(this.api)
    this.loadVehicles()
  }

  loadVehicles() {
    this.vehicleTypeService.listAll(
        (data) => {
          this.vehicles = data.filter(it => it.active !== false)
        },
        () => {
        }
    )
}

  get needUpdate(): boolean {
    if (!this.selectedType) return false //phải chọn bid mode

    if (!this.isAllowPlaceBid && !this.isShowWarpOffer) return false //phải tích chọn 1 trong 2 check box
    if (this.isShowWarpOffer && !this.basePriceInput) return false //nếu chọn show offer thì phải điền số tiền

    return true
  }

  get isCreateNew(): boolean {
    return this.editId ? false : true;
  }

  get hasJobId(): boolean {
    return this.jobId;
  }

  public jobHyperLink;

  ngOnInit(): void {
    super.ngOnInit();
    this.getDataForCreateFromJob();
    this.getDataForEdit();
  }

  protected createFormInput(bindData = undefined) {
    super.createFormInput(bindData);
    this.onInitValidator();
  }

  getApiUrl() {
    return ""
  }
  // init các validator vì base không có message, cũng không có params options để khai báo async validator.
  // Chỉ gọi hàm này sau khi tạo form.
  onInitValidator() {
    for (let controlKey in this.validators) {
      const validators: ValidatorFn | ValidatorFn[] = this.validators[controlKey];
      this.getItemByKey(controlKey).addValidators(validators);
    }
  }

  getDataForCreateFromJob() {
    if (!this.jobId) return;
    //call API for get job data here
    this.isLoading = true;

    this.api.GET(`${API_URLS.GET_JOB_DETAIL}/${this.jobId}`).subscribe(
      (response) => {
        const data = response.data || {};
        this.jobHyperLink = BizUtil.createHyperLinkForJob(data);
        const formData = this.formatDataFromJob(data);
        this.createFormInput(formData);
        this.getItemByKey("pickupAddress").disable();
        this.getItemByKey("dropoffAddress").disable();
        this.getItemByKey("pickupDate").disable();
        this.getItemByKey("dropoffDate").disable();
        this.isLoading = false;
        //fetch Carrier matching on Init
        this.fetchCarrierMatching();
      },
      (err) => {
        this.setEnableFormGroup(true);
      }
    );
  }

  getDataForEdit() {
    if (!this.editId) return;
    this.isEditing = true;
    //call API for get job data here
    this.isLoading = true;

    this.api.GET(`${API_URLS.SUBMIT_BIDS}/${this.editId}`).subscribe(
      (response) => {
        const data = response.data || {};
        data['carrierIds'] = data.carriers.map(carrier => carrier.carrierId);
        const formData = this.formatDataFromBid(data);
        this.model = formData;
        this.createFormInput(formData);

        if (data.jobId) {
          this.jobId = data.jobId;
          this.getItemByKey("pickupAddress").disable();
          this.getItemByKey("dropoffAddress").disable();
          this.getItemByKey("serviceOptions").disable();
          this.getItemByKey("equipments").disable();
          this.getItemByKey("vehicles").disable();
          this.getItemByKey("pickupDate").disable();
          this.getItemByKey("dropoffDate").disable();
          if (data.isSourceMarketPlace) {
            this.isSourceMarketPlace = true;
            this.getItemByKey("type").disable();
            this.getItemByKey("isAllowPlaceBid").disable();
            this.getItemByKey("isShowBasePrice").disable();
          }
        }
        this.isLoading = false;
        //fetch Carrier matching on Init
        this.fetchCarrierMatching({ autoSelected: false });
      },
      (err) => {
        // this.setEnableFormGroup(true);
      }
    );
  }

  formatDataFromJob(data) {
    //zipcode
    const tasks = data.tasks || [];
    const firstPickupTask = tasks.filter((task) => task.type == "PICKUP")[0];
    const lastDropoffTask = tasks.filter((task) => task.type == "DROPOFF").reverse()[0];
    const pickupAddress = firstPickupTask?.info?.addr ?? firstPickupTask?.address ?? {};
    const dropoffAddress = lastDropoffTask?.info?.addr ?? lastDropoffTask?.address ?? {};
    let pickupDate = data.pickDt?.time;
    let dropoffDate = data.dropDt?.time;

    const pickupTimeZone = pickupAddress?.metadata?.timeZoneStandard;
    const dropoffTimeZone = dropoffAddress?.metadata?.timeZoneStandard;
    pickupDate = DateUtil.convertLocalTime2(pickupDate, pickupTimeZone)
    dropoffDate = DateUtil.convertLocalTime2(dropoffDate, dropoffTimeZone)

    const { cost } = data
    const { finance } = cost || {}
    const basePrice = data.basePrice || finance;
    let isShowBasePrice: boolean = false
    if (finance) {
      isShowBasePrice = true
    }
    //serviceOptions & equipments
    const shipments = data.shipments || [];
    let serviceOptions = [];
    let equipments = [];
    for (let shipment of shipments) {
      const shipmentServiceOptions = _.get(shipment, ["cost", "serviceOptions"], []);
      serviceOptions = [...serviceOptions, ...shipmentServiceOptions.map((item) => item._id)];

      const shipmentEquipment = shipment?.metadata?.equipment;
      if (shipmentEquipment) equipments.push(shipmentEquipment.id);
    }
    let vehicles = data.vehicles;
    let optionalEquipments = data.optionalEquipments;
   
    return {
      pickupAddress,
      dropoffAddress,
      pickupDate,
      dropoffDate,
      serviceOptions,
      equipments,
      basePrice,
      isShowBasePrice,
      vehicles,
      optionalEquipments
    };
  }

  formatDataFromBid(data) {
    const pickupTimeZone = data["pickupAddress"]?.metadata?.timeZoneStandard;
    const dropoffTimeZone = data["dropoffAddress"]?.metadata?.timeZoneStandard;
    //.toISOString() để không bị change type của input khi đẩy ra form edit.
    data['pickupDate'] = DateUtil.convertLocalTime2(data['pickupDate'], pickupTimeZone)?.toISOString()
    data['dropoffDate'] = DateUtil.convertLocalTime2(data['dropoffDate'], dropoffTimeZone)?.toISOString()
    //serviceOptions & equipments
    let serviceOptions = data.serviceOptions.map(serviceOption => serviceOption._id);
    let equipments = data.equipments.map(equipment => equipment.id);
    let optionalEquipments = data.optionalEquipments;
    if (optionalEquipments?.length) {
      this.getOptionalEquipment(optionalEquipments)
    }

    return {
      ...data,
      serviceOptions,
      equipments,
      optionalEquipments
    };
  }

  fetchCarrierMatching({ autoSelected } = { autoSelected: true }) {
    const pickupAddress = this.getItemValue("pickupAddress");
    const dropoffAddress = this.getItemValue("dropoffAddress");
    if (!pickupAddress || !dropoffAddress || !pickupAddress.zipcode || !dropoffAddress.zipcode) return;
    this.isLoading = true;
    //API_URLS
    this.api
      .POST(API_URLS.GET_CANDIDATES, {
        addresses: [pickupAddress, dropoffAddress],
        equipments: this.getItemValue("equipments")
      })
      .subscribe(
        (response) => {
          const matchedCarriers = _.get(response, ["data", "list_data"], []);
          this.matchedCarriers = matchedCarriers.map((carrier) => carrier.id);
          this.ngZone.run(() => {
            this.isLoading = false;
            if (autoSelected)
              this.setItemValue("carrierIds", this.matchedCarriers);
          })
        },
        (err) => {
          this.matchedCarriers = [];

          this.ngZone.run(() => {
            this.isLoading = false;
            if (autoSelected)
              this.setItemValue("carrierIds", []);
          })
        }
      );
  }

  onBtnSave() {
    let json: any = this.getFormData_JSON(true);

    //convert data before submit
    const pickupTimeZone = json["pickupAddress"]?.metadata?.timeZoneStandard;
    const dropoffTimeZone = json["dropoffAddress"]?.metadata?.timeZoneStandard;
    json['pickupDate'] = DateUtil.convertLocalTime(json['pickupDate'], pickupTimeZone)
    json['dropoffDate'] = DateUtil.convertLocalTime(json['dropoffDate'], dropoffTimeZone)

    json.serviceOptions = (json.serviceOptions || []).map((item) => ({
      _id: item,
      qty: 1, //fixed
    }));
    json.jobId = this.jobId || undefined;

    // this.setEnableFormGroup(false);
    this.startProgress();

    if (this.editId) {
      this.api.PUT(`${API_URLS.SUBMIT_BIDS}/${this.editId}`, json).subscribe(
        (response) => {
          this.stopProgress();
          this.showSuccess('Carrier Bid has been updated successfully.')
          this.location.back()
        },
        (err) => {
          this.showErr(err);
          this.stopProgress();
          // this.setEnableFormGroup(true);
        }
      );
    }
    else {
      this.api.POST(API_URLS.SUBMIT_BIDS, json).subscribe(
        (response) => {
          this.stopProgress();
          this.showSuccess('Carrier Bid has been created successfully.')
          this.router.navigate([this.routeAdminCarrierSales, response.data.id], { replaceUrl: true });

        },
        (err) => {
          this.showErr(err);
          this.stopProgress();
          // this.setEnableFormGroup(true);
        }
      );
    }
  }

  doCancel(): any {
    this.location.back()
  }

  onDropdownSelectChange(key: string, event) {
    switch (key) {
      // case 'vehicles':
      //   if (event?.code) {
      //     this.setItemValue(key, event);
      //   } else {
      //     this.setItemValue(key, null);
      //   }
      //   break;
      case 'optionalEquipments':
        let data = [];
          if (event?.length) {
            const vehicle = this.vehicles.filter(it => (event ?? []).includes(it.code) );
            data = vehicle?.map(it => Object.assign({}, {
              label: it.name,
              value: it.code,
            }));
          }
          this.setItemValue(key, data);
      default:
        break;
    }
  }

  onInputChanged(event, key) {
    switch (key) {
      case 'basePrice': return InputHelper.handleInputChangeMoney(event, <FormControl>this.formInput.get(key), true);
      case 'type': return this.onTypeChanged(event);
    }
  }

  getOptionalEquipment(vehicles) {
    this.optionalEquipments = (vehicles ?? []).map(it => it.value);
  }

  getPreferredEquipment() {
    const value = this.getItemValue("vehicles");
    if (value?.[0]) {
      return value?.[0]
    }
    return value;
  }

  onTypeChanged(value) {
    console.log("event", event);
    if (value == Const.CarrierBidTypes.direct) {
      this.getItemByKey("isAllowPlaceBid").disable();
      this.getItemByKey("isShowBasePrice").disable();
      this.setItemValue("isAllowPlaceBid", false);
      this.setItemValue("isShowBasePrice", true);
    }
    else {
      this.getItemByKey("isAllowPlaceBid").enable();
      this.getItemByKey("isShowBasePrice").enable();
    }
  }

  carrierBIDType = Object.values(Const.CarrierBidTypes);


  getLabelType(key) {
    switch (key) {
      case Const.CarrierBidTypes.direct: return 'Auto assign when carrier accept load (Direct)';
      case Const.CarrierBidTypes.manual: return 'Manual review (Indirect)';
      default: return 'Manual';
    }
  }

  carrierBidType = {
    direct: Const.CarrierBidTypes.direct,
    manual: Const.CarrierBidTypes.manual
  }

  get selectedType() {
    return this?.formInput?.get('type')?.value;
  }

  get isAllowPlaceBid() {
    return this?.formInput?.get('isAllowPlaceBid')?.value;
  }

  get isShowWarpOffer() {
    return this?.formInput?.get('isShowBasePrice')?.value;
  }

  get basePriceInput() {
    return this?.formInput?.get('basePrice')?.value;
  }
  get isShowOnBidBoard(){
    return this?.formInput?.get('isShowOnBidBoard')?.value;
  }

}

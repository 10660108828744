import {Component, Input} from '@angular/core';
import {BaseFormItem} from '@app/admin/base/form-item';
import {Const} from '@const/Const';

@Component({
  selector: '[client-parent-company-info]',
  templateUrl: './index.html',
  styleUrls: [
    './index.scss',
    '../../../../detail.scss',
    '../../../../../../styles/row-col.scss',
    '../../../../../../styles/form-v2.scss',
  ],
})
// Parent Company Information
export class ClientParentCompanyInfo extends BaseFormItem {
  protected formGroupDeclaration: FormGroupDeclaration = {
    name: { label: "Company name", required: true },
    website: { label: "Company website", inputType: "url", placeHolder: "http://" },
    address: { label: "Address", required: true },
  };
  _parentId
  @Input() set parentId(value) {
    this._parentId = value;
    this.model = null;
    this.getClientDetail(value);
  }

  private getClientDetail(id) {
    let url = `${Const.APIURI_CLIENTS}/${id}`;
    this.onProgress = true;
    this.api.GET(url).subscribe(
      resp => {
        this.model = resp.data;
        this.bindDataModel(this.model);
        this.onProgress = false;
      }, err => {
        this.showErr(err);
        this.onProgress = false;
      }
    );
  }

  disableEditing() {
    this.setEnableFormGroup(false);
    this.isEditing = false;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.disableEditing();
  }

  onBtnViewParentCompany() {
    if(!this._parentId) return
    this.router.navigate([this.routeAdminClientList, this._parentId]);
  }
}

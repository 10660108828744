import { BaseComponent } from "@abstract/BaseComponent";
import { Component, Input } from "@angular/core";
import { DateUtil } from "@services/date-utils";

@Component({
  selector: "dat-truck-detail",
  templateUrl: "./truck-detail.html",
  styleUrls: ["./truck-detail.scss"],
})
export class DatTruckDetailComponent extends BaseComponent {
  @Input() item: any;
  @Input() equipmentTypes: any = [];
  @Input() loadTypes: any = [];

  constructor() {
    super();
  }

  getAddress(data) {
    if (data.open) {
      return 'Anywhere';
    }
    if (data.city && data.stateProv) {
      return `${data.city}, ${data.stateProv}`;
    }
    if (data.area) {
      if (data.area.zones) {
        return data.area.zones.join(', ');
      }
      if (data.area.states) {
        return data.area.states.join(', ');
      }
    }

    return "N/A";
  }

  getDate(data) {
    return DateUtil.formatDate(data, 'MMM DD');
  }

  getEquipment(type: string) {
    const equipment = this.equipmentTypes.find(t => t.value === type);
    return equipment ? `${type} | ${equipment.label}` : type;
  }

  getLoadType(type: string) {
    return this.loadTypes.find(t => t.value === type)?.label || type;
  }
}

import { BaseComponent } from "@abstract/BaseComponent";
import { Component, Input } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: '[warehouse-manifest-navigation-bar]',
  templateUrl: './index.html',
  styleUrls: [
    './index.scss',
  ]

})
export class WarehouseManifestNavigationBar extends BaseComponent {

  constructor(protected route: ActivatedRoute) {
    super();
  }

  @Input() manifestModel: { id: string, mblNumber: string };
  @Input() palletModel: { id: string, name: string };
  @Input() cartonModel: { id: string, name: string };

  ngOnInit(): void {
    super.ngOnInit();
  }

}

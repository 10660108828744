
import { Component, ViewChild, ElementRef } from '@angular/core';
import { BaseDetail } from '../base/detail';
import { ActivatedRoute } from '@angular/router';
import { Const } from '@const/Const';
import { DateUtil } from "@services/date-utils";
import { ApiService } from '@services/api.service';
@Component({
  selector: '[crossdock-warehouse]',
  templateUrl: './view.html',
  styleUrls: ['./style.scss']
})
export class CrossdockWarehouse extends BaseDetail {
  protected get crudEntity(): string { return 'crossdock-warehouses-map' }
  public warehouseId = this.getQueryParam('id');
  public tabIndex = this.getQueryParam('tabIndex');

  public crossdockWarehouseList = []; //list warehouse hiển thị trong selectbox
  private syncInventoriesData = []; //dữ liệu gửi lên khi ấn nút đồng bộ

  public isLoadingWarehouseList; //loading khi get list warehouse
  public isLoadingExport; //loading khi export
  public isLoadingSyncSvg
  public isValidSvg; //check xem SVG đã đúng format hay chưa

  constructor(protected activatedRoute: ActivatedRoute) {
    super(activatedRoute);
  }

  get isShowSyncSvgButton() {
    //mặc định ẩn nút sync => muốn hiện lên thì thêm "&sync=true" vào url
    return this.isValidSvg && this.getQueryParam("sync") 
  }

  get isShowReloadButton() {
    return this.model
  }

  get isShowDownloadExcelButton() {
    return this.warehouseId
  }
  
  handleNavigationEnd() {
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.getCrossdockWarehouseList(); // lấy list warehouse để select
  }

  getCrossdockWarehouseList() {
    this.isLoadingWarehouseList = true;
    const url = `${Const.APIURI_WAREHOUSES}?limit=-1&filter=${JSON.stringify({ warehouseType: 'crossdock' })}`;
    this.api.GET(url).subscribe(
      resp => {
        this.crossdockWarehouseList = resp?.data?.list_data
        this.isLoadingWarehouseList = false;
      }, err => {
        this.isLoadingWarehouseList = false;
      }
    )
  }
  async syncInventoryFromSvgMapWithDeleteBefore() {
    this.confirmYesNo("Are you sure you want to sync (delete all & sync) ?", async () => {
      this.isLoadingSyncSvg = true;
      const url = `${Const.APIURI_WAREHOUSES}/sync_inventories_from_svg_map_with_delete_before`;
      const params = { warehouseId: this.warehouseId, inventories: this.syncInventoriesData }
      const resp = await this.api.POST(url, params).toPromise().catch(e => {
        this.showErr(`${e.message}`)
        // this.selectedInventoryInfo = undefined
        this.isLoadingSyncSvg = false;
      });
      if (resp) this.showSuccess("Data has been synced !");
      this.isLoadingSyncSvg = false;
    })
  }

  async export() {
    this.isLoadingExport = true;
    const params = { warehouseId: this.warehouseId }
    this.api.postExport(`${Const.APIURI_INVENTORIES}/export`, params).subscribe(
      resp => {
        ApiService.handleDownloadResponse(resp);
        this.isLoadingExport = false;
      }, err => {
        this.showErr(err);
        this.isLoadingExport = false;
      }
    );
  }

  async syncInventoryFromSvgMap() {
    this.confirmYesNo("Are you sure you want to sync ?", async () => {
      this.isLoadingSyncSvg = true;
      const url = `${Const.APIURI_WAREHOUSES}/sync_inventories_from_svg_map`;
      const params = { warehouseId: this.warehouseId, inventories: this.syncInventoriesData }
      const resp = await this.api.POST(url, params).toPromise().catch(e => {
        this.showErr(`${e.message}`)
        // this.selectedInventoryInfo = undefined
        this.isLoadingSyncSvg = false;
      });
      if (resp) this.showSuccess("Data has been synced !");
      this.isLoadingSyncSvg = false;
    })
  }

  onChangeWarehouse(value) {
    // if (!value) {
    //   this.clearSvgMap()
    // }
     //đẩy id lên param để khi f5 => vẫn load dữ liệu đang chọn
    this.routeWithQueryUrl({ id: value })
  }

  reload(){
    // this.getCrossdockWarehouseDetail()
  }

  onTabChange(index){
    this.routeWithQueryUrl({tabIndex: index})
    setTimeout(() => {
      if (index == 0) window.location.reload(); //đang fix tạm bug ko load lại svg khi chuyển tab
    }, 200);
  }
}

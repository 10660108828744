<div #filter filter-layout></div>

<ng-template #tplFilter_search let-key="key" let-form="form">
  <ng-container [formGroup]="form">
    <input
      style="width: 300px"
      nz-input
      [formControlName]="key"
      [placeholder]="getFilterPlaceHolder(key)"
      (ngModelChange)="onChangeSearchKeyWord($event)"
    />
  </ng-container>
</ng-template>

<ng-template #tplFilter_shipmentIds let-key="key" let-form="form">
  <ng-container [formGroup]="form">
    <div
      input-warp-ids
      [(warpIdMode)]="warpIdMode"
      [formControlName]="key"
      [placeHolder]="'Enter WARP ID'"
      [allModes]="warpIdModes"
      [getModeDesc]="getWarpIdModeDesc"
      (ngModelChange)="onFilterChange(key, $event)"
      (warpIdModeChange)="onWarpIdModeChanged($event)"
    ></div>
  </ng-container>
</ng-template>

<ng-template #tplFilter_client let-key="key" let-form="form">
  <ng-container [formGroup]="form">
    <nz-select
      nzBackdrop="true"
      nzAllowClear
      nzShowSearch
      [formControlName]="key"
      [nzLoading]="isLoading"
      [nzPlaceHolder]="'Select client'"
      nzMode="multiple"
      (ngModelChange)="onFilterChange(key, $event)"
      style="min-width: 225px"
    >
      <nz-option *ngFor="let item of clients" [nzValue]="item?.id" [nzLabel]="showSelectionLabel(item)"></nz-option>
    </nz-select>
  </ng-container>
</ng-template>

<ng-template #tplFilter_pickupLocation let-key="key" let-form="form">
  <ng-container [formGroup]="form">
    <nz-select
      nzBackdrop="true"
      nzAllowClear
      nzShowSearch
      [formControlName]="key"
      [nzLoading]="isLoading"
      [nzPlaceHolder]="'Select pickup location'"
      nzMode="multiple"
      (ngModelChange)="onFilterChange(key, $event)"
      style="min-width: 300px"
      nzOptionHeightPx="55"
    >
      <nz-option
        nzCustomContent
        *ngFor="let item of pickupLocations"
        [nzValue]="item?.id"
        [nzLabel]="showSelectionLocationLabel(item)"
      >
        <div>{{showSelectionLocation(item)}}</div>
        <div class="sub-info">{{item.name}}</div>
      </nz-option>
    </nz-select>
  </ng-container>
</ng-template>

<ng-template #tplFilter_deliveryLocation let-key="key" let-form="form">
  <ng-container [formGroup]="form">
    <nz-select
      nzBackdrop="true"
      nzAllowClear
      nzShowSearch
      [formControlName]="key"
      [nzLoading]="isLoading"
      [nzPlaceHolder]="'Select delivery location'"
      nzMode="multiple"
      (ngModelChange)="onFilterChange(key, $event)"
      style="min-width: 300px"
      nzOptionHeightPx="55"
    >
      <nz-option
        nzCustomContent
        *ngFor="let item of deliveryLocations"
        [nzValue]="item?.id"
        [nzLabel]="showSelectionLocationLabel(item)"
      >
        <div>{{showSelectionLocation(item)}}</div>
        <div class="sub-info">{{item.name}}</div>
      </nz-option>
    </nz-select>
  </ng-container>
</ng-template>

<ng-template #tplFilter_pickupState let-key="key" let-form="form">
  <ng-container [formGroup]="form">
    <nz-select
      nzBackdrop="true"
      nzAllowClear
      nzShowSearch
      [formControlName]="key"
      [nzLoading]="isLoading"
      [nzPlaceHolder]="'Select pickup state'"
      nzMode="multiple"
      (ngModelChange)="onFilterChange(key, $event)"
      style="min-width: 300px"
    >
      <nz-option
        nzCustomContent
        *ngFor="let item of pickupStates"
        [nzValue]="item?.id"
        [nzLabel]="showSelectionLabel(item)"
      >
        <div>{{showSelectionLabel(item)}}</div>
      </nz-option>
    </nz-select>
  </ng-container>
</ng-template>

<ng-template #tplFilter_originalPickupLocation let-key="key" let-form="form">
  <ng-container [formGroup]="form">
    <nz-select
      nzBackdrop="true"
      nzAllowClear
      nzShowSearch
      [formControlName]="key"
      [nzLoading]="isLoading"
      [nzPlaceHolder]="'Select pickup location'"
      nzMode="multiple"
      (ngModelChange)="onFilterChange(key, $event)"
      style="min-width: 300px"
      nzOptionHeightPx="55"
    >
      <nz-option
        nzCustomContent
        *ngFor="let item of originalPickupLocations"
        [nzValue]="item?.id"
        [nzLabel]="showSelectionLocationLabel(item)"
      >
        <div>{{showSelectionLocation(item)}}</div>
        <div class="sub-info">{{item.name}}</div>
      </nz-option>
    </nz-select>
  </ng-container>
</ng-template>

<ng-template #tplFilter_deliveryState let-key="key" let-form="form">
  <ng-container [formGroup]="form">
    <nz-select
      nzBackdrop="true"
      nzAllowClear
      nzShowSearch
      [formControlName]="key"
      [nzLoading]="isLoading"
      [nzPlaceHolder]="'Select delivery state'"
      nzMode="multiple"
      (ngModelChange)="onFilterChange(key, $event)"
      style="min-width: 300px"
    >
      <nz-option
        nzCustomContent
        *ngFor="let item of deliveryStates"
        [nzValue]="item?.id"
        [nzLabel]="showSelectionLabel(item)"
      >
        <div>{{showSelectionLabel(item)}}</div>
      </nz-option>
    </nz-select>
  </ng-container>
</ng-template>

<ng-template #tplFilter_finalDeliveryLocation let-key="key" let-form="form">
  <ng-container [formGroup]="form">
    <nz-select
      nzBackdrop="true"
      nzAllowClear
      nzShowSearch
      [formControlName]="key"
      [nzLoading]="isLoading"
      [nzPlaceHolder]="'Select delivery location'"
      nzMode="multiple"
      (ngModelChange)="onFilterChange(key, $event)"
      style="min-width: 300px"
      nzOptionHeightPx="55"
    >
      <nz-option
        nzCustomContent
        *ngFor="let item of finalDeliveryLocations"
        [nzValue]="item?.id"
        [nzLabel]="showSelectionLocationLabel(item)"
      >
        <div>{{showSelectionLocation(item)}}</div>
        <div class="sub-info">{{item.name}}</div>
      </nz-option>
    </nz-select>
  </ng-container>
</ng-template>

<ng-template #tplFilter_status let-key="key" let-form="form">
  <ng-container [formGroup]="form">
    <nz-select
      nzBackdrop="true"
      nzAllowClear
      nzShowSearch
      [formControlName]="key"
      [nzLoading]="isLoading"
      [nzPlaceHolder]="'Status'"
      nzMode="multiple"
      (ngModelChange)="onFilterChange(key, $event)"
      style="width: 300px"
    >
      <nz-option
        *ngFor="let item of statusOptions"
        [nzValue]="item.id"
        [nzLabel]="showSelectionLabel(item)"
      ></nz-option>
    </nz-select>
  </ng-container>
</ng-template>

<div>
  <form #form [formGroup]="formInput" nz-form [ngClass]="{filterform: 1, 'collapsed': !isExpanded, expanded: isExpanded}">
    <div class="form-inner-box">
      <div class="form-item">
          <div class="form-label-v2">
            Keyword
            <span style="margin-left: 10px;"
              nz-icon nzType="info-circle" nzTheme="outline"
              nz-tooltip [nzTooltipTitle]="searchPlaceHolder"></span>
          </div>
          <search-box #searchBox style="width: 300px; margin-right: 20px;"
            [placeHolder]="'Search'"
            [searchKeyword]="search"
            (doSearch)="doSearch($event)">
          </search-box>
      </div>

      <ng-container *ngFor="let key of formInputKeys">
        <ng-container>
          <div class="form-item" [ngClass]="key">
            <div class="form-label-v2">{{getLabel(key)}}</div>
            <ng-container [ngSwitch]="key">
              <div *ngSwitchCase="'status'">
                <button nz-button nz-dropdown [nzDropdownMenu]="menu" style="min-width: 150px;">
                  {{getSelectedStatus()}}
                </button>
                <nz-dropdown-menu #menu="nzDropdownMenu">
                  <ul nz-menu class="form-item" [ngClass]="key" *ngFor="let item of allStatuses; let i = index">
                    <li style="padding-left: 5px;">
                      <label nz-checkbox [nzChecked]="isStatusSelected(item)" (nzCheckedChange)="onBtnStatus(item)">{{getStatusLabel(item)}}</label>
                    </li>
                  </ul>
                </nz-dropdown-menu>
              </div>
              <input *ngSwitchCase="'jobId'" 
                nz-input [formControlName]="key" 
                [type]="getInputType(key)" 
                [placeholder]="getPlaceHolder(key)"
                (ngModelChange)="onChange($event, key)">
              <div *ngSwitchCase="'sort'" #sortFilterDispatch>
                <nz-select nzBackdrop="true" nzAllowClear nzShowSearch style="min-width: 150px;"
                  [formControlName]="key"
                  [nzPlaceHolder]="getPlaceHolder(key)" nzDropdownClassName="dispatch-filter-sort"
                  (ngModelChange)="onChange($event, key)">
                  <nz-option *ngFor="let item of sortTypes"
                    [nzValue]="item.value" [nzLabel]="item.name">
                  </nz-option>
                </nz-select>
              </div>
              <div *ngSwitchCase="'clientId'" style="min-width: 150px;" #customerFilter>
                <select-by-searching
                  [version]="2"
                  nzMode="multiple"
                  [formControlName]="key" (modelChange)="onChange($event, key)"
                  (onFocus)="onFocusCustomerFilter()"
                  [placeholder]="getPlaceHolder(key)"
                  dropdownClassName="dispatch-filter-customer">
                </select-by-searching>
              </div>
              <div *ngSwitchCase="'shipmentType'">
                <nz-select nzBackdrop="true" nzAllowClear nzShowSearch style="min-width: 150px;"
                  [formControlName]="key"
                  [nzPlaceHolder]="getPlaceHolder(key)"
                  (ngModelChange)="onChange($event, key)">
                  <nz-option *ngFor="let item of listShipmentTypes"
                    [nzValue]="item" [nzLabel]="item">
                  </nz-option>
                </nz-select>
              </div>
              <div *ngSwitchCase="'carrierId'">
                <nz-select nzBackdrop="true" nzAllowClear nzShowSearch
                  [formControlName]="key" [nzLoading]="isFetchingCarrier"
                  [nzPlaceHolder]="getPlaceHolder(key)" nzDropdownClassName="dispatch-filter-carrier"
                  (ngModelChange)="onChange($event, key)" style="min-width: 150px;">
                  <nz-option-group nzLabel="Filter by assignment status">
                    <nz-option nzValue="no" nzLabel="Not assigned yet"></nz-option>
                    <nz-option nzValue="yes" nzLabel="Already assigned"></nz-option>
                  </nz-option-group>
                  <nz-option-group nzLabel="Filter by specific carrier">
                    <nz-option nzCustomContent *ngFor="let item of listCarriers"
                      [nzValue]="item.id" [nzLabel]="getCarrierNameWithMCandDOT(item)">
                      <div>{{getCarrierName(item)}}</div>
                      <div *ngIf="item?.basicInfo?.mc" class="sub-info">MC: {{getCarrierMC(item)}}</div>
                      <div *ngIf="item?.basicInfo?.dot" class="sub-info">DOT: {{getCarrierDOT(item)}}</div>
                    </nz-option>
                  </nz-option-group>
                </nz-select>
              </div>
              <div *ngSwitchCase="'picId'">
                <nz-select nzBackdrop="true" nzAllowClear nzShowSearch
                  [formControlName]="key" [nzLoading]="isFetchingCarrier"
                  [nzPlaceHolder]="getPlaceHolder(key)" nzDropdownClassName="dispatch-filter-dispatcher"
                  (ngModelChange)="onChange($event, key)" style="min-width: 150px;">
                  <nz-option-group nzLabel="Filter by assignment status">
                    <nz-option nzValue="no" nzLabel="Not assigned yet"></nz-option>
                    <nz-option nzValue="yes" nzLabel="Already assigned"></nz-option>
                  </nz-option-group>
                  <nz-option-group nzLabel="Filter by apecific dispatcher">
                    <nz-option *ngFor="let item of listDispatcherForDropdown"
                      [nzValue]="item.id" [nzLabel]="dispatcherName(item)">
                    </nz-option>
                  </nz-option-group>
                </nz-select>
              </div>
              <div *ngSwitchCase="'carrierSaleRepId'">
                <nz-select nzBackdrop="true" nzAllowClear nzShowSearch
                  [formControlName]="key" [nzLoading]="isFetchingCarrierSaleRep"
                  [nzPlaceHolder]="getPlaceHolder(key)" nzDropdownClassName="dispatch-filter-carrier-sale-rep"
                  (ngModelChange)="onChange($event, key)" style="min-width: 150px;">
                  <nz-option-group nzLabel="Filter by specific carrier sales rep">
                    <nz-option *ngFor="let item of listCarrierSales"
                      [nzValue]="item.id" [nzLabel]="getFullName(item)">
                    </nz-option>
                  </nz-option-group>
                </nz-select>
              </div>
              <div *ngSwitchCase="'clientSaleRepId'">
                <nz-select nzAllowClear nzShowSearch
                  [formControlName]="key" [nzLoading]="isFetchingClientSaleRep"
                  [nzPlaceHolder]="getPlaceHolder(key)" nzDropdownClassName="dispatch-filter-client-sale-rep"
                  (ngModelChange)="onChange($event, key)" style="min-width: 150px;">
                  <nz-option-group nzLabel="Filter by specific carrier sales rep">
                    <nz-option *ngFor="let item of listClientSales"
                      [nzValue]="item._id" [nzLabel]="getFullName(item)">
                    </nz-option>
                  </nz-option-group>
                </nz-select>
              </div>
              <div *ngSwitchCase="'fromDate'">
                <nz-form-control>
                  <nz-input-group [nzAddOnAfter]="getTimezoneShort()">
                    <nz-date-picker
                      [formControlName]="key" nzFormat="yyyy-MM-dd"
                      (ngModelChange)="onChange(startOfDay($event), key)">
                    </nz-date-picker>
                  </nz-input-group>
                </nz-form-control>
              </div>
              <div *ngSwitchCase="'toDate'">
                <nz-form-control>
                  <nz-input-group [nzAddOnAfter]="getTimezoneShort()">
                    <nz-date-picker
                      [formControlName]="key" nzFormat="yyyy-MM-dd"
                      (ngModelChange)="onChange(endOfDay($event), key)">
                    </nz-date-picker>
                  </nz-input-group>
                </nz-form-control>
              </div>
              <div *ngSwitchCase="'pickupState'"  style="min-width: 150px;">
                <ng-container [ngTemplateOutlet]="tplSelectState" [ngTemplateOutletContext]="{key, form: formInput}"></ng-container>
              </div>
              <div *ngSwitchCase="'dropoffState'" style="min-width: 150px;">
                <ng-container [ngTemplateOutlet]="tplSelectState" [ngTemplateOutletContext]="{key, form: formInput}"></ng-container>
              </div>
              <div *ngSwitchCase="'vehicleType'">
                <div vehicle-selector [value]="getVehicleType()" (valueChange)="onChange($event, key)" [quoting]="false" [withOptions]="false"></div>
                <!-- <nz-form-item style="min-width: 250px;" >
                  <nz-form-control>
                    <app-form-equipments [formControlName]="key" (ngModelChange)="onChange($event, key)"></app-form-equipments>
                  </nz-form-control>
                </nz-form-item> -->
              </div>
              <!-- <div *ngSwitchCase="'hotlist'">
                <nz-select nzBackdrop="true" style="width: 250px;" nzShowSearch nzAllowClear
                  [nzPlaceHolder]="getPlaceHolder(key)" [formControlName]="key"
                  (ngModelChange)="onChange($event, key)">
                  <nz-option *ngFor="let item of hotlistOptions" [nzLabel]="getLabelHotlist(item)" [nzValue]="item"></nz-option>
                </nz-select>
              </div> -->
              <div *ngSwitchCase="'showOnBidBoard'">
                <nz-select nzBackdrop="true" style="min-width: 150px;"
                  [nzPlaceHolder]="getPlaceHolder(key)" 
                  (ngModelChange)="onChange($event, key)" nzAllowClear [formControlName]="key">
                  <nz-option [nzLabel]="'No'" [nzValue]="'no'"></nz-option>
                  <nz-option [nzLabel]="'Yes'" [nzValue]="'yes'"></nz-option>
                </nz-select>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>

      <div class="form-item">
        <div class="form-label-v2"></div>
        <div style="padding-top: 5px">
          <label nz-checkbox [(ngModel)]="needDispatcherChecked" [ngModelOptions]="{standalone: true}" (ngModelChange)="onCheckboxNeedDispatcher($event)">Need Dispatcher</label>
        </div>
      </div>

      <div class="form-item">
        <div class="form-label-v2"></div>
        <div style="padding-top: 5px">
          <label nz-checkbox [(ngModel)]="needCarrierChecked" [ngModelOptions]="{standalone: true}" (ngModelChange)="onCheckboxNeedCarrier($event)">Need Carrier</label>
        </div>
      </div>

      <div class="form-item">
        <div class="form-label-v2"></div>
        <div style="padding-top: 5px">
          <label nz-checkbox [(ngModel)]="needPOPChecked" [ngModelOptions]="{standalone: true}" (ngModelChange)="onCheckboxNeedPOD($event)">Need POD</label>
        </div>
      </div>

      <div class="form-item">
        <div class="form-label-v2"></div>
        <button nz-button type="button" (click)="onBtnReassign()">
          <i nz-icon nzType="user-switch" nzTheme="outline"></i>
          Reassign
        </button>
      </div>

      <div class="form-item">
        <div class="form-label-v2"></div>
        <button nz-button type="button" (click)="onBtnExport()" [nzLoading]="isExporting">
          <i nz-icon nzType="file-excel" nzTheme="outline"></i>
          Export
        </button>
      </div>
    </div>
    <div style="display: flex; flex-direction: column; align-items: flex-end;">
      <a nz-button nzType="link" (click)="toggleExpand()" class="btn-expand" style="margin-top: 25px;">
        All Filters
        <i *ngIf="isExpanded" nz-icon nzType="minus" nzTheme="outline"></i>
        <i *ngIf="!isExpanded" nz-icon nzType="plus" nzTheme="outline"></i>
      </a>
      <a nz-button nzType="link" (click)="clearFilter()" style="margin-top: 35px;">Reset All</a>
    </div>
  </form>
  <div *ngIf="lastUpdated">
    <div class="last-updated">Request ID: {{lastUpdatedTime}} [{{requestId}}]</div>
  </div>
</div>

<!-- Chọn state theo quốc gia (US, Canada, Mexico) -->
<ng-template #tplSelectState let-key="key" let-form="form">
  <ng-container [formGroup]="form">
    <form-filter-states [formControlName]="key"
      [placeHolder]="getPlaceHolder(key)"
      [countriesStates]="countriesStates"
      (ngModelChange)="onChange($event, key)"></form-filter-states>
  </ng-container>
</ng-template>
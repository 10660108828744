<form class="form-detail" nz-form>
  <div class="flex">
    <div class="f20 bottom20 flex1">Carrier Sales Rep</div>
  </div>
  <nz-form-item>
    <nz-form-control>
      <nz-select nzBackdrop="true"
        nzAllowClear
        nzShowSearch
        nzPlaceHolder="Choose"
        [(ngModel)]="carrierSalesRepId"
        [nzLoading]="isFetchingCarrierSaleRep"
        [ngModelOptions]="{standalone: true}"
      >
        <nz-option *ngFor="let item of listCarrierSales"
          [nzValue]="item.id" [nzLabel]="getFullName(item)">
        </nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>

</form>

<div
  form-footer
  [nzIconCancel]="null"
  [nzIconOK]="null"
  [canClickCancel]="true"
  [canClickOK]="canClickOk()"
  labelOk="Save"
  (onOK)="onBtnSave()"
  (onCancel)="closeDialog()"
  [onProgress]="onProgress"
></div>

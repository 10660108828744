import { BaseComponent } from "@abstract/BaseComponent";
import { Component, Input } from "@angular/core";

@Component({
    selector: '[loading-bar]',
    templateUrl: './index.html',
    styleUrls: ['./index.scss',]
})
export class LoadingBar extends BaseComponent {
    @Input() height: string = '4px';
    @Input() width: string  = '100px'
}
<div class="form-header">
  <div class="form-title flex">
    <div class="form-title-main">
      <div class="bold f16 bottom10">{{formTitle}}</div>
    </div>
    <div style="margin-left: auto; order: 2">
      <i class="ic-right-close clickable" nz-icon nzType="close" nzTheme="outline" (click)="onClose()"></i>
    </div>
  </div>
</div>
<div class="separator h top10"></div>

<ng-container *ngIf="isEditOrCreate">
  <form nz-form [formGroup]="formInput" class="form-detail top10">
    <div nz-col *ngFor="let item of fields">
      <div class="form-label-v2">
        {{item.label}}
        <span *ngIf="item.required" class="label-mark-required"></span>
      </div>
      <ng-container [ngSwitch]="item.key">
        <ng-container *ngSwitchCase="'type'">
          <div [ngClass]="item.key">
            <nz-form-item>
              <nz-form-control>
                <nz-select [formControlName]="item.key">
                  <nz-option *ngFor="let option of ['email', 'phone']" [nzLabel]="option" [nzValue]="option">
                  </nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'scope'">
          <div [ngClass]="item.key">
            <nz-form-item>
              <nz-form-control>
                <nz-select [formControlName]="item.key">
                  <nz-option *ngFor="let option of scopes" [nzLabel]="option.label" [nzValue]="option.value">
                  </nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <div [ngClass]="item.key">
            <nz-form-item>
              <nz-form-control>
                <input nz-input [formControlName]="item.key" [placeholder]="item.placeHolder"
                  (input)="onInputChanged($event, fullKey(item.key))"
                  (keypress)="onInputKeyPress($event, fullKey(item.key))" />
              </nz-form-control>
            </nz-form-item>
          </div>
        </ng-container>

      </ng-container>
    </div>
  </form>
</ng-container>

<div class="form-footer" style="text-align: center">
  <ng-container *ngIf="requirePermissions([PermissionCode.comm_unsubscribes.update])">
    <div *ngIf="!isEditOrCreate" class="form-title-button">
      <button nz-button (click)="onBtnEdit()">Edit</button>
    </div>
  </ng-container>
  <div *ngIf="isEditOrCreate" class="form-title-button">
    <div form-footer class="no-border" [onProgress]="onProgress" [canClickOK]="needUpdate && !onProgress"
      [canClickCancel]="!onProgress" (onOK)="onBtnSave()" (onCancel)="onClose()"></div>
  </div>
</div>
import { Component, Input } from "@angular/core";
import { BaseDialog } from "@dialogs/base-dlg";
import { InputHelper } from "@services/input-helper";
@Component({
  selector: 'edit-weight',
  templateUrl: './index.html',
  styleUrls: ['./index.scss', '../../../../../../dialogs/dialogs.scss']
})
export class EditWeight extends BaseDialog {
  public onOk: () => void = () => {}
  public isLoading = false;

  protected formGroupDeclaration: FormGroupDeclaration = {
    weight: {
      label: "Weight",
      placeHolder: "Enter base price",
      required: true
    }
  };

  @Input() weight?: boolean;
  constructor() {
    super();
  }

  changeWeight(value){
    console.log('weight 111', value)
  }

  onBtnSave(): void {
    this.onOk();
    this.closeDialog();
  }
}

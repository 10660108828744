import { Component, Input } from '@angular/core';
import { Const } from "@const/Const";
import { Const as WarpConst } from "@wearewarp/universal-libs";
import { BaseFormItem } from "@app/admin/base/form-item";

@Component({
  selector: "[client-detail-cross-dock-info]",
  templateUrl: "./index.html",
  styleUrls: [
    "./index.scss",
    "../../../../detail.scss",
    "../../../../../../styles/row-col.scss",
    "../../../../../../styles/form-v2.scss",
  ],
})
// Cross Dock Shipment Information
export class ClientDetailCrossDockInfo extends BaseFormItem {
  protected formGroupDeclaration: FormGroupDeclaration = {
    inbound: {label: 'Inbound', type: 'formArray', initialValue: [{}], childItem: {
      taskType: {label: 'Task Type', inputType: 'hidden'},
      levels: {label: 'Level', type: 'formGroup', childItem: {
        shipment: { label: 'Shipment', type: 'boolean', required: true, initialValue: false, readOnly: true, submitReadOnly: true },
        item: { label: 'Item', type: 'boolean', initialValue: false },
      }},
    }},
    // sorting: {label: "", type: "formArray", initialValue: [{}], childItem: {
    //   taskType: {label: "Task Type", inputType: "hidden"},
    //   levels: {label: "Level", type: "formGroup", childItem: {
    //     shipment: { label: 'Shipment', type: 'boolean', required: true, initialValue: false, readOnly: true, submitReadOnly: true },
    //     item: { label: "Item", type: "boolean", initialValue: false },
    //   }},
    // }},
    outbound: {label: 'Outbound', type: 'formArray', initialValue: [{}], childItem: {
      taskType: {label: 'Task Type', inputType: 'hidden'},
      levels: {label: 'Level', type: 'formGroup', childItem: {
        shipment: { label: 'Shipment', type: 'boolean', required: true, initialValue: false },
        item: { label: 'Item', type: 'boolean', initialValue: false },
      }},
    }}
  };
  public taskSettings = [];

  private originModel;
  @Input() set myModel(value) {
    let currentModel = this.model;
    this.model = value;
    this.originModel = {...value}
    if(currentModel) this.bindDataModel(value);
  }
  get shouldCreateFormImmediately() {
    return false;
  }

  disableEditing() {
    this.setEnableFormGroup(false);
    this.isEditing = false;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.initFormValues();
  }

  protected getApiUrl(): string {
    return Const.APIURI_CLIENTS;
  }

  protected onUpdateSuccess(resp) {
    super.onUpdateSuccess(resp);
    this.disableEditing();
    this.originModel = {...this.model};
  }

  isLoadingWarehouseTasks = true;
  private async getWarehouseTasks() {
    const url = Const.APIV2(`${Const.APIURI_METADATA}/ORG_0/WAREHOUSE/warehouse-tasks`)
    const response = await this.api.GET(url).toPromise();
    const value = response.value || '[]';
    const settings = JSON.parse(value);
    let inboundTasks = [];
    // let sortingTasks = [];
    let outboundTasks = [];
    for(let setting of settings) {
      const levels = setting.levels;
      const levelObj = {}
      for (let level of levels) {
        let types: any[] = [WarpConst.WarehouseTaskType.uploadBOL];
        const _id = this.model.id;
        if (_id === "add") types = [...types, WarpConst.WarehouseTaskType.addWeight, WarpConst.WarehouseTaskType.uploadProductPhoto];
        if (types.includes(setting.type)) levelObj[level] = true;
        else levelObj[level] = false;
      }

      if (setting.stages.includes(WarpConst.WarehouseTaskStage.inbound)) {
        inboundTasks.push({
          taskType: setting.type,
          levels: levelObj
        })
      }
      // if(setting.stages.includes(WarpConst.WarehouseTaskStage.sorting)) {
      //   sortingTasks.push({
      //     taskType: setting.type,
      //     levels: levelObj
      //   })
      // }
      if (setting.stages.includes(WarpConst.WarehouseTaskStage.outbound)) {
        outboundTasks.push({
          taskType: setting.type,
          levels: levelObj
        })
      }
    }
    const taskSettings = [
      { type: WarpConst.WarehouseTaskStage.inbound, tasks: inboundTasks },
      // { type: WarpConst.WarehouseTaskStage.sorting, tasks: sortingTasks },
      { type: WarpConst.WarehouseTaskStage.outbound, tasks: outboundTasks }
    ];
    this.taskSettings = taskSettings;
    return taskSettings;
  }

  isDisable(mode, index, key) {
    const value = this.getItemValue(`${mode.type}[${index}].${key}`);
    if (mode.type === WarpConst.WarehouseTaskStage.inbound && value === WarpConst.WarehouseTaskType.scanPallet) return true;
    if (mode.type === WarpConst.WarehouseTaskStage.outbound && value === WarpConst.WarehouseTaskType.uploadBOL) return true;
    return false;
  }

  public getLevels(mode, index, key) {
    const value = this.getItemValue(`${mode.type}[${index}].${key}`);
    const tasks = mode.tasks;
    const task = tasks.find((it) => it.taskType === value);
    const levels = task?.levels || {};
    return Object.keys(levels);
  }

  async initFormValues() {
    this.isLoadingWarehouseTasks = true;
    const taskSettings = await this.getWarehouseTasks();
    this.isLoadingWarehouseTasks = false;
    if (taskSettings.length) {
      let inboundTasks = {};
      // let sortingTasks = {};
      let outboundTasks = {};
      for (let key of [WarpConst.WarehouseTaskStage.sorting, WarpConst.WarehouseTaskStage.inbound, WarpConst.WarehouseTaskStage.outbound]) {
        const { tasks } = this.taskSettings.find((it) => it.type === key) || {};
        if (key === WarpConst.WarehouseTaskStage.inbound) {
          for (let task of tasks) {
            inboundTasks = this.getTasks(task, inboundTasks, false, true);
          }
        }
        // if (key === WarpConst.WarehouseTaskStage.sorting) {
        //   for (let task of tasks) {
        //     sortingTasks = this.getTasks(task, sortingTasks, false, true);
        //   }
        // }
        if (key === WarpConst.WarehouseTaskStage.outbound) {
          for (let task of tasks) {
            outboundTasks = this.getTasks(task, outboundTasks, false, true);
          }
        }
      }
      this.formGroupDeclaration.inbound.initialValue = Object.values(inboundTasks);
      // this.formGroupDeclaration.sorting.initialValue = Object.values(sortingTasks);
      this.formGroupDeclaration.outbound.initialValue = Object.values(outboundTasks);
    }

    this.createFormInput(this.model);
    this.disableEditing();
  }

  protected beforeBindModel(model: any) {
    this.getSortingFromWarehouseTasks(model);
    return model;
  }

  private getSortingFromWarehouseTasks(model){
    if (model?.warehouseTasks?.length) {
      const warehouseTasks = model.warehouseTasks || [];
      let inboundTasks = {};
      // let sortingTasks = {};
      let outboundTasks = {};
      for (let key of [WarpConst.WarehouseTaskStage.sorting, WarpConst.WarehouseTaskStage.inbound, WarpConst.WarehouseTaskStage.outbound]) {
        const { tasks } = this.taskSettings.find((it) => it.type === key) || {};
        if(key === WarpConst.WarehouseTaskStage.inbound) {
          for(let task of tasks) {
            inboundTasks = this.getTasks(task, inboundTasks, false)
          }
        }
        // if (key === WarpConst.WarehouseTaskStage.sorting) {
        //   for (let task of tasks) {
        //     sortingTasks = this.getTasks(task, sortingTasks, false);
        //   }
        // }
        if(key === WarpConst.WarehouseTaskStage.outbound) {
          for(let task of tasks) {
            outboundTasks = this.getTasks(task, outboundTasks, false)
          }
        }
      }
      for (let task of warehouseTasks) {
        if(task.stage === WarpConst.WarehouseTaskStage.inbound) {
          inboundTasks = this.getTasks(task, inboundTasks)
        }
        // if (task.stage === WarpConst.WarehouseTaskStage.sorting) {
        //   sortingTasks = this.getTasks(task, sortingTasks);
        // }
        if(task.stage === WarpConst.WarehouseTaskStage.outbound) {
          outboundTasks = this.getTasks(task, outboundTasks)
        }
      }

      model.inbound = Object.values(inboundTasks);
      // model.sorting = Object.values(sortingTasks);
      model.outbound = Object.values(outboundTasks);
    }
  }

  private getTasks(task, obj, value = true, isUse = false) {
    if(!obj[task.taskType]) {
      let levels = isUse ? task.levels : {}
      if(task.level) {
        levels[task.level] = value;
      }
      obj[task.taskType] = {
        taskType: task.taskType,
        levels: levels
      }
    }
    else {
      const levels = obj[task.taskType]['levels'];
      if(task.level && !levels[task.level]) {
        let levels = obj[task.taskType]['levels'];
        levels[task.level] = value;
        obj[task.taskType]['levels'] = levels;
      }
    }

    return obj;
  }

  protected getFormData_JSON(isCreateNew: boolean): object {
    let data: any = super.getFormData_JSON(isCreateNew);
    data.warehouseTasks = this.getWarehouseTaskFromSorting(data);
    return data;
  }

  private getWarehouseTaskFromSorting(formDataJson){
    const settings = {
      inbound: formDataJson.inbound ? formDataJson.inbound : this.model.inbound,
      // sorting: formDataJson.sorting ? formDataJson.sorting : this.model.sorting,
      outbound: formDataJson.outbound ? formDataJson.outbound : this.model.outbound
    };
    let warehouseTasks = [];
    for (let key of [WarpConst.WarehouseTaskStage.sorting, WarpConst.WarehouseTaskStage.inbound, WarpConst.WarehouseTaskStage.outbound]) {
      const tasks = settings[key] || [];
      if (!tasks.length) continue;
      const taskMapped = [];
      for (let task of tasks) {
        const levels = task.levels || {};
        if (Object.values(levels).length) {
          for (let k of ["shipment", "item"]) {
            if (levels[k]) {
              taskMapped.push({
                taskType: task.taskType,
                level: k,
                stage: key,
              });
            }
          }
        }
      }
      warehouseTasks = [...warehouseTasks, ...taskMapped];
    }
    return warehouseTasks;
  }

  getTaskName(type, index, key) {
    const value = this.getItemValue(`${type}[${index}].${key}`);
    return Const.WarehouseTaskName[value] || value;
  }

  onBtnCancel() {
    this.myModel = {...this.originModel};
    this.createFormInput(this.model);
    this.disableEditing();
  }

  isInputsChange() {
    return this.isFormDataChanged();
  }

}

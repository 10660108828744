<div class="manifest-container">
  <div class="container-header">
    <div class="f16 medium">Documents</div>
  </div>
  <div class="container-body">
    <div nz-row class="bottom10">
      <div nz-col nzSm="8" nzLg="12" nzXl="10" class="label">BOL files</div>
      <div nz-col nzSm="8" nzLg="12" nzXl="14">
        <div class="btn-add-file" (click)="onBtnViewAddBOLFiles()">
          <i nz-icon nzType="paper-clip" nzTheme="outline"></i> View / Add files
        </div>
      </div>
    </div>
    <div nz-row class="bottom10">
      <div nz-col nzSm="8" nzLg="12" nzXl="10" class="label">Container images</div>
      <div nz-col nzSm="8" nzLg="12" nzXl="14">
        <div class="btn-add-file" (click)="onBtnViewAddContainerImages()">
          <i nz-icon nzType="paper-clip" nzTheme="outline"></i> View / Add images
        </div>
      </div>
    </div>
  </div>
</div>
<nz-form-item>
  <nz-form-control>
    <nz-select [(ngModel)]="value" [nzDisabled]="isDisabled" nzBackdrop="true" nzOptionHeightPx="55" style="width: 100%;" [nzLoading]="isLoading"
      [nzShowSearch]="true" nzAllowClear nzPlaceHolder="Please select driver" nzDropdownClassName="choose-driver">
      <nz-option nzCustomContent *ngFor="let driver of allDrivers" [nzLabel]="driver.label" [nzValue]="driver.id">
        <div>{{driver.name}}</div>
        <div *ngIf="driver.phone" class="sub-info">{{driver.phone}}</div>
      </nz-option>
    </nz-select>
  </nz-form-control>
</nz-form-item>

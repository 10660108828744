
import { Component, ViewChild, ElementRef, Input } from '@angular/core';
import { BaseDetail } from '@app/admin/base/detail';
import { ActivatedRoute } from '@angular/router';
import { Const } from '@const/Const';
import { DateUtil } from "@services/date-utils";
import { ApiService } from '@services/api.service';
@Component({
  selector: 'crossdock-warehouse-map',
  templateUrl: './view.html',
  styleUrls: ['./style.scss']
})
export class CrossdockWarehouseMap extends BaseDetail {
  private _warehouseId: string;

  @Input() set warehouseId(value: string) {
     this._warehouseId = value;
     this.getCrossdockWarehouseDetail(this._warehouseId);
  }
  
  get warehouseId(): string {
    return this._warehouseId;
  }
  protected get crudEntity(): string { return 'crossdock-warehouse-map' }

  public crossdockWarehouseList = []; //list warehouse hiển thị trong selectbox
  public inventoriesData; // dữ liệu inventories của warehouse đang chọn (fetch từ server)
  private syncInventoriesData = []; //dữ liệu gửi lên khi ấn nút đồng bộ

  public listInventoryOnTable = []; //list inventory hiển thị trên table mode view


  public selectedInventoryInfo; //dữ liệu của ô đang đc click
  private previousInventory; //dữ liệu của ô đc click trước ô hiện tại
  private previousInventoryStatus; //trạng thái của ô đc click trước đó (empty/full)

  public isLoadingWarehouseList; //loading khi get list warehouse
  public isLoadingListInventoryOnTable //loading khi get list warehouse hiển thị trên table view
  public isLoadingMap; //loading khi init svg map
  public isLoadingInventoryInfo; //loading khi click vào từng ô
  public isLoadingSyncSvg; //loading khi đồng bộ SVG
  public isLoadingRemoveItem; //loading khi remove Item
  public isLoadingExport; //loading khi export

  public isValidSvg; //check xem SVG đã đúng format hay chưa

  constructor(protected activatedRoute: ActivatedRoute) {
    super(activatedRoute);
  }

  handleNavigationEnd() {
  }

  convertInventoriesData(){
    const inventories = this.model?.inventories
    return inventories.reduce(
      (obj, item) => Object.assign(obj, {
        [item.code]: {
          ...item,
          status: item?.items?.length ? //cứ có items.length > 0 là trạng thái full
            Const.INVENTORY_FULL_STATUS :
            Const.INVENTORY_EMPTY_STATUS,
          id: item.id        
        }
      }), {});
  }

  initWarehouseData(data){
    this.model = data; 
  }

  initInventoriesData(){
    this.inventoriesData = this.convertInventoriesData() //convert sang object key (key ở đây là inventory code) để dễ lấy dữ liệu cho tiện
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  async getCrossdockWarehouseDetail(warehouseId) {
    console.log('call vào đây ')
    if (!warehouseId) return
    this.isLoadingMap = true;
    this.clearSvgMap(); //xóa toàn bộ dữ liệu cũ đi
    const url = `${Const.APIURI_WAREHOUSES}/${warehouseId}`;
    const resp = await this.api.GET(url).toPromise().catch(e => {
      this.showErr(`${e.message}`)
      this.isLoadingMap = false;
    });

    this.initWarehouseData(resp?.data) // set dữ liệu warehouse detail
    this.initInventoriesData(); //set dữ liệu inventories từ warehouse detail
    this.initSvgMap(); //hiển thị map
    
    this.isLoadingMap = false;
  }


  get isShowNoData() {
    return !this.model?.svgMap && !this.isLoadingMap && !this.isLoadingInventoryInfo
  }

  get isShowWarehouseOverview() {
    // return false
    return !this.isLoadingMap && !this.isLoadingInventoryInfo && !this.selectedInventoryInfo && this.model
  }

  get isShowInventoryDetail() {
    // return true
    return !this.isLoadingMap && !this.isLoadingInventoryInfo && this.selectedInventoryInfo 
  }

  getDetailLabelText(){
    return `AREA ${this.selectedInventoryInfo?.name || 'N/A'}`
  }

  setInventoryDefaultStyle(inventory) {
    inventory.style.cursor = "pointer";

    let textElement = this.getTextElement(inventory);
    if (!textElement) return;
    textElement.style.fontFamily = "Inter, Roboto, Helvetica, Arial, sans-serif"; //svg ban đầu không nhận font mặc định => set lại
    textElement.style.fontSize = '13px';
    textElement.style.whiteSpace = null ;
    textElement.removeAttribute("xml:space");
  }

  initInventoryStyle(inventory) {
    const status = this.getInventoryStatus(inventory)
    this.setInventoryDefaultStyle(inventory)
    this.setInventoryStyleByStatus(inventory, status)
  }

  clearSvgMap() {
    if (!this.mapArea) return
    this.mapArea.innerHTML = "" //clear map svg
    this.selectedInventoryInfo = undefined //clear dữ liệu ô đã chọn
    this.model = undefined //clear dữ liệu warehouse detail
    this.isValidSvg = undefined 
    this.syncInventoriesData = []
  }

  async initSvgMap() {
    if (!this.mapArea) return
    if (!this.model?.svgMap) {
      return this.mapArea.innerHTML = "Please check svgMap URL in database !"
    }
    this.mapArea.innerHTML = this.model?.svgMap
    
    let svgTag = this.mapArea.getElementsByTagName("svg")?.[0];
    if (!svgTag) {
      return this.mapArea.innerHTML = "Data is not in SVG format"
    }
    svgTag.style.width = "100%"

    const svgMap = document.getElementById('warehouse-map');
    if (!svgMap) {
      return this.mapArea.innerHTML = "SVG map is wrong structure - Not found 'warehouse-map' element !"
    }
    const inventories = svgMap?.getElementsByTagName("g");
    if (!inventories.length) {
      return this.mapArea.innerHTML = "SVG map is wrong structure - Not found 'g' tag !"
    }
    Array.from(inventories).forEach(inventory => {
      //set 1 số style mặc định, đổi màu các ô theo status của ô đó
      this.initInventoryStyle(inventory) 
      //gán sự kiện click vào các ô để click => hiển thị chi tiết
      this.addEventClickToMap(inventory)
      //tạo sẵn dữ liệu để đồng bộ vào DB => nếu về sau ảnh hưởng đến performance có thể viết riêng ra
      this.createSyncInventoriesData(inventory) 
    });
    this.isValidSvg = true //biến này dùng để biết file SVG đã check hợp lệ chưa
  }

  addEventClickToMap(inventory){
    inventory.addEventListener("click", () => this.onClickInventory(inventory)); 
  }

  getInventoryId(inventory) { //đây là id trong database
    return this.inventoriesData?.[inventory?.id]?.id; //id lấy từ inventory DOM này chính là inventory code
  }

  getInventoryOrderItems(inventory) {
    return this.inventoriesData?.[inventory?.id]?.items; //id lấy từ inventory DOM này chính là inventory code
  }

  getInventoryStatus(inventory) {
    return this.inventoriesData?.[inventory?.id]?.status;
  }

  get mapArea() {
    return document.getElementById("mapArea");
  }

  getCellElement(inventory) {
    return inventory.getElementsByTagName('rect')?.[0];
  }

  getTextElement(inventory) {
    return inventory.getElementsByTagName('text')?.[0];
  }

  getTSpanElement(inventory) {
    return inventory.getElementsByTagName('tspan')?.[0];
  }

  getClassName(inventory) {
    return inventory.getAttribute('class')
  }

  setInventoryStyleByStatus(inventory, status) {
    switch (status) {
      case Const.INVENTORY_EMPTY_STATUS: //style của ô khi trạng thái là empty
        this.getCellElement(inventory).setAttribute('fill', Const.INVENTORY_MAP_EMPTY_BG_COLOR);
        this.getTextElement(inventory).setAttribute('fill', Const.INVENTORY_MAP_DEFAULT_TXT_COLOR);
        break;
      case Const.INVENTORY_FULL_STATUS: //style của ô khi trạng thái là full
        this.getCellElement(inventory).setAttribute('fill', Const.INVENTORY_MAP_FULL_BG_COLOR);
        this.getTextElement(inventory).setAttribute('fill', Const.INVENTORY_MAP_DEFAULT_TXT_COLOR);
        break;
      default: //các ô không có status thì mặc định để style giống style khi empty
        this.getCellElement(inventory).setAttribute('fill', Const.INVENTORY_MAP_EMPTY_BG_COLOR);
        this.getTextElement(inventory).setAttribute('fill', Const.INVENTORY_MAP_DEFAULT_TXT_COLOR);
    }
  }

  setInventoryStyleSelected(inventory) {
    //style của ô khi đc chọn (selected)
    this.getCellElement(inventory).setAttribute('fill', Const.INVENTORY_MAP_SELECTED_BG_COLOR);
    this.getTextElement(inventory).setAttribute('fill', Const.INVENTORY_MAP_SELECTED_TXT_COLOR);
  }

  handleInventoryStyle(inventory) {
    if (this.previousInventory) {
      //khi click sang ô khác thì thay đổi ô trước đó về màu chuẩn của ô đó
      this.setInventoryStyleByStatus(this.previousInventory, this.previousInventoryStatus)
    }
    this.setInventoryStyleSelected(inventory)

    this.previousInventory = inventory
    this.previousInventoryStatus = this.getInventoryStatus(inventory)
  }

  async setSelectedInventoryInfo(inventoryCode) {
    this.isLoadingInventoryInfo = true;
    const url = `${Const.APIURI_INVENTORIES}/get_order_item_info_on_svg_map`;
    const params = {warehouseId: this.warehouseId, inventoryCode: inventoryCode}
    const resp = await this.api.POST(url, params).toPromise().catch(e => {
      this.showErr(`${e.message}`)
      this.selectedInventoryInfo = undefined
      this.isLoadingInventoryInfo = false;
    });
    this.selectedInventoryInfo = resp?.data 
    this.isLoadingInventoryInfo = false;
  }

  onClickInventory(inventory) {
    this.handleInventoryStyle(inventory)
    this.setSelectedInventoryInfo(inventory?.id)// lấy từ HTML DOM nên id ở đây bản chất là code
  }

  async syncInventoryFromSvgMapWithDeleteBefore() {
    this.confirmYesNo("Are you sure you want to sync (delete all & sync) ?", async () => {
      this.isLoadingSyncSvg = true;
      await this.getCrossdockWarehouseDetail(this.warehouseId) //fetch lại detail về frontend để có dữ liệu svg mới nhất
      const url = `${Const.APIURI_WAREHOUSES}/sync_inventories_from_svg_map_with_delete_before`;
      const params = { warehouseId: this.model?.id, inventories: this.syncInventoriesData }
      const resp = await this.api.POST(url, params).toPromise().catch(e => {
        this.showErr(`${e.message}`)
        this.selectedInventoryInfo = undefined
        this.isLoadingSyncSvg = false;
      });
      if (resp) this.showSuccess("Data has been synced !");
      await this.getCrossdockWarehouseDetail(this.warehouseId)
      this.isLoadingSyncSvg = false;
    })
  }
  createSyncInventoriesData(inventory) {
    this.syncInventoriesData.push({
      warehouseId: this.model?.id,
      code: inventory?.id, //"id" lấy từ htmlDOM của SVG đang quy ước là "code"
      name: this.getTSpanElement(inventory)?.innerHTML,
      type: this.getClassName(inventory)?.split(" ") || ["inventory"],
    })
  }

  removeItem(inventory, item){
    this.confirmDeletion({
      message: 'Do you want remove this item?',
      txtBtnOk: 'Remove',
      fnOk: async () => {
        this.isLoadingRemoveItem = true
        const url = `${Const.APIURI_INVENTORIES}/remove_items`;
        const params = { inventoryId: inventory?.id, routeId: item?.routeId, itemId: item?.id} 
        const resp = await this.api.POST(url, params).toPromise().catch(e => {
          this.showErr(`${e.message}`)
          this.isLoadingRemoveItem = false
        });
        if (resp) this.showSuccess("Item has been removed !");
        this.isLoadingRemoveItem = false
        await this.setSelectedInventoryInfo(inventory?.code)
        if (!this.selectedInventoryInfo.orderItemInfos?.length) {
          await this.getCrossdockWarehouseDetail(this.warehouseId) //khi nào ko còn item nào trong inventory thì update lại map
        }
      }
    });
  }

  getLinkToShipmentDetail(item){
    return `${this.routeAdminShipmentList}/${item?.orderId}`
  }

  formatDate(date) {
    return DateUtil.formatDate(date)
  }

  get naText() {
    return 'N/A'
  }

  getItemWarpId(item){
    if (!item?.warpId) return this.naText
    return this.showItemWarpId(item?.warpId)
  }

  getItemBarcode(item){
    if (!item?.barcode) return this.naText
    return item?.barcode
  }

  getItemWeight(item){
    if (!item?.weight) return this.naText
    return item?.weight
  }

  getItemShipmentId(item){
    if (!item?.shipmentWarpId) return this.naText
    return this.showShipmentWarpId(item?.shipmentWarpId)
  }

  getItemLegId(item){
    if (!item?.legWarpId) return this.naText
    return this.showShipmentWarpId(item?.legWarpId)
  }

  getItemToCity(item){
    if (!item?.toCity) return this.naText
    return item?.toCity
  }

  getItemDateReceived(item){
    if (!item?.dateReceived) return this.naText
    return this.formatDate(item?.dateReceived)
  }
}

<div class="box-container">
    <ngx-json-viewer [json]="_event" [depth]="0"></ngx-json-viewer>
    <div *ngIf="processingResults">
        <nz-divider></nz-divider>
        {{ processingResults.length }} Processing Results
        <div *ngFor="let r of processingResults" class="bottom5 inner-box">
            <div (click)="r.show = !r.show" class="clickable">
                <span nz-icon nzType="right" *ngIf="!r.show" nzTheme="outline"></span>
                <span nz-icon nzType="down" *ngIf="r.show" nzTheme="outline"></span>
                <code>{{ r.handlerId }}</code>
                <span nz-icon nzType="warning" nzTheme="outline" *ngIf="r.status == 'ERROR'"></span>
            </div>
            <ngx-json-viewer *ngIf="r.show" [json]="r" [depth]="0"></ngx-json-viewer>
        </div>
    </div>
</div>
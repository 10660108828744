import { Component } from "@angular/core";
import { BaseDialog } from "@dialogs/base-dlg";
import { Input } from "@angular/core";
import { NzFormatEmitEvent } from "ng-zorro-antd/tree";

@Component({
    selector: '[detail-log]',
    templateUrl: './log-detail.html',
    styleUrls: ['../../../../dialogs/dialogs.scss', './log-detail.scss']
  })
  export class DetailLog extends BaseDialog {

    @Input() data: any;

    ngOnInit(): void {
      // this.data = ;
    }
    nzEvent(event: NzFormatEmitEvent): void {
      
    }
  }
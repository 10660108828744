import { Component, Input } from "@angular/core";
import { Const } from "@const/Const";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { Log } from "@services/log";
import { Utils } from "@services/utils";
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { Mapbox } from "@services/mapbox";
import { MasterData } from "@services/master.data";
import { DateUtil } from "@services/date-utils";
import { countryListAlpha2 } from "./country-code";
import { MapboxStaticService } from "../../mapbox-static/service";

@Component({
  selector: '[select-address-from-map]',
  templateUrl: './select-address-from-map.html',
  styleUrls: [ '../../../../dialogs/dialogs.scss', '../../../../../styles/row-col.scss', './select-address-from-map.scss']
})
export class SelectAddressFromMap extends BaseFormDialog1 {
  protected formGroupDeclaration: FormGroupDeclaration = {
    street: {label: 'Street address', required: true, placeHolder: ''},
    street2: {label: 'Street address 2', required: false, placeHolder: ''},
    city: {label: 'City', required: true, placeHolder: ''},
    state: {label: 'State', required: true, placeHolder: ''},
    zipcode: {label: 'Zip code', required: true, placeHolder: ''},
    countryAlpha2Code: {label: 'Country', required: true, initialValue: Const.CountryCodeAlpha2_USA},
    manualAddressEntry: {label: '', initialValue: true},
    metadata : { label: 'Metadata', type: 'formGroup', childItem: {
      timeZone: {label: 'US short time zone'},
      timeZoneStandard: {label: 'Time zone standard', required: true},
      latitude: {label: 'Latitude', type: 'number', required: true, readOnly: true, submitReadOnly: true},
      longitude: {label: 'Longitude', type: 'number', required: true, readOnly: true, submitReadOnly: true}
    }},
  }

  private allStatesUS = MasterData.getStatesUS();
  private allStateNonUS = [];
  private allShortTimezonesUS = DateUtil.listUsTimezones;
  private allShortTimezonesNonUS = [];
  public allTimezones = DateUtil.listTimezones;
  public allCountries = MasterData.getAllCountries();
  public country = countryListAlpha2;

  public mapBox;
  newAddressMarker;
  searchGeocoder;

  constructor(private mapboxStaticService: MapboxStaticService) {
    super();
  }
  ngAfterViewInit(): void {
      this.mapboxStaticService.attachMap(document.getElementById('dispatch-map-address'));
  }

  get isCountryUS(): boolean {
    let countryCode = this.formInput?.get('countryAlpha2Code')?.value;
    return !countryCode || countryCode == Const.CountryCodeAlpha2_USA
  }

  get allShortTimezones() {
    if (this.isCountryUS) {
      return this.allShortTimezonesUS;
    }
    return this.allShortTimezonesNonUS;
  }

  get allStates() {
    if (this.isCountryUS) {
      return this.allStatesUS;
    }
    return this.allStateNonUS;
  }

  get isCreateNew(): boolean {
    return !this.model;
  }
  public isError = false;
  public isLoading = false;
  @Input() onSave: (data) => void = null;

  ngOnInit(): void {
    super.ngOnInit();
    this.mapBox = new Mapbox();
    setTimeout(() => { 
      this.setupMap();
    }, 200);
  }

  public get idMap(): string {
    return `dispatch-map-address`
  }

  get map() {
    return this.mapBox.getMapbox(this.idMap);
  }

  private setupMap() {
    let center = Const.MAP_LOCATION_LA;
    this.mapBox.setupMap([], [], [], 'streets-v11', 'routes', this.idMap);
    this.newAddressMarker = new mapboxgl.Marker({draggable: true}).setLngLat(center).addTo(this.map);
    this.searchGeocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      placeholder: 'Search address',
      mapboxgl: mapboxgl
    });
    // thêm search address vào map
    this.map.addControl(this.searchGeocoder);

    // khi search được kết quả thì set lại maker trên map và update latlng
    this.searchGeocoder.on('result', ({result}) => {
      if (result?.center) {
        this.newAddressMarker.setLngLat(result.center)
        this.updateMetadateLngLat(result.center);
      }
    });

    this.newAddressMarker.on('dragend', () => {
      this.onDragEnd();
    });

    this.map.on('click', (e) =>{
      if (e.lngLat?.lng && e.lngLat?.lat) {
        let lngLat: [any,any] = [e.lngLat.lng, e.lngLat.lat];
        this.newAddressMarker.setLngLat(lngLat);
        this.updateMetadateLngLat(lngLat);
      }
    })
  }

  private onDragEnd() {
    const lngLat = this.newAddressMarker.getLngLat();
    this.updateMetadateLngLat([lngLat.lng, lngLat.lat]);
  }

  private updateMetadateLngLat([longitude, latitude]) {
    // xóa input search để xóa icon result trên map. chỉ để mỗi icon maker thôi
    this.searchGeocoder?.clear();
    if (latitude != null && longitude != null) {
      this.setItemValue('metadata.latitude',latitude);
      this.setItemValue('metadata.longitude',longitude);
      this.getTimezoneByLocation(latitude, longitude);
    }
  }

  onBtnSave() {
    if (!this.needUpdate) return
    let data = this.getFormData_JSON(true);
    this.onSave(data);
    this.closeDialog();
  }



  addState(value: string) {
    let code = value?.trim();
    if (!code) {
      return;
    }
    for (let item of this.allStateNonUS) {
      if (item.code == code) {
        return;
      }
    }
    this.allStateNonUS.push({code: code});
  }

  addShortTimezone(value: string) {
    let code = value?.trim();
    if (!code) {
      return;
    }
    for (let item of this.allShortTimezonesNonUS) {
      if (item == code) {
        return;
      }
    }
    this.allShortTimezonesNonUS.push(code);
  }

  private getTimezoneByLocation(lat, lng) {
    let url = `${Const.API_PUBLIC('timezone_by_location')}?lat=${lat}&lng=${lng}`;
    this.api.GET(url).subscribe(
      resp => {
        Log.d(`Location [${lat}, ${lng}], timezone: `, resp);
        let data = resp.data;
        let timeZoneStandard = data.zoneName;
        let timeZone = data.abbreviation;
        let currentValueTimeZoneStandard = this.getItemValue('metadata.timeZoneStandard');
        if (!currentValueTimeZoneStandard) {
          // Nếu form chưa có dữ liệu thì điền luôn
          this.applyTimezone(timeZoneStandard, timeZone);
        } else if (currentValueTimeZoneStandard != timeZoneStandard) {
          // Nếu dữ liệu trên form khác với API trả về thì confirm
          this.confirmYesNo(`The time zone you've entered <b>${currentValueTimeZoneStandard}</b> is different to the time zone responsed by API <b>${timeZoneStandard}</b>.<br><br>Do you want to replace with the value from API <b>${timeZoneStandard}</b>?`, () => {
            this.applyTimezone(timeZoneStandard, timeZone);
          });
        }
      },
      err => {
        this.showErr(err);
      }
    )
  }

  private applyTimezone(timeZoneStandard, timeZone) {
    this.addShortTimezone(timeZone);
    this.setItemValue('metadata.timeZoneStandard', timeZoneStandard);
    this.setItemValue('metadata.timeZone', timeZone);
  }

  private _getPlaceHolder(key: string, defaultValue: string): FormControlPlaceHolder {
    let str = super.getPlaceHolder(key);
    if (!str) {
      str = defaultValue;
    }
    return str;
  }

  getPlaceHolder(key: string): FormControlPlaceHolder {
    switch (key) {
      case 'street': return this._getPlaceHolder(key, 'Number & street');
      case 'street2': return this._getPlaceHolder(key,'Street 2');
      case 'city': return this._getPlaceHolder(key, 'City');
      case 'state': return this._getPlaceHolder(key, 'State');
      case 'zipcode': return this._getPlaceHolder(key, 'Zip code');
      case 'countryAlpha2Code': return this._getPlaceHolder(key, 'Country');
      case 'timeZone': return this._getPlaceHolder(key, 'Short time zone US');
      case 'timeZoneStandard': return this._getPlaceHolder(key, 'Standard time zone');
      case 'latitude': return this._getPlaceHolder(key, 'Latitude');
      case 'longitude': return this._getPlaceHolder(key, 'Longitude');
      default: return this._getPlaceHolder(key, '');
    }
  }

  onInputChanged(event, key) {
    switch (key) {
      case 'countryAlpha2Code':
        this.setItemValue('state', null);
        this.allStateNonUS = [];
        this.allShortTimezonesNonUS = [];
        return;
      case 'street':
      case 'zipcode':
        return; // TODO
    }
  }
  
  onInputKeyPress(event, key) {
    if (key == 'zipcode') {
      return this.onlyNumberKey(event)
    }
    return true;
  }

  onInputFocusOut(event: any, key: any): void {
    switch (key) {
      case 'latitude':
      case 'longitude':
        let lat = this.getItemValue('metadata.latitude');
        let lng = this.getItemValue('metadata.longitude');
        if (lat != null && lng != null) {
          this.getTimezoneByLocation(lat, lng);
        }
        break;
      case 'street':
      case 'street2':
      case 'city':
      case 'state':
      case 'zipcode':
        break;
      default:
        break;
    }
  }

}

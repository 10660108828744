import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { NzIconModule } from "ng-zorro-antd/icon";
import { CarrierCostIssue } from './component';
import { ModuleNotes } from '@app/admin/components/notes/module';

@NgModule({
  imports: [
    CommonModule,
    ModuleNotes,
    NzIconModule,
  ],
  declarations: [
    CarrierCostIssue
  ],
  exports: [
    CarrierCostIssue
  ]
})
export class ModuleCarrierCostIssue {}
import { CompositeLayer } from 'deck.gl';
import { IconLayer } from '@deck.gl/layers'
import _ from 'underscore'
import { PIN_ICON_MAPPING } from './warehouse_pin_mapping';

export default class WarehouseLayer extends CompositeLayer {
    id: string
    static layerName = "WarehouseLayer"
    warehouses: any[] = []

    constructor(id, warehouses) {
        super({id, data: warehouses})
        this.id = id
        this.warehouses = warehouses
    }

    createWarehousePinLayer() {
        if (!this.warehouses) return null
        const data = this.warehouses.map(it => {
            const { name, pickAddr, warpId } = it || {}
            const { metadata, street, state, zipcode } = pickAddr || {}
            const { latitude, longitude } = metadata
            return {
                coordinates: [parseFloat(longitude), parseFloat(latitude)],
                type: 'WAREHOUSE',
                warehouse: it,
                info: `[${warpId}] ${name}\n${street}\n${state} ${zipcode}`
            }
        })

        return new IconLayer({
            id: 'icon-layer',
            data,
            pickable: true,
            iconAtlas: '/assets/svg/pin.svg',
            iconMapping: PIN_ICON_MAPPING,
            getIcon: d => 'green',
            sizeScale: 15,
            getPosition: d => d.coordinates,
            getSize: d => 1.5,
        });
    }

    renderLayers() {
        return [
            this.createWarehousePinLayer(),
        ];
    }

}
import { Component, Input } from "@angular/core";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { Utils } from "@services/utils";
import { VehicleTypeService } from "@services/vehicle.service";
import { Observable } from "rxjs";

@Component({
  selector: "edit-optional-equipment",
  templateUrl: "./index.html",
  styleUrls: [
    "./index.scss",
  ],
})
export class EditOptionalEquipment extends BaseFormDialog1 {
  @Input() onSave: (data) => Observable<any>;
  optionalEquipments = [];
  vehicleTypeService: VehicleTypeService = null;
  vehicles = [];

  protected formGroupDeclaration: FormGroupDeclaration = {
    optionalEquipments: {
      label: "Optional",
      placeHolder: "Equipments",
      initialValue: []
    },
  }

  get isCreateNew(): boolean {
    return !this.model;
  }

  constructor() {
    super();
    this.vehicleTypeService = new VehicleTypeService(this.api)
    this.loadVehicles()
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  protected beforeBindModel(model): any {
    if (model?.optionalEquipments?.length) {
      this.optionalEquipments = model.optionalEquipments.map(it => it.value)
    }
    return model;
  }

  loadVehicles() {
    this.vehicleTypeService.listAll(
      (data) => {
        this.vehicles = data.filter(it => it.active !== false)
      },
      () => {}
    )
  }

  onDropdownSelectChange(key: string, event) {
    switch (key) {
      case 'optionalEquipments':
        let data = [];
          if (event?.length) {
            const vehicle = this.vehicles.filter(it => (event ?? []).includes(it.code) );
            data = vehicle?.map(it => Object.assign({}, {
              label: it.name,
              value: it.code,
            }));
          }
          this.setItemValue(key, data);
      default:
        break;
    }
  }

  onBtnSave(): void {
    let data = this.getFormData_JSON(true);
    this.onProgress = true;
    this.onSave(data).subscribe(
      resp => {
        super.onUpdateSuccess(resp);
        this.closeDialog();
        this.onProgress = false;
      }, err => {
        this.showErr(err);
        this.onProgress = false;
      }
    );
  }

}

<style type="text/css">
  .model-btns {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .title {
    font-weight: 600;
    font-size: 16px;
  }

  .dlg-success {
    width: 600px;
  }

  .dlg-items {
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>

<div>
  <div *ngIf="actionName" class="title dlg-items bottom10">{{actionName}}</div>
  <div class="dlg-items bottom20">There are some data you have selected that are not valid for action.</div>

  <div class="bottom20">
    <nz-table #basicTable [nzData]="_items" [nzHideOnSinglePage]="true">
      <thead>
        <tr>
          <th>Shipment</th>
          <th>Inbound Leg</th>
          <th>Outbound Leg</th>
          <th>Validation</th>
          <th>Reason for failure</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let _item of basicTable.data">
          <td>{{showShipmentWarpId(_item?.parent?.warpId)}}</td>
          <td>{{showShipmentWarpId(_item?.inboundLeg?.warpId)}}</td>
          <td>{{showShipmentWarpId(_item?.outboundLeg?.warpId)}}</td>
          <td style="text-align: center;">
            <span *ngIf="!_item?.isValid" nz-icon nzType="close-circle" [nzTheme]="'twotone'"
              [nzTwotoneColor]="'#ff4d4f'" style="font-size: 18px;"></span>
            <span *ngIf="_item?.isValid" nz-icon [nzType]="'check-circle'" [nzTheme]="'twotone'"
              [nzTwotoneColor]="'#52c41a'"  style="font-size: 18px;"></span>

          </td>
          <td><span nz-typography nzType="danger">{{_item.reason}}</span> </td>
        </tr>
      </tbody>
    </nz-table>
  </div>

  <div class="flex">
    <div *ngIf="showUpdateScheduleTime">
      <button nz-button nzType="primary" nzSize="small" (click)="updateScheduleTime()">
        <i nz-icon nzType="edit" nzTheme="outline"></i> {{btnTitle}}
      </button>
    </div>
    <div style="margin-left: auto; order: 2;">
      <span>Total: {{summaryText}}</span>
    </div>
  </div>
</div>
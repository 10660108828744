// Deck.gl arc layer with custom shader
import { ArcLayer } from '@deck.gl/layers'

export default class ArcBrushingLayer extends ArcLayer {
  getShaders() {
    // here comes our custom shader
    // we will use step function to create opacity gradient with colorA and color B
    // for more information see https://thebookofshaders.com/05/
    return Object.assign({}, super.getShaders(), {
      inject: {
        'vs:#decl': `
           uniform float coef;
           uniform float baseOpacity;
          `,
        'vs:#main-end': `
          vec4 pct = vec4(segmentRatio);
          vec4 colorA = mix(instanceSourceColors, instanceTargetColors, pct);
          float pcta = 1.0 + coef;
          if (coef > 0.0) {
            if (pct.a > coef)
              colorA.a = baseOpacity;
          } else {
            if (pct.a < pcta)
              colorA.a = baseOpacity;
          }
          vColor = colorA;
          `,
        'fs:#main-start': `
          if (vColor.a == 0.0) discard;
                      `
      }
    });
  }

  draw(opts) {
    const { coef, baseOpacity } = this.props;
    // add uniforms
    const uniforms = Object.assign({}, opts.uniforms, { coef: coef, baseOpacity: baseOpacity ?? 0.3});
    super.draw(Object.assign({}, opts, { uniforms }));
  }
}
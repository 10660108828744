import {Component, Input} from '@angular/core';
import { Const } from "@const/Const";
import { FormControl } from "@angular/forms";
import { InputHelper } from "@services/input-helper";
import { BaseFormItem } from "@app/admin/base/form-item";
import { Utils } from "@services/utils";

@Component({
  selector: "[client-detail-payment]",
  templateUrl: "./index.html",
  styleUrls: [
    "./index.scss",
    "../../../../detail.scss",
    "../../../../../../styles/row-col.scss",
    "../../../../../../styles/form-v2.scss",
  ],
})
// Payment Information
export class ClientDetailPayment extends BaseFormItem {
  protected formGroupDeclaration: FormGroupDeclaration = {
    paymentTerm: {label: 'Payment Terms'},
    payments: {
      label: "",
      type: "formArray",
      initialValue: [],
      childItem: {
        id: { label: "" },
        bankName: { label: "Bank Name", placeHolder: "Enter Bank Name", required: true },
        bankRoutingNumber: { label: "Routing Number", placeHolder: "Enter Routing Number", required: true },
        bankAccountNumber: {
          label: "Bank Account Number",
          placeHolder: "Enter Bank Account Number",
          required: true,
          validators: [this.validateAccountNumber],
        },
        ein: { label: "EIN Number", placeHolder: "Enter EIN Number", required: true },
        ssn: { label: "SSN Number", placeHolder: "Enter SSN Number", notAcceptEmpty: false },
      },
    },
  };
  private originModel;
  @Input() set myModel(value) {
    let currentModel = this.model;
    this.model = value;
    this.originModel = {...value};
    if(currentModel) this.bindDataModel(value);
  }

  public allPaymentTerms = ['prepay'];

  ngOnInit(): void {
    super.ngOnInit();
    this.disableEditing();
  }

  disableEditing() {
    this.setEnableFormGroup(false);
    this.isEditing = false;
  }

  protected getApiUrl(): string {
    return Const.APIURI_CLIENTS;
  }

  protected onUpdateSuccess(resp) {
    super.onUpdateSuccess(resp);
    this.disableEditing();
    this.originModel = {...this.model};
  }

  get isExistPaymentInfo() {
    return this.getItemValue("payments")?.length ? true : false;
  }

  validateAccountNumber(input: FormControl) {
    const value = input?.value?.trim();
    if (InputHelper.isBankAccountNumberValid(value)) return null;
    return {
      invalid: {
        en: "Invalid account number",
      },
    };
  }

  protected getFormData_JSON(isCreateNew: boolean): object {
    let data: any = super.getFormData_JSON(true);
    data.payments.forEach((item) => {
      //generate System ULID for bank info
      if (!item.id) item.id = Utils.generateULID();
    });
    return data;
  }

  onBtnCancel() {
    this.myModel = {...this.originModel};
    this.createFormInput(this.model);
    this.disableEditing();
  }

  isInputsChange() {
    return this.isFormDataChanged();
  }

}

import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import RouteEntity from './entity/RouteEntity';
import { Const } from '@const/Const';
import { ApiService } from '@services/api.service';
import { AttachedFile, LatLng, ShipmentItem, Task } from '@wearewarp/types/data-model';
import TaskEntity from './entity/TaskEntity';
import ShipmentEntity from './entity/ShipmentEntity';
import { FormDataUpdateTaskStatus } from '@wearewarp/ng-antd';

@Injectable({
  providedIn: 'root',
})
export class DispatchService {
  private routeData = new BehaviorSubject({});
  public message: Subject<{ type: string, message: string }> = new Subject()
  public routeData$ = this.routeData.asObservable();
  public loading: Subject<boolean> = new ReplaySubject(1);
  private routeId: string;
  private routeEntity: RouteEntity;
  private taskEntities: Map<string, TaskEntity> = new Map();
  private shipmentEntities: Map<string, ShipmentEntity> = new Map();
  private shipmentItemsMap: Map<string, ShipmentItem> = new Map();
  private podsMap: Map<string, AttachedFile> = new Map();
  private countDriverMessage = new BehaviorSubject(0);
  public countDriverMessage$ = this.countDriverMessage.asObservable();

  constructor(private api: ApiService) {

  }

  changeData(data: string) {
    this.routeData.next(data)
  }

  public setRouteId(routeId) {
    this.routeId = routeId;
    this.loading.next(true); //chỉ show loading khi đổi route
    return this;
  }

  public getRouteId() {
    return this.routeId;
  }

  public async refresh() {
    let promises = [
      this.fetchRoute(),
      this.fetchTasks(),
      this.fetchShipments(),
      this.fetchShipmentItems(),
      this.fetchPODs(),
      this.fetchDriverMessage()
    ];

    await Promise.all(promises).catch(e => {
      console.error(e);
    });
    this.routeData.next(this.getRouteEntityJsonWithFullShipmentsData());
    this.loading.next(false)
  }

  private getRouteEntityJsonWithFullShipmentsData(){
    let routeEntityJson = this.routeEntity?.toJSON();
    let shipmentsIds = (routeEntityJson.shipments || []).map(item => item.id);
    routeEntityJson.shipments = Array.from(this.shipmentEntities.values())
      .filter(item => shipmentsIds.includes(item.getId()))
      .map(item => item.toJSON());
    return routeEntityJson;
  }

  public getRoute() {
    return this.routeEntity
  }

  public async fetchRoute() {
    if (!this.routeId) return;
    try {
      let url = Const.APIV2(`${Const.APIURI_JOBS}/${this.routeId}`);
      const resp = await this.api.GET(url).toPromise();
      const data = resp.data;
      this.routeEntity = new RouteEntity();
      await this.routeEntity.setDispatchService(this).init(data);
      this.fetchTrafficForEachStops();
    }
    catch (e) {
      console.log(e)
      this.message.next({
        type: 'error',
        message: e.message
      })
    }
  }

  public async fetchTasks(): Promise<void> {
    try {
      let url = Const.APIV2(`${Const.APIURI_JOBS}/${this.routeId}/tasks`);
      const resp = await this.api.GET(url).toPromise();
      const data = resp.data?.list_data;
      for (let item of data) {
        const taskEntity = new TaskEntity().setDispatchService(this).init(item);
        this.taskEntities.set(taskEntity.getId(), taskEntity);
      }
    }
    catch (e) {
      this.message.next({
        type: 'error',
        message: e.message
      })
    }
  }

  public async fetchShipments(): Promise<void> { 
    try {
      let url = Const.APIV2(`${Const.APIURI_JOBS}/${this.routeId}/shipments`);
      const resp = await this.api.GET(url).toPromise();
      const data = resp.data?.list_data;
      for (let item of data) {
        const shipmentEntity = new ShipmentEntity().setDispatchService(this).init(item);
        this.shipmentEntities.set(shipmentEntity.getId(), shipmentEntity);
      }
    }
    catch (e) {
      this.message.next({
        type: 'error',
        message: e.message
      })
    }
  }

  public async fetchShipmentItems(): Promise<void> { 
    try {
      let url = Const.APIV2(`${Const.APIURI_JOBS}/${this.routeId}/shipment_items`);
      const resp = await this.api.GET(url).toPromise();
      const data = resp.data?.list_data;
      for (let item of data) {
        this.shipmentItemsMap.set(item.id, item);
      }
    }
    catch (e) {
      this.message.next({
        type: 'error',
        message: e.message
      })
    }
  }

  public async fetchPODs(): Promise<void> { 
    try {
      let url = Const.APIV2(`${Const.APIURI_JOBS}/${this.routeId}/pods`);
      const resp = await this.api.GET(url).toPromise();
      const data = resp.data?.list_data;
      for (let item of data) {
        this.podsMap.set(String(item._id), item);
      }
    }
    catch (e) {
      this.message.next({
        type: 'error',
        message: e.message
      })
    }
  }

  public async fetchDriverMessage(): Promise<void> { 
    try {
      let url = `${Const.APIURI_CONVERSATIONS}/?subjectId=${this.routeId}&subjectType=job&type=driver_message`;
      const resp = await this.api.GET(url).toPromise();
      const driverMsgNumber = resp.data?.list_data?.length || 0;
      this.countDriverMessage.next(driverMsgNumber);
    }
    catch (e) {}
  }

  public async fetchTrafficForEachStops(): Promise<void> { 
    let processLatLong = (address) => {
      const latitude = address?.metadata?.latitude || address?.location?.latitude;
      const longitude = address?.metadata?.longitude || address?.location?.longitude;
      return { latitude, longitude };
    }
    try {
      const stopsAddr = this.routeEntity.getStops().map(it => it.getAddress());
      const locationsLatLong: LatLng[] = [];
      for (let index = 0; index < stopsAddr.length; index++) {
        const info = stopsAddr[index];
        const { latitude, longitude } = processLatLong(info);
        if (latitude && longitude) {
          locationsLatLong.push({
            latitude,
            longitude,
          });
        }
      }
      let url = Const.APIV2(`${Const.APIURI_JOBS}/traffic-each-stops`);
      const resp = await this.api.POST(url, { locations: locationsLatLong }).toPromise();
      if (resp?.data?.costs?.length == this.routeEntity.getStops().length -1) {
        let stopsEntity = this.routeEntity.getStops();
        for (let i=1; i < stopsEntity.length; i++) {
          let stop = stopsEntity[i];
          stop.setEstimateTraffic(resp.data.costs[i-1]);
        }
      }
    }
    catch (e) {}
  }

  public async updateDriver(driverId) {
    this.loading.next(true);
    //call api here

    this.loading.next(false);
    await this.refresh();
  }

  public async updateTaskStatus({ tasks, data }: { tasks: Task[], data: FormDataUpdateTaskStatus }) {
    try {
      let url = Const.APIV2(`${Const.APIURI_TASKS}/batchUpdate`);
      const resp = await this.api.POST(url, {
        action: 'updateStatus',
        data: tasks.map(task => ({
          id: task.id,
          data: data
        }))
      }).toPromise();
    }
    catch (e) {
      this.message.next({
        type: 'error',
        message: e.message
      })
    }
  }

  public getTaskById(taskId: string) {
    return this.taskEntities.get(taskId);
  }

  public getShipmentById(shipmentId: string) {
    return this.shipmentEntities.get(shipmentId);
  }

  public getShipmentItemById(itemId: string) {
    return this.shipmentItemsMap.get(itemId);
  }

  public getPODById(podId: string) {
    return this.podsMap.get(podId);
  }

}

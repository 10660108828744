<div class="form-header">
  <div style="align-self: center;">
    <span class="form-title-main"> {{ headerTxt }} </span>
  </div>
  <div>
    <i class="ic-right-close clickable" nz-icon nzType="close" nzTheme="outline" (click)="onClose()"></i>
  </div>
</div>
<div class="form-body">
  <form class="form-detail" [formGroup]="formInput" nz-form>
    <div *ngFor="let key of ['warehouseId']">
      <div class="form-label-v2 bottom5">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
      <div>
        <nz-form-item>
          <nz-form-control>
            <nz-select
              nzAllowClear
              nzShowSearch
              [formControlName]="key"
              [nzLoading]="isLoadingWarehouses"
              nzAllowClear="false"
              [nzPlaceHolder]="'Please select a warehouse'"
              style="width: 100%"
            >
              <nz-option
                *ngFor="let item of listOfCrossdockWarehouse"
                [nzValue]="item.id"
                [nzLabel]="item?.label || item?.name"
              ></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <div *ngFor="let key of ['mblNumber']">
      <div class="form-label-v2 bottom5">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
      <div>
        <nz-form-item>
          <nz-form-control>
            <input nz-input [formControlName]="key" 
              [placeholder]="getPlaceHolder(key)"
              (input)="onInputChanged($event, key)">
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <ng-container *ngIf="isCreateNew">
      <div *ngFor="let key of ['manifestFile']" class="bottom15">
        <input #inputFile type="file" hidden accept=".csv" (change)="onFileSelected(key, inputFile.files)">
        <div *ngIf="!hasAttachedFile(key)" class="flex-space-between">
          <div>
            <div class="form-label-v2">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
            <div class="file-support-type">Supported file types: .csv</div>
            <div>
              <a [href]="urlTemplateManifest" target="_blank" class="f12">Download template</a>
            </div>
          </div>
          <div>
            <button nz-button nzShape="round" nzType="dashed" (click)="inputFile.click()">
              <i nz-icon nzType="upload" nzTheme="outline"></i>Upload
            </button>
          </div>
        </div>
        <div *ngIf="hasAttachedFile(key)">
          <div class="form-label-v2">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
          <div class="flex-space-between" style="align-items: center;">
            <div class="file-selected">
              <i nz-icon nzType="paper-clip" nzTheme="outline"></i>{{ labelSelectFile }}
            </div>
            <button nz-button nzType="text" (click)="delFile(key, inputFile)">
              <i nz-icon nzType="delete"></i>
            </button>
          </div>
        </div>
      </div>
    </ng-container>
    <div *ngFor="let key of ['clientId']" class="bottom15">
      <div class="form-label-v2 bottom5">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
      <select-by-searching
        [version]="2"
        [formControlName]="key"
        [placeholder]="getPlaceHolder(key)">
      </select-by-searching>
    </div>
    <div *ngFor="let key of ['intendedInjectionDate']">
      <div class="form-label-v2 bottom5">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
      <div>
        <nz-form-item>
          <nz-form-control>
            <nz-date-picker style="width: 200px;"
            [formControlName]="key" nzFormat="MM/dd/yyyy"></nz-date-picker>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
  </form>
</div>
<div class="form-footer">
  <div form-footer [onProgress]="onProgress" 
    [canClickOK]="needUpdate && !onProgress" [canClickCancel]="!onProgress" 
    [nzIconOK]="'upload'" [labelOK]="labelSubmit" (onOK)="onBtnSave()" 
    (onCancel)="onClose()"></div>
</div>

import { BaseComponent } from "@abstract/BaseComponent";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { DrawerService } from "@app/drawers/drawer.service";
import { ImportManifest } from "../../../import";
import { Const } from "@const/Const";
import dayjs from "dayjs";


@Component({
  selector: '[manifest-detail-basic-info]',
  templateUrl: './index.html',
  styleUrls: ['./index.scss',]

})
export class ManifestDetailBasicInfo extends BaseComponent {
  constructor() {
    super();
  }

  public displayInfo: any = {}
  private _model;
  @Input() set model(value) {
    if (!value) return;
    this._model = value;
    this.buildDisplayInfo(value);
  }
  get model() {
    return this._model;
  }

  @Output() onRefresh: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit(): void {
    super.ngOnInit(); 
  }

  private buildDisplayInfo(data) {
    this.displayInfo = {
      clientName: data.client?.name || 'N/A',
      clientAddr: this.getAddressText(data.client?.address) || 'N/A',
      manifestFileName: data.uploadFile?.name || 'N/A',
      manifestFileUrl: data.uploadFile ? this.attachedFileUrl(data.uploadFile) : '',
    }
    if (data.intendedInjectionDate) {
      let d = dayjs(data.intendedInjectionDate, 'yyyy-MM-dd').toDate();
      this.displayInfo.intendedInjectionDate = dayjs(d).format(Const.FORMAT_GUI_DATE_V2);
  }
  }

  onBtnEdit() {
    DrawerService.openFormDrawer1(ImportManifest, {
      nzContentParams: {
        model: this._model,
        updateSuccess: this.onBtnRefresh.bind(this),
        closeOnSuccess: true
      },
      nzWidth: 410,
      nzWrapClassName: 'wrap-drawer import-manifest-drawer',
    });
  }

  onBtnRefresh() {
    this.onRefresh.emit();
  }

}

import { Component } from "@angular/core";
import { BaseFormDrawer } from "@app/drawers/base-form";
import { Const } from "@const/Const";
import { Const as WarpConst } from '@wearewarp/universal-libs'

@Component({
  selector: '[comm-unsubscribe-form]',
  templateUrl: './index.html',
  styleUrls: ['./style.scss', '../../../../styles/form-v2.scss']
})
export class UnsubscribeForm extends BaseFormDrawer {

  constructor() {
    super();
  }

  public scopes = [
    { value: WarpConst.CommunicationScope.carrier_bid, label: 'Carrier Bid' },
    { value: WarpConst.CommunicationScope.notification, label: 'Notification' },
  ]

  protected formGroupDeclaration: FormGroupDeclaration = {
    owner: { label: 'Email/phone number', required: true, placeHolder: "" },
    type: { label: 'Type', required: true, placeHolder: "" },
    scope: { label: 'Scope', required: true, placeHolder: "" },
    source: { label: 'Source', required: false, placeHolder: "" },
  }

  protected getApiUrl(): string {
    return Const.API_SERVICE_COMM_UNSUBSCRIBE;
  }

  public fields: any = [];

  ngOnInit(): void {
    super.ngOnInit();

    Object.keys(this.formGroupDeclaration).map(key => {
      this.fields.push({ key, ...this.formGroupDeclaration[key] })
    })
  }

  get formTitle() {
    let str = '';
    if (!this.model) {
      str = 'Create record';
    } else {
      if (!this.isEditOrCreate) {
        str = `${this.model.owner}`;
      } else {
        str = `Edit record`;
      }
    }
    return str;
  }

  getFormData_JSON(isCreateNew) {
    let data = super.getFormData_JSON(true);
    if (data.type == 'phone') {
      let owner = String(data.owner);
      if (/^\+/.test(owner)) {
        owner = owner.replace(/[^0-9\+]/g, '');
      } else {
        owner = owner.replace(/[^0-9]/g, '');
        owner = `+1${owner}`;
      }
      data.owner = owner;
    } else if (data.type == 'email') {
      data.owner = String(data.owner).toLowerCase();
    }
    return data;
  }

  protected buildUrl(): string {
    let url = this.getApiUrl();
    if (!url) return url;
    let id = (<any>this.model)?.id;
    if (id) {
      url = `${url}/${id}`;
    }
    return url;
  }
}

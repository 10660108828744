import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { BaseComponent } from "@abstract/BaseComponent";
import { Utils } from '@services/utils';
import { CheckBeforeAddResult, NotesComponent } from "@app/admin/components/notes";
import { NotesComponentEventFetchDataDone, NotesComponentEventItemAdded, NotesComponentEventItemRemoved } from "@app/admin/components/notes/interface";
import { Const } from "@const/Const";
import { UIHelper } from "@services/UIHelper";
import { ApiService } from "@services/api.service";
import { Observable, of } from "rxjs";
import { CarrierCostIssueConfirm } from "../cost-issue-post-confirm/component";
import { ModalHelper } from "@wearewarp/ng-antd";
import { CarrierCostIssueNotificationRecipient } from "./interface";

@Component({
  selector: '[carrier-cost-issue]',
  templateUrl: './view.html',
  styleUrls: ['style.scss']
})
export class CarrierCostIssue extends BaseComponent implements OnInit {
  @ViewChild('noteComponent') noteComponent: NotesComponent;
  @Input() jobId: string;
  @Input() saleRepEmails: CarrierCostIssueNotificationRecipient[] = [];      // mảng các email của tất cả sale rep, để thông báo mỗi khi có issue
  get hasIssue(): boolean { return this.count > 0 }
  private count = 0;

  constructor(private modalHelper: ModalHelper) {
    super();
  }

  ngOnInit(): void {
  }

  onNoteFetchingDone(event: NotesComponentEventFetchDataDone) {
    this.count = event.countTotal;
  }

  onNoteItemAdded(event: NotesComponentEventItemAdded) {
    this.count = event.countBeforeAdd + 1;
    this.notifyAfterAddNote(event.item.id);
  }

  onNoteItemRemoved(event: NotesComponentEventItemRemoved) {
    this.count = event.countBeforeRemove - 1;
  }

  beforeAddNote = (): Observable<CheckBeforeAddResult> => {
    if (this.count > 0) {
      return of({shouldContinue: true});
    }
    return new Observable(o => {
      this.modalHelper.open(CarrierCostIssueConfirm, {
        nzComponentParams: {
          listEmails: this.saleRepEmails
        },
        nzClosable: false,
        nzCentered: true,
        labelBtnOK: 'Yes',
        onOK: component => {
          this.listNotifyEmails = component.getListEmails();
          o.next({shouldContinue: true})
        },
        labelBtnCancel: 'No',
        onCancel: component => {
          o.next({shouldContinue: false})
        }
      });
    });
  }

  private listNotifyEmails: string[] = [];

  private notifyAfterAddNote(noteId: string) {
    const url = Const.APIV2(`jobs/${this.jobId}/notify_financial_issues/${noteId}`);
    ApiService.instance.POST(url, {recipients: this.listNotifyEmails}).subscribe(
      resp => {
        UIHelper.showSuccess('Relevant people have been notified about financial issue');
        this.listNotifyEmails = [];
      }, err => {
        console.error(err)
      }
    );
  }

  showMessageAddSuccess = () => {
    // do nothing
  }

}
<div class="border-box">
  <nz-breadcrumb>
    <nz-breadcrumb-item>
      <a [routerLink]="[routeAdminCrossdockWarehouseManifests]">Manifests</a>
    </nz-breadcrumb-item>
    <nz-breadcrumb-item *ngIf="manifestModel?.id">
      <a [routerLink]="[routeAdminCrossdockWarehouseManifests, manifestModel.id]">
        <span *ngIf="manifestModel?.mblNumber">#{{ manifestModel.mblNumber }}</span>
        <span *ngIf="!manifestModel?.mblNumber">Manifest Detail</span>
      </a>
    </nz-breadcrumb-item>
    <nz-breadcrumb-item *ngIf="palletModel?.name">
      {{ palletModel.name }}
    </nz-breadcrumb-item>
    <nz-breadcrumb-item *ngIf="cartonModel?.name">
      {{ cartonModel.name }}
    </nz-breadcrumb-item>
  </nz-breadcrumb>
</div>
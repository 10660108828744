import { Component, Input } from "@angular/core";
import { BaseForm, FormGroupDeclaration } from '@wearewarp/ng-form';

export interface FormDataRating {
  type: string,
  note: string,
}
@Component({
  selector: 'form-rating-dialog',
  templateUrl: './view.html',
  styleUrls: [
    './style.scss'
  ]
})
export class FromRatingDialog extends BaseForm<FormDataRating> {
  protected override formGroupDeclaration: FormGroupDeclaration = {
    type: {label: ''},
    note: {label: 'Write a review', placeHolder: '', notAcceptEmpty: true},
  }

  @Input() type;
  @Input() viewMode: boolean = false;

  ngOnInit(): void {
    super.ngOnInit();
    if (this.type) {
      this.setItemValue('type', this.type);
    }
  }

  get isLike() {
    return this.getItemValue('type') === 'like';
  }

  get isDislike() {
    return this.getItemValue('type') === 'dislike';
  }

  canSubmit(): boolean {
    if (this.isCreateNew) {
      return true;
    }
    return super.canSubmit();
  }

  onClickBtn(type: 'like'|'dislike') {
    const currentType = this.getItemValue('type');
    if (currentType === type) {
      return;
    }
    this.setItemValue('type', type);
  }
  
}
<div class="form-detail" style="padding: 24px">
  <div class="group-header flex">
    <div>WARP Quick Order Settings</div>
    <div class="left10">
      <button nz-button nzType="dashed" (click)="onBtnAddQuickOrderURL()"><i nz-icon nzType="plus"></i>Add</button>
    </div>
  </div>
  <div class="warp-info group-content">
    <div *ngFor="let info of model?.quickOrderInfo.quickOrderAccesses" class="quick-order-access">
      <div class="bottom5 flex-space-between">
        <div>Email: {{ info.email }}</div>
        <div class="flex">
          <button
            nz-button
            nzDanger
            nzSize="small"
            class="left10 f13"
            (click)="onBtnRemoveQuickOrderSetting(info)"
            [disabled]="isRemoving"
          >
            Remove
          </button>
        </div>
      </div>
      <div class="bottom5">
        <span>Form URL:</span>
        <span *ngIf="info.isTokenExpired" class="left5 token-expired">(Token expired)</span>
      </div>
      <div class="flex">
        <div class="flex1 code-block">
          <span #pre>{{getQuickOrderUrl(info)}}</span>
        </div>
        <div *ngIf="info?.isTokenExpired">
          <button nz-button nzType="primary" (click)="onBtnReGenerateQuickOrderURL(info)" [disabled]="isGenerating">
            Re-Generate
          </button>
        </div>
        <div>
          <button
            nz-button
            nzType="primary"
            class="left10"
            (click)="onBtnSendEmailQuickOrder(info)"
            [disabled]="isSendingEmail"
          >
            {{ getBtnLabelSendEmailQuickOrder(info) }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div  *ngIf="!edittingBarcode" style="width: 150px;" [class]="{'missing': item.isMissing}">
    <code *ngIf="item.internalBarcode">{{item.internalBarcode}}</code>
    <code *ngIf="!item.internalBarcode" class="not-identified">N/A</code>
</div>
<div style="width: 150px;" *ngIf="edittingBarcode">
    <input nz-input nzSize="small" [(ngModel)]="barcode" >
</div>

<div style="width: 160px;" [class]="{'missing': item.isMissing}">{{item?.name}}</div>
<div style="width: 150px;" [class]="{'missing': item.isMissing}">{{item.length || '-'}} x {{ item.width || '-'}} x {{item.height  || '-'}} {{ item.sizeUnit }}</div>
<div style="width: 100px;" *ngIf="!edittingWeight" [class]="{'missing': item.isMissing}">
    <span class="font-medium" *ngIf="item.actualWeight">{{ item.actualWeight }} {{item.actualUnit || item.weightUnit}}</span>
    <span *ngIf="!item.actualWeight && item.weightPerUnit" class="not-identified">{{ item.weightPerUnit.toFixed(0) }} {{ item.weightUnit}}</span>
</div>
<div style="width: 100px;" *ngIf="edittingWeight">
    <input type="number" nz-input nzSize="small" [(ngModel)]="weight" >
</div>

<div style="width: 430px;">
    <ng-container *ngFor="let task of item?.warehouseTasks">
        <ng-container *ngIf="task?.metadata?.isWarning && task.status === 'pending'">
            <nz-tag
                class="clickable"
                [nzColor]="getWarehouseTaskColor(task.status)"
                nz-popconfirm
                [nzPopconfirmTitle]="task?.metadata?.reason"
                nzPopconfirmPlacement="top"
                nzOkText="Keep Doing Task"
                nzCancelText="Skip Task"
                (nzOnConfirm)="handleWarehouseTask(task)"
                (nzOnCancel)="skipTask(task)"
            >
                <span nz-icon [nzType]="getWarehouseTaskIcon(task.status, task.type)" nzTheme="outline"></span>
                ({{task?.no}}-{{getTaskStage(task)}}) {{getWarehouseTaskTypeText(task)}}
                <span nz-icon nzType="warning" nzTheme="outline"></span>
            </nz-tag>
        </ng-container>
    
        <ng-container *ngIf="!task?.metadata?.isWarning || isCompleted(task.status)">
            <nz-tag
                class="clickable"
                [nzColor]="getWarehouseTaskColor(task.status)"
                (click)="handleWarehouseTask(task)"
            >
                <span nz-icon [nzType]="getWarehouseTaskIcon(task.status, task.type)" nzTheme="outline"></span>
                <ng-container *ngIf="task?.status === 'completed' || task?.status === 'pending'">
                    ({{task?.no}}-{{getTaskStage(task)}}) {{getWarehouseTaskTypeText(task)}}
                </ng-container>
                <ng-container *ngIf="task?.status === 'skipped'">
                    ({{task?.no}}-{{getTaskStage(task)}}-Skipped) {{getWarehouseTaskTypeText(task)}}
                </ng-container>
            </nz-tag>
        </ng-container>
    </ng-container>
</div>

<input
  #inputFile
  type="file"
  hidden
  accept=".png,.jpg,.jpeg"
  (change)="onFileSelected(inputFile)"
  [id]="'uploadPhotoInput-' + item.id"
/>

<div class="flex1" style="width: 300px;" *ngIf="edittingWeight && !item.isMissing && !edittingBarcode">
    <span class="clickable primary" (click)="confirmWeight()">Confirm</span>
    <nz-divider nzType="vertical"></nz-divider>
    <span class="clickable" (click)="cancelEdittingWeight()">Cancel</span>
    <nz-divider nzType="vertical"></nz-divider>
    <span class="clickable danger" (click)="confirmRemove('addWeight')">Reset</span>
</div>

<div class="flex1" style="width: 300px;" *ngIf="edittingBarcode && !item.edittingWeight">
    <span class="clickable primary" (click)="confirmBarcode()">Confirm</span>
    <nz-divider nzType="vertical"></nz-divider>
    <span class="clickable" (click)="cancelEdittingBarcode()">Cancel</span>
    <nz-divider nzType="vertical"></nz-divider>
    <span class="clickable danger" (click)="confirmRemove('scanPallet')">Reset</span>
</div>

<div *ngIf="images?.length" class="flex">
    <div *ngFor="let image of images" class="group-image">
        <img class="compact clickable" [src]="image?.fullPath" (click)="showItemPhoto(image?.fullPath)">
        <div class="action">
            <span nz-icon nzType="close" nzTheme="outline" (click)="confirmRemove('uploadProductPhoto', image.id)"></span>
        </div>
    </div>
</div>

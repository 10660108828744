import { Component } from "@angular/core";
import { DateUtil } from "@services/date-utils";
import { ListFilterDrawer } from "../../../base/list-filter-drawer";

@Component({
  selector: '[carrier-bid-filter]',
  templateUrl: './carrier-bid-filter.component.html',
  styleUrls: [
    '../../../../app.scss',
    '../../../../drawers/drawer.scss',
    '../../../../../styles/date-picker-row.scss',
    '../../../../../styles/form-v2.scss'
  ]
})
export class CarrierBidFilterComponent extends ListFilterDrawer {
  public static get declaration(): FormGroupDeclaration {return {
    fromDate: {label: '', type: 'date', notAcceptEmpty: true, placeHolder: 'From date', formatValue: v => v, getValue: v => v},
    toDate: {label: '', type: 'date', notAcceptEmpty: true, placeHolder: 'To date', formatValue: v => v, getValue: v => v},
    assignedCarrierId: {label: 'Assigned Carrier', notAcceptEmpty: true},
  }}

  protected formGroupDeclaration: FormGroupDeclaration = CarrierBidFilterComponent.declaration;

  private fields = [
    'fromDate', 'toDate'
  ];

  get needUpdate(): boolean {
    return true;
  }

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  protected beforeBindModel(model: any): void {
    for(let field of this.fields) {
      if(model[field]) model[field] = new Date(model[field]);
    }
    return model;
  }

  protected getFormData_JSON(isCreateNew: boolean): object {
    let json: any = super.getFormData_JSON(isCreateNew);
    for(let field of this.fields) {
      if (json[field]) {
        let date = DateUtil.toBeginOfDay(json[field]).toISOString();
        if(field.includes("toDate")) date = DateUtil.toEndOfDay(json[field]).toISOString();
        json[field] = date;
      }
    }
    return json;
  }
}

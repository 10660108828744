import { Component, Input } from "@angular/core";
import { ApiMethod } from "@app/enum";
import { Const } from "@const/Const";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";

@Component({
  selector: '[manifest-upload-label-sku]',
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})
export class ManifestUploadLabelSKU extends BaseFormDialog1 {
  protected formGroupDeclaration: FormGroupDeclaration = {
    sku: {label: 'SKU'},
    labelFile: {label: 'Label file', required: true, type: 'uploadFile'},
    manifestItemIds: {label: ''}
  }

  constructor() {
    super();
  }

  @Input() numberOfCartons;
  @Input() manifestId;
  @Input() listOfSKU;

  ngOnInit(): void {
    super.ngOnInit();
    if (this.listOfSKU?.length === 1) {
      let item = this.listOfSKU[0];
      if (item?.sku) {
        this.setItemValue('sku', item.sku);
      }
      if (item?.itemIds) {
        this.setItemValue('manifestItemIds', item.itemIds);
      }
    }
  }

  protected buildUrl(method: ApiMethod): string {
    return `${Const.APIURI_WAREHOUSE_MANIFEST}/${this.manifestId}/item-labels`;
  }

  get isSingleSKU() {
    return this.listOfSKU?.length === 1;
  }

  get isMultiSKU() {
    return this.listOfSKU?.length && this.listOfSKU.length > 1;
  }

  public get labelSelectFile(): string {
    let key = 'labelFile';
    return this.hasAttachedFile(key) ? this.getFileDesc(key) : null;
  }

  onInputSKUChange(event) {
    let item = this.listOfSKU.find(it => it.sku == event);
    if (item?.itemIds) {
      this.setItemValue('manifestItemIds', item.itemIds);
    }
  }

  protected onCreateSuccess(resp) {
    this.showInfo("Label has been uploaded successfully.");
    this.createSuccess(resp);
    if (this.closeOnSuccess) {
      this.closeDialog();
    }
  }

}

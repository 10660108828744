import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '@abstract/BaseComponent';

@Component({
  selector: '[display-rate-info]',
  templateUrl: './comp.html',
  styleUrls: ['./style.scss']
})
export class DisplayRateInfo extends BaseComponent {

  constructor(protected activatedRoute: ActivatedRoute) {
    super(activatedRoute);
  }

  @Input() numOfLike = 0;
  @Input() numOfDislike = 0;
  
}
<div *ngIf="isLoading" class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
<div *ngIf="isError" class="nodata">Cannot fetch data, please try again later</div>

<div class="f20 bottom20">Reassign Dispatchers</div>

<div class="top20">
  <div nz-row>
    <div nz-col nzXs="8">Current Dispatcher:</div>
    <div nz-col nzXs="16">
      <nz-form-item>
        <nz-form-control>
          <nz-select nzBackdrop="true" style="width: 100%" [nzLoading]="isLoading" nzShowSearch
            nzPlaceHolder="Please select PiC" [(ngModel)]="currentPicId">
            <nz-option *ngFor="let user of users" [nzLabel]="getFullName(user)" [nzValue]="user.id">
            </nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
  <div nz-row>
    <div nz-col nzXs="8">New Dispatcher:</div>
    <div nz-col nzXs="16">
      <nz-form-item>
        <nz-form-control>
          <nz-select nzBackdrop="true" style="width: 100%" [nzLoading]="isLoading" nzShowSearch
            nzPlaceHolder="Please select PiC" [(ngModel)]="newPicId">
            <nz-option *ngFor="let user of users" [nzLabel]="getFullName(user)" [nzValue]="user.id">
            </nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
</div>



<div form-footer [onProgress]="onProgress" [nzIconCancel]="null" [nzIconOK]="null"
  [canClickOK]="canClickOK" [canClickCancel]="!onProgress" [labelOK]="labelOK" (onOK)="onBtnSave()"
  (onCancel)="closeDialog()"></div>

import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { RouterModule } from '@angular/router';
import { WarpWhenBy } from '.';
import { WarpWhenByTemplateDefault } from './template-default';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
    ],
    declarations: [
      WarpWhenBy,
      WarpWhenByTemplateDefault,
    ],
    exports: [
      WarpWhenBy,
    ],
    providers: []
})

export class CreateByUserModule { }
import { ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { SidebarModule } from './sidebar/sidebar.module';
import { TopnavModule } from './topnav/topnav.module';
import { UserModule } from './users/module';
import { PageErrorModule } from '../page-error/page-error.module';
import { DashboardComponent } from './dashboard.component';
import { CheckCanQuit } from '@services/check-can-quit';
import { CarrierModule } from './carriers/module';
import { OrderModule } from './orders/module';
import { ClientModule } from './clients/module';
import { AuditLogModule } from './audit-logs/module';
import { NzAffixModule } from 'ng-zorro-antd/affix';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { WareHouseModule } from './warehouses/module';
import { CarrierBidsModule } from './carrier-bids/module';
// import { CrossDockManagementModule } from './crossdock-management/module';
import { CrossdockWarehouseMapModule } from './crossdock-warehouse-map/module';
import { CrossdockWarehouseModule } from './crossdock-warehouse/module';
import { ShipmentParcelModule } from './shipment-parcels/module';
import { DriverModule } from './drivers/module';
import { FinanceModule } from './finance/module';
import { ZipRateModule } from './zip-rate/module';
import { OperationalPerformanceModule } from './operational-performance/module';
import { LoadsBookedModule } from './loads-booked/module';
import { RevenueBookedModule } from './revenue-booked/module';
import { SettingVersionModule } from './settings/version/module';
import { Setting2FAModule } from './settings/2fa/module';
import { FreightQuoteModule } from './freight-quote/module';
import { FreightQuoteFTLModule } from './freight-quote-ftl/module';
import { RoutingLtlModule } from './routing-tool/module';
import { ShipmentInvoiceModule } from './shipment-invoices/module';
import { LabelTemplateModule } from './label-templates/module';
import { DeliveryPlanningModule } from './planning/module';
import { ReportViewerModule } from './report/viewer/module';
import { PoolsModule } from './pools/module';
import { ModuleCrossDockManagement } from './crossdock/module';
import { AnnouncementModule } from './announcements/module';
import { CommUnsubscribeModule } from './common-unsubscribes/module';
import {FinanceTransactionModule} from '@app/admin/finance-transactions/module';
import { ReportPODModule } from './report/pod/module';
import { ManualLoadModule } from './manual-load/module';
import { ModuleCrossDockManifest } from './crossdock/manifests/module';
import { AccountsPayableModule } from './accounts-payable/module';
import { GlobalErrorHandler } from './dasboard.error.handler';
import { DatModule } from "@app/admin/dat/module";

@NgModule({
  imports: [
    CommonModule,
    DashboardRoutingModule,
    SidebarModule,
    TopnavModule,
    UserModule,
    PageErrorModule,
    DriverModule,
    OrderModule,
    ClientModule,
    WareHouseModule,
    CarrierBidsModule,
    ShipmentParcelModule,
    OperationalPerformanceModule,
    LoadsBookedModule,
    RevenueBookedModule,
    SettingVersionModule,
    Setting2FAModule,
    NzAffixModule,
    NzMenuModule,
    NzIconModule,
    FinanceModule,
    RoutingLtlModule,
    // CrossDockManagementModule,
    CrossdockWarehouseMapModule,
    CrossdockWarehouseModule,
    FreightQuoteModule,
    FreightQuoteFTLModule,
    ZipRateModule,
    AuditLogModule,
    ShipmentInvoiceModule,
    LabelTemplateModule,
    DeliveryPlanningModule,
    PoolsModule,
    ModuleCrossDockManagement,
    AnnouncementModule,
    CommUnsubscribeModule,
    FinanceTransactionModule,
    ReportPODModule,
    ManualLoadModule,
    ReportViewerModule,
    ModuleCrossDockManifest,
    AccountsPayableModule,
    DatModule
  ],
  providers: [
    CheckCanQuit,
    {provide: ErrorHandler, useClass: GlobalErrorHandler}
  ],
  declarations: [
    DashboardComponent
  ]
})
export class DashboardModule { }

<ng-container *ngIf="!_data.format || _data.format == 'compact'">
    <span>
        <span>{{ _data.address.street }} </span>
        <span>{{ _data.address.city }}, </span>
        <span>{{ _data.address.state }} {{ _data.address.zipcode }}</span>
    </span>
</ng-container>
<ng-container *ngIf="_data.format == '2line'">
    <div>
        <div>{{ _data.address.street }} </div>
        <div>
            <span>{{ _data.address.city }}, </span>
            <span>{{ _data.address.state }} {{ _data.address.zipcode }}</span>    
        </div>
    </div>
</ng-container>
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingBar } from '.';



@NgModule({
  declarations: [LoadingBar],
  imports: [
    CommonModule
  ],
  exports:[
    LoadingBar
  ]
})
export class LoadingModule { }

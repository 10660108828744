<div class="box-content top20">
  <div class="group-box-action">
    <div class="box-action">
      <button nz-button (click)="onBtnRefresh()">
        <span nz-icon nzType="reload" nzTheme="outline"></span>
        Refresh
      </button>
      <button nz-button (click)="onSyncDataCarriers()" nz-tooltip nzTooltipTitle="Sync data carriers with WARP metadata">
        <span nz-icon nzType="sync" nzTheme="outline"></span>
        Sync Carriers
      </button>
    </div>
  </div>

  <nz-table #nzTable nzBordered="true" [nzData]="listCarriers" 
    [nzScroll]="{ x: '1300px'}"
    [nzLoading]="isLoading" [nzHideOnSinglePage]="true"
    [nzLoadingIndicator]="tplLoading" [nzNoResult]="tplNoData" [nzSize]="'default'">
    <ng-template #tplNoData>
      <div *ngIf="!isLoading" class="nodata"><i nz-icon nzType="search" nzTheme="outline"></i>No data</div>
    </ng-template>
    <ng-template #tplLoading>
      <div class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
    </ng-template>
    <thead>
      <tr>
        <th nzWidth="80px">Source</th>
        <th>Carrier Name</th>
        <th>Carrier Contact</th>
        <th nzWidth="120px">DOT</th>
        <th nzWidth="120px">MC</th>
        <th>Equipment</th>
        <th nzWidth="140px">Status</th>
        <th nzWidth="140px">Answer</th>
        <th nzWidth="100px">Price</th>
        <th nzWidth="80px">Note</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let carrier of nzTable.data">
        <td>{{ carrier?.source }}</td>
        <td>
          <div class="flex flex-space-between">
            <div *ngIf="!isRegisteredCarrier(carrier)">{{ carrier?.carrierName }}</div>
            <a [href]="goToDetailCarrier(carrier?.carrierId)" *ngIf="isRegisteredCarrier(carrier)" style="color: inherit" target="_blank"
              nz-tooltip nzTooltipTitle="Detail carrier">
              {{ carrier?.carrierName }}
              <i nz-icon nzType="rocket" nzTheme="outline"></i>
            </a>
          </div>
        </td>
        <td nzLeft>
          {{carrier?.contact?.name}}
          <p class="small-text">
            <contact-info owner="{{carrier.contact?.phone}}" type="phone"
              [scopes]="['carrier_bid']"></contact-info>
            <contact-info owner="{{carrier.contact?.email}}" type="email"
              [scopes]="['carrier_bid']"></contact-info>
          </p>
        </td>
        <td>{{ carrier?.dot }}</td>
        <td>{{ carrier?.mc }}</td>
        <td>{{ displayCarrierEquipment(carrier) }}</td>
        <td nzAlign="center">
          <div *ngIf="isRegisteredCarrier(carrier)">
            <ng-container *ngIf="carrier?.carrierStatus != 1">
              <nz-tag nzColor="warning">{{$asCarrierStatusText(carrier?.carrierstatus)}}</nz-tag>
            </ng-container>
            <ng-container *ngIf="carrier?.carrierStatus == 1">
              <nz-tag nzColor="success">Active</nz-tag>
            </ng-container>
          </div>
          <div *ngIf="!isRegisteredCarrier(carrier)" class="flex-column">
            <nz-tag nzColor="error">Unregistered</nz-tag>
            <a [href]="" (click)="sendInviteRegisterToCarrier(carrier)" style="text-decoration: underline; margin-top: 10px;">
              {{carrier?.contact?.email ? 'Invite via Email' : 'Invite via SMS'}}
            </a>
            <p class="small-text" style="margin-top: 5px;" *ngIf="getLastSendInvite(carrier)">when: {{getLastSendInvite(carrier)}}</p>
          </div>
        </td>
        <td>
          <div class="flex flex-space-between">
            <div>
              <ng-container *ngIf="carrier?.answer?.state == 0 || !carrier?.answer?.state">
                <nz-tag nzColor="default">No Data</nz-tag>
              </ng-container>
              <ng-container *ngIf="carrier?.answer?.state == 1">
                <nz-tag nzColor="error">Refused</nz-tag>
              </ng-container>
              <ng-container *ngIf="carrier?.answer?.state == 2">
                <nz-tag nzColor="success">Accepted</nz-tag>
              </ng-container>
              <ng-container *ngIf="carrier?.answer?.state == 3">
                <nz-tag nzColor="success">Placed Bid</nz-tag>
              </ng-container>
            </div>
            <div>
              <button nz-button (click)="onBtnEditItem(carrier)" nzSize="small">
                <i nz-icon nzType="edit" nzTheme="outline"></i>
              </button>
            </div>
          </div>
        </td>
        <td nzAlign="right">{{ carrier?.answer?.price ? $asMoney(carrier?.answer?.price) : '-' }}</td>
        <td nzAlign="center">
          <button nz-button (click)="onBtnEditNote(carrier)" nzSize="small">
            <i nz-icon nzType="edit" nzTheme="outline"></i>
          </button>
        </td>
      </tr>
    </tbody>
  </nz-table>
</div>

import { BaseComponent } from "@abstract/BaseComponent";
import { Component, Input } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Const } from "@const/Const";
import { DialogService } from "@dialogs/dialog.service";
import { ImageUtil } from "@services/image-util";
import { LoadingComponent } from "../loading/loading.component";
import { PrintService } from "@services/print.service";

@Component({
  selector: '[warehouse-manifest-carton-detail]',
  templateUrl: './index.html',
  styleUrls: [
    './index.scss',
  ]

})
export class WarehouseManifestCartonDetail extends BaseComponent {

  constructor(protected route: ActivatedRoute) {
    super();
  }

  public manifestId;
  public cartonId;
  public isLoading: boolean = true;
  public model: any;
  public listOfSKU: any[] = [];

  ngOnInit(): void {
    super.ngOnInit();
    this.route.params.subscribe(params => {
      this.manifestId = params['id'];
      this.cartonId = params['itemId'];
      this.getData();
    });
  }

  private getData() {
    let url = `${Const.APIURI_WAREHOUSE_MANIFEST}/${this.manifestId}/cartons/${this.cartonId}`;
    this.isLoading = true;
    this.api.GET(url).subscribe(
      resp => {
        this.model = this.onGetDetailSuccess(resp.data);
        this.isLoading = false;
      }, err => {
        this.showErr(err);
        this.isLoading = false;
      }
    );
  }

  onGetDetailSuccess(data) {
    this.listOfSKU = (data.items || []).filter(item => item.sku).map(item => ({
      itemId: item.id,
      sku: item.sku,
      productId: item.productId || 'N/A',
      quantity: item.sumOfQuantity || 'N/A',
      weight: item.productsWeight || 'N/A',
      weightUnit: item.weightUnit,
      labelFile: item.labelFile,
      printLabelTask: (item.tasks || []).find(it => it.itemId == item.id && it.type == 'printLabel'),
    }));
    
    data.displayInfo = {
      cartonName: data.name || 'N/A',
      dim: this.getDimValue(data),
      commodity: data.commodity || 'N/A',
      carrier: data.carrier || 'N/A',
      consignee: data.consignee || 'N/A',
      consigneeAddress: this.getConsigneeAddress(data) || 'N/A',
      consigneeContact: this.getConsigneeContact(data) || 'N/A',
      orderNo: data.orderNo || 'N/A',
      fulfillmentId: data.fulfillmentId || 'N/A',
      packageNo: data.packageNo || 'N/A',
      cntrNo: data.cntrNo || 'N/A',
      palletName: data.palletName || 'N/A',
      palletLabel: data.pallet?.label || 'N/A',
      palletUrl: data.pallet?.id ? [this.routeAdminCrossdockWarehouseManifests, this.manifestId, 'pallets', data.pallet.id] : null,
      note: this.getNoteMessage(data),
    }
    return data
  }

  getDimValue(data) {
    let dim = 'N/A';
    if (data.length || data.width || data.height) {
      dim = `${data.length || 'N/A'}x${data.width || 'N/A'}x${data.height || 'N/A'}`;    // (LxWxH)
      if (data.dimsUnit) {
        dim = `${dim} ${data.dimsUnit}`;
      }
    }
    return dim;
  }

  getConsigneeAddress(data) {
    let arr = [];
    const keys = ['consigneeStreet1', 'consigneeStreet2', 'consigneeCity', 'consigneeState', 'consigneeZipCode', 'consigneeCountry'];
    for (let key of keys) {
      if (data?.[key]) arr.push(data[key]);
    }
    return arr.join(', ');
  }

  getConsigneeContact(data) {
    let arr = [];
    const keys = ['consigneeContactName', 'consigneeContactPhone', 'consigneeContactEmail']
    for (let key of keys) {
      if (data?.[key]) arr.push(data[key]);
    }
    return arr.join(' • ');
  }

  public onBtnUploadSKULabel(index) {
    setTimeout(() => {
      let element: HTMLElement = document.getElementById(`uploadSKULabelingInput-${index}`) as HTMLElement;
      element.click();
    }, 200);
  }

  onSKULabelFileSelected(item, inputElement: HTMLInputElement) {
    let file = inputElement.files[0];
    inputElement.value = "";
    ImageUtil.resizeImageIfNeeded(file).then((blob) =>
      this.uploadLableFiles(item.itemId, blob, file.name)
    );
  }

  loadingRef;
  private async uploadLableFiles(itemId, file: Blob, fileName: string) {
    if (!itemId) {
      this.showErr('ItemId is required.')
      return;
    }
    let apiUrl = `${Const.APIURI_WAREHOUSE_MANIFEST}/${this.manifestId}/item-labels`;
    let formData = new FormData();
    const jsonData = {
      manifestItemIds: [ itemId ],
    }
    formData.append("params", JSON.stringify(jsonData));
    formData.append("labelFile", file, fileName);
    this.loadingRef = DialogService.openFormDialog1(LoadingComponent, {});
    this.api.postFormData(apiUrl, formData).subscribe(
      (resp) => {
        this.getData();
        this.showSuccess("Label has been uploaded successfully.");
        this.loadingRef?.close();
      },
      (err) => {
        this.showErr(err);
        this.loadingRef?.close();
      }
    );
  }

  getColor(status) {
    switch (status) {
      case Const.WarehouseTaskStatus.pending:
        return "gold"
      case Const.WarehouseTaskStatus.completed:
        return "green"
      default:
        return "grey"
    }
  }

  getStatusText(status) {
    switch (status) {
      case Const.WarehouseTaskStatus.pending:
        return "To Label SKU"
      case Const.WarehouseTaskStatus.completed:
        return "Labeled"
      default:
        return ""
    }
  }

  getNoteMessage(data) {
    return !data.instruction ? '' : `Please kindly check below instructions for Ecom handovers and kindly advise PODs once available. <br> ${data.instruction || ''}`
  }

  onBtnDeleteSKULabel(item) {
    this.confirmDeletion({
      message: `Are you sure you want to delete this label file for SKU ${item.sku} ?`,
      txtBtnOk: this.txtDelete,
      fnOk: () => {
        let url = `${Const.APIURI_WAREHOUSE_MANIFEST}/${this.manifestId}/items/${item.itemId}/label`;
        this.api.DELETE(url).subscribe(
          (resp) => {
            this.showSuccess("The label file has been deleted successfully.");
            this.getData();
          },
          (err) => {
            this.showErr(err);
          }
        );
      },
    });
  }

  onBtnPrintLabel(item) {
    if (!item.labelFile) return;
    PrintService.print(item.labelFile);
  }

  onBtnConfirmLabelDone(item) {
    this.modalService.confirm({
      nzTitle: 'Mark as Labeled SKU',
      nzContent: `Please confirm you want to mark Label SKU ${item.sku} as completed.`,
      nzOkText: 'Confirm',
      nzCancelText: 'Cancel',
      nzClosable: false,
      nzMaskClosable: false,
      nzCentered: true,
      nzOnOk: () => {
        if (!item.itemId || !item?.printLabelTask?.id) {
          this.showErr('printLabelTask not found.');
          return;
        }
        let url = `${Const.APIURI_WAREHOUSE_MANIFEST}/${this.manifestId}/items/${item.itemId}/tasks/${item.printLabelTask.id}/complete`;
        this.api.PUT(url).subscribe(
          (resp) => {
            this.showSuccess(resp);
            this.getData();
          },
          (err) => {
            this.showErr(err);
          }
        );
      }
    });
  }
  
}

import { Component, forwardRef, Inject, Injector, Input, OnInit, Self } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { Const } from "@const/Const";
import { ApiService } from "@services/api.service";
import { TransferItem, TransferChange } from "ng-zorro-antd/transfer";
import _ from "underscore";
import { BaseInputComponent } from "../base-custom-input";

const MATCHING_TYPE = {
  0: {
    color: "warning",
    label: "Unmatched",
  },
  1: {
    color: "success",
    label: "Matched",
  },
};

@Component({
  selector: "app-transfer-carrier-input",
  templateUrl: "./transfer-carrier-input.component.html",
  styleUrls: ["./transfer-carrier-input.component.scss"],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => TransferCarrierInputComponent), multi: true },
  ],
})
export class TransferCarrierInputComponent extends BaseInputComponent {
  public selected: string[] | number[] = [];
  private matchedIds: string[] = [];
  public api;
  list: TransferItem[] = [];
  pools = [];
  private poolTags = {}
  poolSelected = null;
  keyword: any = {
    left: null,
    right: null
  };
  pool: any = {
    left: [],
    right: []
  }
  private originList: TransferItem[] = [];

  @Input()
  public isLoading?: boolean = true;
  public isLoadingPool?: boolean = true;

  @Input() set matchedCarrier(value: string[]) {
    this.matchedIds = value;
    this.updateCarrierList({ updateMatchedIds: true });
  }

  constructor(@Inject(Injector) protected injector: Injector, api: ApiService) {
    super(injector);
    this.api = api;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.onLoadData();
    this.onLoadPool();
  }

  onLoadPool() {
    this.isLoadingPool = true;
    this.api.GET(Const.APIV2(`${Const.APIURI_POOLS}?&limit=-1&filter=${JSON.stringify({subjectType: 'carrier'})}`)).subscribe((response) => {
      const pools = _.get(response, ["data", "list_data"], []);
      this.pools = pools;
      let allCarrierIds = []
      for(let pool of pools) {
        if(pool.subjectIds.length) allCarrierIds = [...allCarrierIds, ...pool.subjectIds]
      }
      let poolTags = {};
      for(let carrierId of allCarrierIds) {
        let result = this.pools.filter(it => it.subjectIds.includes(carrierId));
        poolTags[carrierId] = result.map(it => it.name);
      }
      this.poolTags = poolTags;
      this.isLoadingPool = false;
    });
  }

  public getTags(id) {
    const tags = this.poolTags[id] || [];
    return tags;
  }

  get value() {
    return this.selected
  }

  //override
  set value(value: any) {
    this.selected = value;
    //seclected Data
    this.updateSelectedOnGUI();
    this.onChange(this.selected);
    this.onTouch(this.selected);
  }

  updateSelectedOnGUI() {
    this.list.map((item) => (item.direction = this.value?.includes(item.key) ? "right" : "left"));
  }

  get error() {
    const errors = this.control?.errors;
    if (!errors || Object.keys(errors).length == 0) return;
    const firstKey = Object.keys(errors)[0];
    return _.get(errors, [firstKey, "en"]) || errors[firstKey];
  }

  get matchedCount(): number {
    return this.matchedIds?.length ?? 0;
  }

  get matchingResult(): string {
    if (this.matchedCount > 0) {
      return `Found ${this.matchedCount} carrier${this.matchedCount > 1 ? 's' : ''} matched. You still be able to select manually more from the table on the left then move to the table on the right.`;
    }
    return `Found no carrier matched. Please select manually from the table on the left then move to the table on the right.`;
  }

  writeValue(value: any): void {
    if (!value || value.length == 0) return;
    this.value = value || [];
  }

  /**
   * Load carrier list on init.
   */
  onLoadData() {
    this.isLoading = true;

    this.api.GET(`${Const.APIURI_CARRIERS}/list/all_for_bid`).subscribe((response) => {
      const carriers = _.get(response, ["data", "list_data"], []);
      this.list = carriers.map((carrier) => ({
        id: carrier.id,
        key: carrier.id,
        title: carrier.basicInfo?.name,
        status: carrier.status,
        mc:carrier.basicInfo?.mc,
        dot:carrier.basicInfo?.dot,
      }));
      this.originList = [...this.list];
      this.updateCarrierList();
      this.isLoading = false;
    });
  }

  onSearch(value, direction) {
    this.keyword[direction] = value;
    this.onFilterData();
  }

  onPoolChange(value, direction) {
    const pool = this.pools.find(it => it.id === value);
    const { subjectIds = [] } = pool || {};
    this.pool[direction] = subjectIds;
    this.onFilterData();
  }

  onFilterData() {
    console.log("this.keyword", this.keyword)
    console.log("this.pool", this.pool)
    if(!this.keyword['left'] && !this.keyword['right'] && !this.pool['left'] && !this.pool['right']) {
      this.list = this.originList;
      return;
    }
    let leftList = this.originList.filter(it => it.direction === 'left' && this.filterCarrier(this.keyword['left'], it) && (!this.pool['left'].length || this.pool['left'].includes(it.key)));
    let rightList = this.originList.filter(it => it.direction === 'right' && this.filterCarrier(this.keyword['right'], it) && (!this.pool['right'].length || this.pool['right'].includes(it.key)));
    this.list = [...leftList, ...rightList];
  }

  /**
   * Update selected value when click transfer button on template
   * @param ret : TransferChange - Information of data records has changed.
   */
  change(ret: TransferChange): void {
    const listKeys = ret.list.map((l) => l.key);
    if (ret.from == "left") {
      //selected new items
      this.value = [...(this.value || []), ...listKeys];
    } else {
      //remove selected items
      this.value = this.value.filter((key) => !listKeys.includes(key));
    }
  }

  /**
   * Cập nhật lại carrier list khi list update hoặc matchedList update.
   * Thay đổi trạng thái của từng carrier trong list
   * Sắp xếp lại Carrier theo thứ tự: matched, status, name
   * Auto selected các matched carrier
   */
  updateCarrierList({ updateMatchedIds } = { updateMatchedIds: false }) {
    this.list.map((item) => {
      item.tag = this.matchedIds.indexOf(item.key);
    });

    //sort by tag desc, status desc, title asc
    this.list = _(this.list)
      .chain()
      .sortBy(function (patient) {
        return patient.title;
      })
      .sortBy(function (patient) {
        let status = 0;
        switch (patient.status) {
          case Const.CarrierStatus.active: return 0;
          case Const.CarrierStatus.temporary: return 1;
          case Const.CarrierStatus.pending: return 2;
          case Const.CarrierStatus.rejected: return 3;
          default: return 9
        }
      })
      .sortBy(function (patient) {
        return patient.tag * -1; //reverse sort => sort tag desc
      })
      .value();

    //auto selected matchedIds
    this.updateSelectedOnGUI();
  }

  filterCarrier(inputValue: string, item: any): boolean {
    if(!inputValue) return true;
    const regexName = new RegExp(inputValue, "i");
    const regexMc = new RegExp(inputValue, "i");
    const regexDot = new RegExp(inputValue, "i");
    return regexName.test(item.title) || regexMc.test(item.mc) || regexDot.test(item.dot);
  }

  //cast function in template.
  $asTransferItems = (data: unknown): TransferItem[] => data as TransferItem[];

  $asCarrierStatusText = (status) => {
    function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const statusKey = Object.keys(Const.CarrierStatus).filter((key) => Const.CarrierStatus[key] == status)[0] || "";
    return capitalizeFirstLetter(statusKey);
  };

  $asCarrierMatchingType = (matchingTypeNumber: number) => {
    //matchingTypeNumber !=-1 là có index trong matching. tức là có match. index ==-1 là không match
    return MATCHING_TYPE[matchingTypeNumber != -1 ? 1 : 0];
  };
}

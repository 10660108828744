import { BaseComponent } from "@abstract/BaseComponent";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { DrawerService } from "@app/drawers/drawer.service";
import { Const } from "@const/Const";
import { WarehouseManifestEditPallet } from "../edit-pallet";
import { Utils } from "@services/utils";

@Component({
  selector: '[manifest-items-list-pallet]',
  templateUrl: './index.html',
  styleUrls: ['./index.scss',]

})
export class ManifestItemsListPallet extends BaseComponent {

  constructor() {
    super();
  }

  searchCarrierData;
  searchConsigneeData;
  public listOfOptionCarrier: string[] = [];
  public listOfOptionConsignee: string[] = [];

  public listOfData: any[] = [];
  public listOfDataOriginal: any[] = [];
  @Input() manifestId: string;
  @Input() set listItems(value) {
    this.listOfDataOriginal = value || [];
    this.listOfData = Utils.cloneObject(this.listOfDataOriginal);
    let listOfOptionCarrier = this.listOfData.map(it => it.carrier).filter(x => x);
    this.listOfOptionCarrier = Utils.uniqElementsArray(listOfOptionCarrier);
    let listOfOptionConsignee = this.listOfData.map(it => it.consignee).filter(x => x);
    this.listOfOptionConsignee = Utils.uniqElementsArray(listOfOptionConsignee);
  }

  @Output() onRefresh: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit(): void {
    super.ngOnInit(); 
  }

  onSearchCarrierChanged(event) {
    let listData = Utils.cloneObject(this.listOfDataOriginal);
    this.listOfData = listData.filter(it => this.filterCarrier(event, it));
  }

  onSearchConsigneeChanged(event) {
    let listData = Utils.cloneObject(this.listOfDataOriginal);
    this.listOfData = listData.filter(it => this.filterConsignee(event, it));
  }

  filterCarrier(inputValue: string[], item) {
    if (!Utils.isArrayNotEmpty(inputValue)) return true;
    return inputValue.includes(item.carrier);
  }

  filterConsignee(inputValue: string[], item) {
    if (!Utils.isArrayNotEmpty(inputValue)) return true;
    return inputValue.includes(item.consignee);
  }

  getRouterLink(item) {
    return [this.routeAdminCrossdockWarehouseManifests, this.manifestId, 'pallets', item.id]
  }

  getWeightValue(item) {
    let weight = '';
    if (item.weight) {
      weight = item.weight;
      if (item.weightUnit) {
        weight = `${weight} ${item.weightUnit}`;
      }
    }
    return weight;
  }

  getDimValue(item) {
    let dim = '';
    if (item.length || item.width || item.height) {
      dim = `${item.length || 'N/A'}x${item.width || 'N/A'}x${item.height || 'N/A'}`;    // (LxWxH)
      if (item.dimsUnit) {
        dim = `${dim} ${item.dimsUnit}`;
      }
    }
    return dim;
  }

  shouldShowBtnDelete(item) {
    return item.type != "CREATED_BY_CUSTOMER";
  }

  onBtnEditPallet(item) {
    DrawerService.openFormDrawer1(WarehouseManifestEditPallet, {
      nzContentParams: {
          updateSuccess: this.onRefreshData.bind(this),
          manifestId: this.manifestId,
          model: item,
          closeOnSuccess: true
      },
      nzWidth: 410,
      nzWrapClassName: 'wrap-drawer import-manifest-drawer',
    });
  }

  onRefreshData() {
    this.onRefresh.emit();
  }

  onBtnDeletePallet(item) {
    this.confirmDeletion({message: `There are items inside this pallet ${item.palletName}. Are you sure you want to delete it?`, txtBtnOk: 'Delete', fnOk: () => {
      let url = `${Const.APIURI_WAREHOUSE_MANIFEST}/${this.manifestId}/pallets/${item.id}`;
      this.api.DELETE(url).subscribe(
        (resp) => {
          this.showSuccess("Pallet has been deleted successfully.");
          this.onRefreshData();
        },
        (err) => {
          this.showErr(err);
        }
      );
    }});
  }

  sortName = (a, b) => a?.palletName?.localeCompare(b?.palletName)
  sortLabel = (a, b) => (a?.label || '')?.localeCompare(b?.label || '')
  sortWeight = (a, b) => (a?.weight || 0) - (b?.weight || 0)
  sortItem = (a, b) => (a?.itemIds?.length || 0) - (b?.itemIds?.length || 0)
  sortCarrier = (a, b) => a?.carrier?.localeCompare(b?.carrier)
  sortConsignee = (a, b) => a?.consignee?.localeCompare(b?.consignee)

  // gọi từ comp cha, để update lại data mới nhất cho những pallet đang view
  public refeshDataListItems(value) {
    this.listOfDataOriginal = value || [];
    if (this.searchCarrierData) {
      this.onSearchCarrierChanged(this.searchCarrierData);
    } else {
      this.listOfData = Utils.cloneObject(this.listOfDataOriginal);
    }
  }

}

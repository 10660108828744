<form class="form-detail" [formGroup]="formInput" nz-form>
  <div class="group no-padding">
    <!-- header -->
    <div class="group-header flex" style="align-items: center">
      <div style="text-wrap: nowrap !important">Payments Information</div>
      <ng-container *ngIf="requirePermissions([PermissionCode.client.update])">
        <div style="width: 100%"></div>
        <!-- edit button -->
        <button nz-button (click)="onBtnEdit()" class="btn-primary" *ngIf="!isEditing">
          <i nz-icon nzType="edit" nzTheme="outline"></i>
          Edit
        </button>
        <!-- cancel button with confirmation -->
        <button (nzOnConfirm)="onBtnCancel()"
                *ngIf="isEditing && isInputsChange()"
                [disabled]="onProgress"
                class="btn-primary right10"
                nz-button
                nz-popconfirm
                nzPopconfirmPlacement="top"
                nzOkText="Yes"
                nzCancelText="No"
                nzPopconfirmTitle="Are you sure you want to cancel editing?">
          <i nz-icon nzTheme="outline" nzType="close"></i>
          Cancel
        </button>
        <!-- cancel button -->
        <button (click)="onBtnCancel()"
                *ngIf="isEditing && !isInputsChange()"
                [disabled]="onProgress"
                class="btn-primary right10"
                nz-button>
          <i nz-icon nzTheme="outline" nzType="close"></i>
          Cancel
        </button>
        <!-- save button -->
        <button
          nz-button
          nzType="primary"
          (click)="onBtnSave()"
          class="btn-primary"
          *ngIf="isEditing"
          [disabled]="onProgress || !needUpdate"
          [nzLoading]="onProgress"
        >
          <i nz-icon nzType="save" nzTheme="outline"></i>
          Save
        </button>
      </ng-container>
    </div>
    <!-- form -->
    <div class="group-content">

      <ng-container *ngIf="'paymentTerm' as key">
        <div>{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
        <nz-form-item>
          <nz-form-control>
            <nz-select style="width: 200px;" nzBackdrop="true" nzAllowClear
              [formControlName]="key" [nzPlaceHolder]="getPlaceHolder(key)">
              <nz-option *ngFor="let item of allPaymentTerms" [nzLabel]="getPaymentTermName(item)" [nzValue]="item"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </ng-container>

      <div *ngIf="isEditing && !isExistPaymentInfo">
        <button style="width: 200px;" nz-button (click)="addItemToFormArray('payments')">Add bank information</button>
      </div>

      <ng-container *ngFor="let groupKey of ['payments'];" [formArrayName]="groupKey">
        <form nz-form *ngFor="let item of getArrayControls(groupKey); let i = index" [formGroupName]="i">
          <div nz-row [nzGutter]="12">
            <div nz-col nzMd="23">
              <div class="tdm-row cell-spacing">
                <div
                  class="tdm-col tdm-col-5"
                  *ngFor="let key of ['bankName', 'bankRoutingNumber', 'bankAccountNumber', 'ein', 'ssn']"
                >
                  <div class="form-label-v2">
                    {{getLabel(fullKey(groupKey, key))}}
                    <span *ngIf="isRequired(fullKey(groupKey, key))" class="label-mark-required"></span>
                  </div>
                  <nz-form-item>
                    <nz-form-control>
                      <input
                        nz-input
                        [formControlName]="key"
                        [type]="getInputType(fullKey(groupKey, key))"
                        [placeholder]="getPlaceHolder(fullKey(groupKey, key))"
                        (input)="onInputChanged($event, key)"
                        (keypress)="onInputKeyPress($event, key)"
                      />
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div>
            </div>
            <div nz-col nzMd="1">
              <div class="form-label-v2">&nbsp;</div>
              <nz-form-item>
                <nz-form-control>
                  <nz-space class="group-button">
                    <ng-container *ngIf="!isAdminReadOnlyRole && isEditing">
                      <span
                        *nzSpaceItem
                        nz-icon
                        nzType="plus-circle"
                        nzTheme="outline"
                        (click)="addItemToFormArray(groupKey)"
                      ></span>
                    </ng-container>

                    <ng-container *ngIf="i > 0 && !isAdminReadOnlyRole && isEditing">
                      <span
                        *nzSpaceItem
                        nz-icon
                        nzType="minus-circle"
                        nzTheme="twotone"
                        [nzTwotoneColor]="'#ff4d4f'"
                        (click)="removeItemInFormArray(groupKey, i)"
                      ></span>
                    </ng-container>
                  </nz-space>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </form>
      </ng-container>
    </div>
  </div>
</form>

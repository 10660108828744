
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { BaseComponent } from '@abstract/BaseComponent';

@Component({
    selector: 'view-shipment-bol',
    templateUrl: './index.html',
    styleUrls: ['./style.scss']
})
export class ViewShipmentBol extends BaseComponent {
    @Input() pod: any;
    @Output() onRemove: EventEmitter<any> = new EventEmitter<any>();
    compact: boolean = true
    pdf: boolean = false
    viewDrawer: boolean = false

    toggleCompact() {
        this.compact = !this.compact
        if (!this.compact && this.pdf) {
            this.openDrawer()
        }
    }

    ngOnChanges(): void {
        this.pdf = this.isPdf(this.pod)
    }

    openDrawer(): void {
        this.viewDrawer = true;
    }

    closeDrawer(): void {
        this.viewDrawer = false;
        this.compact = true
    }

    download() {
        this.downloadAttachedFile(this.pod)
    }
    
    remove() {
        const uploadId = this.pod.id;
        this.confirmYesNo(`Do you want to remove this bol?`, () => {
            this.onRemove.emit({ uploadId });
        });
    }
}
import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzTableModule } from 'ng-zorro-antd/table';
import { RouterModule } from '@angular/router';
import { JobStopTimeline } from '.';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NzFormModule,
    NzInputModule,
    NzPopoverModule,
    NzTableModule,
    RouterModule,
    NzDescriptionsModule,
    NzIconModule,
  ],
  declarations: [
    JobStopTimeline,
  ],
  exports: [
    JobStopTimeline,
  ]
})
export class ModuleJobStopTimeline {}
<ng-template [nzModalTitle]>
  <div class="flex-space-between">
    <div>{{headerText}}</div>
    <div>
      <span nz-icon nzType="close" nzTheme="outline" class="clickable" (click)="closeDialog()"></span>
    </div>
  </div>
</ng-template>
<div>
  <form nz-form [formGroup]="formInput">
    <div *ngFor="let key of ['carrierId']">
      <div class="form-label-v2 bottom5">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
      <app-form-carrier [formControlName]="key"></app-form-carrier></div>
  </form>
</div>
<div form-footer class="no-border"
  [canClickOK]="needUpdate" 
  [canClickCancel]="true"
  [onProgress]="onProgress"
  [nzIconOK]="false" [nzIconCancel]="false" labelOK="Update" nzOkDanger="false" (onOK)="onBtnSave()"
  (onCancel)="closeDialog()">
</div>
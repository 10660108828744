<div *ngIf="isLoading" class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
<div *ngIf="isError" class="nodata">Cannot fetch data, please try again later</div>

<form *ngIf="!isLoading" class="form-detail" [formGroup]="formInput" nz-form>
  <div class="carrier-selection">
    <div class="flex">
      <div class="f20 bottom20 flex1">Assign Carrier</div>
      <button *ngIf="!isCreateNew" nz-button nzDanger (click)="onBtnUnassign()">Unassign</button>
    </div>
    <div class="tdm-row cell-spacing">
      <div *ngFor="let key of ['carrierId']" class="tdm-col tdm-col-2" >
        <div class="form-label-v2">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
        <nz-form-item>
          <nz-form-control>
            <nz-select nzBackdrop="true" style="width: 100%;" [nzLoading]="isLoading"
              [formControlName]="key"
              nzShowSearch nzAllowClear nzPlaceHolder="Please select carrier" nzDropdownClassName="choose-carrier">
              <nz-option  nzCustomContent *ngFor="let carrier of listCarriers" [nzLabel]="getCarrierNameWithMCandDOT(carrier)" [nzValue]="carrier.id">
                <div>{{getCarrierName(carrier)}}</div>
                <div *ngIf="carrier?.basicInfo?.mc" class="sub-info">MC: {{getCarrierMC(carrier)}}</div>
                <div *ngIf="carrier?.basicInfo?.dot" class="sub-info">DOT: {{getCarrierDOT(carrier)}}</div>
              </nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div class="tdm-col tdm-col-2" >
        <div class="form-label-v2">Equipment</div>
        <div class="equipment-info">{{equipmentOrderText}}</div>
      </div>
    </div>
    <div class="tdm-row cell-spacing" style="margin-bottom: 10px;">
      <div class="tdm-col tdm-col-2 route-job-carrier-app">
        <a *ngIf="carrierUrl" [href]="carrierUrl" target="_blank">Route link for Carrier</a>
        <a style="font-size: 19px; margin-left: 10px;" (click)="copyCarrierUrl()" nz-tooltip
          nzTooltipTitle="Copy link for carrier">
          <i nz-icon nzType="link" nzTheme="outline"></i>
        </a>
      </div>
    </div>
    <div class="tdm-row cell-spacing">
      <div class="tdm-col tdm-col-2">
        <div class="qty-info">Total Quantity: {{totalQty}}</div>
      </div>
      
    </div>
  </div>

  <div class="section-cost">
    <div form-input-cost #costForm [form]="formInput.get('cost')" [showSubTotal]="false" [showVolumeDiscount]="false" [origin]="'assignCarrierForm'" [totalShipmentCost]="totalShipmentCost"></div>
  </div>

</form>

<div form-footer [onProgress]="onProgress" 
  [nzIconCancel]="null" [nzIconOK]="null"
  [canClickOK]="needUpdate && !onProgress && !isLoading"
  [canClickCancel]="!onProgress" [labelOK]="btnSubmitLabel"
  (onOK)="onBtnSave()" (onCancel)="closeDialog()"></div>
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '@abstract/BaseComponent';
import { Const } from '@const/Const';
import { ImageUtil } from '@services/image-util';

@Component({
  selector: '[warehouse-shipment-item]',
  templateUrl: './view.html',
  styleUrls: ['./style.scss', '../item-content/style.scss']
})
export class WarehouseShipmentItem extends BaseComponent {
  @Input() item: any = null;
  @Input() warehouseTasks: any = [];
  @Input() outboundLeg: any = {};
  @Output() onRefresh: EventEmitter<any> = new EventEmitter<any>();
  @Output() onRemove: EventEmitter<any> = new EventEmitter<any>();
  weight: number;
  barcode: string;
  compact: boolean = true;
  isUploading = false;
  private currentActionTask: any;

  constructor() {
    super();
    this.confirmMarkMissing = this.confirmMarkMissing.bind(this);
  }

  get edittingWeight(): boolean {
    return this.currentActionTask?.type == Const.WarehouseTaskType.addWeight;
  }

  get edittingBarcode(): boolean {
    return this.currentActionTask?.type == Const.WarehouseTaskType.scanPallet;
  }

  get isShowAddBarcode() {
    return !this.item?.internalBarcode;
  }

  turnOnEdittingWeight() {
    this.weight = this.item.actualWeight || this.item.weightPerUnit;
  }

  turnOnEdittingBarcode() {
    this.barcode = this.item.internalBarcode;
  }

  cancelEdittingWeight() {
    this.currentActionTask = null;
  }

  cancelEdittingBarcode() {
    this.currentActionTask = null;
  }

  toggleCompact() {
    this.compact = !this.compact;
  }

  _onRefesh() {
    setTimeout(() => {
      this.onRefresh.emit();
    }, 200);
  }

  confirmWeight() {
    if (!this.currentActionTask) {
      return;
    }
    let task = this.currentActionTask;
    this.currentActionTask = null;

    const url = `${Const.APIURI_WAREHOUSE_TASK}/${task.id}`;
    this.api.PUT(url, {itemId: this.item.id, weight: this.weight}).subscribe((res) => {
      this.item.actualWeight = this.weight;
      this.updateWarehouseTaskToCompleted(task);
      this._onRefesh();
    }, (err) => {
      console.log(err);
      this.showErr(err);
    });
  }

  confirmBarcode() {
    if (!this.currentActionTask) {
      return;
    }
    let task = this.currentActionTask;
    this.currentActionTask = null;

    const url = `${Const.APIURI_WAREHOUSE_TASK}/${task.id}`;
    this.api.PUT(url, {itemId: this.item.id, barcode: this.barcode}).subscribe((res) => {
      this.item.internalBarcode = this.barcode;
      this.updateWarehouseTaskToCompleted(task);
      this._onRefesh();
    }, (err) => {
      console.log(err);
      this.showErr(err);
    });
  }

  markMissing() {
    this.modalService.create({
      nzTitle: 'Mark Item Missing Confirmation',
      nzContent: `Please confirm you want to mark item ${this.item.name} as missing`,
      nzClosable: false,
      nzMaskClosable: false,
      nzCentered: true,
      nzOkText: 'OK',
      nzOnOk: () => this.confirmMarkMissing(),
      nzCancelText: 'Cancel'
    });
  }

  confirmMarkMissing() {
    const url = Const.APIV2(`shipment_items/${this.item.id}/missing`);
    this.api.PUT(url, {isMissing: true}).subscribe((res) => {
      this.item.isMissing = true;
    });
  }

  onOpenUploadForm() {
    if (!this.currentActionTask) {
      return;
    }

    setTimeout(() => {
      let element: HTMLElement = document.getElementById('uploadPhotoInput-' + this.item.id) as HTMLElement;
      //nếu chưa có element thì đợi thêm 200ms và thử lại
      if (!element) {
        return this.onOpenUploadForm();
      }
      element.click();
    }, 200);
  }

  onFileSelected(inputElement: HTMLInputElement) {
    let file = inputElement.files[0];
    inputElement.value = '';
    ImageUtil.resizeImageIfNeeded(file).then((blob) => this.uploadPhoto(blob, file.name));
  }

  updateWarehouseTaskToCompleted(task) {
    //update lại frontend khi edit weight, upload photo... done
    task.status = Const.WarehouseTaskStatus.completed;
  }

  resetWarehouseTaskToPending(task) {
    //update lại frontend khi edit weight, upload photo... done
    task.status = Const.WarehouseTaskStatus.pending;
  }

  updateWarehouseTaskToSkipped(task) {
    task.status = Const.WarehouseTaskStatus.skipped;
  }

  getWarehouseTaskIcon(status, type) {
    if (type == Const.WarehouseTaskType.uploadProductPhoto && this.isUploading) {
      return 'loading';
    } //case upload item photo mới hiển thị loading
    switch (status) {
      case Const.WarehouseTaskStatus.pending:
        return 'exclamation-circle';
      case Const.WarehouseTaskStatus.completed:
      case Const.WarehouseTaskStatus.skipped:
        return 'check-circle';
      default:
        return 'hourglass';
    }
  }

  getWarehouseTaskColor(status) {
    switch (status) {
      case Const.WarehouseTaskStatus.pending:
        return 'warning';
      case Const.WarehouseTaskStatus.completed:
      case Const.WarehouseTaskStatus.skipped:
        return 'success';
      default:
        return 'default';
    }
  }

  getWarehouseTaskTypeText(task) {
    const type = task?.type;
    let text = Const.WarehouseTaskName[type] || type;
    if (type == Const.WarehouseTaskType.uploadProductPhoto && task?.data?.images.length) {
        text = `${text} (${task?.data?.images.length})`;
    }
    return text
  }

  getTaskStage(task) {
    if (task.stage != 'outbound') {
      return 'IN';
    } else {
      return 'OUT';
    }
  }

  showItemPhoto(url) {
    this.showDialog(`<img src=${url} width="100%" />`);
  }

  skipTask(task) {
    const url = `${Const.APIURI_WAREHOUSE_TASK}/${task.id}/skip`;
    this.api.PUT(url).subscribe((res) => {
      this.updateWarehouseTaskToSkipped(task);
      this._onRefesh();
    }, (err) => {
      this.showErr(err);
    });
  }

  handleWarehouseTask(task) {
    this.currentActionTask = task;
    switch (task.type) {
      case Const.WarehouseTaskType.uploadProductPhoto:
        this.onOpenUploadForm();
        break;
      case Const.WarehouseTaskType.addWeight:
        this.turnOnEdittingWeight();
        break;
      case Const.WarehouseTaskType.scanPallet:
        this.turnOnEdittingBarcode();
        break;
      case Const.WarehouseTaskType.markLoaded:
        this.turnOnLoadPallet();
        break;
      default:
        break;
    }
  }

  turnOnLoadPallet() {
    if (!this.item.internalBarcode) {
      this.currentActionTask = null;
      return this.showErr(`Please update barcode for item '${this.item.name}'`);
    }
    if (this.currentActionTask.status == Const.WarehouseTaskStatus.completed) {
      return this.resetTaskLoadPallet()
    }
    if (!this.outboundLeg?.lastJobId) {
      this.currentActionTask = null;
      return this.showErr(`Outbound route is not available.`);
    }
    return this.markLoadPallet();
  }

  private confirmMarkLoaded() {
    if (!this.currentActionTask) return;
    let task = this.currentActionTask;
    this.currentActionTask = null;

    const url = `${Const.APIURI_WAREHOUSE_TASK}/${task.id}`;
    this.api.PUT(url, {itemId: this.item.id}).subscribe((res) => {
        this.updateWarehouseTaskToCompleted(task);
        this._onRefesh();
    }, (err) => {
        this.showErr(err.message);
    });
  }

  private markLoadPallet() {
    this.modalService.create({
      nzTitle: 'Pallet Loaded Confirmation',
      nzContent: `Please confirm that item '${this.item.internalBarcode}' is loaded.`,
      nzClosable: false,
      nzMaskClosable: false,
      nzCentered: true,
      nzOkText: 'Confirm',
      nzOnOk: () => this.confirmMarkLoaded(),
      nzCancelText: 'Cancel',
      nzOnCancel: () => {
        this.currentActionTask = null;
      }
    });
  }

  private resetTaskLoadPallet() {
    this.modalService.create({
      nzTitle: 'Reset Task Load Pallet',
      nzContent: `Are you sure you want to reset task Load pallet for item '${this.item.internalBarcode}'?`,
      nzClosable: false,
      nzMaskClosable: false,
      nzCentered: true,
      nzOkText: 'Reset',
      nzOnOk: () => this.confirmResetTaskLoadPallet(),
      nzCancelText: 'Cancel',
      nzOnCancel: () => {
        this.currentActionTask = null;
      }
    });
  }

  private confirmResetTaskLoadPallet() {
    if (!this.currentActionTask) return;
    let task = this.currentActionTask;
    this.currentActionTask = null;

    const url = `${Const.APIURI_WAREHOUSE_TASK}/${task.id}/reset`;
    this.api.PUT(url, {itemId: this.item.id}).subscribe((res) => {
        this.resetWarehouseTaskToPending(task);
        this._onRefesh();
    }, (err) => {
        this.showErr(err.message);
    });
  }

  private async uploadPhoto(file: Blob, fileName: string) {
    this.isUploading = true;
    let formData = new FormData();
    formData.append('file', file, fileName);
    formData.append('params', JSON.stringify({
      itemId: this.item?.id,
    }));
    this.api.postFormData(Const.APIURI_WAREHOUSE_UPLOAD_FILES, formData).subscribe((resp) => {
      this.isUploading = false;
      const uploadIds = resp?.data?.list_data?.map(item => item.id);
      if (uploadIds) {
        this.assignUploadFileToItem(uploadIds);
      } else {
        this.showErr('Uploading file has no response!');
      }
    }, (err) => {
      this.currentActionTask = null;
      this.isUploading = false;
      console.log(err);
      this.showErr(err);
    });
  }

  private async assignUploadFileToItem(uploadIds) {
    if (!this.currentActionTask) {
      return;
    }
    let task = this.currentActionTask;
    this.currentActionTask = null;

    this.isUploading = true;
    const url = `${Const.APIURI_WAREHOUSE_TASK}/${task.id}`;
    const data = {
      itemId: this.item.id, uploadIds: uploadIds
    };
    this.api.PUT(url, data).subscribe((resp) => {
      this.isUploading = false;
      this.updateWarehouseTaskToCompleted(task);
      this._onRefesh();
      this.showSuccess('Photo upload successfully');
    }, (err) => {
      this.isUploading = false;
      console.log(err);
      this.showErr(err);
    });
  }

  public isCompleted(status) {
    return [Const.WarehouseTaskStatus.completed, Const.WarehouseTaskStatus.skipped].includes(status)
  }

  private getTask(type, stage) {
    const task = this.warehouseTasks.find(it => it.type === type && it.stage === stage);
    return task
  }

  confirmRemove(type, uploadId = '') {
    const task = this.currentActionTask || this.getTask(type, Const.WarehouseTaskStage.inbound) || this.getTask(type, Const.WarehouseTaskStage.outbound);
    if(!type || !task) return;
    this.confirmYesNo(`Do you want to ${type === Const.WarehouseTaskType.uploadProductPhoto ? 'remove this photo' : 'reset this task'}?`, () => {
      this.onRemove.emit({ taskId: task.id, data: { uploadId } });
    });
  }

  get images() {
    if (!this.item?.warehouseTasks?.length) return [];
    const taskUploadPhotos = this.item?.warehouseTasks.filter(item => item.type == Const.WarehouseTaskType.uploadProductPhoto);
    if (!taskUploadPhotos?.length) return [];

    let lists = [];
    taskUploadPhotos.map(item => {
      if (item?.data?.images?.length) lists = [...lists, ...item?.data?.images];
    });
    return lists
  }
}

<nz-modal
  (nzOnCancel)="closeDialog()"
  (nzOnOk)="onBtnSave()"
  [(nzVisible)]="isDialog"
  [nzCentered]="true"
  [nzClassName]="'app-detail-document'"
  [nzFooter]="nzModalFooter"
  [nzMaskClosable]="false"
  [nzTitle]="modalTitle"
>
  <ng-container *nzModalContent>
    <div class="group-title">
      <div class="title">Upload Certificate</div>
      <div class="btn-upload">
        <button (click)="inputFile.click()" nz-button nzShape="round">
          <i nz-icon nzTheme="outline" nzType="upload"></i> Upload
        </button>
        <input #inputFile (change)="onFileSelected(inputFile.files)" accept=".doc,.docx,.pdf,image/*, application/pdf, application/msword"
               hidden
               type="file"
        />
      </div>
    </div>

    <div class="group-files">
      <ng-container *ngIf="fileArrs.length">
        <ng-container *ngFor="let item of fileArrs; let i = index">
          <div class="group-item center-vertical">
            <div class="file-name">
              <ng-container *ngIf="!isFile(item)">
                <a [href]="attachedFileUrl(item)" [ngClass]="{'strikethrough': item.deleted}" nz-tooltip
                   nzTooltipTitle="View file" target="_blank">
                  <i nz-icon nzTheme="outline" nzType="paper-clip"></i>
                  <span class="description">{{ getAttachedFileDesc(item) }}</span>
                </a>
              </ng-container>
              <ng-container *ngIf="isFile(item)">
                <i nz-icon nzTheme="outline" nzType="paper-clip"></i>
                <span class="description">{{ getAttachedFileDesc(item) }}</span>
              </ng-container>
            </div>
            <div>
              <button (click)="delFile(i)" *ngIf="!isFileDeleted(i)" nz-button nzType="text">
                <i nz-icon nz-tooltip nzTooltipPlacement="right" nzTooltipTitle="Delete file" nzType="delete"></i>
              </button>
              <button (click)="undoDeleteFile(i)" *ngIf="isFileDeleted(i)" nz-button nzType="text">
                <i nz-icon nz-tooltip nzTooltipPlacement="right" nzTooltipTitle="Undo delete file" nzType="undo"></i>
              </button>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>

    <div *ngIf="shouldShowDate" [formGroup]="formInput" class="group-fields top10" nz-form>
      <div [nzGutter]="{ xs: 8, sm: 16, md: 16, lg: 16 }" nz-row>
        <div nz-col nzMd="12" nzXs="24">
          <div class="form-label-v2">{{ getLabel('startDate') }}<span *ngIf="requireDate"
                                                                      class="label-mark-required"></span></div>
          <nz-form-item>
            <nz-form-control>
              <nz-date-picker [nzDisabledDate]="disabledStartDate"
                              [nzPlaceHolder]="getPlaceHolder('startDate')"
                              [nzShowToday]="false"
                              class="carrier-input"
                              formControlName="startDate" nzFormat="yyyy-MM-dd"
              ></nz-date-picker>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col nzMd="12" nzXs="24">
          <div class="form-label-v2">{{ getLabel('endDate') }}<span *ngIf="requireDate"
                                                                    class="label-mark-required"></span></div>
          <nz-form-item>
            <nz-form-control>
              <nz-date-picker [nzDisabledDate]="disabledEndDate"
                              [nzPlaceHolder]="getPlaceHolder('endDate')"
                              [nzShowToday]="false"
                              class="carrier-input"
                              formControlName="endDate" nzFormat="yyyy-MM-dd"
              ></nz-date-picker>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #nzModalFooter>
    <button (click)="onBtnSave()"
            [disabled]="!needUpdate || fileArrs.length == 0 || !formInput.valid"
            [nzLoading]="isLoading" [nzLoading]="onProgress"
            class="btn-done btn-black bottom10 top10"
            nz-button nzType="primary"
    >Done
    </button>
  </ng-template>
</nz-modal>

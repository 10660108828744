import { NgModule } from '@angular/core';

import { CommonModule } from "@angular/common";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzPopoverModule } from 'ng-zorro-antd/popover';

import { DeliveryEta } from './eta';

@NgModule({
    imports: [
        NzIconModule,
        CommonModule,
        NzPopoverModule,
    ],
    declarations: [
        DeliveryEta
    ],
    exports: [
        DeliveryEta
    ],
    providers: []
})

export class DeliveryUpdateModule { }
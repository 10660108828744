import { BaseComponent } from "@abstract/BaseComponent";
import { Component, Input } from "@angular/core";
import { DrawerService } from "@app/drawers/drawer.service";
import { Const } from "@const/Const";
import { ManifestUploadBOLFiles } from "../upload-bol-files";
import { ManifestUploadContainerImages } from "../upload-container-images";

@Component({
  selector: '[manifest-detail-documents]',
  templateUrl: './index.html',
  styleUrls: ['./index.scss',]

})
export class ManifestDetailDocuments extends BaseComponent {

  constructor() {
    super();
  }

  @Input() manifestId: string;

  ngOnInit(): void {
    super.ngOnInit(); 
  }

  onBtnViewAddBOLFiles() {
    DrawerService.openDrawer1(ManifestUploadBOLFiles, {
      nzContentParams: {
          manifestId: this.manifestId,
          closeOnSuccess: true
      },
      nzWidth: 410,
      nzWrapClassName: 'wrap-drawer import-manifest-drawer',
    });
  }

  onBtnViewAddContainerImages() {
    DrawerService.openDrawer1(ManifestUploadContainerImages, {
      nzContentParams: {
        manifestId: this.manifestId,
          closeOnSuccess: true
      },
      nzWidth: 410,
      nzWrapClassName: 'wrap-drawer import-manifest-drawer',
    });
  }

}

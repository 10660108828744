import { ScatterplotLayer, PathLayer } from '@deck.gl/layers'
import { DateUtil } from '@services/date-utils';
import { CompositeLayer } from 'deck.gl';

export default class DriverLocationLayer extends CompositeLayer {
    id: string
    static layerName = "DriverLocationLayer"
    locations: any[]
    options: any = {}

    constructor(id, locations, options = {}) {
        super({id, data: {locations}})
        this.options = options
        this.locations = locations
    }

    createDriverLocationLayer() {
        if (!this.locations?.length) return null
        const data = this.locations.filter(it => it.location).map(it => {
            const { location, ts, source } = it
            const { latitude, longitude, speed } = location || {}
            let mph = speed && source && source !== 'driver-app' ? (speed || 0) : (speed || 0) * 3600 / 1609.34
            if (mph < 0) mph = 0
            const info = DateUtil.displayLocalTime(ts, { timezone: Intl.DateTimeFormat().resolvedOptions().timeZone }) +
                `\nGPS: ${latitude?.toFixed(3)},${longitude?.toFixed(3)}` +
                `\nSpeed: ${mph.toFixed(0)} mph`
        return Object.assign(it, {
                coordinates: [location.longitude, location.latitude],
                info
            })
        })
        return new ScatterplotLayer({
            id: `${this.id}-scatterplot-layer`,
            data,
            pickable: true,
            stroked: true,
            filled: true,
            radiusScale: 2,
            radiusMinPixels: 2,
            radiusMaxPixels: 2,
            getPosition: d => d.coordinates,
            opacity: 1.0,
            getFillColor: d => [0, 100, 0, 100],
            getLineColor: d => [0, 100, 0, 255]
        });
    }

    createDriverLocationPathLayer() {
        if (!this.locations?.length) return null
        const points = this.locations.filter(it => it.location).map(it => {
            const { location } = it
            const { latitude, longitude } = location || {}
            return [longitude, latitude]
        })
        const data = [{
            coordinates: points
        }]
        
        return new PathLayer({
            data,
            id: this.id + '-polyline',
            pickable: false,
            widthScale: 1,
            getPath: d => d.coordinates,
            getColor: d => this.options.color || [0, 100, 0, 100],
            getWidth: d => this.options.width || 5,
            opacity: this.options.opacity || 1.0,
            widthMinPixels: this.options.minWidth || 4,
            widthMaxPixels: this.options.maxWidth || 7,
            jointRounded: true,
            capRounded: true,
        });
    }

    renderLayers() {
        return [
            this.createDriverLocationPathLayer(),
            this.createDriverLocationLayer(),
        ];
    }

}

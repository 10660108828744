import { Component, Input, ViewChild } from "@angular/core";
import { Const } from "@const/Const";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { environment } from "@env/environment";
import { Log } from "@services/log";
import { MasterData } from "@services/master.data";
import { Utils } from "@services/utils";
import { FormInputCost } from "@base/form-input-cost/comp";
import {DateUtil} from "@services/date-utils";

@Component({
  selector: "[assign-carrier]",
  templateUrl: "./index.html",
  styleUrls: ['../../../../dialogs/dialogs.scss', '../../../../../styles/row-col.scss', './index.scss']
})
export class AssignCarrier extends BaseFormDialog1 {
  protected formGroupDeclaration: FormGroupDeclaration = {
    carrierId: { label: "Are you sure to assign new carrier?", required: true, readOnly: true, submitReadOnly: true },
    cost: { label: "", type: "formGroup", childItem: FormInputCost.declaration },
  };

  get isCreateNew(): boolean {
    return !this.model;
  }

  get btnSubmitLabel(): string {
    return this.isCreateNew ? 'Assign Carrier' : 'Update'
  }

  public isError = false;
  public isLoading = false;
  public listCarriers = [];
  public carrierUrl = '';

  public get totalQty(): number {
    return this.costForm?.qty ?? 0;
  }

  private _equipmentOrderText;
  @Input() set shipments(value) {
    this._equipmentOrderText = this.getEquipment(value);
  }

  @Input() jobId;
  @Input() job = undefined;
  @Input() carrierId; // nếu có carrierId tức là đã từng assign rồi -> gọi api để lấy dữ liệu
  @Input() matchedCarrier; //carrier info của carrer được chọn trong trường hợp gọi từ carrier bids sang.
  @Input() cost;
  @ViewChild("costForm") costForm: FormInputCost;
  @Input() baseCost: number = null;
  @Input() isRequireCarrierAcceptLoadTender: boolean = false;
  totalShipmentCost: number = undefined;

  constructor() {
    super();
  }

  get noDataVehicle(): string {
    let carrierId = this.getItemValue("carrierId");
    if (!carrierId) return "Please select carrier first";
    return undefined; // return undefined to use default
  }

  get formGroupError() {
    let err = super.formGroupError;
    if (!err) {
      err = this.costForm?.formGroupError;
    }
    return err;
  }

  get shouldCreateFormImmediately() {
    return false;
  }

  get isAssignedCarrier() {
    return this.job?.assignedCarrier;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.getData();
    this.carrierUrl = `${environment.carrierWebUrl}/dashboard/my-loads/${this.jobId}`;
  }

  private getDataDone(err = null) {
    if (err) {
      this.showErr(err);
      this.isError = true;
    } else {
      this.createFormInput(this.model);
      if (this.model) {
        // skip
      } else if (this.matchedCarrier?.carrierId) {
        //trường hợp không có model và có matchedCarrierId
        this.setItemValue("carrierId", this.matchedCarrier?.carrierId);
        this.setItemValue("cost", this.cost);
      } else if (this.baseCost) {
        this.setItemValue("cost.transitCost.rate", this.baseCost);
        this.setItemValue("cost.transitCost.qty", 1);
      }
    }

    this.isLoading = false;
    if (this.isAdminReadOnlyRole) {
      this.setEnableFormGroup(false);
    }
  }

  get getInfoAssignedCarrier (){
    return {
      cost: this.job?.assignedCarrier?.cost?.grandTotal || 0,
      name  : this.job?.carrier?.basicInfo?.name || "N/A",
      assignBy:  this.job?.carrierSalesRep?.fullName || "N/A",
      assignAt: DateUtil.format(this.job?.assignedCarrier?.update?.when, 'YYYY/MM/DD HH:mm:ss') || "N/A"
    }
  }

  private getData() {
    this.isLoading = true;
    // let urls = [`${Const.APIURI_CARRIERS}?status=${Const.CarrierStatus.active}&limit=-1`];
    let urls = [
      Const.API_GET_CARRIER_FOR_ASSIGN,
      `${Const.APIV2(Const.APIURI_JOBS)}/${this.jobId}/revenues`
    ];
    if (this.carrierId) {
      urls.push(`${Const.APIURI_JOBS}/${this.jobId}/metadata`);
    }
    this.api.concurrentGET(urls).subscribe(
      (resp) => {
        this.listCarriers = resp[0].data.list_data;
        //tính shipment revenue để hiển thị lên form assign carrier
        if (resp?.[1]) {
          let shipmentCost = 0;
          const shipmentType = resp?.[1]?.data?.list_data?.[0]?.shipmentType; //shipmentType của shipment đầu tiên
          //nếu shipmentType là fullTruckLoad thì chỉ lấy cost của order của shipment đầu tiên
          if (shipmentType === Const.ShipmentTypes.fullTruckLoad) {
            const firstShipment = resp?.[1]?.data?.list_data?.[0];
            shipmentCost = firstShipment?.order?.cost?.grandTotal ?? 0;
          }
          else {
            //nếu shipmentType là LTL thì lấy tổng cost của các shipment
            for (let shipment of resp?.[1]?.data?.list_data || []) {
              const shCost = shipment.cost?.grandTotal ?? 0;
              const revenueAllocation = shipment.finance?.revenueAllocation || 0;
              const mainCost = revenueAllocation ? revenueAllocation : shCost;
              shipmentCost += mainCost;
            }
          }
          this.totalShipmentCost = shipmentCost;
        }

        if (resp.length > 2) {
          this.model = resp[2].data?.assignedCarrier;
        }
        this.getDataDone();
      },
      (err) => {
        this.getDataDone(err);
      }
    );
  }

  onBtnSave(): void {
    if (!this.needUpdate) {
      return;
    }
    let json = this.getFormData_JSON(true);
    this.assignCarrier(json)
    // this.checkValidateAssignCarrier(json);
  }

  onBtnUnassign() {
    this.confirmYesNo('Do you really want to unassign carrier?', () => {
      this.unassignCarrier();
    });
  }

  protected setEnableFormGroup(enabled: boolean): void {
    super.setEnableFormGroup(enabled);
    if (this.costForm) {
      this.costForm.isEnabled = enabled;
    }
  }

  protected onUpdateSuccess(resp) {
    this.updateSuccess(resp);
    if (this.closeOnSuccess) {
      this.closeDialog();
    }
  }

  // private checkValidateAssignCarrier(data) {
  //   if (!this.jobId) {
  //     return Log.e("jobId is required");
  //   }
  //   this.setEnableFormGroup(false);
  //   this.startProgress();
  //   const oldCarrierId = this.carrierId ?? this.job?.carrier?.id;
  //   if (oldCarrierId && oldCarrierId != data.carrierId) {
  //     this.api.POST(`${Const.APIV2(Const.APIURI_JOBS)}/${this.jobId}/check-time-to-assign-carrier`, {carrierId: data.carrierId}).subscribe(
  //       (resp) => {
  //         if (resp?.data?.isError) {
  //           return this.modalService.confirm({
  //             nzTitle: resp?.data?.messageText,
  //             nzClosable: false,
  //             nzMaskClosable: false,
  //             nzCentered: true,
  //             nzOkText: 'Yes',
  //             nzOnOk: () => {
  //               const oldCarrierAssigned = {
  //                 oldCarrier: resp?.data?.oldCarrier,
  //                 carrierSalesRep: resp?.data?.carrierSalesRep
  //               }
  //               this.assignCarrier({...data, oldCarrierAssigned})
  //             },
  //             nzCancelText: 'No',
  //             nzOnCancel:() => {
  //               this.stopProgress();
  //               this.setEnableFormGroup(true);
  //             }
  //           });
  //         }
  //         return this.assignCarrier(data);
  //       },
  //       (err) => {
  //         this.showErr(err);
  //         this.stopProgress();
  //         this.setEnableFormGroup(true);
  //       }
  //     );
  //   } else {
  //     this.assignCarrier(data);
  //   }
  // }

  private assignCarrier(data, oldCarrierAssigned = null) {
    if (!this.jobId) {
      return Log.e("jobId is required");
    }
    this.setEnableFormGroup(false);
    this.startProgress();
    let isNegativeMargin = data.cost.grandTotal >= this.totalShipmentCost
    if (!isNegativeMargin) {
      if (data.cost.negativeMarginReason) delete data.cost.negativeMarginReason
      if (data.cost.manager) delete data.cost.manager
    }

    const assignedCarrier = this.job?.assignedCarrier;
    if (assignedCarrier && !oldCarrierAssigned) {
      oldCarrierAssigned = {
        oldCarrier: {
          id: assignedCarrier.carrierId,
          name: this.job?.carrier?.basicInfo?.name ?? 'N/A'
        },
        carrierSalesRep: this.job?.carrierSalesRep,
      }
    }
    const params = {
      jobId: this.jobId,
      carrierId: data.carrierId,
      cost: data.cost,
      isRequireCarrierAcceptLoadTender: this.isRequireCarrierAcceptLoadTender,
      oldCarrierAssigned,
    };
    this.api.POST(`${Const.APIURI_CARRIER_BIDS}/accept-bid`, params).subscribe(
      (resp) => {
        Log.d("assignCarrier done ", resp);
        this.onUpdateSuccess(resp);
        this.stopProgress();
      },
      (err) => {
        if (err?.data?.oldCarrier) {
          return this.modalService.confirm({
            nzTitle: `Route ${this.job.code} was just assigned to a new carrier`,
            nzContent: err?.message,
            nzClosable: false,
            nzMaskClosable: false,
            nzCentered: true,
            nzOkText: 'Yes',
            nzOnOk: () => {
              this.assignCarrier(data, {
                oldCarrier: err?.data?.oldCarrier,
                carrierSalesRep: err?.data?.carrierSalesRep
              });
            },
            nzCancelText: 'No',
            nzOnCancel:() => {
              this.stopProgress();
              this.setEnableFormGroup(true);
            }
          });
        } else {
          this.showErr(err);
          this.stopProgress();
          this.setEnableFormGroup(true);
        }
      }
    );
  }

  private unassignCarrier() {
    if (!this.jobId) {
      return Log.e('jobId is required');
    }
    this.setEnableFormGroup(false);
    this.startProgress();
    this.api.PUT(`${Const.APIURI_JOBS}/${this.jobId}/unassign_carrier`, null).subscribe(
      resp => {
        Log.d('unassignCarrier done ', resp);
        this.onUpdateSuccess('Unassign done');
        this.stopProgress();
      }, err => {
        this.showErr(err);
        this.stopProgress();
      }
    );
  }

  public get equipmentOrderText() {
    return this._equipmentOrderText || 'N/A';
  }

  private getEquipment(shipments) {
    let arr = [];
    if (Utils.isArrayNotEmpty(shipments)) {
      for (let order of shipments) {
        if (order.shipmentType != Const.ShipmentTypes.fullTruckLoad) return null;
        if (order.shipmentModeId) {
          let shipmentMode = MasterData.getShipmentModeName(order.shipmentModeId) ?? '';
          if (order.equipmentId) {
            let equipment = MasterData.getEquipmenName(order.equipmentId);
            if (equipment) {
              if (shipmentMode) {
                shipmentMode += '/';
              }
              shipmentMode += equipment;
            }
          }
          if (!arr.includes(shipmentMode)) {
            arr.push(shipmentMode);
          }
        }
      }
    }
    return arr.join(', ');
  }

  copyCarrierUrl() {
    Utils.copyTextToClipboard(this.carrierUrl, e => {
      if (e) {
        this.showErr('Cannot copy text to clipboard');
      } else {
        this.showSuccess('URL has already been copied to the clipboard');
      }
    })
  }

  public getCarrierDOT(carrier): string {
    return carrier?.basicInfo?.dot ?? '';
  }

  public getCarrierNameWithMCandDOT(carrier): string {
    if (carrier?.basicInfo?.mc && carrier?.basicInfo?.dot)
      return `${carrier?.basicInfo?.name} (MC:${carrier?.basicInfo?.mc}/DOT:${carrier?.basicInfo?.dot})`
    if (carrier?.basicInfo?.mc) return `${carrier?.basicInfo?.name} (MC:${carrier?.basicInfo?.mc})`
    if (carrier?.basicInfo?.dot) return `${carrier?.basicInfo?.name} (DOT:${carrier?.basicInfo?.dot})`
    return carrier?.basicInfo?.name ?? '';
  }

  protected readonly DateUtil = DateUtil;
}

import { Component, Input } from "@angular/core";
import { Const } from "@const/Const";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { StringULID } from "@wearewarp/types";
import { LocationSettings } from "@wearewarp/types/data-model";

interface ILocationSetting {
  settings: LocationSettings, 
  locationType: string, 
  warehouseId?: StringULID
  metadata?: any
}

@Component({
  selector: '[location-setting]',
  templateUrl: './index.html',
  styleUrls: []
})
export class LocationSetting extends BaseFormDialog1<ILocationSetting> {

  @Input() onSave: (data: ILocationSetting) => any;
  @Input() headerText = 'Location Settings';

  public listWarehouses = [];
  private _warehouses = [];

  protected formGroupDeclaration: FormGroupDeclaration = {
    warehouseId: { label: 'Warehouse' },
    locationType: { label: 'Location Type' },
    settings: { label: 'Settings' }
  };

  public types = [
    { title: 'All', value: 'ALL' },
    { title: 'Pickup', value: 'PICKUP' },
    { title: 'Dropoff', value: 'DROPOFF' }
  ]

  constructor() {
    super();
  }

  initData() {
    this.getWarehouses();
    this.listWarehouses = this.model ? [{ name: this.model.metadata?.warehouseName, id: this.model.warehouseId }] : [];
  }

  async getWarehouses() {
    const resp = await this.api.GET(`${Const.APIURI_WAREHOUSES}?limit=-1`).toPromise();
    const warehouses = resp?.data?.list_data || [];
    this._warehouses = warehouses;
    return warehouses;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.initData();
  }

  get isCreateNew(): boolean {
    return !this.model;
  }

  get btnSubmitLabel(): string {
    return this.isCreateNew ? 'Add' : 'Update';
  }

  onBtnSave() {
    let data = this.getFormData_JSON(true);
    this.onProgress = true;
    if(data.warehouseId) {
      let warehouse = this._warehouses.find(it => it.id === data.warehouseId);
      data.metadata = {
        warehouseName: warehouse?.name
      }
    }
    this.onSave(data);
    this.onProgress = false;
    this.closeDialog();
  }
}

import { Component, Input } from "@angular/core";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { Log } from "@services/log";
import { Const } from "@const/Const";

@Component({
  selector: "add-carrier-sale-rep",
  templateUrl: "./index.html",
  styleUrls: [
    "../../../../../dialogs/dialogs.scss",
    "../../../../../../styles/row-col.scss",
    "./index.scss",
  ],
})
export class AddCarrierSaleRep extends BaseFormDialog1 {
  @Input() jobId;
  @Input() carrierSalesRepId:string; // ULID

  public listCarrierSales = [];

  ngOnInit(): void {
    super.ngOnInit();
    this.fetchListCarrierSales();
  }

  public isFetchingCarrierSaleRep = false;
  private fetchListCarrierSales(silent: boolean = false) {
    this.isFetchingCarrierSaleRep = true;
    this.api.getListAdminUsers().subscribe(
      resp => {
        this.listCarrierSales = resp?.data?.list_data ?? [];
        this.isFetchingCarrierSaleRep = false;
      }, err => {
        if (silent) {
          // Nếu silent thì không bắn lỗi lên màn hình.
          Log.e(err);
        } else {
          this.showErr(err);
        }
        this.isFetchingCarrierSaleRep = false;
      }
    );
  }

  onBtnSave(): void {
    this.setEnableFormGroup(false);
    this.startProgress();
    if (!this.jobId) {
      return Log.e('jobId is required');
    }
    this.setEnableFormGroup(false);
    this.startProgress();
    const carrierSalesRepId = this.carrierSalesRepId
    this.api.PUT(`${Const.APIURI_JOBS}/${this.jobId}/carrier_sale_rep`, {carrierSalesRepId}).subscribe(
      resp => {
        Log.d('update carrier sales rep done ', resp);
        this.onUpdateSuccess(resp);
        this.stopProgress();
      }, err => {
        this.showErr(err);
        this.stopProgress();
      }
    );
  }

  canClickOk() {
    return this.carrierSalesRepId ? true : false;
  }
}

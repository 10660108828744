<div class="dashboard-child-container no-padding list">
    <div class="list-header" style="align-items: center;">
      <div list-header-filter #listHeader class="flex1"></div>
      <button nz-button nzType="primary" (click)="onBtnImportManifest()">
        <i nz-icon nzType="upload" nzTheme="outline"></i>Import Manifest
      </button>
    </div>
    <div class="list-body">
      <nz-table #nzTable nzBordered="true" 
        [nzData]="listData" 
        [nzTotal]="totalCount"
        [nzFrontPagination]="false" 
        [nzShowPagination]="totalCount > listData.length" 
        [nzPageSize]="limit" 
        [nzPageIndex]="pageIndex"
        [nzLoading]="isLoading" 
        [nzLoadingIndicator]="tplLoading" 
        [nzNoResult]="tplNoData" 
        [nzSize]="'default'"
        (nzPageIndexChange)="onDataListPageChanged($event)">
        <ng-template #tplNoData>
          <div *ngIf="!isLoading" class="nodata"><i nz-icon nzType="search" nzTheme="outline"></i>No data</div>
        </ng-template>
        <ng-template #tplLoading>
          <div class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
        </ng-template>
        <thead>
          <tr>
            <th>MBL#</th>
            <th>Warehouse</th>
            <th>Customer</th>
            <th>Quantity</th>
            <th>Intended Injection Date</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of listData; let i = index">
            <td>
              <a [routerLink]="getRouterLink(item)">{{ item.mblNumber || 'N/A' }}</a>
            </td>
            <td>
              <div>{{ item.displayInfo.warehouseName }}</div>
            </td>
            <td>
              <div>{{ item.displayInfo.clientName }}</div>
              <div class="description">{{ item.displayInfo.clientAddr }}</div>
            </td>
            <td>
              <div>{{ item.displayInfo.totalCartons }} Cartons </div>
              <div class="description">{{ item.displayInfo.totalCartonsInPallet }} in {{ item.displayInfo.totalPallets }} pallets • {{ item.displayInfo.totalLoosedCartons }} separate</div>
            </td>
            <td>
              <div>{{ item.displayInfo.intendedInjectionDate }}</div>
            </td>
          </tr>
        </tbody>
      </nz-table>
    </div>
  </div>
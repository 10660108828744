<div class="parent">
  <nz-page-header
      class="site-page-header"
      (nzBack)="onBack()"
      nzBackIcon
      nzTitle="{{header}}"
      nzSubtitle=""
    ></nz-page-header>
    <div audit-log-list #auditLogList [entity]="entity" [objectId]="id" (onGetHeader)="onGetHeader($event)" ></div>
</div>

<div class="form-header no-padding-bottom">
</div>
<div class="top20" nz-row >
  <div nz-col [nzMd]="6" [nzXs]="24">
    <div class="desktop">
      <div class="f20 bold">Carrier Pools</div>

      <div>Please click the map to select the pools.</div>
      <div class=" bottom20 selected-area-title">Selected Pool{{selectedPools?.length && selectedPools?.length > 1 ? 's' : ''}}:</div>
      <div class="selected-area">
        <div class="flex" style="flex-flow: wrap;">
          <ng-container *ngFor="let item of selectedPools">
            <div class="label-item"><span style="cursor: pointer; margin-right: 5px;" (click)="onSelectHightlight(item)">{{item.name}} ({{item.numberOfCarrier}} {{item.numberOfCarrier > 1 ? 'carriers': 'carrier'}} )</span><span class="icon-x" nz-icon nzType="close" nzTheme="outline" (click)="onDeletedSelectedItem(item)"></span></div>
          </ng-container>
        </div>
      </div>
      <div class="top20 bottom20 text-center" style="margin-right: 16px">
        <div nz-row  [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }">
          <div class="gutter-row" nz-col [nzXs]="12">
            <button style="width: 100%" nz-button nzShape="round"(click)="onBtnCancel()">Cancel</button>
          </div>
          <div  class="gutter-row" nz-col [nzXs]="12">
            <button  style="width: 100%" nz-button nzShape="round" nzType="primary" (click)="onBtnContinue()">Add</button>
          </div>
        </div>
      </div>
    </div>
    <div class="mobile bottom20">
      <div>Please click the map to select your service area.</div>
      <ng-container *ngIf="!selectedPools?.length">
        <div class="bold f20">Select service area...</div>
      </ng-container>
      <ng-container *ngIf="selectedPools?.length">
        <div class="bold f20">{{selectedPools?.length}} pools selected.</div>
      </ng-container>
    </div>
  </div>
  <div nz-col [nzMd]="18" [nzXs]="24">
    <div class="map-controls">
      <div class="parent-control flex">
        <div class="left-button">
          <ng-container>
            <button class="re-calculate" nz-button nzType="primary" (click)="onSelectAll()">Select All</button>
            <button class="re-calculate" nz-button nzType="primary" (click)="onReset()">Reset</button>
            <!-- <button class="re-calculate" nz-button nzType="primary" [nzLoading] = "calculateLoading" (click)="recalculateCoverage()">Re-Calculate</button> -->
          </ng-container>
        </div>
      </div>
    </div>
    <div #mapElm id="dispatch-map" style="width: 100%; margin-bottom: 20px;"></div>
    <div class="mobile">
      <div nz-row  [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }">
        <div class="gutter-row" nz-col [nzXs]="12">
          <button style="width: 100%" nz-button nzShape="round"(click)="onBtnCancel()">Back</button>
        </div>
        <div  class="gutter-row" nz-col [nzXs]="12">
          <button  style="width: 100%" nz-button nzShape="round" nzType="primary" (click)="onBtnContinue()">Continue</button>
        </div>
      </div>
    </div>
  </div>

<form nz-form [formGroup]="formInput!">
  <div *ngIf="'type' as key" class="form-item {{key}}">
    <div class="flex bottom15" style="justify-content: center;" [ngClass]="{ 'viewMode': viewMode }">
      <div class="rate-icon pointer like" [ngClass]="{'active': isLike}" (click)="onClickBtn('like')"></div>
      <div class="rate-icon pointer dislike" [ngClass]="{'active': isDislike}" (click)="onClickBtn('dislike')"></div>
    </div>
  </div>

  <div *ngIf="'note' as key" class="form-item {{key}}">
    <div *ngIf="!viewMode">
      <div class="form-label-v2 bottom5">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
      <nz-form-item>
        <nz-form-control>
          <textarea nz-input [formControlName]="key" [placeholder]="getPlaceHolder(key)" [nzAutosize]="{minRows: 3, maxRows: 6}"></textarea>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div *ngIf="viewMode">
      <div class="form-label-v2 bottom5 medium">Review:</div>
      <div>{{getItemValue(key)}}</div>
    </div>
  </div>
</form>
import { Component } from "@angular/core";
import { BaseDetail } from "@app/admin/base/detail";
import { ActivatedRoute } from "@angular/router";
import { ApiService } from "@services/api.service";
import { Const } from "@const/Const";
import { Log } from "@services/log";
import { Subscription } from "rxjs";
import { BizUtil } from "@services/biz";


@Component({
  selector: "app-dat-load",
  templateUrl: "./dat-load.component.html",
  styleUrls: ["./dat-load.component.scss"],
})
export class DatLoadComponent extends BaseDetail {
  isError = false;
  isLoading = false;

  carrierBidId: string;
  data: any;
  bid: any;

  jobHyperLink: any;

  protected subscribeTopbarTitle(fn: Function) {
    return fn("Carrier Sales > DAT Posting");
  }

  constructor(activatedRoute: ActivatedRoute, api: ApiService) {
    super(activatedRoute);
    this.api = api;
    this.carrierBidId = this.getRouteParam(this.activatedRoute, "id");
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.getBid();
  }

  getApiUrl() {
    return "";
  }

  getBid() {
    this.isLoading = true;
    this.api.GET(`${Const.APIURI_CARRIER_BIDS}/${this.carrierBidId}`).subscribe(
      (response) => {
        this.jobHyperLink = BizUtil.createHyperLinkForJob(response.data?.job);
        this.bid = response.data;
        this.getDetail();
      },
      (error) => {
        this.showErr(error);
      }
    );
  }

  getDetail() {
    this.isLoading = true;

    this.api.GET(`${Const.APIURI_DAT_V2}/carrier-bid/${this.carrierBidId}`).subscribe(
      (response) => {
        this.data = response.data || {};
        this.isLoading = false;
      },
      (error) => {
        this.getInit();
      }
    );
  }

  getInit() {
    this.isLoading = true;
    this.api.GET(`${Const.APIURI_DAT_V2}/carrier-bid/${this.carrierBidId}/init`).subscribe(
      (response) => {
        this.data = response.data || {};;
        this.isLoading = false;
      },
      (error) => {
        this.showErr(error);
      }
    );
  }

  onBack() {
    this.router.navigate([this.routeAdminCarrierSales, this.carrierBidId]);
  }
}

import { IconLayer } from '@deck.gl/layers'

const PIN_ICON_MAPPING = {
    red: { x: 0, y: 0, width: 36, height: 64, mask: false, anchorY: 64 },
    green: { x: 40, y: 0, width: 36, height: 64, mask: false, anchorY: 64 },
    blue: { x: 80, y: 0, width: 36, height: 64, mask: false, anchorY: 64 },
};

export default function createTruckLayer(locations) {
    if (!locations || !locations.length) return null
    const data = locations.map(it => {
        return {
            coordinates: [it.longitude, it.latitude],
        }
    })

    return new IconLayer({
        id: `truck-icon-layer`,
        data,
        pickable: true,
        iconAtlas: '/assets/svg/pin.svg',
        iconMapping: PIN_ICON_MAPPING,
        getIcon: d => 'red',
        sizeScale: 15,
        getPosition: d => d.coordinates,
        getSize: d => 1.5,
    });
}
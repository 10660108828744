import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'display-service-options',
  templateUrl: './display-service-options.component.html',
  styleUrls: ['./display-service-options.component.scss']
})
export class DisplayServiceOptionsComponent implements OnInit {

  @Input() data: any = {};
  constructor() { }

  ngOnInit(): void {
  }

  shouldShowServiceOptionToolTip() {
    if (this.data.serviceOptions_Short && this.data.serviceOptions_Full.length > 1) return true;
    return false;
  }
}

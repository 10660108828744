import { DateUtil } from "@services/date-utils"
import { LatLng } from "@wearewarp/types/data-model"

export default class Utils {
    public static latLngId(latLng: LatLng) {
        if (!latLng?.latitude || !latLng?.longitude) return 'LOC_0::0'
        return `LOC_${Math.round(latLng?.latitude * 10000)}:${Math.round(latLng?.longitude * 10000)}`
    }

    public static latLngId2(latlng) {
        const { lat, lng } = latlng || {}
        if (!lat || !lng) return 'LOC_0::0'
        return `LOC_${Math.round(lat * 10000)}:${Math.round(lng * 10000)}`
    }

    public static calculateAddressLocationId(addr) {
        const { metadata } = addr || {}
        const { latitude, longitude } = metadata || {}
        return Utils.latLngId({latitude, longitude})
    }

    public static getAdressLatLng(addr, long = true) {
        const { metadata } = addr || {}
        const { latitude, longitude } = metadata || {}
        if (long)
            return {latitude: latitude, longitude: longitude}
        else
            return {lat: latitude, lng: longitude}
    }

    public static getAdressLngLat(addr) {
        const { metadata } = addr || {}
        const { latitude, longitude } = metadata || {}
        if (latitude && longitude) return [longitude, latitude]
        else return null
    }

    public static getDisplayTime(info, defaultValue: string = 'N/A') {
        if (info.skipAppointment) {
            return `Time is not required`
        }
        if (info.appointmentInfo?.from) {
            return `Appt: ` + DateUtil.displayTimeWindow(info.appointmentInfo, {
                timezone: info.addr?.metadata?.timeZoneStandard,
                formatDateOnly: 'MM/DD',
                format: 'MM/DD, h:mm A',        
            })
        }
        const t = (info.windows || [])[0]
        if (t?.from) {
            return `Planned: ` + DateUtil.displayTimeWindow(t, {
                timezone: info.addr?.metadata?.timeZoneStandard,
                formatDateOnly: 'MM/DD',
                format: 'MM/DD, h:mm A'
            })
        }
        return defaultValue
    }

    public static getDisplayDate(info) {
        if (info.skipAppointment) {
            return `N/A`
        }
        if (info.appointmentInfo?.from) {
            return DateUtil.displayLocalTime(info.appointmentInfo.from, {
                timezone: info.addr?.metadata?.timeZoneStandard,
                format: 'YYYY-MM-DD'
            })
        }
        const t = (info.windows || [])[0]
        if (t?.from) {
            return DateUtil.displayLocalTime(t.from, {
                timezone: info.addr?.metadata?.timeZoneStandard,
                format: 'YYYY-MM-DD'
            })
        }
        return 'N/A'
    }
}
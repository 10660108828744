import { BaseComponent } from "@abstract/BaseComponent";
import { Component, Input } from "@angular/core";
import { DateUtil } from "@services/date-utils";
import { RawEventJsonContainer } from "./raw";
import { Utils as ServiceUtils } from "@services/utils";
import { EventService } from "@services/event.service";

@Component({
    selector: "event-detail-container",
    templateUrl: "./detail.html",
    styleUrls: ["./detail.scss"],
})
export class EventDetailContainer extends BaseComponent {
    _event: any
    displayInfo: any = {}
    eventService: EventService
    canReprocess = false
    get event() {
        return this._event
    }
    @Input() set event(v) {
        this._event = v
        this.processData()
    }

    constructor() {
        super()
        this.eventService = new EventService(this.api)
        this.canReprocess = this.isSysAdmin
    }

    processData() {
        this.displayInfo = {
            ts: DateUtil.displayLocalTime(this._event?.event?.ts, { format: 'MM/DD hh:mm:ss.SSS A' }),
            action: this._event?.event?.action,
        }
    }

    showEvent(event) {
        let reprocessing = false
        const modal = this.modalService.create({
            nzContent: RawEventJsonContainer,
            nzCancelText: 'Close',
            nzOkText: 'Copy',
            nzWidth: '640px',
            nzOnOk: () => {
            },
            nzFooter: [
                {
                    label: 'Close',
                    onClick: () => modal.destroy()
                },
                {
                    label: 'Resend',
                    disabled: !this.canReprocess || reprocessing,
                    loading: reprocessing,
                    onClick: () => {
                        reprocessing = true
                        this.eventService.reprocessEvent(event.id).subscribe((res) => {
                            reprocessing = false
                        })
                    }
                },
                {
                    label: 'Copy',
                    type: 'primary',
                    onClick: () => {
                        ServiceUtils.copyTextToClipboard(JSON.stringify(event), (e) => {
                            if (e) {
                                this.showErr("Cannot copy to clipboard");
                            } else {
                                this.showSuccess(
                                    `Copied to clipboard`
                                );
                            }
                        });
                        return false
                    }
                },
            ],
            nzComponentParams: {
                event: event,
            }
        })
    }
}  
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Const } from '@const/Const';
import { DateUtil } from '@services/date-utils';

@Component({
  selector: 'date-time-str',
  template: '<span *ngIf="display">{{display}}</span>',
  styleUrls: []
})
export class DateTimeStr extends Component  {
    _data: { time: any, format?: string, tz?: string | null} = null

    @Input() set data(v) {
        this._data = v

        this.display = v.time ? DateUtil.displayLocalTime(v.time, {
            format: v.format ?? Const.FORMAT_GUI_DATETIME_V5,
            timezone: v.tz
        }) : null
    }

    display: string | null = null    
}
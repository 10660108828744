<ng-template [nzModalTitle]>
  <div class="flex-space-between">
    <div>{{ headerText }}</div>
  </div>
</ng-template>
<div *ngIf="timezone" class="flex center-vertical bottom20" style="color: #6b6b6b">
  <span nz-icon nzTheme="outline" nzType="clock-circle"></span>
  <div class="left5">Timezone: {{ timezoneText }}</div>
</div>
<div>
  <form [formGroup]="formInput" class="form-detail" nz-form>
    <div>
      <div>
        {{ getLabel("startDate") }}
        <span *ngIf="isRequired('startDate')" class="label-mark-required"></span>
      </div>
      <div class="top10">
        <nz-date-picker [nzShowToday]="false" class="full-w" formControlName="startDate"
                        nzFormat="MM-dd-yyyy"></nz-date-picker>
      </div>
    </div>
    <div class="top20">
      <div>
        {{ getLabel("endDate") }}
        <span *ngIf="isRequired('endDate')" class="label-mark-required"></span>
      </div>
      <div class="top10">
        <nz-date-picker [nzDisabledDate]="disabledEndDate" [nzShowToday]="false"
                        class="full-w" formControlName="endDate" nzFormat="MM-dd-yyyy"></nz-date-picker>
      </div>
    </div>
  </form>
</div>
<ng-template [nzModalFooter]>
  <div>
    <div (onCancel)="closeDialog()" (onOK)="clickEdit()"
         [canClickCancel]="!onProgress" [canClickOK]="needUpdate && !onProgress"
         [labelOK]="'Save'"
         [nzIconCancel]="null" [nzIconOK]="null"
         [onProgress]="onProgress" form-footer></div>
  </div>
</ng-template>

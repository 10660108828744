import {Component, EventEmitter, Input, Output} from '@angular/core';
import {BaseComponent} from '@abstract/BaseComponent';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Const} from '@const/Const';

@Component({
  selector: '[warehouse-rate-adjustment-storage-fee]', templateUrl: './index.html', styleUrls: ['./style.scss'],
})
export class WarehouseRateAdjustmentStorageFee extends BaseComponent {
  @Input() warehouseId: string;
  @Input() planId: string;
  @Input() rateAdjustment: any;
  @Input() canEdit: boolean;
  @Input() canAdd: boolean;
  @Input() feeType: any;
  @Output() onDataUpdate: EventEmitter<any> = new EventEmitter<any>();
  isEditing = false;
  isLoading = false;
  canSave = false;
  canAddTier = true;
  originalFormValue: any;
  myForm: FormGroup;

  constructor(private formBuilder: FormBuilder) {
    super();
  }

  _uomType: any;

  get uomType() {
    return this._uomType;
  }

  @Input() set uomType(value) {
    let change = this._uomType != value;
    this._uomType = value;
    if (change) {
      this.setupForm();
    }
  }

  get liveExplanationText() {
    const startingPrice = this.myForm.get('startingPrice').value || '0';
    const tierList = this.tierList.getRawValue().filter(item => item.day);
    const unit = this.uomType?.label?.toLowerCase();
    if (tierList.length == 0) {
      return `The storage price is <strong>$${startingPrice}</strong> per ${unit} per day for the entire duration.
            <br>If you wish to apply a different price for items based on stay duration, please click <strong>${this.isEditing ? 'Add Tier' : 'Edit'}</strong>.
            <br>*/ Weekend and US Holiday are excluded.`;
    }

    const firstTier = tierList[0];
    const firstCount = firstTier.day - 1;

    let explanationText = `The storage price changes depending on how long items are stored:`;

    explanationText += `<br> • First ${firstCount == 1 ? 'day' : `<strong>${firstCount}</strong> days`}: <strong>$${startingPrice}</strong> per ${unit} ${firstCount == 1 ? '' : 'per day'}`;

    for (const tier of tierList) {
      explanationText += `<br> • From day <strong>${tier.day}th</strong>: <strong>$${tier.price || '0'}</strong> per ${unit} per day`;
    }

    explanationText += `<br>*/ Weekend and US Holiday are excluded.`;

    return explanationText;
  }

  get tierList(): FormArray {
    return <FormArray> this.myForm.get('tierArray');
  }

  clickAddTier() {
    if (this.isLoading) {
      return;
    }
    if (!this.isTiersFollowingSequence()) {
      this.showErr('"From day" must be entered sequentially from small to large!');
      return;
    }
    if (!this.myForm.valid && this.isFormDirty()) {
      this.showErr('Invalid input!');
      return;
    }
    this.myForm.updateValueAndValidity();
    this.tierList.push(this.getInitTierForm());
  }

  trackByFn(index: number, item: any): number {
    return index;
  }

  clickEdit() {
    this.isEditing = true;
    if (this.canEdit) {
      this.myForm.enable();
      if (this.getInitTierList().length == 0) {
        this.clickAddTier();
      }
    }
  }

  clickCancel() {
    if (this.isLoading) {
      return;
    }
    this.setupForm();
  }

  clickSave() {
    if (this.isLoading) {
      return;
    }
    this.myForm.updateValueAndValidity();
    this.doSaveAdjustment();
  }

  clickDeleteTier(index: number) {
    if (this.isLoading) {
      return;
    }
    this.tierList.removeAt(index);
    this.checkFormCanSave();
  }

  getStartingPriceControl(): FormControl {
    return <FormControl> this.myForm.get('startingPrice');
  }

  getStartingPriceErrorMsg(): string {
    const control = this.getStartingPriceControl();

    if (control.hasError('required')) {
      return 'Starting price is required';
    }

    if (control.hasError('min')) {
      return 'Value must be greater than or equal to 0';
    }

    if (control.hasError('max')) {
      return 'The entered value is too large';
    }

    return '';
  }

  getFromDayControl(tierGroup): FormControl {
    return <FormControl> tierGroup.get('day');
  }

  getFromDayErrorMsg(tierGroup, index) {
    const control = this.getFromDayControl(tierGroup);
    if (control.hasError('required')) {
      return 'From day is required';
    }

    if (control.hasError('max')) {
      return 'The entered value is too large';
    }

    if (control.hasError('min')) {
      let failedNumber = 1;
      if (index > 0) {
        const previousTierControl = this.tierList.at(index - 1).get('day');
        failedNumber = Number(previousTierControl.value || '0');
      }
      return `Value must be greater than ${failedNumber}`;
    }
    if (!control.hasError('min') && index > 0) {
      const previousTierControl = this.tierList.at(index - 1).get('day');
      const currentDay = Number(control.value || '0');
      const previousDay = Number(previousTierControl.value || '0');
      if (currentDay <= previousDay) {
        control.clearValidators();
        control.setValidators([...this.getFromDayValidators(), Validators.min(previousDay + 1)]);
        setTimeout(() => {
          control.updateValueAndValidity();
        });
      }
    }

    return '';
  }

  getPriceControl(tierGroup): FormControl {
    return <FormControl> tierGroup.get('price');
  }

  getPriceErrorMsg(tierGroup) {
    const control = this.getPriceControl(tierGroup);
    if (control.hasError('required')) {
      return 'Price is required';
    }

    if (control.hasError('min')) {
      return 'Value must be greater than 0';
    }

    if (control.hasError('max')) {
      return 'The entered value is too large';
    }

    return '';
  }

  private setupForm() {
    //init form
    this.myForm = this.formBuilder.group({
      startingPrice: this.getFormStartingPriceInstance(this.getInitStartingPrice()),
      tierArray: this.formBuilder.array(this.getInitTierFormList())
    });
    // check form value change
    this.originalFormValue = this.myForm.getRawValue();
    this.myForm.valueChanges.subscribe(value => {
      this.checkFormCanSave();
      this.canAddTier = !this.isFormDirty() || this.myForm.valid && this.isTiersFollowingSequence();
    });
    // disable for first time to view
    this.myForm.disable();
    this.isEditing = false;
  }

  private checkFormCanSave() {
    this.canSave = this.myForm.valid && this.isFormDirty();
  }

  private isFormDirty() {
    return JSON.stringify(this.originalFormValue) !== JSON.stringify(this.myForm.getRawValue());
  }

  private isTiersFollowingSequence(): boolean {
    const tiers = this.tierList.getRawValue();
    const days = tiers.map(tier => Number(tier.day || '0'));

    if (days.length < 2) {
      return true;
    }

    return days.slice(0, -1).every((day, index) => day < days[index + 1]);
  }

  private async doSaveAdjustment() {
    if (!this.isTiersFollowingSequence()) {
      this.showErr('"From day" must be entered sequentially from small to large!');
      return;
    }
    if (!this.warehouseId) {
      this.showErr('Missing warehouseId!');
      return;
    }
    this.isLoading = true;
    try {
      if (this.rateAdjustment) {
        this.rateAdjustment = await this.updateAdjustment(this.rateAdjustment.id);
      } else {
        this.rateAdjustment = await this.addNewAdjustment();
      }
      //update origin form and UI
      this.originalFormValue = this.myForm.getRawValue();
      this.isEditing = false;
      this.myForm.disable();
      // show message
      this.showSuccess(`${this.feeType.label} updated!`);
      // push event for parent component
      this.onDataUpdate.emit(this.rateAdjustment);
    } catch (e) {
      console.log(e);
      this.showErr(e);
    }
    this.isLoading = false;
  }

  private async addNewAdjustment() {
    const url = `${Const.APIURI_WAREHOUSE_RATE_PLAN}/${this.planId}/adjustments`;
    const formValue = this.myForm.getRawValue();
    const body = {
      unit: this.uomType?.value,
      categoryCode: this.feeType?.value,
      price: formValue.startingPrice,
      tiers: formValue.tierArray
    };
    const options = {customHeaders: {warehouseId: this.warehouseId}};
    const response = await this.api.POST(url, body, options).toPromise();
    return response.data;
  }

  private async updateAdjustment(id) {
    const url = Const.API_WAREHOUSE_MANAGEMENT(`rate_adjustments/${id}`);
    const formValue = this.myForm.getRawValue();
    const body = {price: formValue.startingPrice, tiers: formValue.tierArray};
    const options = {customHeaders: {warehouseId: this.warehouseId}};
    const response = await this.api.PUT(url, body, options).toPromise();
    return response.data;
  }

  private getInitStartingPrice() {
    return this.rateAdjustment?.price || 0;
  }

  private getInitTierList() {
    return this.rateAdjustment?.tiers || [];
  }

  private getInitTierFormList() {
    return this.getInitTierList().map(item => this.getInitTierForm(item.day, item.price));
  }

  private getInitTierForm(day: any = '', price: any = '') {
    return this.formBuilder.group({
      day: this.getFormDayInstance(day), price: this.getFormPriceInstance(price)
    });
  }

  private getFormStartingPriceInstance(value: any = '') {
    return this.formBuilder.control(value, [...this.getPriceValidators(), Validators.min(0)]);
  }

  private getPriceValidators() {
    return [Validators.required, Validators.max(999999), Validators.pattern(/^(0|[1-9]\d*)(\.\d+)?$/)];
  }

  private getFormPriceInstance(value: any = '') {
    return this.formBuilder.control(value, [...this.getPriceValidators(), Validators.min(0.01)]);
  }

  private getFormDayInstance(value: any = '') {
    return this.formBuilder.control(value, [...this.getFromDayValidators(), Validators.min(2)]);
  }

  private getFromDayValidators() {
    return [Validators.required, Validators.max(999999), Validators.pattern(/^[0-9]*$/)];
  }
}

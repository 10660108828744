import {Component} from '@angular/core';
import {Const} from '@const/Const';
import {BaseDetail} from '@base/detail';
import {DialogService} from '@dialogs/dialog.service';
import {AddRateAdjustment} from '@app/admin/crossdock/rate-configuration/add-rate';
import {EditRateAdjustment} from '@app/admin/crossdock/rate-configuration/edit-rate';
import {XDockDateHelper} from '@app/admin/crossdock/XDockDateHelper';

@Component({
  selector: '[warehouse-rate-plan-detail]', templateUrl: './index.html', styleUrls: ['./style.scss'],
})
export class WarehouseRatePlanDetail extends BaseDetail {
  public uomTypes = [];
  public adjustmentTypes = [];
  public listAdjustment = [];
  public selectedUomType;
  public warehouseId: string;
  public loadingCheckEditDuration = false;
  public canEditDuration = false;
  // private planList = [];
  private allDetailDataLoaded = false;
  private isRefresh = false;

  get warehouseTimezone() {
    return this.model?.metadata?.warehouse?.timeZone;
  }

  get timezoneText() {
    return XDockDateHelper.timezoneText(this.warehouseTimezone);
  }

  get warehouseName() {
    return this.model?.metadata?.warehouse?.name;
  }

  get isExpired() {
    return this.model?.status && this.model?.status === 'expired';
  }

  get allDataLoaded() {
    return !this.onProgress && this.allDetailDataLoaded && this.model;
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  public getDurationText() {
    return XDockDateHelper.getDurationText(this.model?.startDate, this.model?.endDate, this.warehouseTimezone);
  }

  public statusText() {
    switch (this.model?.status) {
      case 'apply_soon':
        return 'Apply Soon';
      case 'applying':
        return 'Applying';
      case 'expired':
        return 'Expired';
      default:
        return 'Unknown';
    }
  }

  getStatusColor() {
    switch (this.model?.status) {
      case 'apply_soon':
        return 'warning';
      case 'applying':
        return 'success';
      case 'expired':
        return 'default';
      default:
        return 'default';
    }
  }

  public isApplying() {
    return this.model?.status === 'applying';
  }

  public isApplySoon() {
    return this.model?.status === 'apply_soon';
  }

  // async clickEditDuration() {
  //   this.loadingCheckEditDuration = true;
  //   try {
  //     this.planList = await this.fetchPlanList();
  //     this.canEditDuration = this.checkCanEditDuration(this.model?.id);
  //   } catch (e) {
  //     console.log(e);
  //     this.showErr(e);
  //   }
  //   this.loadingCheckEditDuration = false;
  //   if (this.canEditDuration) {
  //     DialogService.openFormDialog1(EditPlanDuration, {
  //       nzComponentParams: {
  //         model: this.model, warehouseId: this.warehouseId, closeOnSuccess: true, updateSuccess: resp => {
  //           this.onBtnRefresh();
  //         }
  //       }, nzClassName: 'modal-no-padding warehouse-add-rate-plan-modal',
  //     });
  //   } else {
  //     this.showErr('Unable to edit the duration for this plan, please go back to the "Plan list" to check!');
  //   }
  // }

  clickChangeUomType(type) {
    if (!this.model?.id) {
      return;
    }
    this.router.navigate([this.routeAdminWarehouseRatePlan, this.model.id], {queryParams: {type: type.value}});
  }

  clickAddFee(item) {
    DialogService.openFormDialog1(AddRateAdjustment, {
      nzComponentParams: {
        warehouseId: this.warehouseId,
        planId: this.model.id,
        unit: this.selectedUomType?.value,
        categoryCode: item.value,
        categoryName: item.label,
        closeOnSuccess: true,
        createSuccess: resp => {
          const newRate = resp.data;
          this.addAdjustmentToList(newRate);
        }
      }, nzClassName: 'modal-no-padding warehouse-add-rate-plan-modal',
    });
  }

  getFeeList(typeItem) {
    return this.listAdjustment?.filter(item => item.categoryCode == typeItem.value && item.unit == this.selectedUomType?.value) || [];
  }

  // checkCanEditDuration(planId) {
  //   if (!this.planList || this.planList.length == 0) {
  //     return false;
  //   }
  //   const newestPlan = this.planList[0];
  //   return newestPlan.id == planId;
  // }

  getStorageRateAdjustment(typeItem) {
    const adjustmentList = this.getFeeList(typeItem);
    if (adjustmentList.length == 0) {
      return null;
    } else {
      return adjustmentList[0];
    }
  }

  onBtnRefresh() {
    this.isRefresh = true;
    super.onBtnRefresh();
  }

  clickEditFee(feeItem) {
    DialogService.openFormDialog1(EditRateAdjustment, {
      nzComponentParams: {
        model: feeItem,
        warehouseId: this.warehouseId,
        closeOnSuccess: true,
        updateSuccess: resp => {
          feeItem.name = resp.name;
          feeItem.code = resp.code;
          feeItem.price = resp.price;
        }
      }, nzClassName: 'modal-no-padding warehouse-add-rate-plan-modal',
    });
  }

  clickDeleteFee(feeItem) {
    if (!this.warehouseId) {
      this.showErr('Missing warehouseId!');
      return;
    }
    const url = Const.API_WAREHOUSE_MANAGEMENT(`rate_adjustments/${feeItem?.id}`);
    const options = {customHeaders: {warehouseId: this.warehouseId}};
    feeItem.deleting = true;
    this.api.DELETE(url, options).subscribe(
      resp => {
        feeItem.deleting = false;
        this.removeAdjustmentFromList(feeItem);
      }, err => {
        this.showErr(err);
        feeItem.deleting = false;
      }
    );
  }

  onStorageFeeUpdate(item) {
    this.updateAdjustment(item);

  }

  protected getApiUrl(): string {
    return Const.APIURI_WAREHOUSE_RATE_PLAN;
  }

  protected handleNavigationEnd(url: string, prevQueryParam: any) {
    super.handleNavigationEnd(url, prevQueryParam);
    this.selectUomType();
  }

  protected onGetDetailSuccess(data): any {
    this.warehouseId = data.warehouseId;
    this.fetchDetail(data.id);
    return super.onGetDetailSuccess(data);
  }

  private addAdjustmentToList(item) {
    if (!this.listAdjustment) {
      this.listAdjustment = [item];
    } else {
      this.listAdjustment.push(item);
    }
  }

  private updateAdjustment(updateItem) {
    const index = this.listAdjustment.findIndex(item => item.id === updateItem.id);
    if (index !== -1) {
      this.listAdjustment.splice(index, 1, updateItem);
    }
  }

  private removeAdjustmentFromList(item) {
    const index = this.listAdjustment.indexOf(item);
    if (index !== -1) {
      this.listAdjustment.splice(index, 1);
    }
  }

  private async fetchDetail(planId) {
    if (!this.warehouseId) {
      this.showErr('Missing warehouseId!');
      return;
    }
    this.startProgress();
    try {
      const notFetchUomTypes = this.uomTypes.length > 0 && !this.isRefresh;
      const uomTypesPromise = notFetchUomTypes ? Promise.resolve(this.uomTypes) : this.fetchUomTypes();
      const notFetchAdjustmentTypes = this.adjustmentTypes.length > 0 && !this.isRefresh;
      const adjustmentTypesPromise = notFetchAdjustmentTypes ? Promise.resolve(this.adjustmentTypes) : this.fetchAdjustmentTypes();
      const notFetchAdjustments = this.listAdjustment.length > 0 && !this.isRefresh;
      const listAdjustmentPromise = notFetchAdjustments ? Promise.resolve(this.listAdjustment) : this.fetchAllAdjustments(planId);
      // const planListPromise = this.fetchPlanList();
      [
        this.uomTypes,
        this.adjustmentTypes,
        this.listAdjustment,
        // this.planList
      ] = await Promise.all(
        [
          uomTypesPromise,
          adjustmentTypesPromise,
          listAdjustmentPromise,
          // planListPromise
        ]
      );
      // this.canEditDuration = this.checkCanEditDuration(planId);
      this.selectUomType();
      this.allDetailDataLoaded = true;
      this.isRefresh = false;
    } catch (e) {
      console.log(e);
      this.showErr(e);
    }

    this.stopProgress();
  }

  private selectUomType() {
    const typeFromUrl = this.getQueryParam('type');

    if (this.selectedUomType && this.selectedUomType?.value === typeFromUrl) {
      return;
    }

    if (this.uomTypes.length === 0) {
      return;
    }

    const firstType = this.uomTypes[0];
    const typeKey = typeFromUrl || firstType?.value;
    this.selectedUomType = this.uomTypes.find(item => item.value === typeKey);
  }

  private async fetchUomTypes() {
    const url = Const.APIV2(`${Const.APIURI_METADATA}/ORG_0/WAREHOUSE_MANAGING_UNITS/rate_adjustment_units`);
    const response = await this.api.GET(url).toPromise();
    const list = response?.value ? JSON.parse(response.value) : [];
    return list.filter(item => item.active) || [];
  }

  private async fetchAdjustmentTypes() {
    const url = Const.APIV2(`${Const.APIURI_METADATA}/ORG_0/WAREHOUSE_MANAGING_SERVICES_FEES/services_fees_crossdock`);
    const response = await this.api.GET(url).toPromise();
    const list = response?.value ? JSON.parse(response.value) : [];
    return list?.filter(item => item.active) || [];
  }

  private async fetchAllAdjustments(planId) {
    // params exam: ?categoryCode=handling-fee&unit=pallet
    const url = `${Const.APIURI_WAREHOUSE_RATE_PLAN}/${planId}/adjustments`;
    const response = await this.api.GET(url).toPromise();
    return response?.data?.list_data || [];
  }

  // private async fetchPlanList() {
  //   const url = `${Const.APIURI_WAREHOUSE_RATE_PLAN}?skip=0&limit=-1`;
  //   const options = {customHeaders: {warehouseId: this.warehouseId}};
  //   const response = await this.api.GET(url, options).toPromise();
  //   return response?.data?.list_data || [];
  // }
}

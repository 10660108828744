import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'time-duration',
  template: '{{display}}',
  styleUrls: []
})
export class TimeDuration extends Component {
    _seconds: number

    get seconds() {
        return this._seconds
    }
    @Input() set seconds(v) {
        this.display = this.seconds2duration(v, this._short)
    }

    _short: boolean
    get short() {
        return this._short
    }
    @Input() set short(v) {
        this._short = v
        this.display = this.seconds2duration(this._seconds, this._short)
    }

    display: string | null = null

    seconds2duration(seconds, short) {
        const s = seconds % 60
        const minutes = (seconds - s) / 60
        const m = minutes % 60
        const hours = (minutes - m) / 60
        const h = hours % 24
        const d = (hours - h) / 24
        const hourSymbol = short === false ? 'hours' : 'h'
        const minuteSymbol = short === false ? 'minutes' : 'm'
        if (d > 1) return `${d} days` + (h > 0 ? ` ${h}${hourSymbol}` : '')
        if (d == 1) return `1 day` + (h > 0 ? ` ${h}${hourSymbol}` : '')
        if (h > 0 && m < 1) return `${h}${hourSymbol}`
        if (h > 0) return `${h}${hourSymbol} ${m}${minuteSymbol}`
        return `${m}${minuteSymbol}`
    }
    
}
import { Component, Input } from "@angular/core";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { Observable } from "rxjs";
@Component({
  selector: 'change-carrier-for-driver',
  templateUrl: './view.html',
  styleUrls: ['./index.scss']
})
export class ChangeCarrierForDriver extends BaseFormDialog1 {

  @Input() onSave: (data) => Observable<any>;
  @Input() onRefresh: () => void;
  @Input() headerText = 'Change Carrier';

  protected formGroupDeclaration: FormGroupDeclaration = {
    carrierId: {label: 'Carrier', required: true},
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  get isCreateNew(): boolean {
    return !this.model;
  }

  onBtnSave() {
    if (!this.needUpdate) return;
    let data = this.getFormData_JSON(true);
    this.onProgress = true;
    this.onSave(data).subscribe(
      resp => {
        this.closeDialog();
        this.onProgress = false;
        this.onRefresh();
      }, err => {
        this.showErr(err);
        this.onProgress = false;
      }
    );
  }
    
}

import {Component, forwardRef, Input} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {AttachedFile} from '@wearewarp/types/data-model';
import {BaseInputComponent} from '../base-custom-input';
import {FormUtil} from '@services/form-util';
import {AttachedFileUtil} from '@services/attached-file-util';
import {DialogService} from '@dialogs/dialog.service';
import {CarrierDetailDocumentComponent} from './components/detail-document';
import filesize from 'filesize';
import {Utils} from '@services/utils';
import {DateUtil} from '@services/date-utils';
import {Const} from '@const/Const';

@Component({
  selector: 'setting-document',
  templateUrl: './index.html',
  styleUrls: ['./index.scss'],
  providers: [{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => CarrierDocument), multi: true}],
})
export class CarrierDocument extends BaseInputComponent {
  @Input() isNotHaveMcAndDot;

  public loadingKey = null;
  protected formGroupDeclaration: FormGroupDeclaration = {
    w9Document: {label: 'W-9 Document', type: 'array'},
    autoInsurance: {label: 'Auto Insurance', type: 'array'},
    generalInsurance: {label: 'General Insurance', type: 'array'},
    cargoInsurance: {label: 'Cargo Insurance', type: 'array'},
    authorityDocument: {label: 'Authority Document', type: 'array'},
    otherDocument: {label: 'Other Documents', type: 'array'},
  };

  get keys() {
    if (this.isNotHaveMcAndDot) return {
      w9Document: 'w9Document',
      autoInsurance: 'autoInsurance',
      otherDocument: 'otherDocument'
    }
    return {
      w9Document: 'w9Document',
      autoInsurance: 'autoInsurance',
      generalInsurance: 'generalInsurance',
      cargoInsurance: 'cargoInsurance',
      authorityDocument: 'authorityDocument',
      otherDocument: 'otherDocument'
    }
  };
  public documentTypes = Object.values(this.keys).map(item => {
    return {key: item, fileArrs: []};
  });

  private _isEditing = false;

  get isEditing() {
    return this._isEditing;
  }

  @Input() set isEditing(value) {
    this._isEditing = value;
  }

  isSameValue(value1, value2) {
    return Utils.compareObject(value1, value2);
  }

  getLabel(key: string): string {
    return FormUtil.getLabelForKey(key, this.formGroupDeclaration);
  }

  public attachedFileUrl(model: AttachedFile): string {
    return AttachedFileUtil.attachedFileUrl(model);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  delFile(index, key) {
    const file = this.selected?.[key]?.docs?.[index];
    if (file instanceof File) {
      this.confirmDeletion({
        message: `Delete file ${file.name}?`, txtBtnOk: 'Delete', fnOk: () => {
          this.selected[key]['docs'].splice(index, 1);
        }
      });
    } else {
      file.deleted = true;
    }
  }

  undoDelFile(index, key) {
    const file = this.selected?.[key]?.docs?.[index];
    delete file.deleted;
  }

  public isFileDeleted(index, key) {
    const file = this.selected?.[key]?.docs?.[index];
    return file.deleted == true;
  }

  getAttachedFileDesc(item) {
    if (!item) {
      return '';
    }
    return this.getVisibleFileName(`${item.name} (${this.displayFileSize(item.size)})`);
  }

  getDateRangeOfInsurance(doc, key) {
    if (!doc?.docs?.length) {
      return '';
    }
    let startDate = '', endDate = '', text = '';
    if (doc?.insurance?.startDate) {
      startDate = DateUtil.dateToString(doc?.insurance?.startDate, Const.FORMAT_GUI_DATE_V2);
      text = `Start ${startDate}`;
    }
    if (doc?.insurance?.endDate) {
      Const.CarrierStatus.DNU;
      endDate = DateUtil.dateToString(doc?.insurance?.endDate, Const.FORMAT_GUI_DATE_V2);
      text = `${text} → End ${endDate}`;
    }
    return text;
  }

  hasExpirationDate(doc) {
    return doc?.insurance?.startDate && doc?.insurance?.endDate;
  }

  isEmpty(doc) {
    return !doc || !doc.docs || doc.docs.length == 0;
  }


  checkShowDate(key) {
    let insurances = [this.keys.autoInsurance, this.keys.generalInsurance, this.keys.cargoInsurance, this.keys.authorityDocument, this.keys.otherDocument];
    // if (this.isNotHaveMcAndDot){
    //   insurances = [this.keys.autoInsurance, this.keys.otherDocument];
    // }
    return insurances.includes(key);
  }

  isRequiredDocument(key) {
    let insurances = [this.keys.autoInsurance, this.keys.generalInsurance, this.keys.cargoInsurance, this.keys.w9Document];
    if (this.isNotHaveMcAndDot){
      insurances = [this.keys.autoInsurance, this.keys.w9Document];
    }
    return insurances.includes(key);
  }

  checkExpired(item) {
    if (!item?.insurance?.endDate) {
      return false;
    }
    const now = new Date().toISOString();
    const endDate = new Date(item?.insurance?.endDate).toISOString();
    return endDate < now ? true : false;
  }

  isFile(item): boolean {
    return item instanceof File;
  }

  public onClickUpload(key) {
    DialogService.openFormDialog1(CarrierDetailDocumentComponent, {
      nzComponentParams: {
        model: this.selected?.[key] || {},
        key: key,
        modalTitle: this.getLabel(key),
        closeOnSuccess: true,
        requireDate: this.isRequiredDocument(key),
        onSave: (data) => {
          if (!this.selected) {
            this.selected = {};
          }
          this.selected[key] = data;
          this.onChange(this.selected);
          this.onTouch(this.selected);
        }
      },
      nzCentered: true,
    });
  }

  getVisibleFileName(name) {
    // limit length of string
    const limitLength = 42;
    if (name.length >= limitLength) {
      name = `${name.slice(0, limitLength - 16 - 3)}...${name.slice(-16)}`;
    }
    return name;
  }

  private displayFileSize(size, options?) {
    if (isNaN(Number(size))) {
      return '';
    }
    return filesize(size, options);
  }
}

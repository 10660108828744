import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { SearchBoxModule } from '@libs/search-box/search-box.module';
import { DialogsModule } from '@dialogs/dialogs.module';
import { DetailModule } from '../base/detail.module';
import { FormAddressUSModule } from '../base/form-address-us/module';
import { SelectBySearchingModule } from '@libs/select-client/module';
import { SharedModule } from '../shared/shared.module';
import { CommUnsubscribeList } from './list';
import { UnsubscribeForm } from './form';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NzCheckboxModule,
    NzRadioModule,
    NzTagModule,
    NzSwitchModule,
    NzDropDownModule,
    NzTableModule,
    NzButtonModule,
    NzSelectModule,
    NzIconModule,
    NzGridModule,
    NzInputModule,
    NzFormModule,
    NzPaginationModule,
    NzDatePickerModule,
    NzTimePickerModule,
    NzAutocompleteModule,
    SearchBoxModule,
    DialogsModule,
    DetailModule,
    FormAddressUSModule,
    SelectBySearchingModule,
    SharedModule,
  ],
  declarations: [
    CommUnsubscribeList,
    UnsubscribeForm
  ],
  exports: [
  ],
  providers: [
  ]
})
export class CommUnsubscribeModule { }
import { LatLng, Shipment } from "@wearewarp/types/data-model";
import { Deck, FlyToInterpolator } from '@deck.gl/core'
import mapboxgl from 'mapbox-gl'
import { MasterData } from "@services/master.data";
import { ElementRef } from "@angular/core";
import _ from 'underscore'
// import { Route } from "./route";
// import RouteLayer from "./route_layer";
import { Const } from "@const/Const";
import ShipmentLayer from "./shipment_layer";
import createTruckLayer from "./truck_layer";
import WarehouseLayer from "./warehouse_layer";
import HighlightedLocationsLayer from "./highlighted_locations_layer";
import RouteLayer from "./route_layer";
import { PALLETS } from "./colors";
import { getUserPreferences } from "@services/userPref.service";
import { getFeatureFlags } from '@services/feature-flag.service';
import Utils from "./utils";
import { BizUtil } from "@services/biz";
import JobLayer from "./job_layer";
import DriverLocationLayer from "./driver_locations_layer";
import LiveTrackingLayer from "./live_tracking_layer";
import ShipmentArcLayer from "./shipment/arc_layer";
import ShipmentDotLayer from "./shipment/dot_layer";
import RoutesTripLayer from "./route/trip_layer";
import { getInjector } from "@services/injector";
import { MapboxStaticService } from "../mapbox-static/service";

export default class DeliveryMap {
    private _shipments: any[] = []
    get shipments() {
        return this._shipments
    }
    set shipments(v) {
        this._shipments = v
        this.prepareShipmentLayers()
    }

    private warehouses: any[] = []
    private routes: any[] = []
    private jobs: any[] = []
    private truckLocations: LatLng[] = []
    private driverLocations: any[] = []
    private historicalDriverLocations: any[] = []
    private liveTrackingLocations: any[] = []
    private showDispatchRoute: boolean = false;
   
    deckgl: Deck = null
    map: any = null
    private highlightLocations: any[] = []
    private highlightRoutes: any[] = []
    timer: any
    currentTime: number = 0

    public static defaultInitialViewState = {
        longitude: -97.33953883423565,
        latitude: 39.079769526598014,
        zoom: 8,
        pitch: 60,
        bearing: 0
    }
    
    mapboxContainer: ElementRef;
    deckCanvas: ElementRef;
    onPickLocation: any = null
    onPickObject: any = null
    onClickLocation: any = null
    id: string = null
    config: any = {}
    initialViewState: any = null

    constructor(id, mapboxContainer, deckCanvas, initialViewState = DeliveryMap.defaultInitialViewState, config = {}) {
        this.id = id
        this.deckCanvas = deckCanvas
        this.mapboxContainer = mapboxContainer
        this.initialViewState = initialViewState || DeliveryMap.defaultInitialViewState
        this.onClick = this.onClick.bind(this);
        this.config = config

        // this.createDeckGl(initialViewState)

        this.setPitch = this.setPitch.bind(this)

        this.loadConfig().then((res) => {
            initialViewState.pitch = this.config.pitch ?? (res.pitch ?? initialViewState.pitch)
            this.createShipmentArcLayer()
            this.createDeckGl(initialViewState)
        }).catch(() => {
            this.createDeckGl(initialViewState)
        })

        this.tic = this.tic.bind(this)
        this.timer = setInterval(this.tic, 50)
    }

    destroy() {

        this.map?.remove()
        this.timer && clearInterval(this.timer)
    }

    tic() {
        let redraw = false
        redraw = redraw || this._routesLayer?.tic()
        redraw = redraw || this._shipmentArcLayer?.tic()

        if (redraw)
            this.refresh()
    }

    loadContainer(mapboxContainer, deckCanvas) {
        this.deckCanvas = deckCanvas
        this.mapboxContainer = mapboxContainer
        this.createDeckGl(this.initialViewState)

        // recreate layers
        this._highlightedLocationLayer = new HighlightedLocationsLayer(`${this.id}-hilight`, this.highlightLocations)
        this._highlightedRouteLayer = this.highlightRoutes?.map(it => new RouteLayer(`${this.id}-hilight-route-layer-${it?.id}`, it, {color: [255,100,100,90], minWidth: 16, fading: false})) || []
        this._warehouseLayer = this.warehouses ? new WarehouseLayer(`${this.id}-warehouse`, this.warehouses) : null
        this.createRoutesLayers()
        this.createShipmentArcLayer()
        this._shipmentDotLayer = new ShipmentDotLayer(`${this.id}-shipment-dot`, this._shipments)

        this._jobLayers = this.jobs?.map((it, index) => new JobLayer(`${this.id}-route-layer-${it?.id}`, it, {color: PALLETS[index % 6]})) || []

        this.refresh()
        setTimeout(() => this.fitBoundsToShipment(), 50)
    }

    async loadConfig() {
        const config: any = {
            animation: true
        }
        await getUserPreferences().getPref('PLANNING_MAP-hideArc').subscribe((res) => {
            config.hideArc = res === 'true'
            this.config = {...this.config, ...config}
        })
        await getUserPreferences().getPref('PLANNING_MAP-animation').subscribe((res) => {
            config.animation = res !== 'false'
            this.config = {...this.config, ...config}
        })
        await getUserPreferences().getPref('PLANNING_MAP-pitch').subscribe((res) => {
            const pitch = parseInt(res)
            config.pitch = pitch
        })
        return config
    }

    updateConfig(key, value) {
        this.config[key] = value
        getUserPreferences().setPref('PLANNING_MAP-' + key, value.toString())
        // this._shipmentLayer = new ShipmentLayer(`${this.id}-shipment`, this.shipments, this.config.hideArc)
        this.createShipmentArcLayer()
        this._shipmentDotLayer = new ShipmentDotLayer(`${this.id}-shipment-dot`, this._shipments)
        this.createRoutesLayers()

        this.refresh()
    }

    loadShipments(shipments: any[], refresh: boolean = false) {
        this.shipments = shipments
        this._shipmentLayer = new ShipmentLayer(`${this.id}-shipment`, this.shipments, this.config.hideArc, this.config?.onSelectShipment)
        if (refresh) {
            this.refresh()
        }
    }

    _shipmentArcData: any[] = null
    _shipmentArcLayer: ShipmentArcLayer = null
    _shipmentDotLayer: ShipmentDotLayer = null
    prepareShipmentLayers() {
        this._shipmentArcData = ShipmentArcLayer.prepareArcData(this.shipments)
        this.createShipmentArcLayer()
        this._shipmentDotLayer = new ShipmentDotLayer(`${this.id}-shipment-dot`, this._shipments)
        this.refresh()
    }

    loadHighlightLocations(locs, refresh: boolean = false) {
        this.highlightLocations = locs
        this._highlightedLocationLayer = new HighlightedLocationsLayer(`${this.id}-hilight`, this.highlightLocations)
        if (refresh) {
            this.refresh()
        }
    }

    loadTruckLocations(locs, refresh: boolean = false) {
        this.truckLocations = locs
        this._truckLayer = createTruckLayer(this.truckLocations)
        if (refresh) {
            this.refresh()
        }
    }

    loadHistoricalDriverLocations(locs, refresh: boolean = false) {
        this.historicalDriverLocations = locs
        this._historicalDriverLocationLayer = new DriverLocationLayer(`${this.id}-historical-driver-locations`, this.historicalDriverLocations)
        if (refresh) {
            this.refresh()
        }
    }

    loadDriverLocations(locs, refresh: boolean = false) {
        this.driverLocations = _.sortBy(locs, 'ts')
        this._driverLocationLayer = new DriverLocationLayer(`${this.id}-driver-locations`, this.driverLocations)
        if (refresh) {
            this.refresh()
        }
    }

    checkLiveUpdate() {
        if (!this.liveTrackingLocations?.length) return
        let updated = false
        for (let loc of this.liveTrackingLocations) {
            const live = loc.ts > Date.now() - 120000
            if (live !== loc.live) {
                loc.live = live
                updated = true
            }
        }
        if (updated) {
            this._liveTrackingLayer = new LiveTrackingLayer(`${this.id}-live-tracking`, this.liveTrackingLocations)
        }
    }

    loadLiveTrackingLocations(locs, refresh: boolean = false) {
        this.liveTrackingLocations = locs?.filter(it => it)
        this.checkLiveUpdate()
        
        if (refresh) {
            this.refresh()
        }
    }

    loadHighlightRoutes(routes, refresh: boolean = false) {
        this.highlightRoutes = routes
        this._highlightedRouteLayer = this.highlightRoutes?.map(it => new RouteLayer(`${this.id}-hilight-route-layer-${it?.id}`, it, {color: [255,100,100,90], minWidth: 16, fading: false})) || []
        if (refresh) {
            this.refresh()
        }
    }

    loadWarehouses(warehouses: any[], refresh: boolean = false) {
        this.warehouses = warehouses
        this._warehouseLayer = this.warehouses ? new WarehouseLayer(`${this.id}-warehouse`, this.warehouses) : null
        if (refresh) {
            this.refresh()
        }
    }

    loadRoutes(routes: any[], refresh: boolean = false) {
        if (!routes?.length) {
            this._routesLayer = null
            return
        }
        this.routes = routes.map(RoutesTripLayer.prepareRouteLayerData)
        for (let i = 0; i < routes.length; i++) {
            this.routes[i].color = PALLETS[i % PALLETS.length]
        }
        this.createRoutesLayers()
        if (refresh) {
            this.refresh()
        }
    }

    createShipmentArcLayer() {
        if (this.config.hideArc) {
            this._shipmentArcLayer = null
            return
        }
        this._shipmentArcLayer = new ShipmentArcLayer(`${this.id}-shipment-arc`, {shipments: this._shipmentArcData}, {selectShipment: this.config?.onSelectShipment, animation: this.config?.animation ? -4 : 0, baseOpacity: 0.6})
    }

    createRoutesLayers() {
        this._routesLayer = new RoutesTripLayer(
            `${this.id}-routes-layer`,
            {
                routes: this.routes,
                currentTime: this.currentTime
            },
            {selectRoute: this.config?.onSelectRoute, animation: this.config?.animation}
        )
    }

    loadJobs(jobs: any[], refresh: boolean = false) {
        this.jobs = jobs
        this._jobLayers = this.jobs?.map((it, index) => new JobLayer(`${this.id}-route-layer-${it?.job?.id}`, it, {pathLayer: PALLETS[index % 6]})) || []
        if (refresh) {
            this.refresh()
        }
    }

    reset() {
        this.loadShipments([])
        this.loadHighlightLocations([])
        this.loadTruckLocations([])
        this.loadHighlightRoutes([])
        this.loadWarehouses([])
        this.loadRoutes([])
        this.refresh()
    }

    fitBoundsToShipment() {
        if (!this.shipments?.length) return
        const locations = _.flatten(this.shipments.map(it => {
            const pickup = Utils.getAdressLatLng(BizUtil.getPickInfo(it)?.addr, false)
            const dropoff = Utils.getAdressLatLng(BizUtil.getDropInfo(it)?.addr, false)
            return [pickup, dropoff]
        }))
        this.fitBounds(locations)
    }

    fitBoundsToJobs() {
        if (!this.jobs?.length) return
        const locations = _.flatten(this.jobs.map(it =>
            it.tasks?.map(t => JobLayer.getLnglat(t)).map(t => Object.assign({}, {
                lat: t[1],
                lng: t[0]
            })) ?? []
        ))
        this.fitBounds(locations)
    }

    fitBounds(bounds) {
        if (!this.deckgl) return
        if (!bounds || !bounds.length) return
        const minLng = _.min(bounds.map(it => it.lng ?? it.longitude))
        const maxLng = _.max(bounds.map(it => it.lng ?? it.longitude))
        const minLat = _.min(bounds.map(it => it.lat ?? it.latitude))
        const maxLat = _.max(bounds.map(it => it.lat ?? it.latitude))

        const currentViewport = this.deckgl.getViewports()[0]
        if (!currentViewport) return
        let fitViewport = currentViewport.fitBounds([[minLng, minLat], [maxLng, maxLat]], {padding: 50, maxZoom: 13})
        this.deckgl.setProps({initialViewState: {
            latitude: fitViewport.latitude,
            longitude: fitViewport.longitude,
            zoom: fitViewport.zoom,
            pitch: currentViewport.pitch,
            transitionDuration: 1000,
            transitionEasing: x => x < 0.5 ? 2 * x * x : 1 - Math.pow(-2 * x + 2, 2) / 2,
            transitionInterpolator: new FlyToInterpolator()
        }})
    }

    setPitch(pitch) {
        getUserPreferences().setPref('PLANNING_MAP-pitch', pitch.toString())
        this.setPitch_(pitch)
    }

    setPitch_(pitch) {
        const currentViewport = this.deckgl.getViewports()[0]
        this.deckgl.setProps({initialViewState: {
            latitude: currentViewport.latitude,
            longitude: currentViewport.longitude,
            zoom: currentViewport.zoom,
            pitch: pitch,
            transitionDuration: 500,
            transitionInterpolator: new FlyToInterpolator()
        }})
    }

    redrawMapbox(map: any) {
        if (map.style) {
            if (map._frame) {
                map._frame.cancel();
                map._frame = null;
            }
            map._render();
        }
    }

    createMapBox(initialViewState) {
        let map;
        if(!this.config?.mapStatic){
            mapboxgl.accessToken = MasterData.mapboxToken;
            map = new mapboxgl.Map({
                container: this.mapboxContainer.nativeElement,
                style: Const.MAPBOX_STYLE(this.config.style || 'light-v10') || Const.MAPBOX_STYLE('light-v10'),
                // style: 'https://basemaps.cartocdn.com/gl/positron-gl-style/style.json',
                center: <any>[initialViewState.longitude, initialViewState.latitude],
                zoom: initialViewState.zoom,
                pitch: initialViewState.pitch,
                interactive: false,
            });
            map.remove = () => {
                //không remove để tránh lỗi với static map
                // map.remove();
            }
            return map;
        }
        else{
            let mapboxStaticService = getInjector().get(MapboxStaticService);
            map = mapboxStaticService.getMap();
            mapboxStaticService.attachMap(this.mapboxContainer.nativeElement)
        }
        map.on('click', 'points', (e) => {
            e.originalEvent.stopPropagation();
        })
        map.addControl(new mapboxgl.ScaleControl({unit: 'imperial'}));
        return map
    }

    createDeckGl(initialViewState) {
        if (!this.mapboxContainer?.nativeElement) return
        if (!this.deckCanvas?.nativeElement) return
        const map = this.createMapBox(initialViewState);
        this.deckgl = new Deck(
            {
                canvas: this.deckCanvas.nativeElement,
                initialViewState: initialViewState,
                controller: true,
                layers: [],
                pickingRadius: 3,
                getTooltip: ({ object, x, y }) => {
                    if (!object) return null
                    if (this.showDispatchRoute && object?.job?.id) {
                        this.onClickLocation && this.onClickLocation({x, y, job: object?.job})
                        return null;
                    }
                    if (object.info) return object.info
                    if (object.getInfo) {
                        const info = object.getInfo(object)
                        return info
                    }
                },
                onBeforeRender: () => {
                    if (this.deckgl) {
                        const viewport = this.deckgl.getViewports()[0];
                        if (viewport)
                        map.jumpTo({
                            center: [viewport.longitude, viewport.latitude],
                            zoom: viewport.zoom,
                            bearing: viewport.bearing,
                            pitch: viewport.pitch
                        });
                        this.redrawMapbox(map);
                    }
                },
                onClick: this.onClick
            },
        )
        this.map = map
        getFeatureFlags().isFlagSetNonBlocking('SIDEBAR_V2').subscribe((res) => {
            setTimeout(() => this.map?.resize(), 10)
        })
    }

    resize() {
        setTimeout(() => this.map?.resize(), 10)
    }

    onClick(info, event) {
        const { coordinate, picked, object, x, y } = info
        event.srcEvent.stopPropagation()
        if (this.showDispatchRoute) {
            return this.onClickLocation && this.onClickLocation({x, y, job: object?.job})
        }
        if (coordinate) {
            this.onPickLocation && this.onPickLocation({ longitude: coordinate[0], latitude: coordinate[1] })
        }
        if (picked && object) {
            this.onPickObject && this.onPickObject({coordinate: { longitude: coordinate[0], latitude: coordinate[1] }, object})
        }
       
    }

    highlightShipment(shipment) {
        if (!shipment) {
            this.loadHighlightLocations([])
            this.refresh()
            return
        }

        const { deliveryInfos } = shipment || {}
        const locations = (deliveryInfos || []).map(info => info.addr.metadata)
        this.loadHighlightLocations(locations)
        this.refresh()
    }

    zoomShipment(shipment) {
        const pickup = Utils.getAdressLatLng(BizUtil.getPickInfo(shipment)?.addr, false)
        const dropoff = Utils.getAdressLatLng(BizUtil.getDropInfo(shipment)?.addr, false)
        const locations = [pickup, dropoff]
        this.fitBounds(locations)
    }

    _warehouseLayer: any = null
    _shipmentLayer: any = null
    _highlightedLocationLayer: any = null
    _routeLayers = []
    _jobLayers = []
    _truckLayer = null
    _highlightedRouteLayer =  []
    _driverLocationLayer: any = null
    _historicalDriverLocationLayer: any = null // no update
    _liveTrackingLayer: any = null
    _routesLayer: RoutesTripLayer = null

    refresh() {
        this.deckgl?.setProps({
            layers: [
                ...this._highlightedRouteLayer,
                this._historicalDriverLocationLayer,
                this._driverLocationLayer,
                this._routesLayer?.deckglLayers,
                ...this._jobLayers,
                this._highlightedLocationLayer,
                this._truckLayer,
                this._shipmentDotLayer,
                this._shipmentArcLayer?.deckglLayer,
                this._warehouseLayer,
                this._liveTrackingLayer
            ]
        })
    }
    public setShowDispatchRoute(value: boolean) {
        return this.showDispatchRoute = value;
    }
}